import React from "react";
import Header from "../../components/Header";
import IMAGES from "@utils/Images";

import "./Error.css";
import { useNavigate } from "react-router-dom";

const Error: React.FC = () => {
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate('/');
};

  return (
    <div>
      <Header onToggleSidebar={() => {}} />
      <div className="error-page-container">
        <div className="error-content">
          <div className="error-message">
            <h1>Oops! Something Went Wrong</h1>
            <h2>Error 404! Page not found</h2>
            <p>
              We can’t seem to find the page you’re looking for. Are you sure
              the website URL is correct?
            </p>
            <button className="home-button" onClick={handleGoBack}>Go Back to Home</button>
          </div>
          <div className="error-graphic">
            <img src={IMAGES.ERROR} alt="Logo" className="h-5 w-5" />
          </div>
        </div>
      </div>
      {/* <footer>
        Contact Support
      </footer> */}
    </div>
  );
};

export default Error;

import {
  Login,
  CreatePassword,
  UpdateUserPayload,
  DayScheduleArrayItem,
  FetchUsersPayload,
  CreateUserPayload,
  CreateCohortPayload,
  LearnerUploadAssignment,
  FetchTutorAIList,
  signatureUploadAssignment,
  ResetPasswordPayload,
  HomeUserPayload,
  FetchHomeUsersPayload,
} from "@modules/types/types";
import ApiRequest from "./ApiRequest";
import { API } from "./ApiConstants";
import axios from "axios";

var FormData = require("form-data");

export const fetch_user_login = async (value: Login) => {
  const response = await ApiRequest.post(API.LOGIN, {
    email: value?.email,
    password: value?.password,
    userType: value?.userType,
  });
  return response; // Here we are returning data check in Interceptors
};

export const reset_password = async (value: CreatePassword) => {
  const response = await ApiRequest.post(API.CHANGE_PASSWORD, {
    newPassword: value?.password,
    confirmPassword: value?.confirmPassword,
  });
  return response;
};

export const get_user_details = async (userId: string) => {
  const response = await ApiRequest.post(API.USER_ACCOUNT, { userId: userId });
  return response;
};

export const resend_credentials = async (user: string) => {
  const response = await ApiRequest.post(API.RESEND_LOGIN_CREDENTIALS, {
    user: user,
  });
  return response;
};

export const deactivate_user = async (userId: string, type: string) => {
  const response = await ApiRequest.patch(
    `${API.USER_ACTIVATION}/${userId}/${type}`
  );
  return response;
};

export const delete_user = async (userId: string) => {
  const response = await ApiRequest.delete(`${API.DELETE_USER}/${userId}`);
  return response;
};

export const update_user_details = async (value: UpdateUserPayload) => {
  const formData = new FormData();
  formData.append("image", value.image);
  formData.append("name", value.name);
  formData.append("phone", value.phone);
  formData.append("userId", value.userId);
  const response = await ApiRequest.put(API.USER_UPDATE, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  return response;
};

export const update_user_schedule = async (
  value: DayScheduleArrayItem[],
  userId: string
) => {
  const response = await ApiRequest.put(API.USER_SCHEDULE_UPDATE, {
    schedules: value,
    userId: userId,
  });
  return response;
};

export const create_user = async (value: CreateUserPayload) => {
  const params = value.values;
  const response = await ApiRequest.post(API.CREATE_USER, params);
  return response;
};

export const create_cohort = async (value: CreateCohortPayload) => {
  let params = value.values;
  const response = await ApiRequest.post(API.COHORT, params);
  return response;
};

export const get_users_list_cohort = async (
  userType: string,
  level: string
) => {
  const params = {
    userType: userType,
    level: level,
  };
  const response = await ApiRequest.get(API.USERS_LIST, { params });
  return response;
};

export const get_users = async (params: FetchUsersPayload) => {
  const response = await ApiRequest.get(API.GET_USERS, { params });
  return response;
};
export const get_home_users = async (params: FetchHomeUsersPayload): Promise<HomeUserPayload> => {
  const response = await ApiRequest.get(API.GET_USERS, { params });
  return { data: response.data }; // Adjust as per your API response
};

export const get_cohorts = async (params: FetchUsersPayload) => {
  const response = await ApiRequest.get(API.COHORT, { params });
  return response;
};

export const get_all_users = async (cohortId: string, userType: string, sortBy?: string) => {
  const params = {
    userType: userType,
    sortBy: sortBy
  }
  const response = await ApiRequest.get(`${API.COHORT}/${cohortId}/users`, { params });
  return response;
};

export const get_levels = async () => {
  const response = await ApiRequest.get(API.LEVEL);
  return response;
};

export const delete_learners_from_cohorts = async (
  cohortId: string,
  learnerIds: string[]
) => {
  const response = await ApiRequest.post(API.DELETE_COHORT_LEARNER, {
    cohortId: cohortId,
    learnerIds: learnerIds,
  });
  return response;
};

export const delete_cohort = async (cohortId: string) => {
  console.log("What is cohort id ?", cohortId);

  const response = await ApiRequest.delete(`${API.DELETE_COHORT}/${cohortId}`)
  console.log("responsee from delete_cohort", response);
  return response
}

export const get_grades_distribution = async (data) => {
  const response = await axios.post(`${process.env.REACT_APP_ANALYTICS_URL}/${API.GRADES_BY_LEVEL}`, data, {
    headers: { "Content-Type": "application/json" },
  })
  return response;
};

export const get_grades_distribution_dashboard = async (data) => {
  console.log("In get_grades_distribution_dashboard", data);

  const response = await axios.post(`${process.env.REACT_APP_ANALYTICS_URL}/${API.GRADES_DISTRIBUTION_DASHBOARD}`, data, {
    headers: { "Content-Type": "application/json" },
  })
  return response
}

export const get_total_active_users = async (data) => {
  const response = await axios.get(`${process.env.REACT_APP_ANALYTICS_URL}/${API.TOTAL_ACTIVE_USERS}?userType=${data.userType}`);

  return response
}
export const get_average_grade_percentage = async (data) => {
  console.log("🚀 ~ constget_average_grade_percentage= ~ data:", data)

  const response = await axios.post(`${process.env.REACT_APP_ANALYTICS_URL}/${API.AVERAGE_GRADE_PERCENTAGE}`, data, {
    headers: { "Content-Type": "application/json" },
  })
  console.log("🚀 ~ constget_average_grade_percentage= ~ response:", response)
  return response

}



export const get_correction_rate = async (data) => {
  const response = await axios.post(`${process.env.REACT_APP_ANALYTICS_URL}/${API.CORRECTION_RATE}`, data, {
    headers: { "Content-Type": "application/json" },
  })
  return response;
};

export const get_feedback = async (data) => {
  const response = await axios.post(`${process.env.REACT_APP_ANALYTICS_URL}/${API.FEEDBACK}`, data, {
    headers: { "Content-Type": "application/json" },
  })
  return response;
};

export const get_assignments_submission = async (data) => {
  const response = await axios.post(`${process.env.REACT_APP_ANALYTICS_URL}/${API.ASSIGNMENT_SUBMISSION}`, data, {
    headers: { "Content-Type": "application/json" },
  })
  return response;
};

export const get_tutor_AI_list = async (params: FetchTutorAIList) => {
  const response = await ApiRequest.get(API.TUTOR_AI, { params });
  return response;
};

export const move_learners_from_cohorts = async (payload: {
  newCohortId: string;
  oldCohortId: string;
  learnerIds: string[];
}) => {
  const response = await ApiRequest.post(API.MOVE_COHORT_LEARNER, payload);
  return response;
};

export const upload_learner_assignment = async (
  value: LearnerUploadAssignment
) => {
  const formData = new FormData();
  formData.append("uploadedAssignmentFile", value.uploadedAssignmentFile);
  formData.append("assessmentId", value.assessmentId);
  formData.append("assignmentBriefDetails", value.assignmentBriefDetails);
  formData.append("file_id", value.file_id);
  const response = await ApiRequest.post(API.UPLOAD_ASSIGNMENT, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  return response;
}
export const fetchAssignments = async (page: string, limit: string, search: string, filterLevel: [], filterStatus: [], sortBy: string) => {
  const response = await ApiRequest.get(API.ASSIGNMENTS, {
    params: { page, limit, search, filterLevel, filterStatus, sortBy },
  });

  return response;
};
export const fetchAssignmentData = async (page: number, limit: number, search: string, userType: string, sortBy: string) => {
  const response = await ApiRequest.get(API.ASSIGNMENT_DATA, {
    params: { page, limit, search, userType, sortBy },
  });
  return response;
};

export const get_assignments_grading = async (page, limit, search, filter, sort) => {
  const response = await ApiRequest.get(API.FETCH_ASSIGNMENT_GRADING, {
    params: { page, limit, search, filter, sort },
  });
  return response;
};

export const fetchLearnerAssignments = async (page: number, limit: number, search: string, type: string, filter: [], sortBy: string) => {
  const response = await ApiRequest.get(API.LEARNER_ASSIGNMENT, {
    params: { page, limit, search, type, filter, sortBy },
  });
  return response;
};

export const upload_signature = async (value: signatureUploadAssignment) => {
  const formData = new FormData();
  formData.append("signatureFile", value.signatureFile);
  formData.append("assignmentId", value.assignmentId);
  if (value.isUploaded) {
    formData.append("isUploaded", value.isUploaded);
  }
  const response = await ApiRequest.post(API.UPLOAD_SIGNATURE, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  return response;
};

export const fetch_signature = async (id: string) => {
  const response = await ApiRequest.get(`${API.FETCH_SIGNATURE}/${id}`);
  return response;
};

export const update_user_supervisor = async (value: UpdateUserPayload) => {
  const userId = localStorage.getItem("edmrki_dkey");
  const formData = new FormData();

  // Only append fields if they are defined
  if (value.image) formData.append("image", value.image);
  if (value.name) formData.append("name", value.name);
  if (value.phone) formData.append("phone", value.phone);
  if (value.email) formData.append("email", value.email);
  if (value.memberId) formData.append("memberId", value.memberId);
  if (value.level) formData.append('level', JSON.stringify(value.level));
  if (value.cohorts) formData.append('cohorts', JSON.stringify(value.cohorts));
  if (value.updateUserId) formData.append("updateUserId", value.updateUserId);

  // userId will always be present, so no need for a check
  formData.append("userId", userId);

  const response = await ApiRequest.put(API.UPDATE, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });

  return response;
};

export const get_user_details_by_userId = async (userId?: string) => {
  const response = await ApiRequest.get(`${API.USER}/${userId}`, {});
  return response;
};

export const get_notification = async () => {
  const response = await ApiRequest.get(API.SETTING_NOTIFICATION);
  return response;
};

export const update_notification = async (data: any) => {
  const response = await ApiRequest.put(`${API.UPDATE_SETTING_NOTIFICATION}`, {
    data,
  });
  return response;
};

export const get_updated_schedule = async (userId?: string) => {
  const response = await ApiRequest.get(
    `${API.GET_UPDATED_SCHEDULE}/${userId}`,
    {}
  );
  return response;
};

export const get_learner_assignments_grading = async (
  page,
  limit,
  search,
  filter,
  sort
) => {
  const response = await ApiRequest.get(API.FETCH_LEARNER_ASSIGNMENT_GRADING, {
    params: { page, limit, search, filter, sort },
  });
  return response;
};

export const get_assignment_job_id = async (level: string, fileId: string) => {
  const response = await axios.post(
    `${process.env.REACT_APP_ENGINE_URL}/${API.ASSIGNMENT_INFORMATION}-${level}?file_id=${fileId}`
  );
  return response;
};

export const get_update_feedback_job_id = async (
  updatedFeedback: any
) => {
  const response = await axios.post(
    `${process.env.REACT_APP_ENGINE_URL}/${API.UPDATE_FFEDBACK}`, updatedFeedback,
    {
      headers: { "Content-Type": "application/json" },
    }
  );
  return response;
};

export const get_second_marking_job_id = async (payload) => {
  const response = await axios.post(
    `${process.env.REACT_APP_ENGINE_URL}/${API.SECOND_MARKING}`, payload,
    {
      headers: { "Content-Type": "application/json" },
    }
  );
  return response;
};

export const get_assignment_details = async (level: string, fileId: string) => {
  const result: any = await get_assignment_job_id(level, fileId);
  const checkTaskStatus = async (jobId) => {
    const response = await axios.get(`${process.env.REACT_APP_ENGINE_URL}/${API.TASK_STATUS}/${result.data.jobId}`)
    if (response?.data?.result) {
      return response;
    }
    await new Promise((resolve) => setTimeout(resolve, 2000));
    return checkTaskStatus(jobId);
  }
  return checkTaskStatus(result?.data?.jobId);
};

export const setting_signature = async () => {
  const response = await ApiRequest.get(API.SETTING_GET_SIGNATURE);
  return response;
};

export const setting_upload_signature = async (value: any) => {
  const formData = new FormData();
  formData.append("signature", value.signature);
  const response = await ApiRequest.post(
    API.SETTING_UPLOAD_SIGNATURE,
    formData,
    { headers: { "Content-Type": "multipart/form-data" } }
  );
  return response;
};

export const download_assignment = async (fileId: string) => {
  const response = await ApiRequest.get(
    `${process.env.REACT_APP_ENGINE_URL}/download/${fileId}`
  );
  return response;
};

export const resubmit_feedback = async (value: any) => {
  const formData = new FormData();
  formData.append("file", value.file);
  const result: any = await ApiRequest.post(
    `${process.env.REACT_APP_ENGINE_URL}/${API.RESUBMIT_FEEDBACK}`,
    formData,
    { headers: { "Content-Type": "multipart/form-data" } }
  );
  console.log("🚀 ~ constresubmit_feedback= ~ result:", result)
  const checkTaskStatus = async (jobId) => {
    const response = await axios.get(
      `${process.env.REACT_APP_ENGINE_URL}/${API.TASK_STATUS}/${jobId}`
    );
    if (response?.data?.result) {
      return response;
    }
    await new Promise((resolve) => setTimeout(resolve, 2000));
    return checkTaskStatus(jobId);
  };
  return checkTaskStatus(result?.jobId);
};

export const update_feedback = async (
  updatedFeedback: any
) => {
  const result: any = await get_update_feedback_job_id(
    updatedFeedback
  );
  const checkTaskStatus = async (jobId) => {
    const response = await axios.get(
      `${process.env.REACT_APP_ENGINE_URL}/${API.TASK_STATUS}/${jobId}`
    );
    if (response?.data?.result) {
      return response;
    }
    await new Promise((resolve) => setTimeout(resolve, 2000));
    return checkTaskStatus(jobId);
  };
  return checkTaskStatus(result?.data?.jobId);
};

export const get_second_marking_details = async (payload) => {
  const result: any = await get_second_marking_job_id(payload);
  const checkTaskStatus = async (jobId) => {
    const response = await axios.get(
      `${process.env.REACT_APP_ENGINE_URL}/${API.TASK_STATUS}/${jobId}`
    );
    if (response?.data?.result) {
      return response;
    }
    await new Promise((resolve) => setTimeout(resolve, 2000));
    return checkTaskStatus(jobId);
  };
  return checkTaskStatus(result.data.jobId);
};

export const get_pre_assignment_brief_check = async (formData) => {
  const result: any = await axios.post(
    `${process.env.REACT_APP_ENGINE_URL}/${API.PRE_ASSIGNMENT_BRIEF_CHECK}`,
    formData,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );

  const checkTaskStatus = async (jobId) => {
    const response = await axios.get(
      `${process.env.REACT_APP_ENGINE_URL}/${API.TASK_STATUS}/${jobId}`
    );
    if (response?.data?.result) {
      return response;
    }
    await new Promise((resolve) => setTimeout(resolve, 2000));
    return checkTaskStatus(jobId);
  };
  return checkTaskStatus(result.data.jobId);
};

export const mark_assignment = async (payload) => {
  const result = await axios.post(
    `${process.env.REACT_APP_ENGINE_URL}/${API.MARK_ASSIGNMENT}`, payload,
    {
      headers: { "Content-Type": "application/json" },
    }
  );
  const checkTaskStatus = async (jobId) => {
    const response = await axios.get(
      `${process.env.REACT_APP_ENGINE_URL}/${API.TASK_STATUS}/${jobId}`
    );
    if (response?.data?.result) {
      return response;
    }
    await new Promise((resolve) => setTimeout(resolve, 2000));
    return checkTaskStatus(jobId);
  };
  return checkTaskStatus(result?.data?.jobId);
};

export const assesor_declaration = async (payload) => {
  const result = await axios.post(
    `${process.env.REACT_APP_ENGINE_URL}/${API.ASSESOR_DECLARATION}`, payload,
    {
      headers: { "Content-Type": "application/json" },
    }
  );
  const checkTaskStatus = async (jobId) => {
    const response = await axios.get(
      `${process.env.REACT_APP_ENGINE_URL}/${API.TASK_STATUS}/${jobId}`
    );
    if (response?.data?.result) {
      return response;
    }
    await new Promise((resolve) => setTimeout(resolve, 2000));
    return checkTaskStatus(jobId);
  };
  return checkTaskStatus(result?.data?.jobId);
}

export const update_learner_grading_feedback = async (assignmentId, payload) => {
  console.log("🚀 ~ constupdate_learner_grading_feedback=async ~ payload:", payload)
  return await ApiRequest.put(`${API.UPDATE_LEARNER_ASSIGNMENT}/${assignmentId}`, payload)
}

export const otp_reset_password = async (values: ResetPasswordPayload) => {
  const response = await ApiRequest.post(API.RESET_PASSWORD, values);
  return response;
}

export const createAssignment = async (value: any) => {
  const formData = new FormData();
  formData.append("assessmentId", value.assessmentId);
  formData.append("unitCode", value.unitCode);
  formData.append("unitTitle", value.unitTitle);
  formData.append("level[]", value.level);
  formData.append("centerNumber", value.centerNumber);
  formData.append("centerName", value.centerName);
  formData.append("releasedDate", value.releasedDate);
  formData.append("lastModeration", value.lastModeration);
  formData.append("dueDate", value.dueDate);
  formData.append("description", value.description);
  formData.append("cohorts[]", value.cohorts);
  formData.append("markers[]", value.markers);
  formData.append("learners[]", value.learners);
  formData.append("assignmentBriefFile", value.assignmentBriefFile);
  formData.append("assessmentCriteriaFile", value.assessmentCriteriaFile);
  console.log('formData: ', formData)
  const response = await ApiRequest.post(API.CREATE_ASSIGNMENT, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  return response;
}

export const claimAssignment = async (assignmentId) => {
  return await ApiRequest.patch(`${API.CLAIM_ASSIGNMENT}/${assignmentId}`)
}

export const get_assignments_status = async (data) => {
  const response = await axios.post(`${process.env.REACT_APP_ANALYTICS_URL}/${API.ASSIGNMENTS_STATUS}`, data, {
    headers: { "Content-Type": "application/json" },
  })

  return response;
}

export const get_average_time_to_mark = async (data) => {
  const response = await axios.post(`${process.env.REACT_APP_ANALYTICS_URL}/${API.AVERAGE_TIME_TO_MARK}`, data, {
    headers: { "Content-Type": "application/json" },
  })

  return response;
}

export const get_learner_satisfaction = async (data) => {
  const response = await axios.post(`${process.env.REACT_APP_ANALYTICS_URL}/${API.GET_LEARNER_SATISFACTION}`, data, {
    headers: { "Content-Type": "application/json" },
  })

  return response;
}

export const get_average_time_to_submit = async (data) => {
  const response = await axios.get(`${process.env.REACT_APP_ANALYTICS_URL}/${API.AVERAGE_TIME_TO_SUBMIT}/${data.learner_id}`)

  return response;
}

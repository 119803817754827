import { RootState } from "@/app/redux/store";
import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

interface Answer {
  question?: string;
  answer?: string;
}

interface TaskEvaluation {
  task_id: string;
  description: string;
  answer: string;
  marks: number;
  total_marks: number;
  feedback: string;
}
interface AnswerProps {
  data: Answer[] | TaskEvaluation[] | any;
  task?: string;
}

const Answer: React.FC<AnswerProps> = ({ data, task }) => {
  console.log("🚀 ~ task:", task,data)
  const {
    assignmentDetail: { assignment, grading_summary, student, tasks },
  } = useSelector((state: RootState) => state.gradingFeedback);
  const { level } = useParams<string>();
  const levels = ["Level 3", "Level 5"];
  const answerArray = data?.filter((item: any) => {
    if (level && levels.includes(level)) {
      if (item.assessmentCriteria === task) {
        return item;
      }
    } else {
      if (item.task_id === task) {
        return item;
      }
    }
  });
  return (
    <div className="answer-comp">
      <div className="card-heading">Answers</div>
      <div className="questions d-flex flex-column">
        {task
          && answerArray &&
            answerArray?.map((item) => (
              <>
                <div className={`d-flex flex-column gap-4 answer-card p-3`}>
                  <div className="answer">
                    <label className="answer-title">Answer</label>
                    <div className="answer-detail">{item.answer}</div>
                  </div>
                </div>
              </>
            ))}
      </div>
    </div>
  );
};

export default Answer;

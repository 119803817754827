import React, { useEffect, useState } from 'react';
import Layout from '@/app/components/Layout';
import UserTableHeader from '@/app/components/Table/UserTableHeader';
import AddUserRole from '@/app/components/AddUserRole';
import AddCohort from '@/app/components/AddCohort';
import LearnerTable from './Learner';
import EducatorTable from './Educator';
import CohortTable from './Cohort';
import styles from './Users.module.css';
import SupervisorTable from './Supervisor';
import { useLocation } from 'react-router-dom';

const Users: React.FC = () => {
    const [selectedUser, setSelectedUser] = useState<string>('Learner');
    const [selectedSort, setSelectedSort] = useState<string>('');
    const [formModal, setFormModal] = useState(false);
    const [addCohort, setAddCohort] = useState(false);
    const [selectedFilterUserStatus, setSelectedFilterUserStatus] = useState<string[]>([])
    const [selectedFilterLevels, setSelectedFilterLevels] = useState<string[]>([]);
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const name = params.get("name");

    useEffect(() => {
        if (name && name !== selectedUser) {
            console.log({ name })
            setSelectedUser(name);
        }
    }, [name])

    return (
        <Layout>
            <div className='vh-100' style={{ padding: '24px', overflowY: 'auto' }}>
                <span className={styles.heading}>Manage Users</span>
                <UserTableHeader
                    onClickAddNew={() => selectedUser === 'Cohort' ? setAddCohort(true) : setFormModal(true)}
                    selectedUser={(value) => setSelectedUser(value)}
                    selectedSortOption={(value) => setSelectedSort(value)}
                    filteredDataOptionUserStatus={(value) => setSelectedFilterUserStatus(value)}
                    filteredDataOptionLevels={(value) => setSelectedFilterLevels(value)}
                    selectedUserTab={selectedUser}
                />
                {selectedUser === 'Learner' && <LearnerTable selectedSort={selectedSort}
                    selectedFilterUserStatus={selectedFilterUserStatus}
                    selectedFilterLevels={selectedFilterLevels}
                />}

                {selectedUser === 'Educator' && <EducatorTable
                    selectedSort={selectedSort}
                    selectedFilterUserStatus={selectedFilterUserStatus}
                    selectedFilterLevels={selectedFilterLevels}
                />}
                {selectedUser === 'Cohort' && <CohortTable
                    selectedSort={selectedSort}
                    selectedFilterUserStatus={selectedFilterUserStatus}
                    selectedFilterLevels={selectedFilterLevels}
                />}
                {selectedUser === 'Supervisor' && <SupervisorTable selectedSort={selectedSort} />}
                {formModal && <AddUserRole
                    role={selectedUser.toLowerCase()}
                    visible={formModal}
                    handleClose={() => setFormModal(false)}
                />
                }
                {addCohort && (
                    <AddCohort
                        visible={addCohort}
                        handleClose={() => setAddCohort(false)}
                        onSubmit={() => {/* handle submit */ }}
                    />
                )}
            </div>
        </Layout>
    );
};

export default Users;

import { get_notification, setting_signature, setting_upload_signature, update_notification } from "@/app/api/Api";
import { getErrorMessage } from "@/app/modules/helpers/ErrorHelper";
import { call, put, takeLatest } from "redux-saga/effects";
import {
    GET_SETTING_NOTIFICATION,
    GET_SETTING_NOTIFICATIONS,
    SET_LOADING_SETTING,
    SET_SETTING_ERROR,
    SETTING_FETCHED_SIGNATURE,
    SETTING_FETCHED_SIGNATURES,
    SETTING_UPLOAD_SIGNATURE,
    SETTING_UPLOAD_SIGNATURES,
    UPDATE_SETTING_NOTIFICATION,
    UPDATE_SETTING_NOTIFICATIONS,
} from "../actionTypes";

function* handleGetSettingNotification(): Generator {
    try {
        yield put({ type: SET_LOADING_SETTING });
        const response = (yield call(get_notification)) as any;
        yield put({ type: GET_SETTING_NOTIFICATIONS, payload: { data: response?.data } })
    } catch (error) {
        const errorMessage = getErrorMessage(error);
        yield put({ type: SET_SETTING_ERROR, payload: { message: errorMessage } });
    }
}

function* handleUpdateSettingNotification(action: { type: string; payload: any }): Generator {
    try {
        yield put({ type: SET_LOADING_SETTING });
        const response = (yield call(update_notification, action.payload.notificationData)) as any;
        yield put({ type: UPDATE_SETTING_NOTIFICATIONS, payload: { data: response?.data, message: response?.message } });
    } catch (error) {
        const errorMessage = getErrorMessage(error);
        yield put({ type: SET_SETTING_ERROR, payload: { message: errorMessage } });
    }
}

function* fetchSettingSignature(): Generator {
    try {
        yield put({ type: SET_LOADING_SETTING });
        const response = (yield call(setting_signature)) as any;
        yield put({ type: SETTING_FETCHED_SIGNATURES, payload: { data: response.data } });
    } catch (error) {
        const errorMessage = getErrorMessage(error);
        yield put({ type: SET_SETTING_ERROR, payload: { message: errorMessage } });
    }
}

function* settingUploadSignature(action: { type: string, payload: {signature : any} }): Generator {
    console.log("action.payload", action.payload)
    try {
        yield put({ type: SET_LOADING_SETTING });
        const response = (yield call(setting_upload_signature, action.payload)) as any;
        yield put({ type: SETTING_UPLOAD_SIGNATURES, payload: { data: response?.data } });
    } catch (error) {
        const errorMessage = getErrorMessage(error);
        yield put({ type: SET_SETTING_ERROR, payload: { message: errorMessage } });
    }
}

function* settingSaga() {
    yield takeLatest(GET_SETTING_NOTIFICATION, handleGetSettingNotification);
    yield takeLatest(UPDATE_SETTING_NOTIFICATION, handleUpdateSettingNotification);
    yield takeLatest(SETTING_FETCHED_SIGNATURE, fetchSettingSignature);
    yield takeLatest(SETTING_UPLOAD_SIGNATURE, settingUploadSignature);
}

export default settingSaga;

import PerformanceChart from "@components/PerformanceChart";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import NotificationList from "@components/NotificationList";
import IMAGES from "@utils/Images";
import ProgressCard from "@components/ProgressCard";
import AssignmentsDetailNew from "./AssignmentDetailNew";
import { FETCH_ASSIGNMENTS_STATUS, FETCH_AVERAGE_TIME_TO_SUBMIT, FETCH_USER } from "../redux/actionTypes";
import { RootState } from "../redux/store";
import { useSelector } from "react-redux";

interface Props { }

const LearnerDashboard: React.FC<Props> = () => {
  const {user_id, user} = useSelector((state: RootState) => state.auth);
  const {assignmentsStatus, averageTimeToSubmit} = useSelector((state: RootState) => state.analytics);

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch({ type: FETCH_ASSIGNMENTS_STATUS, payload: { learner_id: user_id } });
    dispatch({ type: FETCH_AVERAGE_TIME_TO_SUBMIT, payload: { learner_id: user_id } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const containeroneStyle: React.CSSProperties = {
    height: "250px",
  };

  const containertwoStyle: React.CSSProperties = {
    height: "300px",
  };

  const resourceStyle: React.CSSProperties = {
    height: "250px",
    overflowY: "scroll",
    scrollbarWidth: "none",
    fontSize: "11px",
    fontWeight: "500",
  };

  const notificationData = [
    {
      item: "New Assignment",
      date: "5:30pm",
      isReviewCompleted: false,
      icon: IMAGES.ASSIGNMENT,
    },
    {
      item: "New Feedback",
      date: "9:00pm",
      isReviewCompleted: true,
      icon: IMAGES.FEEDBACK_IMG,
    },
  ];

  return (
    <div className="dashboard-container mt-4  z-0">
      <div className="row mx-lg-auto mx-0 ">
        <div className="col-12 col-lg-3 card-content course-container">
          <label className="heading">{"Enrolled course"}</label>
          <ProgressCard
            heading={user?.enrolledCourse || ""}
            Units={"14/30"}
          />
        </div>
        <div
          className="col-12 col-lg-9 mt-lg-0 card-content"
          style={containeroneStyle}
        >
          <div
            className="Resource-container card-container"
            style={resourceStyle}
          >
            <div className="d-flex align-items-center gap-3">
              <img src={IMAGES.DASHBOARD_PRODUCTIVITY} alt="Logo" />
              <label className="productivity-header">{"Your Productivity"}</label>
            </div>
            <div className="row productivity-card">
              <div className="d-lg-flex align-items-center justify-content-between">
                <div className="mt-lg-0 mt-2">
                  <div className="productivity-label">Avg Time To Submit</div>
                  <div className="productivity-sub-label">{averageTimeToSubmit}</div>
                </div>
                <div className="mt-lg-0 mt-2">
                  <div className="productivity-label">Assignments Pending</div>
                  <div className="productivity-sub-label">{assignmentsStatus.assignments_pending}</div>
                </div>
                <div className="mt-lg-0 mt-2">
                  <div className="productivity-label">Assignments Done</div>
                  <div className="productivity-sub-label">{assignmentsStatus.assignments_done}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div
          className="col-12 col-lg-3 mt-lg-0 card-content"
          style={containeroneStyle}
        >
          <Calendar />
        </div> */}
      </div>
      <div className="row mt-4">
        <div className="col-12 col-lg-5 card-content" style={containertwoStyle}>
          {/* <AssignmentsDetail
            labelText={"Assignment Completed"}
            data={seriesData}
          /> */}
          <AssignmentsDetailNew
            labelText={"Assignments Over Time"}
          />
        </div>
        <div
          className="col-12 col-lg-3 mt-lg-0 card-content"
          style={containertwoStyle}
        >
          <PerformanceChart role={"learner"}
          />
        </div>
        <div
          className="col-12 col-lg-4 mt-lg-0 card-content"
        // style={containertwoStyle}
        >
          {/* <TodoList data={data}
          /> */}
          <NotificationList
            data={notificationData}
            role={"learner"}
          />
        </div>
      </div>
      {/* <div className="row mt-4">
        <div>
          <NotificationList
            data={notificationData}
            role={"learner"}
          />
        </div>
      </div> */}
    </div>
  );
};

export default LearnerDashboard;

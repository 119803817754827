import Layout from '@/app/components/Layout'
import PaginatedTable from '@/app/components/Table/PaginatedTable';
import TableActionDropdown from '@/app/components/Table/TableAction';
import { formatDateToMonthDateYear } from '@/app/modules/helpers/FormikHelper';
import { CLEAR_SUCCESS_COHORT, FETCH_COHORT_LEARNERS, FETCH_COHORTS, MOVE_LEARNER } from '@/app/redux/actionTypes';
import { RootState } from '@/app/redux/store';
import COLORS from '@/app/utils/Colors';
import debounce from 'lodash.debounce';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { MdArrowBack } from "react-icons/md";
import Loader from '@/app/components/Loading';
import { User } from '@/app/modules/types/types';
import MoveCohortList from '@/app/components/MoveCohortList';
import { toast } from 'react-toastify';
import { successPopup } from '@/app/components/SuccessModal';
import FilterDropdown from '@/app/components/Table/TableFilter';
import Button from '@/app/components/Button';
import IMAGES from '@/app/utils/Images';
import { sortOptions } from '@/app/utils/helper';
import UserDetailsPopUp from '@/app/components/UserDetailsPopUp/UserDetailsPopUp';

type Props = {}

const EducatorCohort: React.FC<Props> = (props: Props) => {

    const dispatch = useDispatch();

    const { cohorts, success, totalPages, loading, mappedLearners, error } = useSelector((state: RootState) => state.cohort);

    const [selectedCohortDetails, setSelectedCohortDetails] = useState<any>('')

    const [selectedLearner, setSelectedLearner] = useState<User>()

    const [detailModal, setDetailModal] = useState(false)

    const [showLearners, setShowLearners] = useState(false)

    const [isFilterStatus, setIsFilterStatus] = useState(false);

    const [isSortStatus, setIsSortStatus] = useState(false);

    const [moveLearners, setMoveLearners] = useState(false)

    const fetchCohorts = (page: number, rows: number, query: string = '') => {
        dispatch({ type: FETCH_COHORTS, payload: { page, limit: rows, search: query } });
    };

    const handleSearchChange = debounce((event: React.ChangeEvent<HTMLInputElement>) => {
        fetchCohorts(1, 10, event.target.value);
    }, 500);

    useEffect(() => {
        fetchCohorts(1, 10, '');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onViewDetailCohort = (row) => {
        setSelectedCohortDetails(row.original)
        dispatch({ type: FETCH_COHORT_LEARNERS, payload: { cohortId: row.original.id, userType: 'learner' } })
    }

    const handleSuccess = async (message: string) => {
        // Pass success message here
        await successPopup(message);
        dispatch({ type: CLEAR_SUCCESS_COHORT })
        setMoveLearners(false)
        dispatch({ type: FETCH_COHORT_LEARNERS, payload: { cohortId: selectedCohortDetails.id, userType: 'learner' } })
    };

    useEffect(() => {
        if (mappedLearners && selectedCohortDetails) {
            setShowLearners(true)
        }
    }, [mappedLearners, selectedCohortDetails])

    useEffect(() => {
        if (error) {
            toast.error(error);
        }
    }, [error]);

    useEffect(() => {
        if (success) {
            handleSuccess(success)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [success])


    const columns = [
        { header: 'Cohort Name', accessorKey: 'name' },
        { header: 'Learners', accessorKey: 'learners' },
        { header: 'Course', accessorKey: 'course.name' },
        {
            header: 'Actions', cell: ({ row }) => (
                <span role='button' onClick={() => onViewDetailCohort(row)} style={{ color: COLORS.PRIMARY }}>View Details</span>
            )
        },
    ];

    const learnerColumns = [
        { header: 'Name', accessorKey: 'name' },
        { header: 'Learners', accessorKey: 'memberId' },
        { header: 'Enrolled Date', cell: ({ row }) => <span>{formatDateToMonthDateYear(row.original.createdAt)}</span> },
        { header: 'Average Grade', cell: ({ row }) => (<span>74%</span>) },
        {
            header: 'Actions', cell: ({ row }) => (
                <TableActionDropdown
                    actions={[
                        {
                            label: 'View Detail', onClick: () => {
                                setSelectedLearner(row.original)
                                setDetailModal(true)
                            }
                        },
                        {
                            label: 'Request Move', onClick: () => {
                                setSelectedLearner(row.original)
                                setMoveLearners(true)
                            }
                        },
                    ]}
                />
            )
        },
    ];


    const activeOptions = [
        { label: 'Assigned', value: '' },
        { label: 'Unassigned', value: 'New' },
    ];

    const handleStatusFilter = (status: (string | number)[]) => {
        console.log(status);
    };
    const handleSort = (status: string | number) => {
        dispatch({ type: FETCH_COHORT_LEARNERS, payload: { cohortId: selectedCohortDetails.id, userType: 'learner', sortBy: status } })
    };

    return (
        <Layout>
            {loading && <Loader />}
            <div className="assignment-container m-4 vh-100">
                {cohorts && totalPages && !showLearners && (
                    <div className="sticky-top z-3 bg-white">
                        <div className="heading-container mb-4">
                            <span className="heading">Cohorts</span>
                            <span className="label">Manage cohort</span>
                        </div>
                        <PaginatedTable
                            customButtons={
                                <FilterDropdown
                                    type="filter"
                                    options={activeOptions}
                                    onFilterSelect={handleStatusFilter}
                                    placeholder={
                                        <Button
                                            label={"Filter"}
                                            className={"assignment-btn"}
                                            onClick={() => { setIsFilterStatus(!isFilterStatus); }}
                                            isIcon={true}
                                            icon={IMAGES.FILTER}
                                        />
                                    }
                                />
                            }
                            columns={columns}
                            data={cohorts}
                            pageCount={totalPages}
                            fetchData={fetchCohorts}
                            handleSearchChange={handleSearchChange}
                        />
                    </div>
                )}
                {mappedLearners && selectedCohortDetails && showLearners &&
                    <div className="sticky-top z-3 bg-white">
                        <div className="heading-container mb-4">
                            <span className="heading">
                                <MdArrowBack onClick={() => setShowLearners(false)} size={24} />
                                Cohort :&nbsp;<span className='fw-normal'>{selectedCohortDetails?.name}</span>
                            </span>
                            <span className="d-block heading2 my-3">
                                <span className='fw-bold'>Total Learners: <span className='fw-normal'>{selectedCohortDetails.learners}</span></span>
                                <span className='mx-5 fw-bold'>Enrolled Course: <span className='fw-normal'>{selectedCohortDetails.course.name}</span></span>
                            </span>
                        </div>
                        <PaginatedTable
                            customButtons={
                                <>
                                    <FilterDropdown
                                        options={sortOptions}
                                        onSortSelect={handleSort}
                                        type='sort'
                                        placeholder={
                                            <Button
                                                label={""}
                                                className={"assignment-btn"}
                                                onClick={() => { setIsSortStatus(!isSortStatus); }}
                                                isIcon={true}
                                                icon={IMAGES.SORT_ICON}
                                            />
                                        }
                                    />
                                    <FilterDropdown
                                        type="filter"
                                        options={activeOptions}
                                        onFilterSelect={handleStatusFilter}
                                        placeholder={
                                            <Button
                                                label={"Filter"}
                                                className={"assignment-btn"}
                                                onClick={() => { setIsFilterStatus(!isFilterStatus); }}
                                                isIcon={true}
                                                icon={IMAGES.FILTER}
                                            />
                                        }
                                    />
                                </>
                            }
                            columns={learnerColumns}
                            data={mappedLearners}
                            pageCount={1}
                            fetchData={fetchCohorts}
                            handleSearchChange={handleSearchChange}
                        />
                    </div>
                }
            </div>  
            {selectedLearner && detailModal && <UserDetailsPopUp editable={false} visible={detailModal} handleClose={() => setDetailModal(false)} selectedUser={selectedLearner} />}
            <MoveCohortList
                visible={moveLearners}
                handleClose={() => setMoveLearners(false)}
                handleSubmit={(newCohortId) => dispatch({
                    type: MOVE_LEARNER, payload: {
                        newCohortId: newCohortId,
                        oldCohortId: selectedCohortDetails?.id,
                        learnerIds: [selectedLearner?.id]
                    }
                })} />
        </Layout>
    )
}

export default EducatorCohort
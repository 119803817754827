import React, { useEffect, useState } from "react";
import Input from "../Input";
import IMAGES from "@/app/utils/Images";
import learnersData from "@assets/JSON/learner.json";
import { useDispatch } from "react-redux";
import { FETCH_COHORT_LEARNERS, FETCH_COHORTS } from "@/app/redux/actionTypes";
import { RootState } from "@/app/redux/store";
import { useSelector } from "react-redux";
import LearnerList from "./LearnerList";

interface AssignCohortProps {
  formData: { cohorts: string[] };
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | any
  ) => void;
}


const AssignCohort: React.FC<AssignCohortProps> = ({ formData, handleChange }) => {
  type Item = {
    id: string
    name: string;
    memberId: string;
  };
  const dispatch = useDispatch();

  const { cohorts, success, totalPages, loading, mappedLearners, error } = useSelector((state: RootState) => state.cohort);
  const [cohortData, setCohortData] = useState<any>(null);
  const [checked, setChecked] = useState<string | null>(null);
  const [learners, setLearners] = useState<Item[] | null>(null);
  const [cohortDetails, setCohortDetails] = useState<any>(null);
  const fetchCohorts = (page: number, rows: number, query: string = '') => {
    dispatch({ type: FETCH_COHORTS, payload: { page, limit: rows, search: query } });
  };

  useEffect(() => {
    fetchCohorts(1, 10, '');
  }, []);
  useEffect(() => {
    if (checked) {
      dispatch({ type: FETCH_COHORT_LEARNERS, payload: { cohortId: checked, userType: "learner" } })
    }
  }, [checked])
  useEffect(() => {
    if (cohorts && cohorts.length > 0) {
      const cohortDetailList = cohorts?.map((item) => {
        console.log('cohortDetailList: ', item)
        return {
          id: item.id,
          name: item.name,
          learners: item?.learners,
          icon: IMAGES.COHORT_ICON,
          level: item.course.id,
        }
      });
      setCohortData(cohortDetailList)

    }
  }, [cohorts]);
  useEffect(() => {
    if (mappedLearners) {
      setCohortDetails(mappedLearners)
      let items: Item[] = mappedLearners.map(item => {
        console.log('setCohortDetails: ', item)
        return {
          id: item.id,
          name: item.name,
          memberId: item.memberId,
        }
      })
      setLearners(items)
    }
  }, [mappedLearners])

  const handleCheckboxChange = (cohortId: string) => {
    const newChecked = checked === cohortId ? null : cohortId;
    setChecked(newChecked);

    // Update formData.cohorts
    if (newChecked) {
      // Add cohortId if it was checked
      handleChange({ target: { name: "cohorts", value: [...formData.cohorts, cohortId] } });
    } else {
      // Remove cohortId if it was unchecked
      handleChange({ target: { name: "cohorts", value: formData.cohorts.filter(id => id !== cohortId) } });
    }

    setCohortDetails(newChecked ? cohortData.find(cohort => cohort.id === newChecked) : null);
    setLearners(newChecked ? learnersData[newChecked] : null);
  };
  return (
    <div className="cohort-modal m-2">
      <div className="row">
        <div className={`col-md-6 col-12  ${checked && "border-end"}`}>
          <Input
            leftIcon={<img src={IMAGES.SEARCH_LIGHT} alt="Logo" />}
            placeholder={"Search by cohort name"}
            type={"text"}
            className={"search-cohort-input"}
            error={null}
          />
          <div className="d-flex flex-column my-4 gap-4">
            {cohortData?.map((item, index) => (
              <div
                className={`cohort-card p-4 ${item.id === checked && "cohort-selected-card"
                  }`}
                key={index}
              >
                <div className="d-flex align-items-center justify-content-between">
                  <div className="card-title d-flex align-items-center gap-4">
                    <img src={item.icon} className="p-2 icon rounded-circle" alt="Logo" />
                    <div>
                      <label className="title">{item.name}</label>
                    </div>
                  </div>
                  <img src={IMAGES.RIGHT_ARROW} alt="Logo" />
                </div>
                <hr className="card-divider" />
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center gap-4">
                    <div className="card-title cohort-detail">
                      <label className="d-block">{`Total learners : ${item.learners}`}</label>
                      <label>'Centre Name: We-Are-HR'</label>
                    </div>
                  </div>
                  <input
                    type="checkbox"
                    checked={checked === item.id}
                    onChange={() => {
                      setChecked(item.id === checked ? null : item.id);
                      setCohortDetails(item);
                      console.log(learnersData[item.id])
                      setLearners(learnersData[item.id]);
                      handleCheckboxChange(item.id)
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
        {checked && learners && cohortDetails && (
          <div className="col-md-6 col-12 px-3">
            <div className="cohort-details-assignment d-flex justify-content-between align-items-center px-lg-3 px-0">
              <div className="card-title d-flex align-items-center h-100">
                <div>
                  <label className="title">{cohortDetails.name}</label>
                </div>
              </div>
              <div className="card-title d-flex align-items-center h-100">
                <div>
                  <label className="card-heading h-100">
                    {"Total no. of Learners"}
                  </label>
                  <label className="title">{cohortDetails.learners}</label>
                </div>
              </div>
              <div className="card-title d-flex align-items-center h-100">
                <div>
                  <label className="card-heading h-100">
                    {'Centre Name'}
                  </label>
                  <label className="course-name">{cohortDetails.course}</label>
                </div>
              </div>
            </div>
            <LearnerList learners={learners} onSelectedChange={function (selectedIds: string[]): void {
              console.log(selectedIds)
            }} />
          </div>

        )}
      </div>
    </div>
  );
};

export default AssignCohort;


//
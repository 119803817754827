import { FailedPayload } from "@/app/modules/types/types";
import { AnalyticsSliceState } from "@modules/types/sliceTypes";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: AnalyticsSliceState = {
  learnersBylevel: { labels: [], datasets: [] },
  learnerbyUnit: null,
  educatorCorrectionRate: null,
  levelCorrectionRate: null,
  loading: false,
  success: false,
  error: null,
  assignments: null,
  educatorFeedback: null,
  levelFeedback: null,
  gradesDistributionData: { labels: [], datasets: [] },
  averageGradePercentages: {
    id: "",
    average_percentage: 0,
    descriptive_grade: "N/A"
  },
  analyticsOverTime: {},
  totalActiveUsers: {
    learner: 0,
    educator: 0,
    supervisor: 0
  },
  assignmentsStatus: {
    assignments_done: 0,
    assignments_pending: 0,
    assignments_in_progress: 0
  },
  averageTimeToMark: "0 hour",
  learnerSatisfaction: 0,
  averageSatisfaction: 0,
  averageTimeToSubmit: "0 hour"
};

const analyticsSlice = createSlice({
  name: "analytics",
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = true;
      state.error = null;
      state.success = false
    },
    analyticsFailed: (state, action: PayloadAction<FailedPayload>) => {
      state.error = action.payload.message;
      state.loading = false;
      state.success = false;
    },
    fetchedlearnerbyLevel: (
      state,
      action: PayloadAction<any>
    ) => {
      const { levels } = action.payload.data;
      const labels = levels.map((grade) => grade.level);
      const grades_distribution = levels.map((grade) => grade.grades_distribution)
      const lowPassData = grades_distribution.map((grade) => grade.low_pass);
      const passData = grades_distribution.map((grade) => grade.pass);
      const highPassData = grades_distribution.map((grade) => grade.high_pass);
      const failData = grades_distribution.map((grade) => grade.fail);
      state.learnersBylevel = {
        labels,
        datasets: [
          {
            label: "Low Pass",
            data: lowPassData,
            backgroundColor: "#3D5AF1",
            borderSkipped: false,
          },
          {
            label: "Pass",
            data: passData,
            backgroundColor: "rgba(61, 90, 241, 0.35)",
            borderSkipped: false,
          },
          {
            label: "High Pass",
            data: highPassData,
            backgroundColor: "rgba(61, 90, 241, 0.10)",
            borderSkipped: false,
          },
          {
            label: "Fail",
            data: failData,
            backgroundColor: "#F8849D",
            borderSkipped: false,
          },
        ],
      };
      state.success = true;
      state.loading = false;
    },
    fetchedlearnerbyUnit: (
      state,
      action: PayloadAction<any>
    ) => {
      const { units } = action.payload.data;
      const labels = units.map((grade) => grade.unit);
      const grades_distribution = units.map((grade) => grade.grades_distribution)
      const lowPassData = grades_distribution.map((grade) => grade.low_pass);
      const passData = grades_distribution.map((grade) => grade.pass);
      const highPassData = grades_distribution.map((grade) => grade.high_pass);
      const failData = grades_distribution.map((grade) => grade.fail);
      state.learnerbyUnit = {
        labels,
        datasets: [
          {
            label: "Low Pass",
            data: lowPassData,
            backgroundColor: "#3D5AF1",
            borderSkipped: false,
          },
          {
            label: "Pass",
            data: passData,
            backgroundColor: "rgba(61, 90, 241, 0.35)",
            borderSkipped: false,
          },
          {
            label: "High Pass",
            data: highPassData,
            backgroundColor: "rgba(61, 90, 241, 0.10)",
            borderSkipped: false,
          },
          {
            label: "Fail",
            data: failData,
            backgroundColor: "#F8849D",
            borderSkipped: false,
          },
        ],
      };
      state.success = true;
      state.loading = false;
    },
    fetchedCorrectionrate: (
      state,
      action: PayloadAction<any>
    ) => {
      const educatorData = {
        labels: Object.keys(action.payload.data),
        datasets: [
          {
            label: "Educators",
            data: Object.values(action.payload.data),
            backgroundColor: "#3D5AF1",
            borderSkipped: false,
            categoryPercentage: 0.5,
          },
        ],
      };
      const levelData = {
        labels: Object.keys(action.payload.data),
        datasets: [
          {
            label: "Levels",
            data: Object.values(action.payload.data),
            backgroundColor: "#3D5AF1",
            borderSkipped: false,
            categoryPercentage: 0.5,
          },
        ],
      };
      state.educatorCorrectionRate = educatorData;
      state.levelCorrectionRate = levelData;
      state.success = true;
      state.loading = false;
    },
    fetchedFeedback: (state, action: PayloadAction<any>) => {
      const { levels } = action.payload.data;
      const labels = levels?.map((grade) => grade.level);
      const feedback_distribution = levels?.map((grade) => grade.feedback_distribution)
      const levelStar5 = feedback_distribution?.map((grade) => grade["5_star"]);
      const levelStar4 = feedback_distribution?.map((grade) => grade["4_star"]);
      const levelStar3 = feedback_distribution?.map((grade) => grade["3_star"]);
      const levelStar2 = feedback_distribution?.map((grade) => grade["2_star"]);
      const levelStar1 = feedback_distribution?.map((grade) => grade["1_star"]);
      const levelData = {
        labels: labels,
        datasets: [
          {
            label: "5 Star",
            data: levelStar5,
            backgroundColor: "#ECEEFE",
            borderSkipped: false,
          },
          {
            label: "4 Star",
            data: levelStar4,
            backgroundColor: "#BBC5FA",
            borderSkipped: false,
          },
          {
            label: "3 Star",
            data: levelStar3,
            backgroundColor: "#8FA0F7",
            borderSkipped: false,
          },
          {
            label: "2 Star",
            data: levelStar2,
            backgroundColor: "#3D5AF1",
            borderSkipped: false,
          },
          {
            label: "1 Star",
            data: levelStar1,
            backgroundColor: "#F8849D",
            borderSkipped: false,
          },
        ]
      };
      const { educators } = action.payload.data;
      const educatorlabels = educators?.map((grade) => grade.educator);
      const educator_feedback_distribution = educators?.map((grade) => grade.feedback_distribution)
      const edStar5 = educator_feedback_distribution?.map((grade) => grade["5_star"]);
      const edStar4 = educator_feedback_distribution?.map((grade) => grade["4_star"]);
      const edStar3 = educator_feedback_distribution?.map((grade) => grade["3_star"]);
      const edStar2 = educator_feedback_distribution?.map((grade) => grade["2_star"]);
      const edStar1 = educator_feedback_distribution?.map((grade) => grade["1_star"]);
      const educatorData = {
        labels: educatorlabels,
        datasets: [
          {
            label: "5 Star",
            data: edStar5,
            backgroundColor: "#ECEEFE",
            borderSkipped: false,
          },
          {
            label: "4 Star",
            data: edStar4,
            backgroundColor: "#BBC5FA",
            borderSkipped: false,
          },
          {
            label: "3 Star",
            data: edStar3,
            backgroundColor: "#8FA0F7",
            borderSkipped: false,
          },
          {
            label: "2 Star",
            data: edStar2,
            backgroundColor: "#3D5AF1",
            borderSkipped: false,
          },
          {
            label: "1 Star",
            data: edStar1,
            backgroundColor: "#F8849D",
            borderSkipped: false,
          },
        ]
      };
      state.levelFeedback = levelData;
      state.educatorFeedback = educatorData;
      state.success = true;
      state.loading = false;
    },
    fetchedSubmission: (
      state,
      action: PayloadAction<any>
    ) => {
      const labels = action.payload.data.labels;
      // const labels = datas.labels;
      // const labels = ["Jan 2022", "Feb 2022", "Mar 2023", "Mar 2023"];
      // const assignmentCount = action?.payload?.data?.datasets[0]?.data;
      // const assignmentCount = [20, 40, 80, 100];
      // console.log("🚀 ~ assignmentCount:", assignmentCount)
      const datasets = [action.payload.data.datasets.map((data) => {
        return {
          label: data.label,
          fill: true,
          lineTension: 0.5,
          borderColor: "#0343FA",
          backgroundColor: (context) => {
            const chart = context.chart;
            const ctx = chart.ctx;

            // Create a linear gradient
            const gradient = ctx.createLinearGradient(0, 0, 0, chart.height);
            gradient.addColorStop(0, "#5782FC"); // Darker color at the top
            gradient.addColorStop(1, "rgba(87, 130, 252, 0.00)"); // Lighter, transparent color at the bottom

            return gradient; // Return the gradient as background color
          },
          borderWidth: 2,
          pointRadius: 0, // Set point radius to 0 to remove points
          pointHoverRadius: 0, // Set hover radius to 0 to keep it consistent
          data: data.data,
        }
      })];

      // const datasets = [
      //   {
      //     fill: true,
      //     lineTension: 0.5,
      //     borderColor: "#0343FA",
      //     backgroundColor: (context) => {
      //       const chart = context.chart;
      //       const ctx = chart.ctx;

      //       // Create a linear gradient
      //       const gradient = ctx.createLinearGradient(0, 0, 0, chart.height);
      //       gradient.addColorStop(0, "#5782FC"); // Darker color at the top
      //       gradient.addColorStop(1, "rgba(87, 130, 252, 0.00)"); // Lighter, transparent color at the bottom

      //       return gradient; // Return the gradient as background color
      //     },
      //     borderWidth: 2,
      //     pointRadius: 0, // Set point radius to 0 to remove points
      //     pointHoverRadius: 0, // Set hover radius to 0 to keep it consistent
      //     data: assignmentCount,
      //   },
      // ]
      state.assignments = { labels, datasets };
      state.success = true;
      state.loading = false;
    },

    fetchedgradeDistributionDashboard: (state, action: PayloadAction<any>) => {
      // console.log('fetchedgradeDistributionDashboard-->>');
      const { labels, datasets } = action.payload.data;
      state.gradesDistributionData = {
        labels,
        datasets: datasets?.map((dataset: any, index: number) => ({
          ...dataset,
          backgroundColor: ['#EE9797', '#FFB08F', '#FFEDB0', '#99E3B1'][index],
          borderRadius: 8,
          hoverBackgroundColor: 'rgba(0,0,0,0.2)',
          borderSkipped: false,
        })),
      };
      state.success = true;
      state.loading = false
    },

    fetchedAverageGradePercentage: (state, action: PayloadAction<any>) => {
      const { average_grade_percentages } = action.payload || {};

      // Get the identifiers and their corresponding average percentages
      const identifier = Object.keys(average_grade_percentages)[0];

      const averagePercentages = {
        id: identifier,
        average_percentage: average_grade_percentages[identifier]?.average_percentage,
        descriptive_grade: average_grade_percentages[identifier]?.descriptive_grade,
      };

      state.averageGradePercentages = averagePercentages;
      state.success = true;
      state.loading = false;
    },

    fetchedTotalActiveUsers: (state, action) => {
      const { userType, count } = action.payload.data;

      state.loading = false;
      state.totalActiveUsers = {
        ...state.totalActiveUsers,
        [userType]: count
      }; // Assuming data contains { learners: 0, educators: 0 }
    },
    fetchedAssignmentsStatus: (state, action) => {
      state.loading = false;
      state.assignmentsStatus = action.payload.data;
    },
    fetchedAverageTimeToMark: (state, action) => {
      state.loading = false;
      state.averageTimeToMark = action.payload.data.average_time_to_mark || "0 hour";
    },
    fetchedLearnerSatisfaction: (state, action) => {
      state.loading = false;
      if (action.payload.data.learnerSatisfaction) {
        state.learnerSatisfaction = action.payload.data.learnerSatisfaction.toFixed(2) || 0;
      }
      else {
        state.averageSatisfaction = action.payload.data.average_satisfaction || 0;
      }
    },
    fetchedAverageTimeToSubmit: (state, action) => {
      state.loading = false;
      state.averageTimeToSubmit = action.payload.data.average_time_to_submit || "0 hour";
    },

    fetchAverageTimeToMarkSlice: (state, action) => {
      state.averageTimeToMark = action.payload.average_time_to_mark
    },
    fetchAnalyticsOverTimeSlice: (state, action) => {
      state.analyticsOverTime = action.payload
    }
  }
});


export const {
  fetchedlearnerbyLevel,
  fetchedlearnerbyUnit,
  fetchedCorrectionrate,
  fetchedFeedback,
  fetchedSubmission,
  fetchedgradeDistributionDashboard,
  fetchedAverageGradePercentage,
  fetchAverageTimeToMarkSlice,
  fetchAnalyticsOverTimeSlice
} = analyticsSlice.actions;

export default analyticsSlice.reducer;

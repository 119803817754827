import React from "react";

interface NotificationListProps {
  data: {
    item: string;
    date: string;
    icon: string;
    isReviewCompleted: boolean;
  }[];
  role: string
}

const NotificationList: React.FC<NotificationListProps> = ({
  data,
  role
}) => {
  const containerStyles: React.CSSProperties = {
    height: "290px",
    overflowY: "scroll",
    scrollbarWidth: "none",
  };
  const heading: React.CSSProperties = {
    fontSize: "18.85px",
    fontWeight: "500",
    lineHeight: "16.86px",
    color: "#000000",
  };
  const listitemStyle: React.CSSProperties = {
    fontSize: "15.71px",
    fontWeight: "500",
    lineHeight: "19.01px",
    color: "#1C1D1D",
  };
  const dateStyle: React.CSSProperties = {
    fontSize: "15.08px",
    fontWeight: "400",
    lineHeight: "19px",
    color: "#1C1D1D",
  };

  const itemStyle: React.CSSProperties = {
    borderBottom: "1.03px solid",
    borderColor: "#ECECEC",
    padding: "25px",
    boxShadow: "0px 0px 39.85px 0px #00000012",
    borderRadius: "9.96px",
  };

  const labelStyle: React.CSSProperties = {
    width: "62.87px",
    height: "27.53px",
    borderRadius: "6px",
    color: "#FFFFFF",
    background: "#0343FA",
    fontSize: "15.71px",
    fontWeight: "500",
  };

  const labelStyleTwo: React.CSSProperties = {
    ...labelStyle,
    color: "#0343FA",
    background: "#0343FA0F"
  };
  return (
    <div className="Notification-container card-container" style={containerStyles}>
      <label className="heading" style={heading}>
        Notifications
      </label>
      <div className={`col-12`}>
        {data?.map(
          (detail: {
            item: string;
            date: string;
            icon: string;
            isReviewCompleted: boolean;
          }, index) => (
            <div
              className="d-flex align-items-center gap-3 mt-3"
              style={itemStyle}
              key={index}
            >
              <img src={detail.icon} alt="Logo" />
              <div style={listitemStyle}>
                <label className="d-block">{detail.item}</label>
                <label style={dateStyle}>{detail.date}</label>
              </div>
              <div
                style={detail.isReviewCompleted ? labelStyleTwo : labelStyle}
                className="ms-auto d-flex justify-content-center align-items-center"
              >
                {detail.isReviewCompleted ? "Review" : "View"}
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default NotificationList;

import * as React from 'react';
import { FieldProps } from 'formik';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker as MUIDatePicker } from '@mui/x-date-pickers/DatePicker';
import { InputAdornment } from '@mui/material';
import { Dayjs } from 'dayjs';
import COLORS from '../utils/Colors';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

interface DatePickerProps extends FieldProps {
    label?: string;
}

const DatePicker: React.FC<DatePickerProps> = ({ field, form, label }) => {
    const { name, value } = field;
    const { setFieldValue, setFieldTouched } = form;
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (date: Dayjs | null) => {
        setFieldValue(name, date);
        handleClose();
    };

    const datePickerStyle: React.CSSProperties = {
        backgroundColor: COLORS.WHITE,
        height: '54px',
        outline: 'none',
        boxShadow: 'none',
        padding: '0px',
        width: '100%',
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MUIDatePicker
            open={open}
            value={value || null}
            onChange={handleChange}
            onAccept={handleClose}
            onClose={handleClose}
            slotProps={{
                textField: {
                    onBlur: () => setFieldTouched(name, true),
                    onClick: () => handleOpen(),
                    variant: 'outlined',
                    fullWidth: false,
                    style: datePickerStyle,
                    InputProps: {
                        endAdornment: (
                            <InputAdornment position="end">
                                <CalendarTodayIcon />
                            </InputAdornment>
                        ),
                    },
                },
            }}
        />
    </LocalizationProvider>
    );
};

export default DatePicker;

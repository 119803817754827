import { CrossIcon } from "@/app/assets/icons/svg";
import { CLEAR_GRADING_ASSIGNMENT_STATE } from "@/app/redux/actionTypes";
import IMAGES from "@/app/utils/Images";
import React from "react";
import { Button as ButtonInput, Modal, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";

const SuccessModal = ({ open, setOpen,assesmentID }) => {
  const dispatch = useDispatch();
  return (
    <Modal show={open} className="success-modal">
      <Modal.Header className="w-100">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            width: "100%",
          }}
        >
          <ButtonInput
            variant="link"
            onClick={() => {
              setOpen(false);
              dispatch({
                type: CLEAR_GRADING_ASSIGNMENT_STATE
              });
            }}
          >
            <img src={CrossIcon} alt="" />
          </ButtonInput>
        </div>
      </Modal.Header>
      <Modal.Body>
        <Row className="w-100 d-flex gap-4 flex-column justify-content-center align-items-center">
          <img  src={IMAGES.FILE_CHECK} className="file-img" alt="Logo"/>  
          <label className="signature-text text-center success-popup-text">
            Review for assignment ID {assesmentID} is published.{" "}
          </label>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default SuccessModal;

import React from 'react'
import COLORS from '../utils/Colors';

type Props = {
    error?: string | null
}

const ErrorText: React.FC<Props> = ({ error }) => {

    const errorStyle: React.CSSProperties = {
        color: COLORS.ERROR,
        fontSize: '14px',
        fontStyle: 'italic',
        lineHeight: '14px',
        marginTop: '5px'
    };

    return (
        <span style={errorStyle}>{error}</span>
    )
}

export default ErrorText

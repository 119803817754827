import React, { FC, TextareaHTMLAttributes, ReactNode, useState } from 'react';
import { Row, Col } from 'react-bootstrap'; // Make sure to install react-bootstrap
import COLORS from '@utils/Colors';

interface Props extends TextareaHTMLAttributes<HTMLTextAreaElement> {
    leftIcon?: ReactNode;
    rightIcon?: ReactNode;
    placeholder: string;
    className?: string;
    error: string | null | undefined;
    label?: string;
    containerStyle?: React.CSSProperties;
}

const TextArea: FC<Props> = ({
    containerStyle,
    label,
    error,
    leftIcon,
    rightIcon,
    placeholder,
    className,
    ...props
}) => {
    const [focus, setFocus] = useState(false);

    const textAreaStyle: React.CSSProperties = {
        backgroundColor: focus ? COLORS.WHITE : COLORS.INPUT.BG,
        height: '100px', // Adjust height as needed
        border: 'none',
        outline: 'none',
        boxShadow: 'none',
        padding: leftIcon ? '0px' : '16px',
        ...(props.disabled && {
            backgroundColor: COLORS.INPUT.DISABLED,
            cursor: 'not-allowed',
            opacity: 0.6,
        }),
    };

    const groupStyle: React.CSSProperties = {
        borderWidth: 1,
        borderColor: error ? COLORS.ERROR : COLORS.BORDER.GRAY,
        borderStyle: 'solid',
        borderRadius: '.5rem',
        ...(props.disabled && {
            borderColor: COLORS.BORDER.DISABLEDINPUT,
        }),
    };

    const iconStyle: React.CSSProperties = {
        backgroundColor: focus ? COLORS.WHITE : COLORS.INPUT.BG,
        border: 'none',
        cursor: 'pointer',
    };

    const errorStyle: React.CSSProperties = {
        color: COLORS.ERROR,
        fontSize: '14px',
        fontStyle: 'italic',
        lineHeight: '14px',
        marginTop: '5px'
    };

    const labelStyle: React.CSSProperties = {
        fontSize: '14px',
        color: COLORS.BLACK,
        lineHeight: '20px',
    };

    return (
        <div className={`${className}`} style={containerStyle}>
            {label && (
                <Row>
                    <Col>
                        <h6 style={labelStyle}>{label}</h6>
                    </Col>
                </Row>
            )}
            <Row>
                <Col>
                    <div style={groupStyle} className="input-group">
                        {leftIcon && (
                            <span style={iconStyle} className="input-group-text">
                                {leftIcon}
                            </span>
                        )}
                        <textarea
                            data-testid={'textarea-field'}
                            style={textAreaStyle}
                            className="form-control"
                            placeholder={placeholder}
                            onFocus={() => setFocus(true)}
                            onBlur={() => setFocus(false)}
                            {...props}
                        />
                        {rightIcon && (
                            <span style={iconStyle} className="input-group-text">
                                {rightIcon}
                            </span>
                        )}
                    </div>
                </Col>
            </Row>
            {error && (
                <Row>
                    <Col>
                        <span style={errorStyle}>{error}</span>
                    </Col>
                </Row>
            )}
        </div>
    );
};

export default TextArea;

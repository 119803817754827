import { claimAssignment, createAssignment, fetchAssignmentData, fetchAssignments, fetchLearnerAssignments } from "@/app/api/Api";
import { FetchAssignmentsResponse } from "@/app/modules/types/types";
import { call, put, takeLatest } from "redux-saga/effects";
import { CLAIM_ASSIGNMENT, FETCH_ALL_ASSIGNMENTS, FETCH_ASSIGNMENTS, FETCHED_ALL_ASSIGNMENTS, FETCHED_ASSIGNMENTS, FETCHED_LEARNER_ASSIGNMENT, FETCHED_LEARNER_ASSIGNMENTS, GET_ASSIGNMENT, GET_ASSIGNMENTS, SET_ERROR } from "../actionTypes";
import { getErrorMessage } from "@/app/modules/helpers/ErrorHelper";


function* handleFetchAssignments(action: { type: string, payload: { page: string, limit: string, search: string, filterLevel: [], filterStatus: [], sortBy: string } }) {
    try {
        const { page, limit, search, sortBy } = action.payload
        const response = (yield call(fetchAssignments, page, limit, search, action.payload.filterLevel, action.payload.filterStatus, sortBy)) as FetchAssignmentsResponse;
        yield put({ type: FETCHED_ASSIGNMENTS, payload: { data: response.data } })
    } catch (error: any) {
        const errorMessage = getErrorMessage(error);
        yield put({ type: SET_ERROR, payload: { message: errorMessage } });
    }
}

function* handleFetchAllAssignments(action: { type: string, payload: { page: number, limit: number, search: string, userType: string, sortBy: string } }) {
    try {
        const { page, limit, search, userType, sortBy } = action.payload;
        const response = (yield call(fetchAssignmentData, page, limit, search, userType, sortBy)) as FetchAssignmentsResponse;
        yield put({ type: FETCHED_ALL_ASSIGNMENTS, payload: { data: response.data } })
    } catch (error: any) {
        const errorMessage = getErrorMessage(error);
        yield put({ type: SET_ERROR, payload: { message: errorMessage } });
    }
}

function* handleFetchLearnerAssignments(action: { type: string, payload: { page: number, limit: number, search: string, type: string, filter: [], sortBy: string } }) {
    try {
        const { page, limit, search, type, filter, sortBy } = action.payload
        const response = (yield call(fetchLearnerAssignments, page, limit, search, type, filter, sortBy)) as any;
        yield put({ type: FETCHED_LEARNER_ASSIGNMENTS, payload: { data: response.data } })
    } catch (error: any) {
        const errorMessage = getErrorMessage(error);
        yield put({ type: SET_ERROR, payload: { message: errorMessage } });
    }
}

function* handleCreateAssignments(action: { type: string, payload: any}) {
    console.log("🚀 ~ function*handleCreateAssignments ~ payload:", action.payload)
    try {
        const response = (yield call(createAssignment, action.payload)) as any;
        yield put({ type: GET_ASSIGNMENTS, payload: { data: response.data } })
    } catch (error: any) {
        const errorMessage = getErrorMessage(error);
        yield put({ type: SET_ERROR, payload: { message: errorMessage } });
    }
}

function* handleClaimAssignment(action: { type: string, payload: any}) {
    try {
        yield call(claimAssignment, action.payload.assignmentId);
        yield put({ type: FETCH_ASSIGNMENTS, payload: action.payload });
    } catch (error: any) {
        const errorMessage = getErrorMessage(error);
        yield put({ type: SET_ERROR, payload: { message: errorMessage } });
    }
}

function* assignmentSaga() {
    yield takeLatest(FETCH_ASSIGNMENTS, handleFetchAssignments);
    yield takeLatest(FETCH_ALL_ASSIGNMENTS, handleFetchAllAssignments);
    yield takeLatest(FETCHED_LEARNER_ASSIGNMENT, handleFetchLearnerAssignments);
    yield takeLatest(GET_ASSIGNMENT, handleCreateAssignments);
    yield takeLatest(CLAIM_ASSIGNMENT, handleClaimAssignment);
}

export default assignmentSaga
import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Filler,
} from 'chart.js';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';

interface assignmentsProps {
    labelText: string;
    // startDate: Date | any;
    // endDate: Date | any;
}

// Register required components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, Filler);

const AssignmentsDetailNew: React.FC<assignmentsProps> = ({ labelText }) => {

    const gradeDistributionData = useSelector((state: RootState) => state.analytics.gradesDistributionData)
    // console.log("🚀 ~ gradeDistributionData:", gradeDistributionData)

    const [chartData, setChartData] = useState<any>({
        labels: [],
        datasets: []
    })

    const containerStyles: React.CSSProperties = {
        height: "290px",
    };

    const heading: React.CSSProperties = {
        fontSize: "13.93px",
        fontWeight: "700",
        lineHeight: "16.86px",
        color: "#000000",
    };

    const chartContainerStyle: React.CSSProperties = {
        border: "1px solid transparent",
        borderColor: "#E5E7E7",
        borderRadius: "8.36px",
        margin: "0px",
        // width:"auto",
        // height: "25vh"
        height: '200px',
        width: '100%',
    };

    const defaultData = {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May'],
        datasets: [
            {
                label: 'Fail',
                data: [30, 40, 2, 10, 3], // Data for total assignments
                backgroundColor: '#EE9797', // Solid dark blue for completed
                borderRadius: {
                    topLeft: 0,
                    topRight: 0,
                    bottomLeft: 10,
                    bottomRight: 10,
                },
                borderSkipped: false,
                categoryPercentage: 0.6, // Control the bar spacing
                barThickness: 30
            },
            {
                label: 'Low Pass',
                data: [2, 10, 3, 4, 2], // Data for completed assignments
                backgroundColor: '#FFB08F', // Light transparent blue for total
                borderRadius: {
                    topLeft: 10,
                    topRight: 10,
                    bottomLeft: 0,
                    bottomRight: 0,
                },
                borderSkipped: false,
                categoryPercentage: 0.6, // Control the bar spacing
                barThickness: 30
            },
            {
                label: 'Pass',
                data: [2, 1, 30, 4, 2], // Data for completed assignments
                backgroundColor: '#FFEDB0', // Light transparent blue for total
                borderRadius: {
                    topLeft: 10,
                    topRight: 10,
                    bottomLeft: 0,
                    bottomRight: 0,
                },
                borderSkipped: false,
                categoryPercentage: 0.6, // Control the bar spacing
                barThickness: 30
            },
            {
                label: 'High Pass',
                data: [20, 1, 3, 40, 20], // Data for completed assignments
                backgroundColor: '#99E3B1', // Light transparent blue for total
                borderRadius: {
                    topLeft: 10,
                    topRight: 10,
                    bottomLeft: 0,
                    bottomRight: 0,
                },
                borderSkipped: false,
                categoryPercentage: 0.6, // Control the bar spacing
                barThickness: 30
            },
        ],
    };

    useEffect(() => {

        if (gradeDistributionData && gradeDistributionData.labels.length > 0 && gradeDistributionData.datasets.length > 0) {
            setChartData({
                labels: gradeDistributionData?.labels,
                datasets: gradeDistributionData?.datasets
            })
        } else {
            setChartData(defaultData)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gradeDistributionData])



    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top' as const,
                labels: {
                    boxWidth: 12,
                    font: {
                        size: 12,
                    },
                },
            },
            tooltip: {
                position: 'nearest' as 'nearest', // Use 'nearest' type explicitly
                yAlign: 'bottom' as const, // Tooltip above bars
                callbacks: {
                    label: function (tooltipItem: any) {
                        const label = tooltipItem.dataset.label || 'Label';
                        const value = tooltipItem.raw; // Access the data value
                        return `${label}: ${value}`; // Display in "Label: Value" format
                    }
                },
                displayColors: false, // Hide the color box in tooltips
                backgroundColor: '#2E2E48', // Dark background to match design
                bodyFont: {
                    size: 14, // Tooltip font size
                },
                padding: 8, // Adjust padding for better visibility
                titleColor: '#ffffff', // White text in the tooltip
                bodyColor: '#ffffff', // White body text in the tooltip
            },
        },
        scales: {
            x: {
                stacked: true,
                grid: {
                    display: false,
                },
                padding: 10,
                offset: true,
            },
            y: {
                border: { dash: [8, 8] },
                stacked: true,
                grid: {
                    display: true,
                    color: 'rgba(0, 0, 0, 0.1)',
                    borderColor: 'rgba(0, 0, 0, 0.5)',
                    tickLength: 1,
                },
                min: 0,
                max: 80,
                ticks: {
                    stepSize: 20,
                    padding: 10,
                },
                margin: 10,
            },
        },
    };
    return (
        <div className="container card-container" style={containerStyles}>
            <label className="heading" style={heading}>
                {labelText}
            </label>
            <div className="chart-container" style={chartContainerStyle}>
                <Bar data={chartData} options={options} />
            </div>
        </div>
    );
};

export default AssignmentsDetailNew;

import FilterDropdown from "@/app/components/Table/TableFilter";
import { RootState } from "@/app/redux/store";
import { activeEductorsOptions, activeOptions, educatorActiveOptions, headingsForEducator, sortOptions } from "@/app/utils/helper";
import AssignmentList from "@components/AssignmentList/AssignmentList";
import Button from "@components/Button";
import CreateNewAssignment from "@components/CreateAssignment";
import Input from "@components/Input";
import Layout from "@components/Layout";
import PaginationButton from "@components/Pagination";
import IMAGES from "@utils/Images";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ActiveLearnerAssignment from "../../assets/JSON/activeLearnerAssignment.json";
import Assignments from "../../assets/JSON/assignment.json";
import "./Assignment.module.style.css";
import { useDispatch } from "react-redux";
import { FETCH_ASSIGNMENTS } from "@/app/redux/actionTypes";

interface AssignmentsProps { }
const Assignment: React.FC<AssignmentsProps> = ({ }) => {
  const { userType } = useSelector((state: RootState) => state.auth);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [pageNo, setPageNo] = useState<number>(1);
  const [open, setOpen] = useState<boolean>(false);
  const [isFilterStatus, setIsFilterStatus] = useState(false);
  const [filter, setFilter] = useState<any>("");
  console.log("🚀 ~ filter:", filter)
  const { "Level": filterLevel, "Assignment Status": filterStatus } = filter;
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState("");
  const [openUpload, setOpenUpload] = useState(false);
  const [selectedItem, setSelectedItem] = useState(ActiveLearnerAssignment[0]);
  const [supervisorAssignment, setSupervisorAssignment] = useState(Assignments);

  const [isSupervisorSortStatus, setIsSupervisorSortStatus] = useState(false);
  const [supervisorSort, setSupervisorSort] = useState<any>("");
  const dispatch = useDispatch()



  const fetchAssignments = (page, limit, search = "", filterLevel = "", filterStatus = "", sortBy = "") => {
    console.log("In fetch assignments");
    dispatch({
      type: FETCH_ASSIGNMENTS,
      payload: { page, limit, search, filterLevel, filterStatus, sortBy }
    })
  }

  useEffect(() => {
    // if (userType === "supervisor") {
    fetchAssignments(pageNo, rowsPerPage, searchTerm, filterLevel, filterStatus, "")
    // }
  }, [])

  const componentData =
    userType === "supervisor"
    && supervisorAssignment
  const data = Array.isArray(componentData) ? componentData : [];
  console.log("🚀 ~ data:", data)

  const startIndex = (pageNo - 1) * rowsPerPage;
  const [paginatedData, setPaginatedData] = useState(
    data.slice(startIndex, startIndex + rowsPerPage)
  );

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setPageNo(1);
  };

  const handlePageChange = (event: any) => {
    const newRowsPerPage = Number(event.target.value);
    setRowsPerPage(newRowsPerPage);
    setPageNo(1);
  };

  const handleViewDetails = (item: any) => {
    setSelectedItem(item);
    setOpenUpload(true);
  };
  const handlePagination = (page: number) => {
    setPageNo(page);
    const startIndex = (page - 1) * rowsPerPage;
    setPaginatedData(data.slice(startIndex, startIndex + rowsPerPage));
  };

  const handleStatusFilter = (status) => {
    console.log("🚀 ~ handleStatusFilter ~ status:", status)

  };


  const handleSupervisorSortData = (status: (string | number)[]) => {
    setSupervisorSort(status);
  };

  useEffect(() => {
    let supervisorAssignmentsToSet = [...Assignments];

    if (supervisorAssignmentsToSet.length > 0) {
      switch (supervisorSort) {
        case "Name A-Z":
          supervisorAssignmentsToSet.sort((a, b) => a.title.localeCompare(b.title));
          break;
        case "Name Z-A":
          supervisorAssignmentsToSet.sort((a, b) => b.title.localeCompare(a.title));
          break;
        case "Date  - Newest to Oldest":
          supervisorAssignmentsToSet.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
          break;
        case "Date  - Oldest to Newest":
          supervisorAssignmentsToSet.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
          break;
        default:
          break;
      }
    }

    setSupervisorAssignment(supervisorAssignmentsToSet);
  }, [supervisorSort]);


  return (
    <Layout>
      <div className="assignment-container m-4 vh-100">
        <div className="sticky-top z-3 bg-white">
          <div className="heading-container">
            <span className="heading">Assignments</span>
            <span className="label">Manage course assignments</span>
          </div>
          {/* For Supervisor and Educator */}
          {(userType === "supervisor" ||
            userType === "educator" ||
            userType === "admin") && (
              <>
                <div className="d-flex flex-lg-row flex-column justify-content-between mt-4 align-items-lg-center">
                  <Input
                    leftIcon={<img src={IMAGES.SEARCH_LIGHT} alt="" />}
                    placeholder={"Search list"}
                    type={"text"}
                    className={"user-search-input"}
                    error={null}
                    value={searchTerm}
                    onChange={handleSearchChange}
                  />
                  <div className="buttons d-flex flex-lg-row flex-column gap-lg-4 gap-2 mt-lg-0 mt-2">
                    {(userType === "supervisor" || userType === "admin") && (
                      <div className="d-flex gap-2">
                        <FilterDropdown
                          type="sort"
                          options={sortOptions}
                          onFilterSelect={handleSupervisorSortData}
                          placeholder={
                            <Button
                              label={""}
                              className={"assignment-btn"}
                              onClick={() => {
                                setIsSupervisorSortStatus(!isSupervisorSortStatus);
                              }}
                              isIcon={true}
                              icon={IMAGES.SORT_ICON}
                            />
                          }
                        />
                        <FilterDropdown
                          type="filter"
                          options={activeEductorsOptions}
                          headings={headingsForEducator}
                          isMultiple={true}
                          onFilterSelect={handleStatusFilter}
                          placeholder={
                            <Button
                              label={"Filter"}
                              className={"assignment-btn"}
                              onClick={() => {
                                setIsFilterStatus(!isFilterStatus);
                              }}
                              isIcon={true}
                              icon={IMAGES.FILTER}
                            />
                          }
                        />
                      </div>
                    )}
                    {userType === "educator" && (
                      <FilterDropdown
                        type="filter"
                        options={educatorActiveOptions}
                        onFilterSelect={handleStatusFilter}
                        placeholder={
                          <Button
                            label={"Filter"}
                            className={"assignment-btn"}
                            onClick={() => {
                              setIsFilterStatus(!isFilterStatus);
                            }}
                            isIcon={true}
                            icon={IMAGES.FILTER}
                          />
                        }
                      />
                    )}
                    {/* <div className="status-filter">
                    <Button
                      label={"Filter"}
                      className={"assignment-btn"}
                      onClick={() => {
                        setIsFilterStatus(!isFilterStatus);
                      }}
                      isIcon={true}
                      icon={IMAGES.FILTER}
                    />

                    {isFilterStatus &&
                      (userType === "supervisor" || userType === "admin") && (
                        <div className="status-popup">
                          <div
                            className="status-option"
                            onClick={() => handleStatusSelect("")}
                          >
                            All
                          </div>
                          <div
                            className="status-option"
                            onClick={() => handleStatusSelect("Done")}
                          >
                            Done
                          </div>
                          <div
                            className="status-option"
                            onClick={() => handleStatusSelect("In Progress")}
                          >
                            In Progress
                          </div>
                          <div
                            className="status-option"
                            onClick={() => handleStatusSelect("Pending")}
                          >
                            Pending
                          </div>
                        </div>
                      )}
                    {isFilterStatus && userType === "educator" && (
                      <div className="status-popup">
                        <div
                          className="status-option"
                          onClick={() => handleStatusSelect("")}
                        >
                          All
                        </div>
                        <div
                          className="status-option"
                          onClick={() => handleStatusSelect("Unassigned")}
                        >
                          Unassigned
                        </div>
                        <div
                          className="status-option"
                          onClick={() => handleStatusSelect("Assigned")}
                        >
                          Assigned
                        </div>
                      </div>
                    )}
                  </div> */}
                    <Button
                      label={"Create Assignment"}
                      className={"assignment-btn primary-btn relative"}
                      onClick={() => {
                        setOpen(true);
                      }}
                      isIcon={true}
                      icon={IMAGES.PLUS}
                    />
                  </div>
                </div>
                <hr className="my-3 w-100 bg-light" style={{ height: "1px" }} />
              </>
            )}
          {paginatedData.length > 0 ?
            <>
              <AssignmentList
                data={paginatedData}
                onViewDetails={handleViewDetails}
              />
              <div className="pagination-section my-4 d-flex flex-lg-row flex-column gap-lg-0">
                <div className="col-lg-4 col-12">
                  <div className="d-flex align-items-center gap-2 page-dropdown">
                    <label>Show</label>
                    <select
                      name="number"
                      id="number"
                      className="page-dropdown"
                      onChange={handlePageChange}
                    >
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="15">15</option>
                      <option value="20">20</option>
                    </select>
                    <label htmlFor="">Row</label>
                  </div>
                </div>
                <div className="col-lg-4 col-12 mt-lg-0 mt-2">
                  <div className="flex justify-center">
                    <PaginationButton
                      count={totalPages}
                      page={pageNo}
                      changePage={(e, page) => {
                        console.log(page);
                        handlePagination(page);
                      }} />
                  </div>
                </div>
              </div>
            </>
            : <div>No Record Found.</div>}
        </div>
      </div>
      {open && (userType === "supervisor" || userType === "admin") && (
        <CreateNewAssignment
          isOpen={open}
          onClose={() => setOpen((prev) => !prev)}
        />
      )}
    </Layout>
  );
};

export default Assignment;

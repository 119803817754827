import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { Formik } from 'formik';
import Input from './Input';
import Button from '@components/Button'
import COLORS from '../utils/Colors';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { coHortSchema } from '../modules/validations/validations';
import ErrorField from './ErrorField';
import Asterisk from './Asterisk';
import DropdownWithSearchAndSelectAll from './SearchableDropdown';
import { useDispatch } from 'react-redux';
import { _USER_CLEAR_SUCCESS, CREATE_COHORT, FETCH_LEVELS, FETCH_USERS_FOR_COHORT } from '../redux/actionTypes';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { toAbsoluteUrl } from '../modules/helpers/assetHelper';
import Swal from 'sweetalert2';
import Loader from './Loading';

type Props = {
    visible: boolean;
    handleClose: () => void;
    onSubmit: () => void;
};


const AddCohort: React.FC<Props> = ({ visible, handleClose }) => {

    const [show, setShow] = useState<boolean>(false);

    const { learners, educators, success, loading } = useSelector((state: RootState) => state.user)

    const { levels } = useSelector((state: RootState) => state.cohort)

    useEffect(() => {
        setShow(visible);
    }, [visible]);

    const labelStyle: React.CSSProperties = {
        fontSize: '14px',
        color: COLORS.BLACK,
        lineHeight: '20px',
    };

    const dispatch = useDispatch()

    const headers = ['Label', 'Email', 'Level'];

    const handleEducatorsChange = useCallback((newSelectedUsers: any, setFieldValue: any) => {
        setFieldValue('educatorIds', newSelectedUsers);
    }, []);

    const handleLearnerChange = useCallback((newSelectedUsers: any, setFieldValue: any) => {
        setFieldValue('learnerIds', newSelectedUsers);
    }, []);

    useEffect(() => {
        dispatch({ type: FETCH_LEVELS })
    }, [])


    const fetchUsers = (id) => {
        dispatch({
            type: FETCH_USERS_FOR_COHORT,
            payload: {
                userType: ['educator', 'learner'],  // Pass multiple user types as an array
                level: id
            }
        });
    }

    //<span style='font-weight:bold;'>User Name</span>

    useEffect(() => {
        if (success) {
            Swal.fire({
                html: `<p style='font-size: 16px;'>${success}<br/></p>`,
                imageUrl: `${toAbsoluteUrl('/media/modal/confirm.png')}`,
                imageWidth: 80,
                imageHeight: 80,
                imageAlt: 'Custom image',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'OK',
                allowOutsideClick: false
            }).then((result) => {
                if (result.isConfirmed) {
                    dispatch({ type: _USER_CLEAR_SUCCESS })
                    handleClose();
                }
            });
        }
    }, [success])


    return (
        <Modal size='lg' centered backdrop="static" show={show} onHide={handleClose}>
            {loading && <Loader />}
            <Modal.Header closeButton>
                <Modal.Title>Add New Cohort</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <span className='text-muted fs-6'>
                    Required fields are marked with an asterisk <Asterisk />
                </span>
                <div className="row">
                    <Formik

                        initialValues={{ name: '', level: '', educatorIds: [], learnerIds: [] }}
                        onSubmit={(values) => {
                            dispatch({ type: CREATE_COHORT, payload: { values } })
                        }}
                        validationSchema={coHortSchema}
                    >
                        {({ handleSubmit, handleChange, handleBlur, setFieldValue, values, errors, touched }) => (
                            <>
                                <div className="col-12 col-sm-12 col-lg-7 pt-3">
                                    <Input
                                        onChange={handleChange('name')}
                                        onBlur={handleBlur('name')}
                                        value={values.name}
                                        label={'Cohort Name'}
                                        name={'name'}
                                        type="text"
                                        placeholder={''}
                                        error={errors.name && touched.name ? errors.name : null}
                                    />
                                    <h6 style={labelStyle} className="form-check-label mb-2 mt-2">
                                        {"Level"}
                                    </h6>
                                    <Select
                                        name="level"
                                        sx={{ width: '100%' }}
                                        onBlur={(e) => fetchUsers(e.target.value)}
                                        value={values.level}
                                        onChange={handleChange} // Directly pass handleChange, no need for a wrapper function
                                    >
                                        {levels.map((item, index) => (
                                            <MenuItem value={item?.id}>{item?.name}</MenuItem>
                                        ))}
                                    </Select>
                                    <ErrorField error={errors.level && touched.level ? errors.level : null} />
                                </div>
                                <div className="col-12 col-sm-12 col-lg-9 pb-3">
                                    <h6 style={labelStyle} className="form-check-label mb-2 mt-2">
                                        {"Select Educators"}
                                    </h6>
                                    <DropdownWithSearchAndSelectAll
                                        options={educators}
                                        headers={headers}
                                        selectedOptions={values.educatorIds}
                                        onSelectionChange={(newSelectedUsers: any) => handleEducatorsChange(newSelectedUsers, setFieldValue)}
                                    />
                                    <ErrorField error={touched.educatorIds && errors.educatorIds ? errors.educatorIds : null} />
                                    <h6 style={labelStyle} className="form-check-label mb-2 mt-2">
                                        {"Select Learners"}
                                    </h6>
                                    <DropdownWithSearchAndSelectAll
                                        options={learners}
                                        headers={headers}
                                        selectedOptions={values.learnerIds}
                                        onSelectionChange={(newSelectedUsers: any) => handleLearnerChange(newSelectedUsers, setFieldValue)}
                                    />
                                    <ErrorField error={errors.learnerIds && touched.learnerIds ? errors.learnerIds : null} />
                                </div>
                                <Modal.Footer style={{ position: 'sticky' }}>
                                    <div className="column">
                                        <Button
                                            label={'Cancel'}
                                            backgroundColor={COLORS.WHITE}
                                            onClick={handleClose}
                                            labelStyle={{ color: COLORS.PRIMARY, fontSize: '14px' }}
                                        />
                                        <Button
                                            className='ms-2'
                                            label={'Create'}
                                            backgroundColor={COLORS.PRIMARY}
                                            onClick={handleSubmit}
                                            style={{ height: 40 }}
                                            labelStyle={{ color: COLORS.WHITE, fontSize: '14px' }}
                                        />
                                    </div>
                                </Modal.Footer>
                            </>
                        )}
                    </Formik>
                </div>
            </Modal.Body>

        </Modal >
    )
}

export default AddCohort;
import IMAGES from "@utils/Images";
import React, { useEffect, useRef, useState } from "react";
import Input from "./Input";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { Link } from "react-router-dom";

type HeaderProps = {
  onToggleSidebar: () => void;
  onClickProfileButton?: () => void;
};

const Header: React.FC<HeaderProps> = ({ onToggleSidebar, onClickProfileButton }) => {
  const { userType } = useSelector((state: RootState) => state.auth)
  const [searchTerm, setSearchTerm] = useState("");
  const [isSearchModal, setIsSearchModal] = useState(false);
  const [isNotificationModal, setIsNotificatinModal] = useState<boolean>(false);
  const searchResultsRef = useRef<HTMLDivElement | null>(null);
  const [filteredPages, setFilteredPages] = useState<{ name: string; path: string, selectedUser?: string }[]>([]);
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase();
    setIsSearchModal(true)
    setSearchTerm(value);
    const availablePages = pagesByRole[userType];
    const filtered = availablePages.filter((page: any) =>
      page.name.toLowerCase().includes(value)
    );
    setFilteredPages(filtered);
  };

  const handleSearchAtClick = () => {
    setIsSearchModal(true)
    const availablePages = pagesByRole[userType];
    setFilteredPages(availablePages);
  };
  const pagesByRole = {
    learner: [
      { name: "Assignments", path: "/assignment" },
      { name: "Grading & Feedback", path: "/grading-feedback" },
      { name: "Settings", path: "/setting" },
    ],
    educator: [
      { name: "Assignments", path: "/assignment" },
      { name: "Grading & Feedback", path: "/grading-feedback" },
      { name: "Cohorts", path: "/users", selectedUser: 'Cohort' },
      { name: "Learners", path: "/users", selectedUser: 'Learner' },
      { name: "Settings", path: "/setting" },
    ],
    supervisor: [
      { name: "Assignments", path: "/assignment" },
      { name: "Educator", path: "/users", selectedUser: 'Educator' },
      { name: "Cohorts", path: "/users", selectedUser: 'Cohort' },
      { name: "Learners", path: "/users", selectedUser: 'Learner' },
      { name: "Analytics", path: "/analytics" },
      { name: "Settings", path: "/setting" },
    ],
    admin: [
      { name: "Assignments", path: "/assignment" },
      { name: "Supervisors", path: "/users", selectedUser: 'Supervisor' },
      { name: "Educator", path: "/users", selectedUser: 'Educator' },
      { name: "Cohorts", path: "/users", selectedUser: 'Cohort' },
      { name: "Learners", path: "/users", selectedUser: 'Learner' },
      { name: "Settings", path: "/setting" },
      { name: "Analytics", path: "/analytics" },
    ],
  };
  const inputRef = useRef<HTMLDivElement>(null);
  const [dropdownStyles, setDropdownStyles] = useState<React.CSSProperties>({});
  const handleClickOutside = (event: any) => {
    setIsNotificatinModal(false);
    if (searchResultsRef.current && !searchResultsRef.current.contains(event.target)) {
      setIsSearchModal(false);
    }
  };
  useEffect(() => {
    if (inputRef.current) {
      const inputRect = inputRef.current.getBoundingClientRect();
      setDropdownStyles({
        top: `${inputRect.bottom}px`,
        right: `${window.innerWidth - inputRect.right}px`,
        width: `${inputRect.width}px`,
      });
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [inputRef, searchTerm]);

  const headerStyles: React.CSSProperties = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "86px",
    padding: "0px 32px 0px 32px",
    backgroundColor: "white",
    borderBottom: "1px solid #D3D3D3",
  };

  const logoStyle: React.CSSProperties = {
    width: "150px",
    height: "auto",
  };

  const feedbackLabelStyles: React.CSSProperties = {
    width: "auto",
    minWidth: "123px",
    height: "32px",
    padding: "5px 12px",
    opacity: "1",
    fontSize: "14px",
    fontWeight: "500",
    color: "#1C1D22",
    background: "#FEF5EA",
    border: "1px solid #FEF5EA",
    borderRadius: "8px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    display: "flex",
    alignItems: "center",
  };

  const searchResultsStyles: React.CSSProperties = {
    position: "absolute",
    backgroundColor: "white",
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
    zIndex: 1000,
    padding: "10px 0",
    ...dropdownStyles,
  };

  const searchResultItemStyles: React.CSSProperties = {
    padding: "8px 16px",
    fontSize: "14px",
    color: "#1C1D22",
    cursor: "pointer",
    transition: "background-color 0.2s ease-in-out",
    display: "block",
    textDecoration: "none",
  };

  return (
    <header className="sticky-top">
      <div style={headerStyles}>
        <div className="img-section" onClick={() => { window.location.href = "/dashboard" }}>
          <img src={IMAGES.LOGIN_LOGO} alt="Logo" style={logoStyle} />
        </div>
        <div
          className="fw-bold text-dark fs-2 d-md-none d-block"
          onClick={onToggleSidebar}
        >
          ☰
        </div>
        <div className="d-md-flex d-none gap-4 align-items-center" ref={inputRef}>
          <Input
            leftIcon={<img src={IMAGES.SEARCH} alt="SEARCH" />}
            placeholder={"Search"}
            type={"text"}
            className={"header-search-input"}
            error={null}
            value={searchTerm}
            onChange={handleSearch}
            onFocus={handleSearchAtClick}
            onClick={handleSearchAtClick}
          />

          <img onClick={onClickProfileButton} src={IMAGES.PROFILE} alt="Logo" className="h-[32px]" />
          <img src={IMAGES.NOTIFICATION} onClick={() => setIsNotificatinModal(true)} alt="Logo" className="h-[30px]" />
          <div style={feedbackLabelStyles}>Give Feedback</div>
        </div>
      </div>
      {filteredPages.length > 0 && isSearchModal && (
        <div style={searchResultsStyles} ref={searchResultsRef} >
          <ul style={{ listStyle: "none", padding: "0", margin: "0" }}>
            <li style={searchResultItemStyles} className="ms-3"><b>{userType === "learner" ? "Links" : "Pages"}</b></li>
            <hr />
            {filteredPages.map((page, index) => (
              <li
                key={index}
                style={searchResultItemStyles}
                onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#F5F5F5")}
                onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "transparent")}
                onClick={() => setIsSearchModal(false)}
              >
                <Link
                  to={
                    page.selectedUser
                      ? `${page.path}?name=${encodeURIComponent(page.selectedUser)}`
                      : page.path
                  }
                  style={searchResultItemStyles}
                >
                  {page.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* WIP - Notification Model  */}
      {isNotificationModal && (
        <div style={{
          position: 'fixed',
          // top: '20px',
          right: '20px',
          width: '400px',
          backgroundColor: '#fff',
          borderRadius: '10px',
          padding: '20px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          fontFamily: 'Arial, sans-serif',
          zIndex: 1000,
        }} ref={searchResultsRef}>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '10px',
          }}>
            <h3>Notifications</h3>
            <button style={{
              padding: '5px 10px',
              backgroundColor: '#007bff',
              color: '#fff',
              borderRadius: '5px',
              fontSize: '12px',
              border: 'none',
              cursor: 'pointer',
            }}>Mark all as read</button>
          </div>

          <div style={{
            display: 'flex',
            marginBottom: '15px',
            padding: '10px',
            borderBottom: '1px solid #e0e0e0',
          }}>
            <div style={{
              width: '40px',
              height: '40px',
              borderRadius: '50%',
              backgroundColor: '#007bff',
              color: '#fff',
              fontSize: '18px',
              textAlign: 'center',
              lineHeight: '40px',
              marginRight: '15px',
            }}>JD</div>
            <div style={{ flexGrow: 1 }}>
              <div style={{
                fontSize: '14px',
                fontWeight: 'bold',
                marginBottom: '5px',
              }}>
                New assignment submitted by a learner.
              </div>
              <div style={{
                fontSize: '12px',
                color: '#777',
                marginBottom: '5px',
              }}>Today</div>
              <button style={{
                padding: '5px 10px',
                backgroundColor: '#007bff',
                color: '#fff',
                borderRadius: '5px',
                fontSize: '12px',
                border: 'none',
                cursor: 'pointer',
              }}>View Assignment</button>
            </div>
          </div>

          <div style={{
            display: 'flex',
            marginBottom: '15px',
            padding: '10px',
            borderBottom: '1px solid #e0e0e0',
          }}>
            <div style={{
              width: '40px',
              height: '40px',
              borderRadius: '50%',
              backgroundColor: '#28a745',
              color: '#fff',
              fontSize: '18px',
              textAlign: 'center',
              lineHeight: '40px',
              marginRight: '15px',
            }}>AT</div>
            <div style={{ flexGrow: 1 }}>
              <div style={{
                fontSize: '14px',
                fontWeight: 'bold',
                marginBottom: '5px',
              }}>
                New grading and feedback update.
              </div>
              <div style={{
                fontSize: '12px',
                color: '#777',
                marginBottom: '5px',
              }}>07 Oct 2024</div>
              <button style={{
                padding: '5px 10px',
                backgroundColor: '#007bff',
                color: '#fff',
                borderRadius: '5px',
                fontSize: '12px',
                border: 'none',
                cursor: 'pointer',
              }}>View Feedback</button>
            </div>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;

import IMAGES from "@utils/Images";
import React, { useState } from "react";
import { Button, Col, Dropdown, Modal, Row } from "react-bootstrap";
import "./style.css";
import { useSelector } from "react-redux";
import { RootState } from "@/app/redux/store";
import { CommonFileCheckIcon, CrossIcon } from "@/app/assets/icons/svg";
type marker = {
  id: string,
  name: string,
  levels: number[],
  avatar: string
}

export type listitemType = {
  assessmentId: string,
  code: string,
  title: string,
  date: string,
  label: string,
  description: string,
  assignmentBriefFile: string,
  assessmentCriteriaFile: string,
  lastModerationDate: string,
  markers: marker[]
}
interface AssignmentListProps {
  data: listitemType[];
  activeTab?: string | "active";
  onViewDetails?: (item?: listitemType) => void;
}
const AssignmentList: React.FC<AssignmentListProps> = ({ data, activeTab, onViewDetails }) => {
  console.log("data----", data);

  const { userType } = useSelector((state: RootState) => state.auth)
  // const [userType,setUserType] = useState("educator");
  const [isAssigned, setIsAssigned] = useState(false);
  const [isAssignedAssessment, setIsAssignedAssessment] = useState("");
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const handleToggle = (id: any) => {
    setOpenDropdownId(openDropdownId === id ? null : id);
  };
  const handleAssignEducator = (index: number) => {
    data[index].label = "Unassigned"
    setIsAssigned(true);
    setIsAssignedAssessment(data[index].assessmentId);
  }
  const onClose = () => {
    setIsAssigned(false);
    setIsAssignedAssessment("");
  }

  const handleDownload = (fileType: string, index: number) => {
    let pdfUrl: any;
    let fileName: any;

    if (fileType === 'assignment') {
      pdfUrl = data[index].assignmentBriefFile;
      fileName = `assignment${data[index].assessmentId}.pdf`;
    } else if (fileType === 'criteria') {
      pdfUrl = data[index].assessmentCriteriaFile;
      fileName = `criteria${data[index].assessmentId}.pdf`;
    }

    if (fileType === 'assignment') {
      pdfUrl = data[index].assignmentBriefFile;
      fileName = `assignment${data[index].assessmentId}.pdf`;
    } else if (fileType === 'criteria') {
      pdfUrl = data[index].assessmentCriteriaFile;
      fileName = `criteria${data[index].assessmentId}.pdf`;
    }

    const link = document.createElement('a');
    link.href = pdfUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="table-container z-2">
      <table className="assignment-table">
        <thead className="sticky-top z-1">
          <tr>
            <th scope="col" className="table-heading first-child">Assignment ID</th>
            <th scope="col" className="table-heading">Unit Tittle</th>
            <th scope="col" className="table-heading">Released Date</th>
            <th scope="col" className="table-heading">Status</th>
            <th scope="col" className="table-heading">Actions</th>
          </tr>
        </thead>
        <tbody className="z-0">
          {data?.map((item, index) => (
            <tr>
              <td scope="row" className="table-cell first-child">{item.assessmentId}</td>
              <td className="title-column table-cell">{item.title}</td>
              <td className="table-cell">{item.date}</td>
              <td className="table-cell">
                {(userType === "supervisor" || userType === "admin") && <div
                  className={`d-flex align-items-center gap-2  ${item.label === "Done"
                    ? "label-success"
                    : item.label === "Pending"
                      ? "label-danger"
                      : "label-primary"
                    }`}
                >
                  <span className="border rounded-circle p-1" />
                  <label className="item">{item.label}</label>
                </div>}
                {userType === "educator" && <div
                  className={`d-flex align-items-center gap-2  ${item.label === "Unassigned"
                    ? "label-success"
                    : "label-primary"
                    }`}
                >
                  <span className="border rounded-circle p-1" />
                  <label className="item">{item.label}</label>
                </div>}
                {userType === "learner" && activeTab === "active" && <div
                  className={`d-flex align-items-center gap-2  ${item.label === "New"
                    ? "label-success"
                    : item.label === "Resubmission"
                      ? "label-danger"
                      : "label-primary"
                    }`}
                >
                  <span className="border rounded-circle p-1" />
                  <label className="item">{item.label}</label>
                </div>}
                {userType === "learner" && activeTab === "past" && <div
                  className={`d-flex align-items-center gap-2  ${item.label === "Under Review"
                    ? "label-success"
                    : item.label === "Fail"
                      ? "label-danger"
                      : item.label === "Resubmission"
                        ? "label-warning"
                        : "label-primary"
                    }`}
                >
                  <span className="border rounded-circle p-1" />
                  <label className="item">{item.label}</label>
                </div>}
              </td>
              <td className="table-cell">
                {(userType === "supervisor" || userType === 'admin') && <Dropdown
                  id={`id${index}`}
                  show={openDropdownId === index}
                  onToggle={() => handleToggle(index)}
                >
                  <Dropdown.Toggle as="img"
                    src={IMAGES.ACTION}
                    alt="Menu Icon"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleToggle(index)}
                  />
                  <Dropdown.Menu className="assignment-actions-dropdown z-4">
                    <Dropdown.Item>View assignment</Dropdown.Item>
                    <Dropdown.Item>Edit assignment</Dropdown.Item>
                    <Dropdown.Item>Delete assignment</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>}
                {userType === "educator" && <Dropdown
                  id={`id${index}`}
                  show={openDropdownId === index}
                  onToggle={() => handleToggle(index)}
                >
                  <Dropdown.Toggle as="img"
                    src={IMAGES.ACTION}
                    alt="Menu Icon"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleToggle(index)}
                  />
                  <Dropdown.Menu className="assignment-actions-dropdown z-4">
                    <Dropdown.Item onClick={() => { handleAssignEducator(index) }}>Claim Assignment</Dropdown.Item>
                    <Dropdown.Item onClick={() => handleDownload('assignment', index)}>Download Assignment</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>}
                {userType === "learner" && <Dropdown
                  id={`id${index}`}
                  show={openDropdownId === index}
                  onToggle={() => handleToggle(index)}
                >
                  <Dropdown.Toggle as="img"
                    src={IMAGES.ACTION}
                    alt="Menu Icon"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleToggle(index)}
                  />
                  <Dropdown.Menu className="assignment-actions-dropdown z-4">
                    <Dropdown.Item onClick={() => handleDownload('assignment', index)}>Download Assignment</Dropdown.Item>
                    <Dropdown.Item onClick={() => handleDownload('criteria', index)}>Download Criteria</Dropdown.Item>
                    <Dropdown.Item onClick={() => onViewDetails?.(item)}>View Details</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Modal show={isAssigned} className='success-modal'>
        <Row className='w-100'>
          <Col className='col-md-12 text-end'>
            <Button variant="link" onClick={() => { onClose() }} style={{ padding: "10px", color: "black" }}>
              <img src={CrossIcon} alt="CrossIcon" />
            </Button>
          </Col>
          <Col className='col-md-12 text-center'>
            <img src={CommonFileCheckIcon} alt='CommonFileCheckIcon' className='cstm-img' />
          </Col>
          <Col className='col-md-12 text-center ctsm-span-col mb-5'>
            <span>Assessment for assignment ID {isAssignedAssessment}</span><br />
            <span>is assignment to you </span>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default AssignmentList;

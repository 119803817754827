import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { Dayjs } from 'dayjs';
import COLORS from '@utils/Colors';
import { InputAdornment } from '@mui/material';

const theme = createTheme({
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiInputBase-root': {
                        backgroundColor: COLORS.WHITE,
                        width: '100%',
                        border: "1px solid #030303",
                        padding: '0px 16px', // Adjust padding to fit the height
                        height: '44px', // Set the height to 44px
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                    },
                    '& .MuiInputLabel-root': {
                        color: '#3f51b5',
                        top: '-6px', // Adjust label position for the custom height
                    },
                    '& .MuiInputBase-input': {
                        color: '#333',
                        fontWeight: 'regukar',
                        padding: 0,
                        textAlign: 'center'

                        // Remove padding inside the input
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#ff5722',
                    },
                    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#4caf50',
                    },
                },
            },
        },
    },
});



type Props = {
    value: Dayjs | null;
    onChange: (newValue: Dayjs | null) => void;
}


const CustomTimePicker: React.FC<Props> = ({ ...rest }) => {

    const [open, setOpen] = React.useState(false)


    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    return (
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                    open={open} // Control the open state
                    onAccept={handleClose} // Close the picker on accept
                    onClose={handleClose} // Close the picker on close
                    slotProps={{
                        textField: {
                            onClick: () => handleOpen(),
                            variant: 'outlined',
                            fullWidth: false,
                            InputProps: {
                                endAdornment: (
                                    <InputAdornment position="end">
                                    </InputAdornment>
                                ),
                            },
                        },
                    }}
                    minutesStep={1} ampm={false} {...rest} />
            </LocalizationProvider>
        </ThemeProvider>
    );
}


export default CustomTimePicker

import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { RootState } from '../redux/store'

const Logout: React.FC = () => {

    const { userType } = useSelector((state: RootState) => state.auth)

    useEffect(() => {
        const removeToken = () => {
            localStorage.removeItem('edmrki_dkey')
            localStorage.removeItem('edumrk_dv_ath')
        }

        removeToken()
        // document.location.reload()
    }, [])

    return <Navigate to={`/${userType}/login`} replace={true} />
}

export default Logout
import React from 'react'
import COLORS from '../utils/Colors';

type Props = {
    error: string | null | any
}



const ErrorField: React.FC<Props> = ({ error }) => {

    const errorStyle: React.CSSProperties = {
        color: COLORS.ERROR,
        fontSize: '14px',
        fontStyle: 'italic',
        lineHeight: '14px',
        marginTop: '5px'
    };

    return (
        <div style={errorStyle}>{error}</div>
    )
}


export default ErrorField
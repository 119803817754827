import { AssignmentGradingSliceState } from "@/app/modules/types/sliceTypes";
import {
  FailedPayload,
  FetchGradingAssignmentsResponse,
  SuccessPayload,
} from "@/app/modules/types/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import assignmentAIdata from "@assets/JSON/assignmentAI.json"

const initialState: AssignmentGradingSliceState = {
  loading: false,
  error: null,
  assignmentsGrading : null,
  success: false,
  message: null,
  signature: null,
  learnerAssignmentsGrading : null,
  assignmentDetail:null,
  downloadAssignments: null,
  resubmitSucces:false,
  updatedFileId: null,
  learner_file_id : null,
  fileId: ""
};

const gradingFeedbackSlice = createSlice({
  name: "gradingFeedback",
  initialState,
  reducers: {
    setLearnerGradingLoading :(state,action) => {
      state.loading = true;
      state.error = null
    },
    fetchAssignmentsGrading: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    setGradingFeedbackLoading: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    GradingFeedbackFailed: (state, action: PayloadAction<FailedPayload>) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    fetchedAssignmentsGrading: (state, action) => {
      state.assignmentsGrading = action.payload.data;
      state.error = null;
    },
    fetchedLearnerGradingFeedback:(state,action) => {
      state.learnerAssignmentsGrading = action.payload.data;
      state.error = null;
    },
    uploadSignature: (state) => {
      state.loading = false;
    },
    setSignatureLoading: (state) => {
      state.loading = true;
    },
    uploadSignatureSuccess: (state, action) => {
      state.success = true;
      state.loading = false;
      state.message = action.payload.message;
      state.learner_file_id = action.payload.data;
    },
    uploadSignatureFailed: (state, action: PayloadAction<FailedPayload>) => {
      state.success = false;
      state.message = action.payload.message;
      state.loading = false;
    },
    fetchedSignature: (state, action) => {
      state.signature = action?.payload?.data;
      state.loading = false;
    },
    FailedfetchSignature: (state, action) => {
      state.message = action.payload.message;
      state.loading = false;
    },
    setGradingLoading: (state) => {
      state.loading = true;
      state.error = null;
    },
    LearnergradingFeedbackFailed :(state,action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    fetchedassignmentinfo:(state,action) => {
      state.assignmentDetail = action.payload.data;
      state.error = null;
    },
    downloadAssignments :(state,action) => {
      state.fileId = action.payload.fileId;
      state.downloadAssignments = action.payload.data;
    },
    ClearState : (state) => {
      state.error = null;
      state.success = false;
      state.message = null;
      state.resubmitSucces = false;
      state.loading = false;
    },
    ResubmittedFeedback : (state) => {
      state.loading = false;
      state.error = null;
      state.resubmitSucces = true;
    },
    SetUpdatedFeedback : (state,action)=> {
      state.loading = false;
      state.error = null;
      state.resubmitSucces= true;
      state.updatedFileId = action.payload.data
    },
    fetchedSecondFeedbackgrading :(state,action) => {
      state.loading = false;
      state.error = null;
      state.learner_file_id = action.payload.data
    },
    clearAssignmentDetail : (state) => {
      state.assignmentDetail = null
    }
  },
});

export const {
  fetchAssignmentsGrading,
  setGradingFeedbackLoading,
  GradingFeedbackFailed,
  fetchedAssignmentsGrading,
  uploadSignature,
  setSignatureLoading,
  uploadSignatureSuccess,
  uploadSignatureFailed,
  fetchedSignature,
  setGradingLoading,
  setLearnerGradingLoading,
  fetchedLearnerGradingFeedback,
  LearnergradingFeedbackFailed,
  ResubmittedFeedback,
  SetUpdatedFeedback,
  ClearState,
  clearAssignmentDetail
} = gradingFeedbackSlice.actions;

export default gradingFeedbackSlice.reducer;

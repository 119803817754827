import React, { useEffect, useRef, useState } from 'react';
import { Modal, Button, Row } from 'react-bootstrap';
import "./style.css";
import CreateAssignment from './CreateAssignment';
import AssignCohort from './AssignCohort';
import AssignedMarker from './AssignMarker';
import { Col } from 'react-bootstrap';
import { ArrowLeftIcon, CollapseIcon, CommonFileCheckIcon, CrossIcon, ExpandIcon } from '@/app/assets/icons/svg';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { GET_ASSIGNMENT } from '@/app/redux/actionTypes';

interface formData {
  assessmentId: string,
  unitCode: string,
  unitTitle: string,
  level: string,
  centerNumber: string,
  cohorts: string[],
  centerName: string,
  releasedDate: string,
  lastModeration: string,
  dueDate: string,
  description: string,
  assignmentBriefFiles: File[] | [],
  assignmentCriteriaFiles: File[] | [],
  markers: string[],
  learners: string[] // Add this line
}

interface CreateNewAssignmentProps {
  isOpen: boolean;
  onClose: () => void;
  setGetAssignment?: (value: boolean) => void;
}

const CreateNewAssignment: React.FC<CreateNewAssignmentProps> = ({ isOpen, onClose, setGetAssignment }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState<formData>({
    assessmentId: '', unitCode: '', unitTitle: '', level: '',
    centerNumber: '', centerName: '', cohorts: [], releasedDate: '', lastModeration: new Date().toLocaleDateString(),
    dueDate: '', description: '', assignmentBriefFiles: [], assignmentCriteriaFiles: [], markers:[], learners:[]
  });
  const [isMaximized, setIsMaximized] = useState(false);
  const [step, setStep] = useState(1);
  const [isPublished, setIsPublished] = useState(false);
  const [assessment, setAssessment] = useState("");
  const [anyAssigned, setAnyAssigned] = useState<boolean>(false);


  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, field: 'assignmentBriefFiles' | 'assignmentCriteriaFiles') => {
    const selectedFiles = event.target.files;

    if (selectedFiles && selectedFiles.length > 0) {
      const filesArray = Array.from(selectedFiles);
      setFormData(prevState => ({
        ...prevState,
        [field]: filesArray
      }));
    } else {
      setFormData(prevState => ({
        ...prevState,
        [field]: []
      }));
    }
  };

  const handleNext = () => {
    if (validateCurrentStep()) {
      setStep(prev => Math.min(prev + 1, 4)); // Move to the next step
    } else {
      Swal.fire({
        title: 'Missing Fields',
        text: 'Please fill in all required fields before proceeding.',
        icon: 'warning',
        confirmButtonText: 'Okay',
      });
    }
  };
  const validateCurrentStep = () => {
    switch (step) {
        case 1:
            return !!formData.assessmentId && !!formData.unitCode && !!formData.unitTitle && !!formData.level && !!formData.centerNumber &&
              formData.assignmentBriefFiles.length && formData.assignmentCriteriaFiles.length; // Add more required fields if necessary
        case 2:
            return !!formData.assessmentId ;
        case 3:
            return true;
        default:
            return true;
    }
};
const close = ()=>{
  setFormData({
    assessmentId: '', unitCode: '', unitTitle: '', level: '', cohorts: [], centerNumber: '', centerName: '', releasedDate: '',
    lastModeration:new Date().toLocaleDateString(), dueDate: '', description: '', assignmentBriefFiles: [], assignmentCriteriaFiles: [],markers:[],learners:[]
  });
  setStep(1);
  onClose();
  setAssessment("")
  setIsPublished(false);
}
const finish =()=>{
  //API Call
  setAssessment(formData.assessmentId)
  setFormData({
    assessmentId: '', unitCode: '', unitTitle: '', level: '', cohorts: [], centerNumber: '', centerName: '', releasedDate: '',
    lastModeration: new Date().toLocaleDateString(), dueDate: '', description: '', assignmentBriefFiles: [], assignmentCriteriaFiles: [],markers:[],learners:[]
  });
  setStep(1);
}

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (step === 4) {
      const createAssignmentPayload = {
        assessmentId : formData.assessmentId,
        unitCode: formData.unitCode,
        unitTitle: formData.unitTitle,
        level: formData.level,
        centerNumber: formData.centerNumber,
        centerName: formData.centerName,
        releasedDate: formData.releasedDate,
        lastModeration: formData.lastModeration,
        dueDate: formData.dueDate,
        description: formData.description,
        cohorts: formData.cohorts,
        markers: formData.markers,
        learners: formData.learners,
        assignmentBriefFile: formData.assignmentBriefFiles,
        assessmentCriteriaFile: formData.assignmentCriteriaFiles,
      }
      console.log('createAssignmentPayload: ', createAssignmentPayload)
       await dispatch({
          type: GET_ASSIGNMENT,
          payload: createAssignmentPayload,
        });
      setIsPublished(true);
      setGetAssignment && setGetAssignment(true);
      finish()
    } else {
      handleNext();
    }
  };

  const toggleMaximize = () => {
    setIsMaximized(prev => !prev);
  };

  const handleUnassignRef = useRef<{ handleUnassignAction: () => void }>(null);

  const handleassigningFunction = () => {
    if (handleUnassignRef.current) {
      handleUnassignRef.current.handleUnassignAction();
    }
  };
  return (
    <>
    <Modal show={isOpen && !isPublished} onHide={close} className={isMaximized ? 'maximized' : ''}>
      <Modal.Header>
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          {step > 1 && (
            <Button variant="link" onClick={() => setStep(step - 1)} style={{ padding: "10px", color: "black" }}>
              <img src={ArrowLeftIcon} alt=''/>
            </Button>
          )}
          <span style={{ flex: 1, fontWeight: 'bold' }}>
            {step === 1 ? 'Create New Assignment' : step === 2 ? "Assign cohort" : step === 3 ? "Assign Marker" : step===4 ? "Preview":""}
          </span>
          <Button variant="link" onClick={toggleMaximize} style={{ padding: "10px", color: "black" }}>
            {isMaximized ? <img src={CollapseIcon} alt=''/> : <img src={ExpandIcon} alt=''/>}
          </Button>
          <Button variant="link" onClick={() => { close() }} style={{ padding: "10px", color: "black" }}>
              <img src={CrossIcon} alt=""/>
          </Button>
        </div>
        <hr />
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          {step === 1 && <CreateAssignment formData={formData} handleChange={handleChange} handleFileChange={handleFileChange} />}
          {step === 2 && <AssignCohort formData={formData} handleChange={handleChange} />}
          {step === 3 && <AssignedMarker formdata={formData} ref={handleUnassignRef} setAnyAssigned={setAnyAssigned} />}
          {step === 4 && <CreateAssignment formData={formData} handleChange={handleChange} handleFileChange={handleFileChange} readOnly />}
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Row className='w-100'>
          {step === 3 && <Col>
            <Button className={`text-primary  ${anyAssigned ? 'assigned-btn' : 'proceed-btn'}`} style={{ background: "none" }} onClick={handleassigningFunction}>
              Proceed Unassigned
            </Button>
          </Col>}
          <Col className='d-flex'>
            <Button variant="light" className="bg-none text-primary me-2 ms-auto" onClick={() => { close() }}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleSubmit}>
              {step === 4 ? 'Publish' : 'Next'}
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
      <Modal show={isPublished} className='success-modal'>
        <Row className='w-100'>
            <Col className='col-md-12 text-end'>
              <Button variant="link" onClick={() => { close() }} style={{ padding: "10px", color: "black" }}>
                <img src={CrossIcon} alt="" />
              </Button>
            </Col>
          <Col className='col-md-12 text-center'>
            <img src={CommonFileCheckIcon} alt='' className='cstm-img' />
          </Col>
          <Col className='col-md-12 text-center ctsm-span-col mb-5'>
            <span>You have successfully created new assignment</span><br/>
            <span>{assessment}</span>
          </Col>
        </Row>
      </Modal>
    </>
  );
};
export default CreateNewAssignment;

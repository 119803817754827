import React, { useEffect, useRef, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { Levels, User } from '../modules/types/types';
import { formatDateToMonthDateYear } from '../modules/helpers/FormikHelper';
import Button from './Button';
import COLORS from '../utils/Colors';
import LearnerAssignmentsModal from './LearnerAssignmentsModal';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { useDispatch } from 'react-redux';
import { _USER_CLEAR_SUCCESS, FETCH_USER_BY_USER_ID, UPDATE_USER_SUPERVISOR } from '../redux/actionTypes';
import IMAGES from '../utils/Images';
import Loader from './Loading';
import { successPopup } from './SuccessModal';


type Props = {
    visible: boolean;
    handleClose: () => void;
    learner?: User;
};

type TextProps = {
    value?: string | number | null;
    heading?: string;
    fontSize?: string;
    fontWeight?: 'bold' | 'normal';
    headingStyle?: React.CSSProperties;
    valueStyle?: React.CSSProperties;
    editable?: boolean;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

interface UserDetailFieldProps {
    heading?: string | undefined;
    value?: string | undefined;
    name?: string | undefined;
    editable?: boolean | undefined;
    handleInputChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
    inputType?: 'text' | 'email' | 'tel' | 'select' | undefined;
    options?: Levels[];
    valueStyle?: React.CSSProperties;
}

interface UserUpdateDetails {
    updateUserId: string;
    name?: string | undefined;
    image: File | null | undefined;
    phone: string | null | undefined;
    level?: string[] | undefined;
    email?: string | undefined;
    memberId?: string | undefined;
}

const UserProfileModal: React.FC<Props> = ({ visible, handleClose }) => {

    const [selectedFile, setSelectedFile] = useState<File | null>(null);

    const [preview, setPreview] = useState<string | null>(null);

    const dispatch = useDispatch()

    const [show, setShow] = useState<boolean>(false);

    const [showAssignments, setShowAssignments] = useState(false);

    const [isEditing, setIsEditing] = useState(false);

    const [editableUser, setEditableUser] = useState<User>();

    const { user, loading, success } = useSelector((state: RootState) => state.user)

    const { userType, user_id } = useSelector((state: RootState) => state.auth)

    const fileInputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        setShow(visible);
        dispatch({ type: FETCH_USER_BY_USER_ID, payload: { userId: user_id } })
    }, [dispatch, user_id, visible]);

    useEffect(() => {
        if (user) {
            setEditableUser(user)
        }
    }, [user])

    const handleSuccess = async (message: string) => {
        // Pass success message here
        await successPopup(message);
        dispatch({ type: _USER_CLEAR_SUCCESS })
        setIsEditing(false)

    };

    useEffect(() => {
        if (success) {
            handleSuccess(success)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [success])


    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
            setPreview(URL.createObjectURL(file)); // Show a preview of the image
        }
    };

    const handleEditImageClick = () => {
        fileInputRef.current?.click();
    };


    const fieldStyle: React.CSSProperties = {
        display: 'flex',
        alignItems: 'center',
        height: '32px',
    }


    const UserDetailField: React.FC<UserDetailFieldProps> = ({ heading, value, name, editable, handleInputChange, inputType = 'text', valueStyle }) => {
        return (
            <div style={{
                backgroundColor: editable ? '#edf2fa' : '#ffffff',
                marginTop: editable ? 10 : 0, ...fieldStyle
            }}   >
                <span style={{ backgroundColor: editable ? '#edf2fa' : '#ffffff', fontSize: '14px', fontWeight: 'bold', }}>{heading ? `${heading} : ` : ''}</span>
                {editable ? (
                    <input
                        type={inputType}
                        name={name}
                        value={value}
                        className="border-0"
                        onChange={handleInputChange}
                        style={{ marginLeft: '10px', backgroundColor: '#edf2fa', flex: 1 }}
                    />
                ) : (
                    <span style={{ fontSize: '14px', fontWeight: "normal", ...valueStyle }}>{value}</span>
                )}
            </div>
        );
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        if (editableUser) {
            setEditableUser({
                ...editableUser,
                [name]: value
            });
        }
    };

    const Text: React.FC<TextProps> = ({ value, heading, headingStyle, valueStyle }) => (
        <div>
            {heading && <span style={{ fontSize: '14px', fontWeight: 'bold', ...headingStyle }}>{heading}</span>}
            {value && <span style={{ fontSize: '14px', fontWeight: "normal", ...valueStyle }}>&nbsp;:&nbsp;{value}</span>}
        </div>
    )

    function extractLevelNames(levels: Array<{ name: string, [key: string]: any }>): string[] {
        return levels.map(level => level.name);
    }

    const updatedLevels = extractLevelNames(editableUser?.levelsArray ? editableUser?.levelsArray : []);

    const saveDetails = (): void => {
        const payload: UserUpdateDetails = {
            updateUserId: user_id,
            phone: editableUser?.phone,
            image: selectedFile
        };

        if (userType === 'admin') {
            // Check if the user is an admin
            payload.name = editableUser?.name;
            payload.level = editableUser?.levels;
            payload.email = editableUser?.email;
            payload.memberId = editableUser?.memberId;
        }

        dispatch({
            type: UPDATE_USER_SUPERVISOR,
            payload
        });
    };


    return (
        <>
            {loading && <Loader />}
            <Modal contentClassName='my-modal-profile' backdrop="static" show={show} onHide={handleClose}>
                <Modal.Header className='px-4 py-3 fw-bold' closeButton >
                    <Modal.Title>My profile</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='d-flex flex-row'>
                        <div className='d-flex flex-column align-items-center' style={{ flex: 0.3 }}>
                            <img src={preview ? preview : editableUser?.avatarChunkData ? `data:image/png;base64,${editableUser?.avatarChunkData}` : IMAGES.AVTAR} alt='profile-img' style={imageStyle} />
                            <input ref={fileInputRef} type="file" accept="image/*" onChange={handleFileChange} hidden />
                            {isEditing && editableUser?.avatarChunkData && <div className=''>
                                <Button
                                    onClick={handleEditImageClick}
                                    label={'Upload'}
                                    backgroundColor={COLORS.WHITE}

                                    style={{ height: '40px' }}
                                    labelStyle={{ color: COLORS.PRIMARY, fontSize: '14px' }}
                                />
                            </div>}
                        </div>
                        <div style={{ flex: 0.5 }}>
                            <UserDetailField
                                value={editableUser?.name ? editableUser?.name : 'Guest'}
                                valueStyle={{ fontSize: '20px', fontWeight: 'bold' }}
                                editable={isEditing && userType === 'admin'}
                            />
                            <UserDetailField
                                heading="Membership ID"
                                value={editableUser?.memberId}
                                name="memberId"
                                editable={isEditing && userType === 'admin'}
                            />
                            <UserDetailField
                                heading="Email"
                                value={editableUser?.email}
                                name="email"
                                editable={isEditing && userType === 'admin'}
                            />
                            <UserDetailField
                                heading="Date Joined"
                                value={formatDateToMonthDateYear(editableUser?.enrolmentDate ? editableUser.enrolmentDate : '')}
                                name="enrolmentDate"
                            />
                            <UserDetailField
                                heading="Centre ID"
                                value={editableUser?.centreId}
                                name="centreId"
                                editable={isEditing && userType === 'admin'}
                            />
                            <UserDetailField
                                heading="Centre Name"
                                value={editableUser?.centreName}
                                name="centreName"
                                editable={isEditing && userType === 'admin'}
                            />
                            {/* educators */}
                            {(userType === 'educator' || userType === 'learner') && (
                                <UserDetailField
                                    heading="Level"
                                    value={updatedLevels.toString()}
                                    name="levels"
                                />
                            )}
                            {/* educators */}
                            {/* learner */}
                            {userType === 'learner' &&
                                <>
                                    <UserDetailField
                                        heading="Alloted Cohort"
                                        value={editableUser?.cohorts?.map(item => item.name).join(', ')}
                                        name="cohorts"
                                    />
                                    <UserDetailField
                                        heading="Assigned Educator"
                                        value={editableUser?.educators?.map(item => item.name).join(', ')}
                                        name="educators"
                                    />
                                </>
                            }
                            {/* learner */}
                            <UserDetailField
                                heading="Phone"
                                editable={isEditing}
                                handleInputChange={handleInputChange}
                                value={editableUser?.phone ? editableUser?.phone : ''}
                                name="phone"
                            />
                        </div>
                        <div style={{ flex: 0.2 }}>
                            <Button
                                label={isEditing ? 'Save Details' : 'Edit Details'}
                                backgroundColor={COLORS.PRIMARY}
                                onClick={() => isEditing ? saveDetails() : setIsEditing(!isEditing)}
                                style={{ height: '40px' }}
                                labelStyle={{ color: COLORS.WHITE, fontSize: '14px' }}
                            />
                        </div>
                    </div>
                    {editableUser?.avatarChunkData ? null : <div className='d-flex flex-row rounded p-3 my-3 px-5 border align-items-center justify-content-between'>
                        <div className='d-flex flex-column'>
                            <span style={uploadPhotoText} className='fw-bold'>Improve your profile</span>
                            <span style={uploadPhotoText} className=''>Help others recognise you by adding your photo.</span>
                        </div>
                        <div>
                            <Button
                                isIcon
                                icon={IMAGES.UPLOAD}
                                label={'Upload Photo'}
                                backgroundColor={COLORS.WHITE}
                                onClick={() => handleEditImageClick()}
                                style={{ height: '40px', border: `2px solid ${COLORS.PRIMARY}` }}
                                labelStyle={{ color: COLORS.PRIMARY, fontSize: '14px' }}
                            />
                        </div>
                    </div>}
                    {userType === 'learner' && <div className='d-flex gap-5 align-items-center justify-content-around'>
                        <div className='bg-light p-3 pb-0 rounded'>
                            <h6 className='fw-bold'>Average Grade</h6>
                            <div className='d-flex flex-row align-items-end justify-content-between'>
                                <h2 style={gradeText}>3.5</h2>
                            </div>
                            <h6 style={unitsText}><span style={myUnits}>74&nbsp;</span>/&nbsp;100 Units</h6>
                        </div>
                        <div>
                            <Text
                                heading={'Assignment'}
                            />
                            <div style={{ width: '316px' }} className='rounded p-2 border mt-2'>
                                <Text
                                    heading="Assignment Submitted"
                                    valueStyle={{ fontWeight: 'bold' }}
                                    value={user && user.assignments ? user.assignments.length.toString() : '0'}
                                />
                                <div className='d-flex'>
                                    <div style={{ flex: 0.5 }}>
                                        <Text valueStyle={assignmentLabel} headingStyle={assignmentLabel} heading="High Pass" value={user?.assignmentStats.labelCounts['high pass']} />
                                        <Text valueStyle={assignmentLabel} headingStyle={assignmentLabel} heading="Pass" value={user?.assignmentStats.labelCounts['pass']} />
                                    </div>
                                    <div style={{ flex: 0.5 }}>
                                        <Text valueStyle={assignmentLabel} headingStyle={assignmentLabel} heading="Low Pass" value={user?.assignmentStats.labelCounts['low pass']} />
                                        <Text valueStyle={assignmentLabel} headingStyle={assignmentLabel} heading="Fail" value={user?.assignmentStats.labelCounts['fail']} />
                                        <Text valueStyle={reSubmissionAssignmentLabel} headingStyle={reSubmissionAssignmentLabel} heading="Resubmission" value={user?.assignmentStats.resubmissionCount} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
                </Modal.Body>
            </Modal>
            <LearnerAssignmentsModal visible={showAssignments} handleClose={() => setShowAssignments(false)} />
        </>
    )
}


const imageStyle: React.CSSProperties = {
    height: 100,
    width: 100,
    borderRadius: '50%'
};


const assignmentLabel: React.CSSProperties = {
    color: '#8A8A8A',
    fontWeight: 'bold',
};

const reSubmissionAssignmentLabel: React.CSSProperties = {
    color: '#DC2626',
    fontWeight: 'bold',
};

const uploadPhotoText: React.CSSProperties = {
    fontSize: '14px',
};

const gradeText: React.CSSProperties = {
    color: '#2188E7',
    fontWeight: 'bold'
};

const myUnits: React.CSSProperties = {
    color: '#00C853',
    fontWeight: 'bold'
};

const unitsText: React.CSSProperties = {
    fontWeight: 'bold'
};


export default UserProfileModal;

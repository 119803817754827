import { call, put, takeLatest } from 'redux-saga/effects';
import { CHECK_AUTH, FETCH_LOGIN, HANDLE_LOGOUT, LOGIN_FAILED, LOGIN_SUCCESS, RESET_PASSWORD, RESET_PASSWORD_FAILED, RESET_PASSWORD_SUCCESS, SEND_VERIFY_OTP_RESET_PASSWORD, SEND_VERIFY_OTP_RESET_PASSWORD_SUCCESS, SET_LOADING } from '../actionTypes';
import { fetch_user_login, otp_reset_password, reset_password } from '../../api/Api';
import { Login, AuthPayload, CreatePassword, Payload, ResetPasswordPayload, ResetPasswordResponse } from '@modules/types/types';
import { getErrorMessage } from '@modules/helpers/ErrorHelper';

function* checkAuth(): Generator {
    try {
        const token = localStorage.getItem('edumrk_dv_ath');
        if (token) {
            const response = { data: { valid: '' } }
            if (response.data.valid) {
                // yield put(loginSuccess({ api_token: token }));
            } else {
                yield put({ type: HANDLE_LOGOUT });
            }
        }
    } catch (error) {
        yield put({ type: LOGIN_FAILED, payload: { message: 'Authentication check failed' } });
        localStorage.removeItem('edumrk_dv_ath');
    }
}

//Implimented!>

function* loginSaga(action: { type: string; payload: Login }): Generator {
    try {
        yield put({ type: SET_LOADING });
        const response = (yield call(fetch_user_login, action.payload)) as AuthPayload;
        yield put({ type: LOGIN_SUCCESS, payload: response });
    } catch (error) {
        if (error) {
            const errorMessage = getErrorMessage(error);
            yield put({ type: LOGIN_FAILED, payload: { message: errorMessage } });
        }
    }
}

function* resetPasswordSaga(action: { type: string; payload: CreatePassword }): Generator {
    try {
        yield put({ type: SET_LOADING });
        const response = (yield call(reset_password, action.payload)) as Payload;
        console.log(response)
        yield put({ type: response.status === 200 ? RESET_PASSWORD_SUCCESS : RESET_PASSWORD_FAILED });
    } catch (error) {
        const errorMessage = getErrorMessage(error);
        yield put({ type: LOGIN_FAILED, payload: { message: errorMessage } });
    }
}

function* sendVerifyOtpSaga(action: { type: string; payload: ResetPasswordPayload }): Generator {
    try {
        yield put({ type: SET_LOADING });
        const response = (yield call(otp_reset_password, action.payload)) as ResetPasswordResponse;
        console.log(response, 'response')
        yield put({ type: SEND_VERIFY_OTP_RESET_PASSWORD_SUCCESS, payload: { message: response.message, step: response.data.step } });
    } catch (error) {
        const errorMessage = getErrorMessage(error);
        yield put({ type: LOGIN_FAILED, payload: { message: errorMessage } });
    }
}




function* authSaga() {
    yield takeLatest(FETCH_LOGIN, loginSaga);
    yield takeLatest(CHECK_AUTH, checkAuth);
    yield takeLatest(RESET_PASSWORD, resetPasswordSaga);
    yield takeLatest(SEND_VERIFY_OTP_RESET_PASSWORD, sendVerifyOtpSaga)
}

export default authSaga;

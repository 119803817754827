import { SettingSliceState } from "@/app/modules/types/sliceTypes";
import { createSlice } from "@reduxjs/toolkit";

const initialState: SettingSliceState = {
    loading: false,
    notification: {
        selectAll: [], emailNotifications: [], appNotifications: [],
    },
    signature: null,
};

const settingSlice = createSlice({
    name: "setting",
    initialState,
    reducers: {
        setLoading: (state) => {
            state.loading = true;
        },
        notifications: (state, action) => {
            state.notification = action.payload.data;
            state.loading = false;
        },
        settingFetchedSignatures: (state, action) => {
            state.signature = action.payload.data;
            state.loading = false;
        },
        settingUploadSignatures: (state, action) => {
            state.signature = action.payload.data;
            state.loading = false;
        }
    },
});

export const { notifications } = settingSlice.actions;

export default settingSlice.reducer;

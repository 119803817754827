import NotificationList from "@components/NotificationList";
import PerformanceChart from "@components/PerformanceChart";
import CohortList from "@components/CohortList";
import IMAGES from "@/app/utils/Images";
import React, { useEffect } from "react";
import AssignmentsDetailNew from "./AssignmentDetailNew"
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { useDispatch } from "react-redux";
import { FETCH_ASSIGNMENTS_STATUS, FETCH_AVERAGE_TIME_TO_MARK, FETCH_COHORTS, FETCH_LEARNER_SATISFACTION, FETCH_TOTAL_ACTIVE_USERS } from "../redux/actionTypes";

interface Props { }

const EducatorDashboard: React.FC<Props> = () => {
  const {user_id} = useSelector((state: RootState) => state.auth)
  const {assignmentsStatus, totalActiveUsers, averageTimeToMark, learnerSatisfaction} = useSelector((state: RootState) => state.analytics)
  const { cohorts } = useSelector((state: RootState) => state.cohort);

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch({ type: FETCH_TOTAL_ACTIVE_USERS, payload: { userType: 'learner' } });
    dispatch({ type: FETCH_ASSIGNMENTS_STATUS, payload: { educator_id: user_id } });
    dispatch({ type: FETCH_AVERAGE_TIME_TO_MARK, payload: { educator_id: user_id } });
    dispatch({ type: FETCH_LEARNER_SATISFACTION, payload: { educator_id: user_id } });
    dispatch({ type: FETCH_COHORTS, payload: { page: 1, limit: 5 } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const containertwoStyle: React.CSSProperties = {
    height: "300px",
  };

  const weekDrpdownStyle: React.CSSProperties = {
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "24px",
  };

  const asignmentLabel: React.CSSProperties = {
    fontSize: "14px",
    fontWeight: "400",
    color: "#727272"
  };

  const numberLabel: React.CSSProperties = {
    fontSize: "20px",
    fontWeight: "600",
    lineHeight: "30px",
    color: "#000000",
  };
  // const totalData = [1, 2, 3, 4, 5];
  // const correctData = [6, 7, 8, 9, 10];

  // type Series = {
  //   data: number[];
  //   label: string;
  //   id: string;
  //   stack: string;
  //   color: string;
  // };

  // const seriesData: Series[] = [
  //   {
  //     data: correctData,
  //     label: "Correct",
  //     id: "pvId",
  //     stack: "total",
  //     color: "#0343FA",
  //   },
  //   {
  //     data: totalData,
  //     label: "Overall numbers",
  //     id: "uvId",
  //     stack: "total",
  //     color: "#DDE5FF",
  //   },
  // ];

  const notificationData = [
    {
      item: "Mark is added in cohort ID 234",
      date: "5:30pm",
      isReviewCompleted: false,
      icon: IMAGES.ASSIGNMENT,
    },
    {
      item: "New Assignment",
      date: "9:00pm",
      isReviewCompleted: true,
      icon: IMAGES.FEEDBACK_IMG,
    },
  ];

  return (
    <div className="dashboard-container mt-4">
      <div className="row mx-lg-auto mx-0 gap-5">
        <div className="col-12 col-lg-4 card-content course-educator-container">
          <div className="h-100 d-flex flex-column">
            <div className="d-flex align-items-center gap-3">
              <img src={IMAGES.ICON} alt="ICON" />
              <div style={weekDrpdownStyle}>Assignments</div>
            </div>
            <div className="mt-auto">
              <div
                className="d-flex justify-content-between"
                style={asignmentLabel}
              >
                <label>Pending</label>
                <label>In Progress</label>
              </div>
              <div
                className="d-flex justify-content-between"
                style={numberLabel}
              >
                <label>{assignmentsStatus.assignments_pending}</label>
                <label>{assignmentsStatus.assignments_in_progress}</label>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-4 card-content course-educator-container">
          <div className="h-100 d-flex flex-column">
            <div className="d-flex align-items-center gap-3">
              <img src={IMAGES.USERS} alt="ICON" />
              <div style={weekDrpdownStyle}>Learners</div>
            </div>
            <div className="mt-auto">
              <div
                className="d-flex justify-content-between"
                style={asignmentLabel}
              >
                <label>Total Learners</label>
                <label>Learner Satisfaction</label>
              </div>
              <div
                className="d-flex justify-content-between"
                style={numberLabel}
              >
                <label>{totalActiveUsers.learner}</label>
                <label>{learnerSatisfaction}/5</label>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-4 card-content course-educator-container">
          <div className="h-100 d-flex flex-column">
            <div className="d-flex align-items-center gap-3">
              <img src={IMAGES.DASHBOARD_PRODUCTIVITY} alt="ICON" />
              <div style={weekDrpdownStyle}>Your Productivity</div>
            </div>
            <div className="mt-auto">
              <div
                className="d-flex justify-content-between"
                style={asignmentLabel}
              >
                <label>Avg Time To Mark</label>
                <label>Assignments Marked</label>
              </div>
              <div
                className="d-flex justify-content-between"
                style={numberLabel}
              >
                <label>{averageTimeToMark}</label>
                <label>{assignmentsStatus.assignments_done}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div
          className="col-12 col-lg-4 mt-lg-0 card-content"
          style={containeroneStyle}
        >
          <div style={learnerReportStyle} className="card-container">
            <div className="h-100 d-flex flex-column">
              <div className="d-flex justify-content-between">
                <img src={IMAGES.USERS} alt="USERS" />
                <label style={weekDrpdownStyleLight}>Assignments</label>
              </div>
              <div className="mt-auto">
                <div
                  className="d-flex justify-content-between"
                  style={asignmentLabelLight}
                >
                  <label>New Assignment</label>
                  <label>Graded</label>
                </div>
                <div
                  className="d-flex justify-content-between"
                  style={numberLabel}
                >
                  <label>1</label>
                  <label>12</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-12 col-lg-4 mt-lg-0 card-content"
          style={containeroneStyle}
        >
          <Calendar />
        </div> */}
      <div className="row mt-4">
        <div className="col-12 col-lg-5 card-content" style={containertwoStyle}>
          {/* <AssignmentsDetail
            labelText={"Assignment success rate"}
            data={seriesData}
          /> */}
          <AssignmentsDetailNew
            labelText={"Assignments Over Time"}
          />
        </div>
        <div
          className="col-12 col-lg-3 mt-lg-0  card-content"
          style={containertwoStyle}
        >
          <PerformanceChart role={"educator"} />
        </div>
        <div
          className="col-12 col-lg-4 mt-lg-0 card-content"
          style={containertwoStyle}
        >
          <NotificationList data={notificationData} role={"educator"} />
        </div>
      </div>
      <div className="row mt-4">
        <div className={"col-5 listitems-container"}>
          <CohortList cohorts={cohorts} />
        </div>
      </div>
    </div>
  );
};

export default EducatorDashboard;

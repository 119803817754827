import React, { useState, useEffect } from 'react';
import { TextField, Checkbox } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { FETCH_LEVELS } from '../redux/actionTypes';
import { RootState } from '../redux/store';

interface Props {
    onSelectedLevelsChange: (v: string[]) => void; // Update type to pass array of IDs
    multiple?: boolean; // New prop to determine if multiple selection is allowed
    selected?: string[],
    options: any[]
}

const LevelsDropdown: React.FC<Props> = ({ selected, onSelectedLevelsChange, options, multiple = true, ...rest }) => {
    const [selectedIds, setSelectedIds] = useState<string[]>([]); // State to store selected IDs

    // console.log({ selected, onSelectedLevelsChange, multiple, rest });

    useEffect(() => {
        if (selected && Array.isArray(selected)) {
            setSelectedIds([...selected]); // Spread selected into a new array and update state
        } else {
            setSelectedIds([]); // If selected is not valid, reset to an empty array
        }
    }, [selected])

    const handleSelectChange = (event, newValue) => {
        // Map the selected items to an array of IDs
        const selectedIds = multiple
            ? newValue.map((user) => user.id)
            : newValue ? [newValue.id] : [];

        setSelectedIds(selectedIds || []);
    };

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: FETCH_LEVELS });
    }, [dispatch]);

    useEffect(() => {
        onSelectedLevelsChange?.(selectedIds); // Trigger callback with the array of IDs
    }, [selectedIds]);

    return (
        <div style={{ flex: 1 }}>
            <Autocomplete
                {...rest}
                multiple={multiple}
                options={options}
                getOptionLabel={(option) => option.name}
                value={multiple ? options?.filter((option) => selectedIds.includes(option.id)) : options.find((option) => option.id === selectedIds[0]) || null}
                onChange={handleSelectChange}
                disableCloseOnSelect={multiple}
                renderOption={(props, option, { selected }) => (
                    <li {...props}>
                        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                            {multiple && (
                                <Checkbox checked={selected} />
                            )}
                            <div style={{ flex: 1, marginLeft: multiple ? '10px' : '0' }}>{option.name}</div>
                        </div>
                    </li>
                )}
                renderInput={(params) => (
                    <TextField {...params} variant="outlined" placeholder="Select Level" />
                )}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                sx={{
                    '& .MuiAutocomplete-inputRoot': {
                        maxHeight: '100px',
                        overflowY: 'auto',
                    },
                    '& .MuiAutocomplete-endAdornment': {
                        alignItems: 'flex-start',
                    },
                    '& .MuiChip-root': {
                        padding: '1px',
                    },
                    '& .MuiAutocomplete-listbox': {
                        fontSize: '10px',
                    },
                }}
            />
        </div>
    );
};

export default LevelsDropdown;

import React, { useState } from 'react';
import COLORS from '@utils/Colors';

interface ButtonProps {
    label: string;
    onClick: () => void;
    className?: string;
    style?: React.CSSProperties;
    backgroundColor?: string;
    labelStyle?: React.CSSProperties;
    type?: 'button' | 'submit';
    isIcon?: boolean;
    icon?: string;
    [key: string]: any;
}
const Button: React.FC<ButtonProps> = ({ type, label, onClick, className, style, backgroundColor, labelStyle, isIcon, icon, ...props }) => {
    const [hover, setHover] = useState(false);

    const defaultStyle: React.CSSProperties = {
        height: '54px',
        backgroundColor: hover ? COLORS.BUTTON.HOVER : COLORS.BUTTON.BLUE,
        cursor: 'pointer',
        ...style,
    };

    const textStyle: React.CSSProperties = {
        fontSize: '1rem',
        color: 'white',
        fontWeight: '600',
        ...labelStyle
    };

    return (
        <button
            data-testid={'button'}
            onClick={onClick}
            style={{ ...defaultStyle, backgroundColor: backgroundColor || defaultStyle.backgroundColor }}
            className={`btn ${className}`}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            type={type ? type : "button"}
            {...props}
        >
            <div className={`d-flex gap-2 ${isIcon ? 'flex-row' : 'flex-column'} align-items-center`}>
                {isIcon &&
                    <img src={icon} alt='' />
                }
                <span style={textStyle}>
                    {label}
                </span>

            </div>
        </button>
    );
};

export default Button;

import React from 'react';
import IMAGES from '@utils/Images';
import { useLocation } from 'react-router-dom';

type Props = {
    type: 'learner' | 'educator' | 'supervisor' | string
    backgroundColor: string;
}

const contentMap = {
    learner: {
        heading: "Empowering  your learning journey",
        description: "The simplest way to manage your assignments and feedback.",
        image: IMAGES.LOGIN_BANNER,
        details: [
            { title: "Track Assignments ", text: "Stay on top of your coursework with a clear overview." },
            { title: "Submit Easily", text: "Upload correct assignments in just a few clicks." },
            { title: "Quick Feedbacks", text: "Learn from quick feedback on your assignments." }
        ]
    },
    educator: {
        heading: <>Speed track marking and<br />feedback with AI</>,
        description: <>The simplest way to manage your<br />assignment feedbacks and cohorts</>,
        image: IMAGES.LOGIN_BANNER,
        details: [
            { title: "Streamline Marking", text: "Speed up assignment grading with AI-assisted feedback" },
            { title: "Manage Cohorts", text: "Easily track your students and their progress" },
            { title: "Automate Feedback", text: "Provide detailed, consistent feedback with minimal effort" }
        ]
    },
    supervisor: {
        heading: <>Master organisational oversight<br />with EduMark</>,
        description: <>Simplify how you manage educators,<br />learners, and tasks across your organisation</>,
        image: IMAGES.LOGIN_BANNER,
        details: [
            { title: "Oversee Educators", text: "Assign tasks, track progress, and monitor educator performance effortlessly" },
            { title: "Monitor Learners", text: "Review progress and assignments, and manage cohorts with ease" },
            { title: "Optimise Operations", text: "Access powerful tools to streamline reporting, user management, and institutional workflows" }
        ]
    },
    landing: {
        heading: <>Empowering learners<br />educators and administrators<br />with tools for success</>,
        description: '',
        image: IMAGES.LOGIN_BANNER,
        details: [
            { title: "Seamless Grading & Feedback", text: "Assign Grading, provide feedback, and track progress" },
            { title: "Comphrensive Analytics", text: "Real time data and exports to track performance" },
            { title: "Flexible System", text: "Manage , courses , assignments , users and settings all in one place" }
        ]
    },
    admin: {
        heading: <>Master organisational oversight<br />with EduMark</>,
        description: <>Simplify how you manage educators,<br />learners, and tasks across your organisation</>,
        image: IMAGES.LOGIN_BANNER,
        details: [
            { title: "Oversee Educators", text: "Assign tasks, track progress, and monitor educator performance effortlessly" },
            { title: "Monitor Learners", text: "Review progress and assignments, and manage cohorts with ease" },
            { title: "Optimise Operations", text: "Access powerful tools to streamline reporting, user management, and institutional workflows" }
        ]
    }
};








const LogInBanner: React.FC<Props> = ({ type, backgroundColor }) => {

    const location = useLocation();

    if (location.pathname === '/login') {
        // Checking the url when user redirects
    }

    const roleContent = contentMap[type];

    return (
        <div style={{ backgroundColor }} className="d-flex flex-column justify-content-center text-dark w-100 h-100 w-md-50 p-3 p-lg-5">
            <div>
                <h2 className="mb-0 fw-bold">{roleContent.heading}</h2>
                <h5 className="mb-0 fw-normal mt-3">
                    {roleContent.description}
                </h5>
            </div>
            <div className='my-2 mt-5'>
                <img src={roleContent.image} alt={`${type} banner`} className='img-fluid' style={{ width: '430px' }} />
            </div>
            {roleContent.details.map((detail, index) => (
                <div className='mt-2' key={index}>
                    <h6 className='m-0 mt-2 fw-bold'>{detail.title}</h6>
                    <span>{detail.text}</span>
                </div>
            ))}
        </div>
    );
}

export default LogInBanner;

import React, { useState } from 'react'
import LogInBanner from '../components/LogInBanner'
import COLORS from '../utils/Colors'
import IMAGES from '../utils/Images'
import Button from '../components/Button'
import { useNavigate } from 'react-router-dom'

type Props = {}

const Landing: React.FC<Props> = ({ }) => {

    const users = ['learner', 'educator', 'supervisor', 'admin']

    const [checked, setChecked] = useState('')

    const navigate = useNavigate()

    return (
        <div className="vh-100 vh-md-100 d-flex flex-column flex-md-row overflow-y-auto" >
            <div className="d-flex flex-column justify-content-center align-items-center bg-white w-100 h-100 w-md-50 p-3 p-lg-5">
                <img alt='logo' src={IMAGES.LOGIN_LOGO} className="login-logo" />
                <div className='w-75 login-form-container login_form mt-5 shadow bordered p-3 rounded-3 p-md-5'>
                    <h1>Welcome</h1>
                    <h6 className='mt-3'>
                        Select your account type to log in
                    </h6>
                    {checked}
                    <div className='mt-3'>
                        {users.map((item, index) =>
                            <div className="d-flex flex-row align-items-center gap-3">
                                <input className='bg-danger' value={item} onChange={(e) => setChecked(e.target.value)} type="radio" name={'usertype'} id={item} />
                                <label htmlFor={item} className="form-check-label" >
                                    <span className='fs-6 fw-medium text-capitalize'>{item}</span>
                                </label>
                            </div>
                        )}
                    </div>
                    <Button
                        label={'Continue'}
                        backgroundColor={checked === '' ? '#CFD3D4' : COLORS.PRIMARY}
                        className={'mt-4 loginButton w-100'}
                        onClick={() => checked ? navigate(`/${checked}/login`) : null} />
                </div>
                <p className='text-center help-section-text'>
                    Need Help Visit Our&nbsp;
                    <a className='text-decoration-none' href="https://www.example.com/helpcenter">Help Center&nbsp;</a>
                    or contact <a href="mailto:support@edumark.com" >support@edumark.ai</a>.
                </p>
            </div>
            <LogInBanner backgroundColor={COLORS.LOGIN.LIGHTBLUE} type={'landing'} />
        </div>
    )
}

export default Landing
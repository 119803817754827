import React, { useState } from "react";
import Question from "./Question";
import Answer from "./Answer";
import Grade from "./Grade";
import { RootState } from "@/app/redux/store";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

interface SummaryProps {}
const SummaryOne: React.FC<SummaryProps> = () => {
  const { assignmentDetail :{assignment,grading_summary,student,tasks} } = useSelector(
    (state: RootState) => state.gradingFeedback
  );
  const [task,setTask] = useState<any>(tasks[0].assessmentCriteria)

  return (
    <>
      <div className="row g-3">
        <div className="col-lg-3 col-12">
          <Question questions={tasks} setTask={setTask}/>
        </div>
        <div className="col-lg-5 col-12">
          <Answer data={tasks} task={task}/>
        </div>
        <div className="col-lg-4 col-12">
          <Grade data={tasks} task={task}/>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-12">
          <div className="feedback-comp">
            <div className="card-heading">Feedback summary</div>
            <div className="feedback-content p-3">
              {grading_summary?.feedback_summary}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SummaryOne;

import React, { ReactNode, useEffect } from 'react'
import styles from './ForgetPassword.module.css'
import IMAGES from '@/app/utils/Images'
import COLORS from '@/app/utils/Colors'
import { IoMdArrowRoundBack } from 'react-icons/io'
import { useLocation, useNavigate } from 'react-router-dom'
import Loader from '@/app/components/Loading'
import { useSelector } from 'react-redux'
import { RootState } from '@/app/redux/store'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import { CLEAR_FORGET_STEPS, CLEAR_SUCCESS } from '@/app/redux/actionTypes'

type Props = {
    children: ReactNode;
    heading: string;
    description?: string
}

const ForgetPasswordWrapper: React.FC<Props> = ({ children, heading, description }) => {

    const navigate = useNavigate()

    const location = useLocation()

    const { loading, error, success, step, userType } = useSelector((state: RootState) => state.auth)

    const dispatch = useDispatch()

    useEffect(() => {
        if (error) {
            toast.error(error)
        }
    }, [error])

    useEffect(() => {
        if (success) {
            toast.success(success)
        }
    }, [success])

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            event.preventDefault();
            event.returnValue = ''; // Required for some browsers
            return ''; // Show a confirmation dialog
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);


    const onPressBack = () => {
        if (step === 1) {
            navigate("../login", { replace: true })
        } else if (step === 2) {
            console.log("called for step 2");
            dispatch({ type: CLEAR_FORGET_STEPS, payload: { step: 1 } });
            navigate(`../forget-password`, { replace: true });
        } else if (step === 3) {
            dispatch({ type: CLEAR_FORGET_STEPS, payload: { step: 1 } });
            navigate(`../forget-password`, { replace: true });
        } else {
            dispatch({ type: CLEAR_FORGET_STEPS, payload: { step: 1 } });
            navigate("/login", { replace: true })
        }
    };

    return (

        <div className="vh-100 vh-md-100 d-flex flex-column flex-md-row overflow-y-auto" >
            {loading && <Loader />}
            <div onClick={onPressBack} style={{ position: 'absolute', left: 24, top: 16 }} className='d-flex py-3 px-2 align-items-center gap-1'>
                <IoMdArrowRoundBack className='fw-bold' color={COLORS.PRIMARY} fontSize={24} /> <span className='fw-bold'>Back</span>
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center bg-white w-100 h-100 w-md-50 p-3 p-lg-5">
                <img alt='logo' src={IMAGES.LOGIN_LOGO} className="login-logo" />
                <div className={`${styles.card} mt-5 shadow bordered p-3 rounded-3 p-md-5`}>
                    <span className={styles.heading}>{heading ? heading : 'Forgot password?'}</span>
                    {description && <div className='my-3 mb-5'>
                        <span className={styles.description}>
                            {description ? description : 'No worries, We will send you reset instructions'}
                        </span>
                    </div>}
                    <div className='my-3'>
                        {children}
                    </div>
                </div>
                <p className='text-center help-section-text'>
                    Need Help Visit Our&nbsp;
                    <a className='text-decoration-none' href="https://www.example.com/helpcenter">Help Center&nbsp;</a>
                    or contact <a href="mailto:support@edumark.com" >support@edumark.ai</a>.
                </p>
            </div>
        </div>
    )
}

export default ForgetPasswordWrapper
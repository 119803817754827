import COLORS from '@/app/utils/Colors';
import React, { useState, useEffect } from 'react';
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6";
interface Props {
    table: any;
}

const Pagination: React.FC<Props> = ({ table }) => {
    const [visiblePages, setVisiblePages] = useState([1, 2, 3, 4, 5]);

    useEffect(() => {
        const totalPages = table.getPageCount();
        if (totalPages > 0) {
            updateVisiblePages(table.getState().pagination.pageIndex + 1);
        }
    }, [table.getState().pagination.pageIndex, table.getPageCount()]);

    const handleNext = () => {
        if (table.getCanNextPage()) {
            table.nextPage();
        }
    };

    const handlePrevious = () => {
        if (table.getCanPreviousPage()) {
            table.previousPage();
        }
    };

    const updateVisiblePages = (currentPage: number) => {
        const totalPages = table.getPageCount();
        if (totalPages <= 5) {
            setVisiblePages(Array.from({ length: totalPages }, (_, i) => i + 1));
        } else if (currentPage <= 3) {
            setVisiblePages([1, 2, 3, 4, 5]);
        } else if (currentPage > 2 && currentPage < totalPages - 2) {
            setVisiblePages([currentPage - 2, currentPage - 1, currentPage, currentPage + 1, currentPage + 2]);
        } else {
            setVisiblePages([
                totalPages - 4,
                totalPages - 3,
                totalPages - 2,
                totalPages - 1,
                totalPages,
            ]);
        }
    };

    const pageIndex = table.getState().pagination.pageIndex;
    const pageSize = table.getState().pagination.pageSize;

    return (
        <div className="pagination-container mt-3" style={style.paginationContainer}>
            <div className="d-flex flex-lg-row flex-column justify-content-lg-between align-items-lg-center gap-3 gap-lg-0">
                {/* Row Selection on the Left */}
                <div className="d-flex flex-row align-items-center">
                    <span>Show&nbsp;</span>
                    <select
                        value={pageSize}
                        onChange={(e) => {
                            table.setPageSize(Number(e.target.value));
                        }}
                        style={style.select}
                    >
                        {[10, 20, 30, 40, 50].map((size) => (
                            <option key={size} value={size}>
                                {size}
                            </option>
                        ))}
                    </select>
                    <span>Rows</span>
                </div>



                {/* Pagination Controls on the Right */}
                <div className="d-flex flex-row justify-content-lg-center">
                    <div className="d-flex flex-row align-items-center">
                        <button
                            style={style.button}
                            onClick={handlePrevious}
                            disabled={!table.getCanPreviousPage()}
                        >
                            <FaChevronLeft />
                        </button>

                        {visiblePages.map((page) => (
                            <div
                                role="button"
                                key={page}
                                style={{
                                    ...style.button,
                                    fontWeight: 600,
                                    color: page === pageIndex + 1 ? COLORS.WHITE : COLORS.BLACK,
                                    backgroundColor: page === pageIndex + 1 ? COLORS.PRIMARY : 'white',
                                }}
                                onClick={() => table.setPageIndex(page - 1)}
                            >
                                {page}
                            </div>
                        ))}

                        <button
                            style={style.button}
                            onClick={handleNext}
                            disabled={!table.getCanNextPage()}
                        >
                            <FaChevronRight />
                        </button>
                    </div>
                </div>
                {/* Page count in the center */}
                <div className="d-flex flex-row align-items-center">
                    <span style={style.pageInfo}>
                        Page {pageIndex + 1} of {table.getPageCount()}
                    </span>
                </div>
            </div>
        </div>

    );
};

const style = {
    paginationContainer: {
        alignItems: 'center',
    },
    select: {
        padding: '5px',
        borderRadius: '5px',
        marginRight: '10px',
    },
    button: {
        margin: '0 5px',
        padding: '10px',
        width: '35px',
        height: '35px',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '0px solid #ccc',
        borderRadius: '5px',
        cursor: 'pointer',
    },
    pageInfo: {
        marginLeft: '10px',
    },
};

export default Pagination;

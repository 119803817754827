import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import Dashboard from '@screens/Dashboard/Dashboard'
import Users from '@screens/Users/Users';
import { useSelector } from 'react-redux'
import { RootState } from '../redux/store'
import FeedbackSummary from '@screens/FeedbackSummary/FeedbackSummary';
import EducatorAssignment from '../screens/Assignment/EducatorAssignment';
import GradingFeedback from '@screens/GradingFeedback/GradingFeedback';
import EducatorCohort from '../screens/Cohorts/EducatorCohort';
import Analytics from '@screens/Analytics/Analytics'
import Settings from '../screens/Settings/Settings';
import LearnerAssignment from '../screens/Assignment/LearnerAssignment';
import SupervisorAssignment from '../screens/Assignment/SupervisorAssignment';
import Assignment from '../screens/Assignment/Assignment';
// import Assignment from '../screens/Assignment'

interface RouteConfig {
    path: string;
    component: React.FC;
}

const routeConfig: Record<string, RouteConfig[]> = {
    learner: [
        { path: '/dashboard', component: Dashboard },
        { path: '/assignment', component: LearnerAssignment },
        { path: '/setting', component: Settings },
        { path: '/grading-feedback', component: GradingFeedback },
    ],
    educator: [
        { path: '/dashboard', component: Dashboard },
        { path: '/grading-feedback', component: GradingFeedback },
        { path: '/grading-feedback/:level', component: FeedbackSummary },
        { path: '/assignment', component: EducatorAssignment },
        { path: '/educatorCohort', component: EducatorCohort },
        { path: '/cohorts', component: EducatorCohort },
        { path: '/setting', component: Settings },
    ],
    supervisor: [
        { path: '/dashboard', component: Dashboard },
        { path: '/users', component: Users },
        { path: '/assignment', component: SupervisorAssignment },
        { path: '/analytics', component: Analytics },
        { path: '/setting', component: Settings },
    ],
    admin: [
        { path: '/dashboard', component: Dashboard },
        { path: '/users', component: Users },
        { path: '/assignment', component: Assignment },
        { path: '/analytics', component: Analytics },
        { path: '/setting', component: Settings },
    ]
};

const PrivateRoutes: React.FC = () => {
    const { userType } = useSelector((state: RootState) => state.auth)
    const getRoutesForUserType = () => {
        const routes = routeConfig[userType] || [];
        return routes.map(({ path, component: Component }, index) => (
            <Route key={index} path={`${path}`} element={<Component />} />
        ));
    };

    return (
        <Routes>
            <Route path={`${userType}/login`} element={<Navigate to='/dashboard' />} />
            {/* <Route path='dashboard' element={<Dashboard />} /> */}
            {getRoutesForUserType()}
            <Route path='*' element={<Navigate to='/error' />} />
        </Routes>
    )
}

export default PrivateRoutes
import React, { useState } from 'react';
import { Autocomplete, TextField, Checkbox, List, ListItem, ListItemIcon, ListItemText, Box } from '@mui/material';
import { RiCheckboxBlankFill, RiCheckboxMultipleLine } from 'react-icons/ri';
import COLORS from '../utils/Colors';
import { IoIosList } from 'react-icons/io';
import { IoCheckbox } from "react-icons/io5";
import { MdCheckBoxOutlineBlank } from "react-icons/md";

interface Option {
    id: number | any;
    label: string;
    [key: string]: any;
}

interface DropdownWithSearchAndSelectAllProps {
    options: Option[];
    headers: string[];
    selectedOptions: number[];
    onSelectionChange: (selectedOptions: number[]) => void;
}

const DropdownWithSearchAndSelectAll: React.FC<DropdownWithSearchAndSelectAllProps> = ({
    options,
    headers,
    selectedOptions: externalSelectedOptions,
    onSelectionChange
}) => {
    const [showList, setShowList] = useState(false);
    const [selectAllChecked, setSelectAllChecked] = useState(false);

    const handleSelectOption = (event: React.ChangeEvent<{}>, values: Option[]) => {
        const selectedIds = values.map(option => option.id);
        onSelectionChange(selectedIds);
    };

    const handleManualSelect = (optionId: number) => {
        const updatedSelectedOptions = externalSelectedOptions.includes(optionId)
            ? externalSelectedOptions.filter(id => id !== optionId)
            : [...externalSelectedOptions, optionId];
        onSelectionChange(updatedSelectedOptions);
    };

    const toggleList = () => {
        setShowList(!showList);
    };

    const handleSelectAll = () => {
        if (selectAllChecked) {
            onSelectionChange([]);
        } else {
            onSelectionChange(options.map(option => option.id));
        }
        setSelectAllChecked(!selectAllChecked);
    };

    const icon = <MdCheckBoxOutlineBlank size={24} color={COLORS.PRIMARY} />;
    const checkedIcon = <IoCheckbox size={24} color={COLORS.PRIMARY} />;

    return (
        <div style={{ width: '100%' }}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                }}
            >
                <Autocomplete
                    multiple
                    options={options}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.label}
                    value={options.filter(option => externalSelectedOptions.includes(option.id))}
                    onChange={handleSelectOption}
                    filterOptions={(options, { inputValue }) => {
                        return options.filter(
                            option =>
                                option.label.toLowerCase().includes(inputValue.toLowerCase()) ||
                                option.email.toLowerCase().includes(inputValue.toLowerCase())
                        );
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            placeholder="Search..."
                            sx={{
                                border: '1px solid lightgray',
                                borderRadius: '10px',
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        border: 'none',
                                    },
                                },
                            }}
                        />
                    )}
                    sx={{
                        width: '100%',
                        '.MuiAutocomplete-inputRoot': {
                            maxHeight: '100px',
                            overflowY: 'auto',
                        },
                        '.MuiAutocomplete-tag': {
                            height: '32px',
                        },
                    }}
                />
                <div style={{ flex: 'none', }}>
                    <button
                        style={{
                            height: '40px',
                            padding: '0 10px',
                            backgroundColor: COLORS.WHITE,
                            border: `1px solid ${COLORS.PRIMARY}`,
                            justifyContent: 'center',
                            color: COLORS.PRIMARY,
                            borderRadius: '5px',
                            fontWeight: 600
                        }}
                        onClick={toggleList}>
                        {showList ? <span><IoIosList size={24} />&nbsp; Close</span> : <span><IoIosList size={24} />&nbsp; View List</span>}
                    </button>
                </div>
            </Box>
            {showList && (
                <div className='shadow' style={{ width: '100%', marginTop: '10px', borderRadius: '4px' }}>
                    <ListItem
                        component="div"
                        sx={{
                            marginBottom: '0px',
                            padding: '0px 16px',
                            borderBottom: '1px solid #e0e0e0',
                        }}
                    >
                        <ListItemIcon>
                            <Checkbox
                                edge="start"
                                checked={selectAllChecked}
                                icon={icon}
                                checkedIcon={checkedIcon}
                                onChange={handleSelectAll}
                            />
                        </ListItemIcon>
                        {headers.map((header, index) => (
                            <ListItemText key={index} primary={header} />
                        ))}
                    </ListItem>
                    <List
                        style={{
                            maxHeight: '150px',
                            overflowY: 'auto',
                            width: '100%',
                        }}
                    >
                        {options.map(option => (
                            <ListItem
                                key={option.id}
                                component="div"
                                onClick={() => handleManualSelect(option.id)}
                                sx={{
                                    marginBottom: '0px',
                                    padding: '0px 16px',
                                    borderBottom: '1px solid #e0e0e0',
                                }}
                            >
                                <ListItemIcon >
                                    <Checkbox
                                        edge="start"
                                        checked={externalSelectedOptions.includes(option.id)}
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                    />
                                </ListItemIcon>
                                <ListItemText primary={option.label} style={{ width: 150,  wordWrap: 'break-word' }} />
                                {headers.slice(1).map((header, index) => (
                                    <ListItemText key={index} primary={option[header.toLowerCase()]}
                                    style={{
                                        width: 200,
                                        wordWrap: 'break-word'
                                    }} />
                                ))}
                            </ListItem>
                        ))}
                    </List>
                </div>
            )}
        </div>
    );
};

export default DropdownWithSearchAndSelectAll;
// import React from "react";

// interface LearnerListProps {
//   learners: {
//     name: string;
//     memberId: string;
//   }[];
// }
// const LearnerList: React.FC<LearnerListProps> = ({ learners }) => {
//   return (
//     <div className="table-container z-2 mt-4">
//       <table className="learner-table">
//         <thead className="sticky-top z-1">
//           <tr>
//             <th scope="col" />
//             <th scope="col">Name</th>
//             <th scope="col">Membership ID</th>
//             <th scope="col">Action</th>
//           </tr>
//         </thead>
//         <tbody className="z-0">
//           {learners?.map((item, index) => (
//             <tr key={index}>
//               <td>
//                 <input type="checkbox" />
//               </td>
//               <td>{item.name}</td>
//               <td>{item.memberId}</td>
//               <td className="view-detail fw-medium">View Details</td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// };

// export default LearnerList;

import React, { useState } from "react";

interface LearnerListProps {
  learners: {
    id: string;
    name: string;
    memberId: string;
  }[];
  onSelectedChange: (selectedIds: string[]) => void; // Callback to parent
  onclickViewDetails?: (id: string) => void
}

const LearnerList: React.FC<LearnerListProps> = ({
  learners,
  onSelectedChange,
  onclickViewDetails,
}) => {
  const [selectedLearners, setSelectedLearners] = useState<string[]>([]);

  const handleCheckboxChange = (id: string, isChecked: boolean) => {
    let updatedSelected = [...selectedLearners];

    if (isChecked) {
      // Add memberId to the array
      updatedSelected.push(id);
    } else {
      // Remove memberId from the array
      updatedSelected = updatedSelected.filter((aid) => aid !== id);
    }

    setSelectedLearners(updatedSelected);
    onSelectedChange(updatedSelected); // Return the updated array to parent
  };

  return (
    <div className="table-container z-2 mt-4">
      <table className="learner-table">
        <thead className="sticky-top z-1 bg-white">
          <tr>
            <th scope="col" className="user-heading" />
            <th scope="col" className="user-heading">
              Name
            </th>
            <th scope="col" className="user-heading">
              Membership ID
            </th>
            <th scope="col" className="user-heading">
              Action
            </th>
          </tr>
        </thead>
        <tbody className="z-0">
          {learners?.map((item, index) => (
            <tr key={index}>
              <td>
                <input
                  type="checkbox"
                  onChange={(e) =>
                    handleCheckboxChange(item.id, e.target.checked)
                  }
                />
              </td>
              <td>{item.name}</td>
              <td>{item.memberId}</td>
              <td onClick={() => onclickViewDetails?.(item.id)} className="view-detail fw-medium">View Details</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default LearnerList;

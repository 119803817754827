const COLORS = {
    INPUT: {
        BG: '#F3F5FA',
        DISABLED: '#CDD9FE',
    },
    BUTTON: {
        BLUE: '#0343FA',
        HOVER: '#82a2fa'
    },
    WHITE: '#FFFFFF',
    SUCCESS: "#259800",
    ERROR: '#D32F2F',
    LOGIN: {
        LIGHTBLUE: '#CDD9FE'
    },
    BORDER: {
        GRAY: '#CFD3D4',
        DISABLEDINPUT: '#CDD9FE',

    },
    PRIMARY: '#0343FA',
    BLACK: '#000000',
    GREEN: '#008936',
    GRAY: '#6E7079',
    TABLE: {
        BLUE: '#0343FA'
    }
}


export default COLORS;
const IMAGES = {
    LOGIN_BANNER: require('@assets/images/login_banner.png'),
    LOGIN_LOGO: require('@assets/images/login_logo.png'),
    PROFILE:require('@assets/images/profile.png'),
    NOTIFICATION:require('@assets/images/notification.png'),
    SEARCH:require('@assets/images/search.png'),
    ACTIVE_HOME:require('@assets/images/active-home.png'),
    HOME:require('@assets/images/home.png'),
    REPORT : require('@assets/images/report.png'),
    ACTIVE_REPORT : require('@assets/images/active-report.png'),
    FEEDBACK:require('@assets/images/feedback.png'),
    ACTIVE_FEEDBACK:require('@assets/images/active-feedback.png'),
    PROGRESS:require('@assets/images/progress.png'),
    FOLDER:require('@assets/images/folder.png'),
    SETTING:require('@assets/images/setting.png'),
    ACTIVE_SETTING:require('@assets/images/active-setting.png'),
    COHORT:require('@assets/images/cohort.png'),
    ACTIVE_COHORT:require('@assets/images/active-cohort.png'),
    ORDER_REPORT:require('@assets/images/order-report.png'),
    ACTIVE_ORDER_REPORT:require('@assets/images/active-order-report.png'),
    CONTACT : require('@assets/images/contact.png'),
    LOGOUT : require('@assets/images/logout.png'),
    FEEDBACK_IMG : require('@assets/images/feedback-img.png'),
    ASSIGNMENT : require('@assets/images/assignment.png'),
    PEN : require('@assets/images/pen.png'),
    EDUCATOR_PEN : require('@assets/images/pen.png'),
    PDFICON:require('@assets/images/pdf-icon.png'),
    PNGICON : require('@assets/images/png-icon.png'),
    DOCXICON : require('@assets/images/docx-icon.png'),
    DOCUMENT : require('@assets/images/document.png'),
    CLOCK : require('@assets/images/clock.png'),
    CHECK : require('@assets/images/check.png'),
    ICON : require('@assets/images/icon.png'),
    USERS : require('@assets/images/User.png'),
    SEARCH_LIGHT: require('@assets/images/search-light.png'),
    FILTER:require("@assets/images/filter.png"),
    PLUS:require("@assets/images/plus.png"),
    ACTION:require('@assets/images/action.png'),
    AVTAR: require('../assets/images/avtar.png'),
    COHORT_ICON: require("@assets/images/cohort-icon.png"),
    RIGHT_ARROW: require("@assets/images/right-arrow.png"),
    DOWNLOAD:require("@assets/images/download.png"),
    ARROW:require("@assets/images/arrow.png"),
    UPLOAD: require("@assets/images/upload.png"),
    SIGNATURE:require("@assets/images/siganture.png"),
    FILE_CHECK:require("@assets/images/file-check.png"),
    CLOCK_ICON : require("@assets/images/clock-icon.png"),
    PROFILE_ICON:require("@assets/images/profile-icon.png"),
    SORT_ICON:require("@assets/images/sort.png"),
    RATING:require("@assets/images/rating.png"),
    ERROR:require("@assets/images/Error.png"),
    MAINTENANCE:require("@assets/images/Maintenance.png"),
    DASHBOARD_PRODUCTIVITY:require("@assets/images/setting.png"),
    SUPERVISOR_ICON:require("@assets/images/supervisor-icon.png"),
}

export default IMAGES;

import { CrossIcon } from "@/app/assets/icons/svg";
import IMAGES from "@/app/utils/Images";
import { Modal, Row, Button } from "react-bootstrap";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import StarIcon from "@mui/icons-material/Star";
import { useState } from "react";

const RatingModal = ({ open, setOpen, assignmentID }) => {
  const [value, setValue] = useState<number | null>(0);
  const [hover, setHover] = useState(-1);
  const labels: { [index: string]: string } = {
    0: "0/5",
    1: "1/5",
    2: "2/5",
    3: "3/5",
    4: "4/5",
    5: "5/5",
  };

  function getLabelText(value: number) {
    return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
  }
  return (
    <Modal show={open} className="success-modal rating-modal">
      <Modal.Body>
        <Row className="w-100 d-flex gap-1 flex-column justify-content-center align-items-center">
          <div>
            <Button
              variant="link"
              onClick={() => {
                setOpen(false);
                setValue(0)
              }}
              style={{position: "absolute", right: 0}}
            >
              <img src={CrossIcon} alt="" />
            </Button>
          </div>
          <img alt="" src={IMAGES.RATING} className="file-img" />
          <label className="signature-text text-center success-popup-text">
            Please rate the feedback you received on your assignment{" "}
            {assignmentID}
          </label>
          <div className="rating-container d-flex align-items-center justify-content-center">
            <Box
              sx={{
                width: 200,
                display: "flex",
                alignItems: "center",
                justifyContent: "center", // Center horizontally
                borderRadius: "7.406px",
                background: "#F7F8FA",
                padding: "12px", // Optional padding
                marginBottom: "2rem",
              }}
            >
              <Rating
                name="hover-feedback"
                value={value}
                precision={1}
                getLabelText={getLabelText}
                onChange={(event, newValue) => {
                  setValue(newValue);
                }}
                onChangeActive={(event, newHover) => {
                  setHover(newHover);
                }}
                emptyIcon={
                  <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                }
              />
              {value !== null && (
                <Box
                  sx={{
                    ml: 2,
                    color: "#687D94",
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontWeight: 600,
                    lineHeight: "24.688px",
                  }}
                >
                  {labels[hover !== -1 ? hover : value]}
                </Box>
              )}
            </Box>
          </div>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default RatingModal;

import React, { Dispatch, SetStateAction, useState } from "react";
import IMAGES from "@utils/Images";

interface UserListProps {
  data: {
    item: string;
    level: string;
    cohort: string;
    isMarked: boolean;
    icon: string;
    name?: string;
  }[];
  label?: string;
  totalPages?: number;
  fetchData?: (userType: string[], page: number, pageSize: number) => void;
  userType?: string[]
}
const UserList: React.FC<UserListProps> = ({
  data,
  label,
  totalPages = 1,
  fetchData,
  userType
}) => {
  const [page, setPage] = useState<number>(1);

  const [pageSize, setPageSize] = useState<number>(10);
  const containerStyles: React.CSSProperties = {
    height: "302px",
    overflowY: "scroll",
    scrollbarWidth: "none",
  };
  const heading: React.CSSProperties = {
    fontSize: "18.85px",
    fontWeight: "500",
    lineHeight: "16.86px",
    color: "#1C1D1D",
  };
  const listitemStyle: React.CSSProperties = {
    fontSize: "15.71px",
    fontWeight: "500",
    lineHeight: "19.01px",
    color: "#1C1D1D",
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  };
  const itemStyle: React.CSSProperties = {
    borderBottom: "1.03px solid",
    borderColor: "#ECECEC",
    padding: "25px",
    boxShadow: "0px 0px 39.85px 0px #00000012",
    borderRadius: "9.96px",
  };
  const dateStyle: React.CSSProperties = {
    fontSize: "13.82px",
    fontWeight: "400",
    lineHeight: "17.41px",
    color: "1C1D1D",
  };

  const handlePaginationChange = (newPage: number, newPageSize: number) => {
    setPage(newPage);
    setPageSize(newPageSize);
    if (fetchData) {
      if (userType) {
        fetchData(userType, newPage, newPageSize);
      }
    }
  };

  return (
    <div className="Userlist-container card-container" style={containerStyles}>
      <div className="d-flex justify-content-between">
        <label className="heading" style={heading}>
          {label}
        </label>
        <div
          className="heading d-flex gap-4 align-items-center"
          style={heading}
        >
          {/* <label>{"All"}</label>
          <img
            src={IMAGES.SEARCH}
            alt="searchIcon"
            className="w-6 h-6 ml-auto mr-2"
          /> */}
        </div>
      </div>
      <div className={`col-12`}>
        {data?.map(
          (detail: {
            item: string;
            level: string;
            cohort: string;
            isMarked: boolean;
            icon: string;
            name?: string;
          }, index) => (
            <div
              className="d-flex column align-items-center gap-4 mt-3"
              style={itemStyle}
              key={index}
            >
              <img src={detail.icon} alt="Logo" />
              <div style={listitemStyle}>
                <label>{detail.item}</label>
                <div className="d-flex gap-2" style={dateStyle}>
                  <div className="d-flex gap-2 align-items-center">
                    <img src={IMAGES.DOCUMENT} alt="Logo" />
                    <label>{detail.level}</label>
                  </div>
                  <div className="d-flex gap-2 align-items-center">
                    {/* <img src={IMAGES.CLOCK} alt="Logo"/> */}
                    - {" "}
                    <label>{detail.cohort}</label>
                  </div>
                  {detail.isMarked && (
                    <div className="d-flex gap-2 align-items-center">
                      {/* <img src={IMAGES.CHECK} alt="Logo"/> */}
                      - {" "}
                      <label>{detail.name}</label>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )
        )}
      </div>
      <div className="pagination-controls my-3 d-flex justify-content-center align-items-center">
        {/* Pagination component similar to LearnerTable */}
        <div>
          <button
            onClick={() => handlePaginationChange(page - 1, pageSize)}
            disabled={page === 1}
            style={{
              margin: '0px 5px',
              padding: '10px',
              width: '35px',
              height: '35px',
              display: 'inline-flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '0px solid rgb(204, 204, 204)',
              borderRadius: '5px',
              cursor: 'pointer'
            }}        >
            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 320 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z">
            </path></svg>
          </button>
          <span>
            Page
            <span>{page}</span> of {totalPages}
          </span>

          <button
            style={{
              margin: "0px 5px",
              padding: "10px",
              width: "35px",
              height: "35px",
              display: "inline-flex",
              justifyContent: "center",
              alignItems: "center",
              border: "0px solid rgb(204, 204, 204)",
              borderRadius: "5px",
              cursor: "pointer",
            }}
            onClick={() => handlePaginationChange(page + 1, pageSize)}
            disabled={page === totalPages}
          >
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 320 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" />
            </svg>
          </button>

        </div>


      </div>
    </div>
  );
};

export default UserList;

import React from 'react';
import { Dropdown, ButtonGroup } from 'react-bootstrap';
import styles from './TableAction.module.css'
// Define the type for the action item
interface Action {
    label: string;
    onClick: () => void;
}

// Define the props for the component
interface TableActionDropdownProps {
    actions: Action[];
}



const TableActionDropdown: React.FC<TableActionDropdownProps> = ({ actions }) => {
    return (
        <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle
                className={styles.customDropdownToggle}
                style={{ background: 'none', border: 'none' }}
            >
                <i className="text-dark fa-solid fa-ellipsis"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu className={styles.usersDropdown} style={{ zIndex: 1050 }}>
                {actions.map((action, index) => (
                    <Dropdown.Item
                        key={index}
                        className={styles.dropdownItem}
                        onClick={action.onClick}
                    >
                        {action.label}
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default TableActionDropdown;

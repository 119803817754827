import * as Yup from 'yup'
import { daysOfWeek, updateProfileFields } from '@modules/static/FormArray';

export const loginSchema = Yup.object().shape({
    email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
    password: Yup.string()
        .required('Password is required')
});

export const emailSchema = Yup.object().shape({
    email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
});

export const passwordSchema = Yup.object().shape({
    password: Yup.string()
        .test(
            'custom-password-test',
            'Password must be at least 8 characters long, include 1 uppercase letter, and 1 number.',
            (value) =>
                !!value &&
                value.length >= 8 &&
                /[A-Z]/.test(value) &&
                /\d/.test(value)
        )
        .required('Password is required'),

    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password')], 'The passwords do not match. Please try again')
        .required('Please confirm your password'),
});


export const updateProfileSchema = Yup.object().shape(
    updateProfileFields?.reduce<Record<string, Yup.StringSchema>>((schema, field) => {
        if (field.required) {
            schema[field.name] = Yup.string().required(`${field.label} is required`);
        } else {
            schema[field.name] = Yup.string(); // No required validation
        }
        return schema;
    }, {})
);


export const scheduleSchema = Yup.object({
    status: Yup.boolean(),
    timeFrom: Yup.date().when('status', {
        is: true,
        then: (schema) =>
            schema
                .required('From Time is required')
                .typeError('From Time must be a valid date'),
        otherwise: (schema) => schema.nullable(),
    }),
    timeTo: Yup.date().when('status', {
        is: true,
        then: (schema) =>
            schema
                .required('To Time is required')
                .typeError('To Time must be a valid date')
                .test('is-greater', 'To Time must be after From Time', function (value) {
                    const { fromTime } = this.parent;
                    return fromTime && value ? value > fromTime : true;
                }),
        otherwise: (schema) => schema.nullable(),
    }),
});

interface DaySchema {
    status?: boolean;
    timeFrom?: Date;
    timeTo?: Date;
}

export const scheduleValidationSchema = Yup.object().shape(
    daysOfWeek.reduce((schema, day) => {
        schema[day.dayName] = scheduleSchema;
        return schema;
    }, {} as Record<string, Yup.ObjectSchema<DaySchema>>)
);

export const coHortSchema = Yup.object().shape({
    name: Yup.string()
      .required('Name is required'),
    level: Yup.string()
      .required('Level is required'),
    educatorIds: Yup.array()
      .of(Yup.string())
      .optional(), // Educator IDs are optional
    learnerIds: Yup.array()
      .of(Yup.string())
      .optional(), // Learner IDs are optional
  });
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { passwordSchema } from '@modules/validations/validations';
import Input from '@/app/components/Input';
import ICONS from '@/app/utils/Icons';
import COLORS from '@/app/utils/Colors';
import ForgetPasswordWrapper from './ForgetPasswordWrapper';
import Button from '@/app/components/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import { CLEAR_FORGET_STEPS, SEND_VERIFY_OTP_RESET_PASSWORD } from '@/app/redux/actionTypes';
import { useSelector } from 'react-redux';
import { RootState } from '@/app/redux/store';
import { FaCircleCheck } from "react-icons/fa6";

type Props = {}

const ForgetEnterPassword = (props: Props) => {

    const location = useLocation();

    const { email, otp } = location.state;

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const [secured, setSecured] = useState(true)

    const [confirmSecured, setConfirmSecured] = useState(true)

    const { step, userType } = useSelector((state: RootState) => state.auth)

    const formik = useFormik({
        initialValues: { password: '', confirmPassword: '' },
        onSubmit: (values) => {
            dispatch({
                type: SEND_VERIFY_OTP_RESET_PASSWORD,
                payload: {
                    type: 'resetpassword',
                    email: email,
                    otpCode: otp,
                    step: 4,
                    password: values.password,
                    confirmPassword: values.confirmPassword
                }
            })
        },
        validationSchema: passwordSchema
    })

    const customError: React.CSSProperties = {
        color: formik.touched.password && formik.errors.password ? COLORS.ERROR : formik.touched.password && formik.errors.password === undefined ? COLORS.GREEN : COLORS.GRAY, // Conditional color change
        fontSize: '14px',
        marginLeft: '16px',
        marginTop: '5px',
        fontStyle: 'italic',
        lineHeight: '20px'
    }

    useEffect(() => {
        if (step === 1) {
            setTimeout(() => {
                navigate('/forget-password', { replace: true })
            }, 300);
        }
    }, [step])


    return (
        <ForgetPasswordWrapper
            heading={step === 4 ? 'Password Updated' : 'Create new password'}
            description={step === 4 ? 'Your password has been updated successuly' : ''}
        >
            {step === 3 && <div>
                <Input
                    label={'New password'}
                    name="password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                    type={secured ? 'password' : 'text'}
                    className={'mt-md-4 mt-4'}
                    placeholder={'Password'}
                    leftIcon={<img src={ICONS.INPUT.PASSWORD} alt='password' style={{ height: 20, width: 18 }} />}
                    rightIcon={<i onClick={() => setSecured(!secured)} className={`fa ${secured ? 'fa-eye-slash' : 'fa-eye'} eye-icon`} id="clearIcon"></i>}
                    error={null}
                />
                <div style={customError} >
                    Password must be at least 8 characters long, include 1 uppercase letter, and 1 number.
                </div>
                <Input
                    label={'Confirm password'}
                    name="confirmPassword"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.confirmPassword}
                    type={confirmSecured ? 'password' : 'text'}
                    className={'mt-4'}
                    placeholder={'Password'}
                    leftIcon={<img src={ICONS.INPUT.PASSWORD} alt='confirmPassword' style={{ height: 20, width: 18 }} />}
                    rightIcon={<i onClick={() => setConfirmSecured(!confirmSecured)} className={`fa ${confirmSecured ? 'fa-eye-slash' : 'fa-eye'} eye-icon`} id="clearIcon"></i>}
                    error={formik.errors.confirmPassword && formik.touched.confirmPassword ? formik.errors.confirmPassword : null}
                />
                <Button
                    label={'Continue'}
                    backgroundColor={COLORS.PRIMARY}
                    className={'mt-5 loginButton w-100'}
                    onClick={formik.handleSubmit} />
            </div>}

            {step === 4 && <div className='d-flex flex-column align-items-center mt-5'>
                <FaCircleCheck size={80} color='#4BC769' />
                <Button
                    label={'Login'}
                    backgroundColor={COLORS.PRIMARY}
                    className={'mt-5 w-75'}
                    onClick={() => {
                        dispatch({ type: CLEAR_FORGET_STEPS, payload: { step: 1 } });
                        navigate("..../login", { replace: true })
                    }} />
            </div>}
        </ForgetPasswordWrapper>
    )
}

export default ForgetEnterPassword;
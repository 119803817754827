import { UploadIcon } from "@/app/assets/icons/svg";
import Loader from "@/app/components/Loading";
import PaginatedTable from "@/app/components/Table/PaginatedTable";
import TableActionDropdown from "@/app/components/Table/TableAction";
import FilterDropdown from "@/app/components/Table/TableFilter";
import UploadAssignment from "@/app/components/UploadAssignment";
import { formatDateToMonthDateYear } from "@/app/modules/helpers/FormikHelper";
import { FETCHED_LEARNER_ASSIGNMENT, FETCHED_LEARNER_ASSIGNMENTS } from "@/app/redux/actionTypes";
import { RootState } from "@/app/redux/store";
import COLORS from "@/app/utils/Colors";
import { learnerActiveOptions, learnerHeadings, learnerPastOptions, sortOptions } from "@/app/utils/helper";
import Button from "@components/Button";
import Layout from "@components/Layout";
import IMAGES from "@utils/Images";
import debounce from "lodash.debounce";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "./Assignment.module.style.css";
import { apiUrl } from "@/app/api/ApiRequest";

interface AssignmentsProps {
    userType?: string
}
const LearnerAssignment: React.FC<AssignmentsProps> = () => {
    const [isFilterStatus, setIsFilterStatus] = useState(false);
    const [filter, setFilter] = useState<any>("");
    const [activeTab, setActiveTab] = useState("Active");
    const [openUpload, setOpenUpload] = useState(false);
    const { "Assignment Status": filterLevel } = filter;
    const [sortBy, setSortBy] = useState<any>("");
    const [pages, setPages] = useState<number>(1);
    const [searchQuery, setSearchQuery] = useState("");
    const [pageSize, setPageSize] = useState<number>(10);
    const [isSortStatus, setIsSortStatus] = useState(false);
    const { LearnerAssignments, error, totalPages, loading } = useSelector((state: RootState) => state.assignment);
    const [selectedItem, setSelectedItem] = useState(LearnerAssignments[0]);

    const handleDownload = (assessmentId: string, fileName: string) => {
        const link = document.createElement('a');
        link.href = `${apiUrl}assignment/download-assignment/${assessmentId}/${fileName}`;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const columns: any = [
        { header: 'Assignment Id', accessorKey: 'assessmentId' },
        { header: 'Unit Title', cell: ({ row }) => <span style={{ color: COLORS.GRAY }}>{row.original.title}</span> },
        { header: 'Released Date', cell: ({ row }) => <span style={{ color: COLORS.GRAY }}>{formatDateToMonthDateYear(row.original.date)}</span> },
        {
            header: 'Status', cell: ({ row }) => {
                return (
                    activeTab === "Active" ?
                        (
                            <div
                                className={`d-flex align-items-center gap-2  ${row.original.label === "New"
                                    ? "label-success"
                                    : row.original.label === "Resubmisson"
                                        ? "label-danger"
                                        : "label-success"
                                    }`}
                            >
                                <span className="border rounded-circle p-1" />
                                <label className="item">{row.original.label}</label>
                            </div>
                        ) : (
                            <div
                                className={`d-flex align-items-center gap-2  ${row.original.label === "Under Review"
                                    ? "label-success"
                                    : row.original.label === "Fail"
                                        ? "label-danger"
                                        : row.original.label === "Resubmission"
                                            ? "label-warning"
                                            : "label-primary"
                                    }`}
                            >
                                <span className="border rounded-circle p-1" />
                                <label className="item">{row.original.label}</label>
                            </div>
                        )
                )
            }
        },
        {
            header: 'Actions', cell: ({ row, index }) => (
                <TableActionDropdown
                    actions={[
                        { label: 'Download Assignment', onClick: () => handleDownload(row.original.assessmentId, 'AssignmentBrief') },
                        { label: 'Download Criteria', onClick: () => handleDownload(row.original.assessmentId, 'AssignmentCriteria') },
                        { label: 'View Details', onClick: () => handleViewDetails(row.original) },
                    ]}
                />
            )
        },
    ];

    useEffect(() => {
        if (error) {
            toast.error(error);
        }
    }, [error])

    const handleStatusFilter = (status: (string | number)[]) => {
        setFilter(status);
    };

    const handleSortData = (status: string | number) => {
        setSortBy(status);
    };

    const dispatch = useDispatch();

    const fetchLearnerAssignments = async (pageIndex: number, pageSize: number, query: string, type?: string, filter?: [], sortBy?: string) => {
        const limit = pageSize;

        await dispatch({
            type: FETCHED_LEARNER_ASSIGNMENT,
            payload: { page: pageIndex, limit, search: query, type, filter, sortBy }
        });
    };

    console.log(fetchLearnerAssignments)

    useEffect(() => {
        fetchLearnerAssignments(pages, pageSize, searchQuery, activeTab, filterLevel, sortBy);
    }, [pages, pageSize, searchQuery, activeTab, filter, sortBy]);

    const handleSearchChange = debounce((event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
    }, 500);

    const handlePaginationChange = (pageNumber: number, pageSize: number) => {
        setPages(pageNumber);
        setPageSize(pageSize);
    };

    const handleTabChange = (tab: any) => {
        setActiveTab(tab);
    };

    const handleViewDetails = (item: any) => {
        setSelectedItem(item);
        setOpenUpload(true);
    };

    useEffect(() => {
        if (LearnerAssignments) {
            setSelectedItem(LearnerAssignments[0]);
        }
    }, [LearnerAssignments])

    return (
        <Layout>
            {loading && <Loader />}
            <div className="assignment-container m-4 vh-100">
                <div className="sticky-top z-3 bg-white">
                    <div className="heading-container mb-4">
                        <span className="heading">Assignments</span>
                        <span className="label">Manage course assignments</span>
                    </div>
                    <div className="buttons d-flex flex-lg-row flex-column gap-lg-4 gap-2 mt-lg-0 mt-2 mb-3">
                        <Button
                            label={"Active"}
                            className={`tab-btn ${activeTab === "Active" ? "active-btn" : ""
                                }`}
                            onClick={() => handleTabChange("Active")}
                            isIcon={true}
                        />
                        <Button
                            label={"Past"}
                            className={`tab-btn ${activeTab === "Past" ? "active-btn" : ""
                                }`}
                            onClick={() => handleTabChange("Past")}
                            isIcon={true}
                        />
                    </div>
                    <PaginatedTable
                        columns={columns}
                        data={LearnerAssignments || []}
                        pageCount={totalPages || 2}
                        fetchData={fetchLearnerAssignments}
                        handlePaginationChange={handlePaginationChange}
                        handleSearchChange={handleSearchChange}
                        customButtons={
                            <div className="d-flex gap-2">
                                <FilterDropdown
                                    type="sort"
                                    options={sortOptions}
                                    onSortSelect={handleSortData}
                                    placeholder={
                                        <Button
                                            label={""}
                                            className={"assignment-btn"}
                                            onClick={() => {
                                                setIsSortStatus(!isSortStatus);
                                            }}
                                            isIcon={true}
                                            icon={IMAGES.SORT_ICON}
                                        />
                                    }
                                />
                                <FilterDropdown
                                    type="filter"
                                    options={activeTab === "Active" ? learnerActiveOptions : learnerPastOptions}
                                    onFilterSelect={handleStatusFilter}
                                    headings={learnerHeadings}
                                    isMultiple={true}
                                    placeholder={
                                        <Button
                                            label={"Filter"}
                                            className={"assignment-btn"}
                                            onClick={() => {
                                                setIsFilterStatus(!isFilterStatus);
                                            }}
                                            isIcon={true}
                                            icon={IMAGES.FILTER}
                                        />
                                    }
                                />
                            </div>
                        }
                    />
                </div>
            </div> 
            {(openUpload) && (
                <UploadAssignment
                    isOpen={openUpload}
                    item={selectedItem}
                    onClose={() => setOpenUpload(!openUpload)}
                />
            )}
        </Layout>
    );
};

export default LearnerAssignment;

import Button from '@/app/components/Button'
import Input from '@/app/components/Input'
import { RootState } from '@/app/redux/store'
import COLORS from '@/app/utils/Colors'
import ICONS from '@/app/utils/Icons'
import { useFormik } from 'formik'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { emailSchema } from '@modules/validations/validations';
import ForgetPasswordWrapper from './ForgetPasswordWrapper'
import { CLEAR_SUCCESS, SEND_VERIFY_OTP_RESET_PASSWORD } from '@/app/redux/actionTypes'


type Props = {}

const ForgetPassword = (props: Props) => {

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const { step } = useSelector((state: RootState) => state.auth)

    useEffect(() => {
        if (step === 2) {
            setTimeout(() => {
                dispatch({ type: CLEAR_SUCCESS })
                navigate('/verifyOtp', { state: { email: formik.values.email } })
            }, 300);
        }
    }, [step])

    const formik = useFormik({
        initialValues: { email: '' },
        onSubmit: (value) => { dispatch({ type: SEND_VERIFY_OTP_RESET_PASSWORD, payload: { email: value.email, type: 'resetpassword', step: 1 } }) },
        validationSchema: emailSchema
    })

    return (
        <ForgetPasswordWrapper
            heading='Forgot password?'
            description='No worries, We will send you reset instructions.  '
        >
            <>
                <Input
                    type='email'
                    name="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    placeholder={'Email Address'}
                    leftIcon={<img alt='email' src={ICONS.INPUT.EMAIL} style={{ height: 20, width: 18 }} />}
                    error={formik.errors.email && formik.touched.email ? formik.errors.email : null}
                />
                <Button
                    label={'Reset Password'}
                    backgroundColor={COLORS.PRIMARY}
                    className={'mt-4 loginButton w-100'}
                    onClick={formik.handleSubmit} />
            </>
        </ForgetPasswordWrapper>
    )
}

export default ForgetPassword
import { getErrorMessage } from "@/app/modules/helpers/ErrorHelper";
import { FetchGradingAssignmentsResponse, signatureUploadAssignment } from "@/app/modules/types/types";
import { call, put, takeLatest } from "redux-saga/effects";
import { AIASSIGNMENT_FAILED, CREATE_LEARNER_UPLOAD_ASSIGNMENT_FAILED, CREATE_LEARNER_UPLOAD_ASSIGNMENT_SUCCESS, CREATE_UPLOAD_SIGNATURE_FAILED, CREATE_UPLOAD_SIGNATURE_SUCCESS, DOWNLOAD_ASSIGNMENTS, DOWNLOADS_ASSIGNMENTS, FETCH_GRADING_ASSIGNMENT, FETCH_GRADING_FEEDBACK_AI, FETCH_GRADING_FEEDBACK_SECOND_MARKING_AI, FETCH_LEARNER_GRADING_ASSIGNMENT, FETCH_SIGNATURE, FETCHED_GRADING_ASSIGNMENT, FETCHED_GRADING_FEEDBACK_AI, FETCHED_GRADING_FEEDBACK_SECOND_MARKING_AI, FETCHED_LEARNER_GRADING_ASSIGNMENT, FETCHED_SIGNATURE, FETCHED_SIGNATURE_FAILED, GRADINGFEEDBACK_FAILED, LEARNER_GRADINGFEEDBACK_FAILED, RESUBMIT_FEEDBACK, RESUBMITED_FEEDBACK, SET_LOADING_GRADING_AI_ASSIGNMENT, SET_LOADING_GRADING_ASSIGNMENT, SET_LOADING_GRADINGFEEDBACK, SET_LOADING_LEARNER_GRADING_ASSIGNMENT, SET_LOADING_UPLOAD_SIGNATURE, UPDATE_FEEDBACK, UPDATE_LEARNER_GRADING_ASSIGNMENT, UPDATED_FEEDBACK, UPDATED_LEARNER_GRADING_ASSIGNMENT, UPLOAD_SIGNATURE } from "../actionTypes";
import { assesor_declaration, download_assignment, get_assignment_details, get_assignments_grading, get_learner_assignments_grading, get_second_marking_details, resubmit_feedback, setting_signature, update_feedback, update_learner_grading_feedback, upload_signature } from "@/app/api/Api";
import assignmentAIdata from "@assets/JSON/assignmentAI.json"

function* fetchGradingFeedback(action: { type: string, payload: { page: string, limit: string, search: string, filter: string, sort: string } }): Generator {
    try {
        yield put({ type: SET_LOADING_GRADING_ASSIGNMENT });
        const { page, limit, search, filter, sort } = action.payload
        const response = (yield call(get_assignments_grading, page, limit, search, filter, sort)) as FetchGradingAssignmentsResponse;
        yield put({ type: FETCHED_GRADING_ASSIGNMENT, payload: { data: response.data } });
    } catch (error) {
        const errorMessage = getErrorMessage(error);
        yield put({ type: GRADINGFEEDBACK_FAILED, payload: { message: errorMessage } });
    }
}

function* fetchLearnerGradingFeedback(action: { type: string, payload: { page: string, limit: string, search: string, filter: string, sort: string } }): Generator {
    try {
        yield put({ type: SET_LOADING_LEARNER_GRADING_ASSIGNMENT });
        const { page, limit, search, filter, sort } = action.payload
        const response = (yield call(get_learner_assignments_grading, page, limit, search, filter, sort)) as FetchGradingAssignmentsResponse;
        yield put({ type: FETCHED_LEARNER_GRADING_ASSIGNMENT, payload: { data: response.data } });
    } catch (error) {
        const errorMessage = getErrorMessage(error);
        yield put({ type: LEARNER_GRADINGFEEDBACK_FAILED, payload: { message: errorMessage } });

    }
}

function* uploadSignature(action: { type: string, payload: { fileUploadpayload: signatureUploadAssignment, assesorDeclarationPayload: any } }): Generator {
    try {
        yield put({ type: SET_LOADING_UPLOAD_SIGNATURE });
        const response = (yield call(upload_signature, action.payload.fileUploadpayload)) as FetchGradingAssignmentsResponse;
        if (response?.success) {
            const response = (yield call(assesor_declaration, action.payload.assesorDeclarationPayload)) as any;
            if (response?.data?.result?.status === "error") {
                yield put({ type: CREATE_UPLOAD_SIGNATURE_FAILED, payload: { message: response?.data?.result?.message } });
            } else {
                yield put({ type: CREATE_UPLOAD_SIGNATURE_SUCCESS, payload: { data: response?.data?.result?.new_file_id } });
            }
        } else {
            yield put({ type: CREATE_UPLOAD_SIGNATURE_FAILED, payload: { message: response?.message } });
        }
    } catch (error) {
        const errorMessage = getErrorMessage(error);
        yield put({ type: CREATE_UPLOAD_SIGNATURE_FAILED, payload: { message: errorMessage } });
    }
}

function* fetchSignature(action: { type: string, payload: { assignmentId: string } }): Generator {
    try {
        yield put({ type: SET_LOADING_UPLOAD_SIGNATURE });
        console.log(action.payload)
        const response = (yield call(setting_signature)) as any;
        yield put({ type: FETCHED_SIGNATURE, payload: { data: response.data } });
    } catch (error) {
        const errorMessage = getErrorMessage(error);
        yield put({ type: FETCHED_SIGNATURE_FAILED, payload: { message: errorMessage } });
    }
}

function* fetchAssignmentInfo(action: { type: string, payload: { file_id: string, level: string } }): Generator {
    try {
        yield put({ type: SET_LOADING_GRADING_AI_ASSIGNMENT });
        const { level, file_id } = action.payload;
        const response = (yield call(get_assignment_details, level, file_id)) as any;
        if (response?.data?.result?.status === "error") {
            yield put({ type: GRADINGFEEDBACK_FAILED, payload: { message: response?.data?.result?.message } });
        } else {
            yield put({ type: FETCHED_GRADING_FEEDBACK_AI, payload: { data: response?.data?.result?.result ? response?.data?.result?.result : assignmentAIdata } });
        }
    } catch (error) {
        const errorMessage = getErrorMessage(error);
        yield put({ type: AIASSIGNMENT_FAILED, payload: { message: errorMessage } });
    }
}

function* handleDownloadAssignment(action: { type: string, payload: string }): Generator {
    try {
        yield put({ type: SET_LOADING_GRADING_ASSIGNMENT });
        const response = (yield call(download_assignment, action.payload)) as any;
        yield put({ type: DOWNLOADS_ASSIGNMENTS, payload: { fileId: action.payload, data: response } });
    } catch (error) {
        const errorMessage = getErrorMessage(error);
        yield put({ type: GRADINGFEEDBACK_FAILED, payload: { message: errorMessage } });
    }
}

function* resubmit(action: { type: string, payload: string }): Generator {
    try {
        yield put({ type: SET_LOADING_GRADING_ASSIGNMENT });
        const response = (yield call(resubmit_feedback, action.payload)) as any;
        if (response?.data?.result?.status === "error") {
            yield put({ type: CREATE_LEARNER_UPLOAD_ASSIGNMENT_FAILED, payload: { message: response?.data?.result?.message } });
        } else {
            yield put({ type: CREATE_LEARNER_UPLOAD_ASSIGNMENT_SUCCESS, payload: { message: response?.data?.result?.message } });
            yield put({ type: RESUBMITED_FEEDBACK });
        }

        yield put({ type: RESUBMITED_FEEDBACK, payload: { data: response } });
    } catch (error) {
        const errorMessage = getErrorMessage(error);
        yield put({ type: GRADINGFEEDBACK_FAILED, payload: { message: errorMessage } });
    }
}


function* updateFeedback(action: { type: string, payload: { updatedFeedback: any } }): Generator {
    try {
        yield put({ type: SET_LOADING_GRADING_ASSIGNMENT });
        const response = (yield call(update_feedback, action.payload.updatedFeedback)) as any;
        if (response?.data?.result?.status === "error") {
            yield put({ type: GRADINGFEEDBACK_FAILED, payload: { message: response?.data?.result?.message } });
        } else {
            yield put({ type: UPDATED_FEEDBACK, payload: { data: response?.data?.result?.new_file_id } });
        }
    } catch (error) {
        const errorMessage = getErrorMessage(error);
        yield put({ type: GRADINGFEEDBACK_FAILED, payload: { message: errorMessage } });
    }
}

function* fetchSecondMarking(action: { type: string, payload: { updatedFeedback: any } }): Generator {
    try {
        yield put({ type: SET_LOADING_GRADING_ASSIGNMENT });
        const response = (yield call(get_second_marking_details, action.payload.updatedFeedback)) as any;
        if (response?.data?.result?.status === "error") {
            yield put({ type: GRADINGFEEDBACK_FAILED, payload: { message: response?.data?.result?.message } });
        } else {
            yield put({ type: FETCHED_GRADING_FEEDBACK_SECOND_MARKING_AI, payload: { data: response?.data?.result?.new_file_id } });
        }

    } catch (error) {
        const errorMessage = getErrorMessage(error);
        yield put({ type: GRADINGFEEDBACK_FAILED, payload: { message: errorMessage } });
    }
}

function* updateLearnerAssignment(action: { type: string, payload: { assignmentId: string, data: any } }): Generator {
    try {
        yield put({ type: SET_LOADING_GRADING_ASSIGNMENT });
        console.log("🚀 ~ function*updateLearnerAssignment ~ action.payload.data:", action.payload.data)
        const response = (yield call(update_learner_grading_feedback, action.payload.assignmentId, action.payload.data)) as any;
        yield put({ type: UPDATED_LEARNER_GRADING_ASSIGNMENT, payload: { message: response.message } });
    } catch (error) {
        const errorMessage = getErrorMessage(error);
        yield put({ type: GRADINGFEEDBACK_FAILED, payload: { message: errorMessage } });
    }
}

function* gradingFeedbackSaga() {
    yield takeLatest(FETCH_GRADING_ASSIGNMENT, fetchGradingFeedback)
    yield takeLatest(UPLOAD_SIGNATURE, uploadSignature);
    yield takeLatest(FETCH_SIGNATURE, fetchSignature);
    yield takeLatest(FETCH_LEARNER_GRADING_ASSIGNMENT, fetchLearnerGradingFeedback);
    yield takeLatest(FETCH_GRADING_FEEDBACK_AI, fetchAssignmentInfo);
    yield takeLatest(DOWNLOAD_ASSIGNMENTS, handleDownloadAssignment)
    yield takeLatest(RESUBMIT_FEEDBACK, resubmit);
    yield takeLatest(UPDATE_FEEDBACK, updateFeedback);
    yield takeLatest(FETCH_GRADING_FEEDBACK_SECOND_MARKING_AI, fetchSecondMarking);
    yield takeLatest(UPDATE_LEARNER_GRADING_ASSIGNMENT, updateLearnerAssignment)
}

export default gradingFeedbackSaga
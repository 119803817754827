import React, { useEffect } from "react";
import {
  GaugeContainer,
  GaugeValueArc,
  GaugeReferenceArc,
  useGaugeState,
} from "@mui/x-charts/Gauge";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { FETCH_AVERAGE_GRADE_PERCENTAGE } from "../redux/actionTypes";

interface performanceChartProps {
  role: string
}
const PerformanceChart: React.FC<performanceChartProps> = ({
  role
}) => {
  const { user_id } = useSelector((state: RootState) => state.auth)
  const { average_percentage, descriptive_grade } = useSelector((state: RootState) => state.analytics.averageGradePercentages)

  const dispatch = useDispatch();


  useEffect(() => {
    dispatch({ type: FETCH_AVERAGE_GRADE_PERCENTAGE, payload: { type: role, identifiers: [user_id] } })
  }, [dispatch, role, user_id])

  const containerStyles: React.CSSProperties = {
    height: "290px",
  };

  const heading: React.CSSProperties = {
    fontSize: "13.93px",
    fontWeight: "700",
    lineHeight: "16.86px",
    color: "#000000",
  };

  const chartContainerStyle: React.CSSProperties = {
    padding: "38px 21px",
    border: "1px solid transparent",
    borderColor: "#E5E7E7",
    borderRadius: "8.36px",
    margin: "0px",
    width: "100%",
  };

  const rateLabelStyle: React.CSSProperties = {
    fontSize: "18px",
    fontWeight: "500",
    lineHeight: "15.49px",
    color: "#83868E",
  };
  const GaugePointer = () => {
    const { valueAngle, outerRadius, cx, cy } = useGaugeState();

    if (valueAngle === null) {
      return null;
    }

    const target = {
      x: cx + outerRadius * Math.sin(valueAngle),
      y: cy - outerRadius * Math.cos(valueAngle),
    };
    return (
      <g>
        <circle cx={cx} cy={cy} r={5} fill="#0343FA" />
        <path
          d={`M ${cx} ${cy} L ${target.x} ${target.y}`}
          stroke="#0343FA"
          strokeWidth={3}
        />
      </g>
    );
  };

  return (
    <div className="performance-container card-container" style={containerStyles}>
      <label className="heading" style={heading}>
        {"All Time Average Grade"}
      </label>
      <div
        className="chart-container d-flex flex-column gap-2"
        style={chartContainerStyle}
      >
        {/* <div className="d-flex justify-content-between">
          <div className="d-flex align-items-center gap-2">
            <div className="rounded-1" style={assignmentLabelStyle} />
            <div style={labelStyle}>{role === 'learner' ? 'Assignment Submission Performance' : 'Learner Satisfaction Scores'}</div>
          </div>
          <div className="d-flex justify-content-center align-items-center" style={labelContainer}>
            <div style={btnStyle}>Monthly</div>
          </div>
        </div> */}
        <GaugeContainer
          height={100}
          startAngle={-110}
          endAngle={110}
          value={average_percentage}
        >
          <GaugeReferenceArc />
          <GaugeValueArc />
          <GaugePointer />
          <text
            x="50%"
            y="90%"
            textAnchor="middle"
            dominantBaseline="middle"
            fill="#0343FA"
            fontSize="16px"
            fontWeight="bold"
          >
            {`${average_percentage.toFixed(0)}%`}
          </text>
        </GaugeContainer>
        <div className="d-flex justify-content-center" style={rateLabelStyle}>
          <div className="text-dark mt-1 fw-bold">{descriptive_grade}</div>
        </div>
      </div>
    </div>
  );
};

export default PerformanceChart;

import { PasswordLockIcon } from "@/app/assets/icons/svg";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { RootState } from "@/app/redux/store";
import "./style.css";
import COLORS from "@/app/utils/Colors";

interface PasswordModalProps {
    open: boolean;
    onClose: () => void;
}

const PasswordModal: React.FC<PasswordModalProps> = ({ open, onClose }) => {
    const { loading, success, error } = useSelector((state: RootState) => state.auth);

    useEffect(() => {
        if (!loading && onClose) {
            const timer = setTimeout(() => {
                onClose();
            }, 3000);

            return () => clearTimeout(timer);
        }
    }, [loading, onClose]);

    return (
        <div>
            <Modal
                size="lg"
                centered
                backdrop="static"
                show={open}
                onHide={onClose}
                className="custom-modal"
            >
                <Modal.Header className="border-0 px-4 pt-4 fw-bold" closeButton>
                </Modal.Header>
                <Modal.Body className="text-center p-4">
                    <div className="d-flex justify-content-center mb-3">
                        <img src={PasswordLockIcon} alt="Password lock icon" className="modal-image" />
                    </div>
                    {loading && (
                        <span className="d-block">You have successfully changed your password</span>
                    )}
                    {success && (
                        <span style={{color: COLORS.SUCCESS}} className="d-block">{success}</span>
                    )}
                    {error && (
                        <span style={{color: COLORS.ERROR}} className="d-block">{error}</span>
                    )}
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default PasswordModal;

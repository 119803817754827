// LearnersCard.js
import React from 'react';
import { Card, Button, Row, Col} from 'react-bootstrap';
import "./style.css"
import { AssignedIcon, ClockIcon, CompletedIcon, TickIcon, ViewIcon } from '@/app/assets/icons/svg';

interface LearnersCardProps {
    id?:string;
    name: string;
    level: string;
    joinedTime: string;
    assigned: number;
    completed: number;
    avatar: string;
    isAssigned:boolean;
    handleAssignClick:(id?:string)=>void;
}

const LearnersCard: React.FC<LearnersCardProps> = ({ id, name, level, joinedTime, assigned, completed, avatar, isAssigned, handleAssignClick }) => {
    
    return (
        <Card className="mb-3" style={{fontFamily:"Inter"}}>
            <Card.Body>
                <Row>
                    <Col xs={2} className='me-3'>
                        <img src={avatar} alt="Avatar" style={{ width: '60px', height: '60px', borderRadius: '50%', marginRight: '10px' }} />
                    </Col>
                    <Col>
                        <div>
                            <strong className='fs-6 fw-semibold'>{name}</strong>
                            <div className='cstm-text'>Level {level}</div>
                        </div>
                    </Col>
                </Row>
                <hr/>
                <Row>
                    <Col>
                    <div className="clock-container">
                        <img src = {ClockIcon} alt='' className='clock'></img>
                        <span className='cstm-text'>{joinedTime}</span>
                    </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                    <div className="assigned-container">
                        <img src = {AssignedIcon} alt='' className='assigned'></img>
                        <span className='cstm-text'>Assigned &nbsp;</span> <span className='text-danger'>{assigned}</span>
                    </div>
                    </Col>
                    <Col>
                        <div className='completed-container'>
                        <img src = {CompletedIcon} alt='' className='completed'></img>
                        <span className='cstm-text'>Completed &nbsp;</span> <span className='text-success'>{completed}</span>
                        </div>
                    </Col>
                </Row>
                <Row className="mt-2">
                        <Col>
                            <Button onClick={() => alert('Profile Modal')} className='view-button'>
                                <img src={ViewIcon} alt=''/> &nbsp;
                                <span className='text-primary'>View Profile</span>
                            </Button>
                        </Col>
                        <Col xs={5}>
                            <Button
                                onClick={()=>{handleAssignClick(id)}}
                                variant={isAssigned ? "success" : "outline-primary"}
                                style={isAssigned ? { backgroundColor: 'green', color: 'white' } : {}}
                            >
                                {isAssigned ? <img src={TickIcon} alt=''/> : '+ '}
                                {isAssigned ?  "  Assigned": 'Assign'} 
                            </Button>
                        </Col>
                    </Row>
            </Card.Body>
        </Card>
    );
};

export default LearnersCard;

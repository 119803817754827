import { ArrowLeftIcon } from "@/app/assets/icons/svg";
import Layout from "@components/Layout";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./FeedbackSummary.module.style.css";
import Button from "@components/Button";
import IMAGES from "@/app/utils/Images";
import SummaryOne from "./SummaryOne";
import SummaryTwo from "./SummaryTwo";
import {
  CLEAR_ASSIGNMENT_DETAIL,
  CLEAR_GRADING_ASSIGNMENT_STATE,
  DOWNLOAD_ASSIGNMENTS,
  FETCH_GRADING_FEEDBACK_AI,
  FETCH_SIGNATURE,
  UPDATE_LEARNER_GRADING_ASSIGNMENT,
  UPLOAD_SIGNATURE,
} from "@/app/redux/actionTypes";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "@/app/redux/store";
import { toast } from "react-toastify";
import Loader from "@/app/components/Loading";
import UplaodSignatureModal from "./UplaodSignatureModal";
import SuccessModal from "./SuccessModal";
import moment from "moment";

interface FeedbackSummaryProps {}
const FeedbackSummary: React.FC<FeedbackSummaryProps> = () => {
  const { level } = useParams<string>();
  console.log("🚀 ~ level:", level);
  const levels = ["Level 3", "Level 5"];
  const [checked, setChecked] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const fileInputRef: any = useRef(null);
  const resubmitfileInputRef: any = useRef(null);
  const location = useLocation();
  const { assignmentId, file_id, item } = location.state || {}; // Destructure to get row details
  console.log("🚀 ~ item:", item);
  const dispatch = useDispatch();
  const {
    success,
    message,
    loading,
    signature,
    resubmitSucces,
    assignmentDetail,
    downloadAssignments,
    updatedFileId,
    error,
    learner_file_id,
  } = useSelector((state: RootState) => state.gradingFeedback);
  console.log(assignmentDetail)
  const { user_id } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();
  const handleLabelClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current?.click(); // Trigger the file input click
    }
  };

  const getStatusbylevel = (status: string) => {
    if (status === "ready_to_review") return "first_marking_in_progress";
    else if (
      status === "first_marking_in_progress" &&
      level &&
      levels.includes(level)
    )
      return "done";
    else if (
      status === "first_marking_in_progress" &&
      level &&
      !levels.includes(level)
    )
      return "seond_marking_in_progress";
    else if (status === "seond_marking_in_progress") return "done";
  };

  const handleResubmitClick = () => {
    dispatch({
      type: UPDATE_LEARNER_GRADING_ASSIGNMENT,
      payload: {
        assignmentId: item.id,
        data: {
          markedAs:
            item.markedAs === "resubmission_1"
              ? "resubmission_2"
              : "resubmission_1",
          Status:
            item?.Status === "ready_to_review"
              ? "first_marking_in_progress"
              : item.Status,
        },
      },
    });
  };

  const handleFileChange = (event) => {
    const files = event.target.files;
    if (files.length > 0) {
      setChecked(true);
      setFile(files[0]);
    }
  };

  const handleFileUpload = (file: File | null) => {
    let fileUploadpayload = {
      assignmentId: item?.assessmentId,
      signatureFile: file ? file : signature?.signatureFiledata,
    };
    const currentDateUtc = moment.utc();
    const formattedDate = currentDateUtc.format("DD/MM/YYYY");
    let assesorDeclarationPayload = {
      firstName: item?.Learner_firstname,
      lastName: item?.Learner_surname,
      otherNames: item?.Learner_other_names,
      membershipID: item?.membership_id,
      centreName: item?.Centre_name,
      centreID: item?.Centre_number,
      signature: user_id,
      markingDate: formattedDate,
      file_id: updatedFileId ? updatedFileId : file_id,
    };
    dispatch({
      type: UPLOAD_SIGNATURE,
      payload: { fileUploadpayload, assesorDeclarationPayload },
    });
  };

  useEffect(() => {
    if (success) {
      setIsSuccess(true);
    } else {
      toast.error(message);
    }
  }, [success, message]);

  useEffect(() => {
    toast.error(error);
  }, [error]);

  useEffect(() => {
    if (resubmitSucces) {
      toast.success("Feedback Updated successfully!", {
        autoClose: 3000, // Close after 3000 ms (3 seconds)
      });
      dispatch({ type: CLEAR_GRADING_ASSIGNMENT_STATE });
    }
  }, [resubmitSucces]);

  useEffect(() => {
    if (open) {
      dispatch({
        type: FETCH_SIGNATURE,
        payload: { assignmentId: assignmentId },
      });
    }
  }, [open]);

  useEffect(() => {
    dispatch({
      type: FETCH_GRADING_FEEDBACK_AI,
      payload: {
        level: level && levels.includes(level) ? "l3l5" : "l7",
        file_id,
      },
    });
  }, [file_id]);

  useEffect(() => {
    if (downloadAssignments) {
      const blob = new Blob([downloadAssignments], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `assignment_${file_id}.pdf`;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    }
  }, [downloadAssignments]);

  useEffect(() => {
    if (learner_file_id) {
      dispatch({
        type: UPDATE_LEARNER_GRADING_ASSIGNMENT,
        payload: {
          assignmentId: item.id,
          data: { learner_file_id, Status: getStatusbylevel(item.Status) },
        },
      });
    }
  }, [learner_file_id]);

  return (
    <Layout>
      {!assignmentDetail ? (
        <Loader />
      ) : (
        assignmentDetail && (
          <div className="feedback-summary mx-4 mt-4 vh-100">
            <div className="sticky-top z-3 bg-white">
              <div className="marker-heading d-flex flex-lg-row flex-column justify-content-lg-between align-items-lg-center">
                <div className="d-flex gap-4">
                  <img
                    src={ArrowLeftIcon}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate("/grading-feedback");
                      dispatch({ type: CLEAR_ASSIGNMENT_DETAIL });
                      dispatch({ type: CLEAR_GRADING_ASSIGNMENT_STATE });
                    }}
                    alt="arrow-left"
                  />
                  <div>
                    <span className="fw-bold">
                      {assignmentDetail?.student?.name}
                    </span>
                    /{assignmentDetail?.student?.student_id}
                  </div>
                </div>
                <div className="buttons d-flex flex-lg-row flex-column gap-lg-4 gap-2 mt-lg-0 mt-2">
                  <Button
                    label={"Download assignment"}
                    className={"assignment-btn"}
                    onClick={() => {
                      dispatch({
                        type: DOWNLOAD_ASSIGNMENTS,
                        payload: file_id,
                      });
                    }}
                    isIcon={true}
                    icon={IMAGES.DOWNLOAD}
                  />
                  <Button
                    label={"Publish review"}
                    className={"assignment-btn primary-btn"}
                    onClick={() => {
                      setOpen(true);
                    }}
                  />
                </div>
              </div>
              <div className="assignment-detail mt-4 d-flex flex-lg-row flex-column justify-content-lg-between align-items-lg-center">
                <div className="d-flex gap-4 flex-wrap">
                  <div className="d-flex flex-column gap-1">
                    <label className="assignment-heading">Assignment ID</label>
                    <label className="assignment-detail">
                      {assignmentDetail?.assignment?.assignment_id}
                    </label>
                  </div>
                  <div className="d-flex flex-column gap-1">
                    <label className="assignment-heading">
                      Enrolled course
                    </label>
                    <label className="assignment-detail">
                      Level {assignmentDetail?.assignment?.course_level}
                    </label>
                  </div>
                  <div className="d-flex flex-column gap-1">
                    <label className="assignment-heading">Over all Marks</label>
                    <label className="assignment-detail">
                      {level && levels.includes(level) ? (
                        assignmentDetail?.assignment?.overall_marks
                      ) : (
                        <div className="d-flex flex-col gap-1 align-items-center">
                          {assignmentDetail?.assignment?.overall_marks
                            ?.first_marking && (
                            <div>
                              {" "}
                              1st Marking :
                              {
                                assignmentDetail?.assignment?.overall_marks
                                  ?.first_marking
                              }
                            </div>
                          )}
                          {assignmentDetail?.assignment?.overall_marks
                            ?.second_marking && (
                            <div>
                              {" "}
                              2nd Marking :
                              {
                                assignmentDetail?.assignment?.overall_marks
                                  ?.second_marking
                              }
                            </div>
                          )}
                        </div>
                      )}
                    </label>
                  </div>
                  <div className="d-flex flex-column gap-1">
                    <label className="assignment-heading">Unit result</label>
                    <label className="assignment-detail">
                      {assignmentDetail?.assignment?.unit_result}
                    </label>
                  </div>
                </div>
                <div
                  className="resubmit-label mt-lg-0 mt-1"
                  onClick={handleResubmitClick}
                  style={{ cursor: "pointer" }}
                >
                  Resubmit
                </div>
              </div>
            </div>
            <hr
              className="w-100"
              style={{ height: "1px", marginTop: "3rem" }}
            />
            {level && levels.includes(level) ? <SummaryOne /> : <SummaryTwo />}
          </div>
        )
      )}
      <UplaodSignatureModal
        loading={loading}
        open={open}
        setOpen={setOpen}
        setChecked={setChecked}
        signature={signature}
        handleLabelClick={handleLabelClick}
        fileInputRef={fileInputRef}
        handleFileChange={handleFileChange}
        handleFileUpload={handleFileUpload}
        file={file}
        checked={checked}
      />
      <SuccessModal
        open={isSuccess}
        setOpen={setIsSuccess}
        assesmentID={assignmentId}
      />
    </Layout>
  );
};

export default FeedbackSummary;

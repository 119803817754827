import { call, put, takeLatest } from "redux-saga/effects";
import { UPDATE_SCHEDULE_FAILED, FETCH_USER, FETCH_USER_FAILED, FETCH_USER_SUCCESS, SET_USER_LOADING, UPDATE_SCHEDULE_SUCCESS, UPDATE_USER, UPDATE_USER_FAILED, UPDATE_USER_SCHEDULE, UPDATE_USER_SUCCESS, CREATE_USER, CREATE_USER_FAILED, CREATE_USER_SUCCESS, FETCH_USERS, FETCH_USERS_SUCCESS, CREATE_COHORT, FETCH_USERS_FOR_COHORT, FETCHED_EDUCATORS_FOR_COHORT, FETCHED_LEARNERS_FOR_COHORT, RESEND_CREDENTIALS, RESEND_CREDENTIALS_SUCCESS, USER_ACTION_FAILED, ACT_DEACTIVATE_USER, USER_ACT_DEACTIVATED, DELETE_USER, USER_DELETED, FETCH_USER_BY_USER_ID, UPDATE_USER_SUPERVISOR, GET_UPDATED_SCHEDULE_FAILED, GET_UPDATED_SCHEDULE_SUCCESS, GET_UPDATED_SCHEDULE, FETCH_HOME_USERS, } from "../actionTypes";
import { getErrorMessage } from "@/app/modules/helpers/ErrorHelper";
import { create_cohort, create_user, deactivate_user, delete_user, get_updated_schedule, get_user_details, get_user_details_by_userId, get_users, get_users_list_cohort, resend_credentials, update_user_details, update_user_schedule, update_user_supervisor } from "@/app/api/Api";
import { CreateCohortPayload, CreateUserPayload, GetUserDetailsPayload, HomeUserPayload, NewSchedulesPayload, SuccessResponse, UpdateUserPayload } from "@/app/modules/types/types";
import { fetchedEducatorsForHome, fetchedLearnersForHome, fetchHomeUsersFailed } from "../slices/homeSlice";

function* fetchUserSaga(action: { type: string; payload: { userId: string } }): Generator {
    try {
        yield put({ type: SET_USER_LOADING });
        const response = (yield call(get_user_details, action.payload.userId)) as GetUserDetailsPayload;
        yield put({ type: FETCH_USER_SUCCESS, payload: response.user });
    } catch (error) {
        const errorMessage = getErrorMessage(error);
        yield put({ type: FETCH_USER_FAILED, payload: { message: errorMessage } });
    }
}

function* fetchUserByUserIdSaga(action: { type: string; payload: { userId: string } }): Generator {
    try {
        yield put({ type: SET_USER_LOADING });
        const response = (yield call(get_user_details_by_userId, action.payload.userId)) as GetUserDetailsPayload;
        yield put({ type: FETCH_USER_SUCCESS, payload: response.user });
    } catch (error) {
        const errorMessage = getErrorMessage(error);
        yield put({ type: FETCH_USER_FAILED, payload: { message: errorMessage } });
    }
}

function* updateUserSaga(action: { type: string; payload: UpdateUserPayload }): Generator {
    try {
        yield put({ type: SET_USER_LOADING });
        const response = (yield call(update_user_details, action.payload)) as SuccessResponse;
        console.log(response, 'response')
        yield put({ type: UPDATE_USER_SUCCESS, payload: response });
    } catch (error) {
        const errorMessage = getErrorMessage(error);
        yield put({ type: UPDATE_USER_FAILED, payload: { message: errorMessage } });
    }
}

function* updateUserSchedule(action: { type: string; payload: NewSchedulesPayload }): Generator {
    try {
        yield put({ type: SET_USER_LOADING });
        const response = (yield call(update_user_schedule, action.payload.newSchedules, action.payload.userId)) as GetUserDetailsPayload;
        yield put({ type: UPDATE_SCHEDULE_SUCCESS, payload: { schedules: response?.updatedUser.schedules, message: response?.message } });
    } catch (error) {
        const errorMessage = getErrorMessage(error);
        yield put({ type: UPDATE_SCHEDULE_FAILED, payload: { message: errorMessage } });
    }
}

function* handleUserCreation(action: { type: string, payload: CreateUserPayload }): Generator {
    try {
        yield put({ type: SET_USER_LOADING });
        const response = (yield call(create_user, action.payload)) as GetUserDetailsPayload;
        yield put({ type: CREATE_USER_SUCCESS, payload: { message: response?.message } });
    } catch (error) {
        const errorMessage = getErrorMessage(error);
        yield put({ type: CREATE_USER_FAILED, payload: { message: errorMessage } });
    }
}

function* handleUsers(action: { type: string, payload: { page: string, limit: string, search: string, userType: string, sortBy: string, filterUserStatus: string } }): Generator {
    try {
        yield put({ type: SET_USER_LOADING });
        const response = (yield call(get_users, action.payload)) as GetUserDetailsPayload;
        yield put({ type: FETCH_USERS_SUCCESS, payload: { data: response.data, pagination: response.pagination } });
    } catch (error) {
        const errorMessage = getErrorMessage(error);
        yield put({ type: CREATE_USER_FAILED, payload: { message: errorMessage } });
    }
}

function* handleHomeUsers(action: { type: string; payload: { page: string; limit: string; search: string; userType: string; sortBy: string; filterUserStatus: string } }): Generator {
    try {
        yield put({ type: SET_USER_LOADING });
        const response = (yield call(get_users, action.payload)) as HomeUserPayload;

        if (action.payload.userType === 'educator') {
            yield put(fetchedEducatorsForHome({ data: response.data }));
        } else if (action.payload.userType === 'learner') {
            yield put(fetchedLearnersForHome({ data: response.data }));
        }
    } catch (error) {
        const errorMessage = getErrorMessage(error);
        yield put(fetchHomeUsersFailed({ message: errorMessage }));
    }
}




function* handleCohortCreation(action: { type: string, payload: CreateCohortPayload }): Generator {
    try {
        yield put({ type: SET_USER_LOADING });
        const response = (yield call(create_cohort, action.payload)) as GetUserDetailsPayload;
        yield put({ type: CREATE_USER_SUCCESS, payload: { message: response?.message } });
    } catch (error) {
        const errorMessage = getErrorMessage(error);
        yield put({ type: CREATE_USER_FAILED, payload: { message: errorMessage } });
    }
}

function* handleUsersForCohort(action: { type: string, payload: { userType: string | string[], level: string } }): Generator {
    try {
        yield put({ type: SET_USER_LOADING });

        const userTypes = Array.isArray(action.payload.userType) ? action.payload.userType : [action.payload.userType];
        for (const userType of userTypes) {
            const response = (yield call(get_users_list_cohort, userType, action.payload.level)) as GetUserDetailsPayload;
            if (userType === 'educator') {
                yield put({ type: FETCHED_EDUCATORS_FOR_COHORT, payload: { data: response.data } });
            } else if (userType === 'learner') {
                yield put({ type: FETCHED_LEARNERS_FOR_COHORT, payload: { data: response.data } });
            }
        }
    } catch (error) {
        const errorMessage = getErrorMessage(error);
        yield put({ type: CREATE_USER_FAILED, payload: { message: errorMessage } });
    }
}

function* handleResendEmail(action: { type: string, payload: { user: string } }): Generator {
    try {
        const response = (yield call(resend_credentials, action.payload.user)) as GetUserDetailsPayload;
        yield put({ type: RESEND_CREDENTIALS_SUCCESS, payload: { message: response?.message } });
    } catch (error) {
        const errorMessage = getErrorMessage(error);
        yield put({ type: USER_ACTION_FAILED, payload: { message: errorMessage } });
    }
}

function* handleUserActivation(action: { type: string, payload: { userId: string, type: string } }): Generator {
    try {
        const response = (yield call(deactivate_user, action.payload.userId, action.payload.type)) as GetUserDetailsPayload;
        yield put({ type: USER_ACT_DEACTIVATED, payload: { message: response?.message } });
    } catch (error) {
        const errorMessage = getErrorMessage(error);
        yield put({ type: USER_ACTION_FAILED, payload: { message: errorMessage } });
    }
}

function* handleUserDeletion(action: { type: string, payload: { userId: string } }): Generator {
    try {
        const response = (yield call(delete_user, action.payload.userId)) as GetUserDetailsPayload;
        yield put({ type: USER_DELETED, payload: { message: response?.message } });
    } catch (error) {
        const errorMessage = getErrorMessage(error);
        yield put({ type: USER_ACTION_FAILED, payload: { message: errorMessage } });
    }
}


function* updateUserSupervisor(action: { type: string; payload: UpdateUserPayload }): Generator {
    try {
        yield put({ type: SET_USER_LOADING });
        const response = (yield call(update_user_supervisor, action.payload)) as SuccessResponse;
        console.log(response, 'response')
        yield put({ type: UPDATE_USER_SUCCESS, payload: response });
    } catch (error) {
        const errorMessage = getErrorMessage(error);
        yield put({ type: UPDATE_USER_FAILED, payload: { message: errorMessage } });
    }
}

function* fetchUpdatedSchedule(action: {
    type: string; payload: {
        newSchedules: any; userId: string
    }
}): Generator {
    try {
        yield put({ type: SET_USER_LOADING });
        const response = (yield call(get_updated_schedule, action.payload.userId)) as GetUserDetailsPayload;
        const { data, message } = response
        yield put({ type: GET_UPDATED_SCHEDULE_SUCCESS, payload: { schedules: data, message: message } });
    } catch (error) {
        const errorMessage = getErrorMessage(error);
        yield put({ type: GET_UPDATED_SCHEDULE_FAILED, payload: { message: errorMessage } });
    }
}


function* userSaga() {
    yield takeLatest(FETCH_USER, fetchUserSaga);
    yield takeLatest(UPDATE_USER, updateUserSaga);
    yield takeLatest(UPDATE_USER_SCHEDULE, updateUserSchedule);
    yield takeLatest(CREATE_USER, handleUserCreation);
    yield takeLatest(CREATE_COHORT, handleCohortCreation);
    yield takeLatest(FETCH_USERS, handleUsers);
    yield takeLatest(FETCH_HOME_USERS, handleHomeUsers);
    yield takeLatest(FETCH_USERS_FOR_COHORT, handleUsersForCohort);
    yield takeLatest(RESEND_CREDENTIALS, handleResendEmail);
    yield takeLatest(ACT_DEACTIVATE_USER, handleUserActivation);
    yield takeLatest(DELETE_USER, handleUserDeletion);
    yield takeLatest(FETCH_USER_BY_USER_ID, fetchUserByUserIdSaga);
    yield takeLatest(UPDATE_USER_SUPERVISOR, updateUserSupervisor);
    yield takeLatest(GET_UPDATED_SCHEDULE, fetchUpdatedSchedule)

}

export default userSaga;





// function* fetchUserSchedule(): Generator {
//     try {
//         yield put({ type: SET_USER_LOADING });
//         const response = (yield call(get_user_details)) as GetUserDetailsPayload;
//         yield put({ type: FETCH_SCHEDULE_SUCCESS, payload: response.user });
//     } catch (error) {
//         const errorMessage = getErrorMessage(error);
//         yield put({ type: FAILED_USER_SCHEDULE, payload: { message: errorMessage } });
//     }
// }

// yield takeLatest(FETCH_USER_SCHEDULE, fetchUserSchedule);
const sortOptions = [
  { label: "Name A-Z", value: "sortByNameAsc" },
  { label: "Name Z-A", value: "sortByNameDesc" },
  { label: "Date  - Newest to Oldest", value: "sortByDateDesc" },
  { label: "Date  - Oldest to Newest", value: "sortByDateAsc" },
];

const learnerPastOptions = [
  {
    "Assignment Status": [
      { label: "Under Review", value: "Under Review" },
      { label: "Resubmission", value: "Resubmission" },
      { label: "Low Pass", value: "Low Pass" },
      { label: "Pass", value: "Pass" },
      { label: "High Pass", value: "High Pass" },
      { label: "Fail", value: "Fail" },
    ],
  },
];

const gradingFeedbackOptions = [
  { label: "Resubmission", value: "Resubmission" },
  { label: "Low Pass", value: "Low Pass" },
  { label: "Pass", value: "Pass" },
  { label: "High Pass", value: "High Pass" },
  { label: "Fail", value: "Fail" },
];

const learnerHeadings = ["Assignment Status"];

const learnerActiveOptions = [
  {
    "Assignment Status": [
      { label: "New", value: "New" },
      { label: "Resubmission", value: "Resubmission" },
    ],
  },
];

const activeOptions = [
  { label: "Sort by", value: "Sort by" },
  { label: "Name A-Z", value: "Name A-Z" },
  { label: "Name Z-A", value: "Name Z-A" },
  { label: "Date  - Newest to Oldest", value: "Date  - Newest to Oldest" },
  { label: "Date  - Oldest to Newest", value: "Date  - Oldest to Newest" },
];

const educatorActiveOptions = [
  { label: "All", value: "All" },
  { label: "Unassigned", value: "Unassigned" },
  { label: "Assigned", value: "Assigned" },
];

interface FilterOption {
  label: string;
  value: string | number;
}
interface MultipleFilterOption {
  [key: string]: FilterOption[];
}

const headingsForEducator = ["Level", "Assignment Status"];
const activeEductorsOptions: MultipleFilterOption[] = [
  {
    "Level": [
      { label: "Level 3", value: "Level 3" },
      { label: "Level 5", value: "Level 5" },
      { label: "Level 7", value: "Level 7" },
    ],
  },
  {
    "Assignment Status": [
      { label: "In Progress", value: "InProgress" },
      { label: "Pending", value: "Pending" },
      { label: "Done", value: "Done" },
    ],
  },
];

const superVisorOptions = [
  { label: "Active User", value: "Active User" },
  { label: "Inactive User", value: "Inactive User" },
  { label: "Pending Activation", value: "Pending Activation" },
];

export {
  sortOptions,
  learnerPastOptions,
  gradingFeedbackOptions,
  learnerActiveOptions,
  activeOptions,
  educatorActiveOptions,
  headingsForEducator,
  activeEductorsOptions,
  learnerHeadings,
};

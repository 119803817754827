import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import Button from './Button';
import COLORS from '../utils/Colors';
import { IoMdArrowRoundBack } from "react-icons/io";
import { FaCalendar } from "react-icons/fa6";
import ViewAssignment from './UploadAssignment/ViewAssignment';
import { AssignmentStats } from '../modules/types/types';

type Props = {
    visible: boolean;
    handleClose: () => void;
    assignments?: any[];
    stats?: AssignmentStats
};

type TextProps = {
    value?: string | null | any;
    heading?: string | any;
    fontSize?: string;
    fontWeight?: 'bold' | 'normal';
    headingStyle?: React.CSSProperties;
    valueStyle?: React.CSSProperties;

};

type marker = {
    id: string,
    name: string,
    levels: number[],
    avatar: string
}

type assignment = {
    assessmentId: string,
    code: string,
    title: string,
    date: string,
    label: string,
    description?: string,
    assignmentBriefFile: string,
    assessmentCriteriaFile: string,
    lastModerationDate: string,
    markers: marker[]
}

enum LabelType {
    HighPass = "high pass",
    Pass = "pass",
    Fail = "fail",
    LowPass = "low pass"
}


const LearnerAssignmentsModal: React.FC<Props> = ({ assignments, visible, handleClose, stats }) => {

    const [show, setShow] = useState<boolean>(false);

    const [showSelectedAssignment, setShowSelectedAssignment] = useState(false)

    const [array, setArray] = useState<any>([{}, {}])

    const [selectedAssignment, setselectedAssignment] = useState<assignment | null>()

    useEffect(() => {
        setArray(assignments)
    }, [assignments])

    useEffect(() => {
        setShow(visible);
    }, [visible]);


    const assignmentLabel: React.CSSProperties = {
        color: '#8A8A8A',
        fontWeight: 'bold',
    }

    const reSubmissionAssignmentLabel: React.CSSProperties = {
        color: '#DC2626',
        fontWeight: 'bold',
    }

    const nameHeading: React.CSSProperties = {
        fontSize: '18px',
    }

    const assignmentValue: React.CSSProperties = {
        fontSize: '18px',
    }

    const gradeText: React.CSSProperties = {
        color: '#2188E7',
    }

    const myUnits: React.CSSProperties = {
        textTransform: 'capitalize'
    }

    const unitsText: React.CSSProperties = {
        fontWeight: 'light'
    }

    const resubmittedDiv: React.CSSProperties = {
        backgroundColor: '#FFEFEF',
        paddingRight: '8px',
        paddingLeft: '8px',
        borderBottomLeftRadius: '10px',
        borderTopRightRadius: '6px',
    }

    const resubmittedLabel: React.CSSProperties = {
        fontSize: '12px',
        color: '#F93333',
        textTransform: 'capitalize',
        fontWeight: '400'
    }


    const Text: React.FC<TextProps> = ({ value, heading, fontSize = '16px', fontWeight = 'normal', headingStyle, valueStyle }) => {
        return (
            <div>
                {heading && (
                    <span style={{ fontSize: '14px', fontWeight: 'bold', ...headingStyle }}>
                        {heading}
                    </span>
                )}
                {value && (
                    <span style={{ fontSize: '14px', fontWeight: "normal", ...valueStyle }}>
                        &nbsp;:&nbsp;{value}
                    </span>
                )}
            </div>
        );
    };


    const handleCloseAssignment = () => {
        setShowSelectedAssignment(false)
        setselectedAssignment(null)
    }

    const onSelectAssignment = (item: assignment) => {
        setselectedAssignment(item)
        setShowSelectedAssignment(true)
    }


    return (
        <>
            <Modal contentClassName='my-assignments-modal' backdrop="static" show={show} onHide={handleClose}>
                <Modal.Header className='px-4 py-3 fw-bold' closeButton >
                    <Modal.Title><span style={nameHeading}><IoMdArrowRoundBack onClick={handleClose} size={24} />&nbsp;Assignments</span></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='d-flex flex-row p-2 gap-4'>
                        <Text heading="Assignment Submitted" valueStyle={{ fontWeight: 'bold' }} value={assignments?.length} />
                        <Text valueStyle={assignmentLabel} headingStyle={assignmentLabel} heading="High Pass&nbsp;:&nbsp;" value={stats?.labelCounts[LabelType.HighPass]} />
                        <Text valueStyle={assignmentLabel} headingStyle={assignmentLabel} heading="Pass&nbsp;:&nbsp;" value={stats?.labelCounts[LabelType.Pass]} />
                        <Text valueStyle={assignmentLabel} headingStyle={assignmentLabel} heading="Low Pass&nbsp;:&nbsp;" value={stats?.labelCounts[LabelType.LowPass]} />
                        <Text valueStyle={assignmentLabel} headingStyle={assignmentLabel} heading="Fail&nbsp;:&nbsp;" value={stats?.labelCounts[LabelType.Fail]} />
                        <Text valueStyle={reSubmissionAssignmentLabel} headingStyle={reSubmissionAssignmentLabel} heading="Resubmission&nbsp;:&nbsp;" value={stats?.resubmissionCount} />
                        {/* <Text valueStyle={myUnits} headingStyle={myUnits} heading="Merit" value={'5'} /> */}
                    </div>
                    <div className='d-flex flex-row flex-wrap'>
                        {array?.map((item, index) =>
                            <div key={index} style={{ width: '316px', position: 'relative' }} className='rounded p-3 border mt-2 m-1'>
                                {item.assignmentstatus === 'Resubmission' && <div style={{ position: 'absolute', top: 0, right: 0, ...resubmittedDiv }}>
                                    <span style={resubmittedLabel}>{'resubmitted'}</span>
                                </div>}
                                <div className='mt-2'>
                                    <div className='my-2'>
                                        <Text heading="Assessment ID:" headingStyle={nameHeading} valueStyle={assignmentValue} value={item?.assessmentId} />
                                    </div>
                                    <Text valueStyle={unitsText} headingStyle={unitsText} heading="Unit Title" value={item?.title} />
                                    <div className='my-2'>
                                        <Text headingStyle={unitsText} heading={
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <FaCalendar size={20} />
                                                <span style={{ marginLeft: '5px' }}>{item?.date}</span>
                                            </div>
                                        } />
                                    </div>
                                    <Text headingStyle={unitsText} valueStyle={gradeText} heading={'Overall Mark'} value={item?.overallMark} />
                                    <Text headingStyle={unitsText} valueStyle={{ color: item?.label === 'high pass' ? '#00C853' : '#00000', ...myUnits }} heading={'Unit Result'} value={item?.label} />
                                </div>
                                <div className='text-center  mt-2'>
                                    <Button
                                        label={'View Details'}
                                        backgroundColor={COLORS.PRIMARY}
                                        onClick={() => onSelectAssignment(item)}
                                        className='w-100 text-center'
                                        style={{ height: '40px' }}
                                        labelStyle={{ color: COLORS.WHITE, fontSize: '14px' }}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </Modal.Body>

            </Modal>
            {selectedAssignment && showSelectedAssignment && <Modal contentClassName='my-assignments-modal' backdrop="static" show={showSelectedAssignment} onHide={handleCloseAssignment}>
                <Modal.Header className='px-4 py-3 fw-bold' closeButton >
                    <Modal.Title><span style={nameHeading}><IoMdArrowRoundBack onClick={handleCloseAssignment} size={24} />&nbsp;{selectedAssignment.assessmentId}</span></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ViewAssignment data={selectedAssignment} />
                </Modal.Body>
            </Modal>}
        </>
    )
}

export default LearnerAssignmentsModal
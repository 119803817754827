import { GET_SETTING_NOTIFICATION, UPDATE_SETTING_NOTIFICATION } from "@/app/redux/actionTypes";
import { Checkbox, Switch } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/app/redux/store";
import "./style.css";

const SettingNotification: React.FC = () => {
  const dispatch = useDispatch();
  const { notification } = useSelector((state: RootState) => state.setting);

  const [checked, setChecked] = React.useState(true);
  const [notificationStates, setNotificationStates] = React.useState({});
  const [appNotificationStates, setAppNotificationStates] = React.useState({});

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const handleSelectAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    const newStates = {
      1: isChecked,
      2: true,
      3: isChecked,
      4: isChecked,
      5: isChecked,
    };
    setNotificationStates(newStates);
    Object.entries(newStates).forEach(([id, isEnabled]) => {
      callApiForNotifications(isEnabled, Number(id));
    });
  };

  const handleSelectAppAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    const newStates = {
      1: isChecked,
      2: true,
      3: isChecked,
      4: isChecked,
      5: isChecked,
    };
    setAppNotificationStates(newStates);
    Object.entries(newStates).forEach(([id, isEnabled]) => {
      callApiForAppNotifications(isEnabled, Number(id));
    });
  };

  const handleCheckboxChange = (id: number) => {
    const newStates = { ...notificationStates, [id]: !notificationStates[id] };
    const allChecked = Object.values(newStates).slice(1).every((state) => state);
    newStates[1] = allChecked;
    setNotificationStates(newStates);
    callApiForNotifications(newStates[id], id);
    callApiForNotifications(allChecked, 1);
  };

  const handleAppCheckboxChange = (id: number) => {
    const newStates = { ...appNotificationStates, [id]: !appNotificationStates[id] };
    const allChecked = Object.values(newStates).slice(1).every((state) => state);
    newStates[1] = allChecked;
    setAppNotificationStates(newStates);
    callApiForAppNotifications(newStates[id], id);
    callApiForAppNotifications(allChecked, 1);
  };

  const callApiForNotifications = (isEnabled: boolean, id: number) => {
    const notificationData = {
      notificationType: "email",
      alertType: emailNotificationData[id - 1].alertType,
      isEnabled,
    };
    dispatch({ type: UPDATE_SETTING_NOTIFICATION, payload: { notificationData } });
  };

  const callApiForAppNotifications = (isEnabled: boolean, id: number) => {
    const notificationData = {
      notificationType: "app",
      alertType: appNotificationData[id - 1].alertType,
      isEnabled,
    };
    dispatch({ type: UPDATE_SETTING_NOTIFICATION, payload: { notificationData } });
  };

  const updateEmailNotificationData = (emailNotifications, emailNotificationData) => {
    const notificationMap = Object.fromEntries(
      emailNotifications.map(notification => [notification.alertType, notification.isEnabled])
    );

    return emailNotificationData.map(notification => ({
      ...notification,
      isEnabled: notificationMap[notification.alertType] !== undefined
        ? notificationMap[notification.alertType]
        : notification.isEnabled
    }));
  };

  function updateAppNotificationData(appNotifications, appNotificationData) {
    const notificationMap = Object.fromEntries(
      appNotifications.map(notification => [notification.alertType, notification.isEnabled])
    );

    return appNotificationData.map(notification => ({
      ...notification,
      isEnabled: notificationMap[notification.alertType] !== undefined
        ? notificationMap[notification.alertType]
        : notification.isEnabled,
    }));
  }

  const emailNotificationData = [
    { id: 1, alertType: "Select All", isEnabled: false, canChange: true, notificationType: "email" },
    { id: 2, alertType: "General notifications", isEnabled: true, canChange: false, notificationType: "email" },
    { id: 3, alertType: "New cohort", isEnabled: false, canChange: true, notificationType: "email" },
    { id: 4, alertType: "Grading and feedback updates", isEnabled: false, canChange: true, notificationType: "email" },
    { id: 5, alertType: "New assignments", isEnabled: false, canChange: true, notificationType: "email" },
  ];

  const appNotificationData = [
    { id: 1, alertType: "Select All", isEnabled: false, canChange: true, notificationType: "app" },
    { id: 2, alertType: "General notifications", isEnabled: true, canChange: false, notificationType: "app" },
    { id: 3, alertType: "New cohort", isEnabled: false, canChange: true, notificationType: "app" },
    { id: 4, alertType: "Grading and feedback updates", isEnabled: false, canChange: true, notificationType: "app" },
    { id: 5, alertType: "New assignments", isEnabled: false, canChange: true, notificationType: "app" },
  ];

  useEffect(() => {
    dispatch({ type: GET_SETTING_NOTIFICATION });
  }, [dispatch]);

  useEffect(() => {
    if (notification?.emailNotifications) {
      const updatedEmailNotificationData = updateEmailNotificationData(notification.emailNotifications, emailNotificationData);
      const initialStates = updatedEmailNotificationData.reduce((acc, curr) => {
        acc[curr.id] = curr.isEnabled;
        return acc;
      }, {});
      setNotificationStates(initialStates);
    }

    if (notification?.appNotifications) {
      const updatedAppNotificationData = updateAppNotificationData(notification.appNotifications, appNotificationData);
      const initialAppStates = updatedAppNotificationData.reduce((acc, curr) => {
        acc[curr.id] = curr.isEnabled;
        return acc;
      }, {});
      setAppNotificationStates(initialAppStates);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification]);

  return (
    <div>
      <div className="p-3">
        <p className="setting-title p-lg-3 pb-0">
          Control when you receive email or in-product notifications from
        </p>
        <div className="p-3">
          <div className="d-flex flex-row justify-content-between align-items-center setting-contact-header">
            <p>Enable email notification</p>
            <Switch
              checked={checked}
              onChange={handleChange}
              inputProps={{ "aria-label": "controlled" }}
            />
          </div>
          {checked && (
            <div className="setting-contact">
              <div className="setting-contact-details p-3 ps-4">
                <p className="text">Receive emails for:</p>
                <div className="p-1">
                  {emailNotificationData.map(({ id, alertType, canChange }) => (
                    <div key={id} className="form-check mb-4">
                      <Checkbox
                        className="form-check-input"
                        checked={notificationStates[id] || false}
                        disabled={!canChange}
                        onChange={
                          id === 1
                            ? handleSelectAllChange
                            : () => handleCheckboxChange(id)
                        }
                      />
                      <label className="form-check-label ms-2 text">
                        {alertType}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="p-3">
          <div className="setting-contact">
            <div className="d-flex flex-row justify-content-between align-items-center setting-contact-header">
              <p>In-app notification</p>
            </div>
            <div className="setting-contact-details p-3 ps-4">
              <p className="text">Receive notifications and alerts for:</p>
              <div className="p-1">
                {appNotificationData.map(({ id, alertType, canChange }) => (
                  <div key={id} className="form-check mb-4">
                    <Checkbox
                      className="form-check-input"
                      checked={appNotificationStates[id] || false}
                      disabled={!canChange}
                      onChange={
                        id === 1
                          ? handleSelectAppAllChange
                          : () => handleAppCheckboxChange(id)
                      }
                    />
                    <label className="form-check-label ms-2 text">
                      {alertType}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingNotification;

import axios from 'axios';

export const apiUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3000/';

const ApiRequest = axios.create({
    baseURL: apiUrl,
    timeout: 10000,
});


ApiRequest.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('edumrk_dv_ath');
        const userId = localStorage.getItem('edmrki_dkey');

        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
            const contentType = config.headers['Content-Type'];

            if (typeof contentType !== 'string' || !contentType.includes('multipart/form-data')) {
                config.headers['Content-Type'] = 'application/json';
            }
        }

        // Modify logic for GET requests
        if (userId && config.method === 'get') {
            // Append userId as a query parameter for GET requests
            config.params = {
                ...config.params, // Preserve any existing query parameters
                // userId: userId // Add userId to the query parameters
            };
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

ApiRequest.interceptors.response.use(
    (response) => {
        return response.data;
    },
    (error) => {
        if (error.response) {
            switch (error.response.status) {
                case 404:

                    break;
                case 500:

                    break;
                default:

            }
            console.error('Response error:', error.response.data);
            console.error('Response status:', error.response.status);
        } else {
            console.error('Error:', error.message);
        }
        return Promise.reject(error);
    }
);

export default ApiRequest;

import React from "react";
import IMAGES from "@utils/Images";
import { Cohort } from "../modules/types/types";

interface CohortListProps {
  cohorts: Cohort[] | null;
}

const CohortList: React.FC<CohortListProps> = ({ cohorts }) => {
  const containerStyles: React.CSSProperties = {
    height: "290px",
    overflowY: "scroll",
    scrollbarWidth: "none",
  };
  const heading: React.CSSProperties = {
    fontSize: "18.85px",
    fontWeight: "500",
    lineHeight: "16.86px",
    color: "#000000",
  };
  const listitemStyle: React.CSSProperties = {
    fontSize: "15.71px",
    fontWeight: "500",
    lineHeight: "19.01px",
    color: "#1C1D1D",
  };
  const dateStyle: React.CSSProperties = {
    fontSize: "15.08px",
    fontWeight: "400",
    lineHeight: "19px",
    color: "#1C1D1D",
  };

  const itemStyle: React.CSSProperties = {
    borderBottom: "1.03px solid",
    borderColor: "#ECECEC",
    padding: "25px",
    boxShadow: "0px 0px 39.85px 0px #00000012",
    borderRadius: "9.96px",
  };

  return (
    <div
      className="Notification-container card-container"
      style={containerStyles}
    >
      <label className="heading" style={heading}>
        Cohorts
      </label>
      <div className={`col-12`}>
        {cohorts?.map(
          (
            cohort,
            index
          ) => (
            <div
              className="d-flex align-items-center gap-3 mt-3"
              style={itemStyle}
              key={index}
            >
              <img src={IMAGES.USERS} alt="Logo" />
              <div style={listitemStyle}>
                <label className="d-block">{cohort.name}</label>
                <label style={dateStyle}>
                  <span>Course: {cohort.course.name}</span>
                  <span>Learners: {cohort.learners}</span>
                </label>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default CohortList;

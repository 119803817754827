import Swal from 'sweetalert2';
import COLORS from '@/app/utils/Colors'; // Assuming COLORS is in your utils folder

export const successPopup = async (message: string): Promise<void> => {
    await Swal.fire({
        title: 'Success',
        text: message,
        icon: 'success',
        confirmButtonColor: COLORS.PRIMARY,
        confirmButtonText: 'OK',
        allowOutsideClick: false
    });
};

import { UserSliceState } from '@/app/modules/types/sliceTypes';
import { FailedPayload, GetUserDetailsPayload, SuccessPayload, UpdatedSchedulesPayload, User } from '@/app/modules/types/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';


const initialState: UserSliceState = {
    user: null,
    loading: false,
    error: null,
    success: null,
    schedulesUpdated: null,
    schedules: [],
    users: [],
    pagination: {
        page: 1,
        limit: 10,
        totalPages: 0,
        totalUsers: 0
    },
    learners: [],
    educators: [],
};


const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        fetchUser: (state) => {
            state.loading = true;
            state.error = null;
        },
        fetchUserSuccess: (state, action: PayloadAction<User>) => {
            state.user = action.payload;
            state.schedules = action.payload.schedules;
            state.loading = false;
            state.error = null;
        },
        fetchUserFailed: (state, action: PayloadAction<FailedPayload>) => {
            state.error = action.payload.message;
            state.loading = false;
        },
        updateUser: (state) => {
            state.loading = true;
            state.error = null;
        },
        updateUserSuccess: (state, action: PayloadAction<SuccessPayload>) => {
            state.success = action.payload.message
            state.loading = false;
            state.error = null;
        },
        updateUserFailed: (state, action: PayloadAction<FailedPayload>) => {
            state.error = action.payload.message;
            state.loading = false;
        },
        updateUserSchedule: (state) => {
            state.loading = true;
        },
        updateScheduleSuccess: (state, action: PayloadAction<UpdatedSchedulesPayload>) => {
            state.schedules = action.payload.schedules;
            state.schedulesUpdated = action.payload.message;
            state.loading = false;
        },
        updateScheduleFailed: (state, action: PayloadAction<FailedPayload>) => {
            state.error = action.payload.message;
            state.loading = false;
        },
        clearSuccess: (state) => {
            state.success = null;
            state.schedulesUpdated = null;
            state.loading = false;
        },
        setLoading: (state) => {
            state.loading = true;
            state.error = null;
        },
        createUser: (state) => {
            state.loading = true;
            state.error = null;
        },
        createUserFailed: (state, action: PayloadAction<FailedPayload>) => {
            state.error = action.payload.message;
            state.loading = false;
        },
        userActionFailed: (state, action: PayloadAction<FailedPayload>) => {
            state.error = action.payload.message;
            state.loading = false;
        },
        createUserSuccess: (state, action: PayloadAction<SuccessPayload>) => {
            state.success = action.payload.message;
            state.loading = false;
        },
        fetchUsers: (state) => {
            state.users = []
            state.loading = true;
            state.error = null;
        },
        fetchUsersSuccess: (state, action: PayloadAction<GetUserDetailsPayload>) => {
            state.users = action.payload.data;
            state.pagination = action.payload.pagination;
            state.loading = false;
            state.error = null;
        },
        createCohort: (state) => {
            state.loading = true;
            state.error = null;
        },
        fetchUsersForCohort: (state) => {
            state.loading = true;
            state.error = null;
        },
        fetchedEducatorsForCohort: (state, action: PayloadAction<GetUserDetailsPayload>) => {
            const { data } = action.payload
            if (data) {
                state.educators = data?.map(educator => ({
                    id: educator.id,
                    email: educator.email,
                    label: educator.name,
                    level: educator.levels.join(', '),
                }));
            }

            state.loading = false;
            state.error = null;
        },
        fetchedLearnersForCohort: (state, action: PayloadAction<GetUserDetailsPayload>) => {
            const { data } = action.payload
            if (data) {
                state.learners = data?.map(learner => ({
                    id: learner.id,
                    email: learner.email,
                    label: learner.name,
                    level: learner.levels.join(', '),
                }));
            }
            console.log(state.learners, 'cdccdc')
            state.loading = false;
            state.error = null;
        },
        resend_credentials: (state) => {
            state.loading = true;
            state.error = null;
        },
        credentialsSendSuccess: (state, action: PayloadAction<SuccessPayload>) => {
            state.success = action.payload.message;
            state.loading = false;
            state.error = null;
        },
        act_deactivate_user: (state) => {
            state.loading = true;
            state.error = null;
        },
        userActDeactivated: (state, action: PayloadAction<SuccessPayload>) => {
            state.success = action.payload.message;
            state.loading = false;
            state.error = null;
        },
        deleteUser: (state) => {
            state.loading = true;
            state.error = null;
        },
        userDeleted: (state, action: PayloadAction<SuccessPayload>) => {
            state.success = action.payload.message;
            state.loading = false;
            state.error = null;
        },
        resetState: () => initialState,

        fetchUpdatedSchedules: (state) => {
            state.loading = true;
            state.error = null;
        },
        fetchUpdatedSchedulesSuccess: (state, action: PayloadAction<UpdatedSchedulesPayload>) => {
            state.schedules = action.payload.schedules;
            state.loading = false;
            state.error = null;
        },
        fetchUpdatedSchedulesFailed: (state, action: PayloadAction<FailedPayload>) => {
            state.error = action.payload.message;
            state.loading = false;
        },
    }
});

export const {
    fetchUser,
    fetchUserSuccess,
    fetchUserFailed,
    updateUser,
    updateUserSuccess,
    updateUserFailed,
    updateScheduleSuccess,
    updateScheduleFailed,
    setLoading,
    clearSuccess,
    createUser,
    createUserFailed,
    createUserSuccess,
    fetchUsers,
    fetchUsersSuccess,
    fetchUsersForCohort,
    fetchedEducatorsForCohort,
    fetchedLearnersForCohort,
    createCohort,
    resend_credentials,
    credentialsSendSuccess,
    act_deactivate_user,
    userActDeactivated,
    deleteUser,
    userDeleted,
    fetchUpdatedSchedules,
    fetchUpdatedSchedulesSuccess,
    fetchUpdatedSchedulesFailed,
} = userSlice.actions;

export default userSlice.reducer;
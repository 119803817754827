import { call, put, takeLatest } from "redux-saga/effects";
import { getErrorMessage } from "@/app/modules/helpers/ErrorHelper";
import { correctionRateResponse, feedbackResponse, FetchTutorAIList, tutorAIResponse } from "@/app/modules/types/types";
import { get_feedback, get_tutor_AI_list, get_assignments_submission, get_correction_rate, get_grades_distribution, get_grades_distribution_dashboard, get_average_grade_percentage, get_total_active_users, get_assignments_status, get_average_time_to_mark, get_learner_satisfaction, get_average_time_to_submit } from "@/app/api/Api";
import { ANALYTICS_FAILED, FETCH_ASSIGNMENTS_STATUS, FETCH_AVERAGE_GRADE_PERCENTAGE, FETCH_AVERAGE_TIME_TO_MARK, FETCH_AVERAGE_TIME_TO_SUBMIT, FETCH_CORRECTION_RATE, FETCH_FEEDBACK, FETCH_GRADES_BY_LEVEL, FETCH_GRADES_BY_UNIT, FETCH_GRADES_DISTRIBUTION_DASHBOARD, FETCH_LEARNER_SATISFACTION, FETCH_SUBMISSION, FETCH_TOTAL_ACTIVE_USERS, FETCH_TUTOR_AI, FETCHED_ASSIGNMENTS_STATUS, FETCHED_AVERAGE_GRADE_PERCENTAGE, FETCHED_AVERAGE_TIME_TO_MARK, FETCHED_AVERAGE_TIME_TO_SUBMIT, FETCHED_CORRECTION_RATE, FETCHED_FEEDBACK, FETCHED_GRADES_BY_LEVEL, FETCHED_GRADES_BY_UNIT, FETCHED_GRADES_DISTRIBUTION, FETCHED_LEARNER_SATISFACTION, FETCHED_SUBMISSION, FETCHED_TOTAL_ACTIVE_USERS, FETCHED_TUTOR_AI, SET_LOADING_ANALYTICS } from "../actionTypes";
import moment from "moment";

function* fetchLearnersByLevel(action: { type: string, payload }): Generator {
    try {
        yield put({ type: SET_LOADING_ANALYTICS });
        const response = (yield call(get_grades_distribution, action.payload)) as any;
        yield put({ type: FETCHED_GRADES_BY_LEVEL, payload: { data: response.data } });
    } catch (error) {
        const errorMessage = getErrorMessage(error);
        yield put({ type: ANALYTICS_FAILED, payload: { message: errorMessage } });
    }
}

function* fetchGradesDistributionDashboard(action: { type: string, payload }): Generator {
    try {
        yield put({ type: SET_LOADING_ANALYTICS });
        const response = (yield call(get_grades_distribution_dashboard, action.payload)) as any;
        // console.log("🚀 ~ function*fetchGradesDistributionDashboard ~ response:", response)
        yield put({ type: FETCHED_GRADES_DISTRIBUTION, payload: { data: response.data } })
    } catch (error) {
        const errorMessage = getErrorMessage(error)
        yield put({ type: ANALYTICS_FAILED, payload: { message: errorMessage } })
    }

}
function* fetchTotalActiveUsers(action: { type: string, payload }): Generator {
    try {
        yield put({ type: SET_LOADING_ANALYTICS });
        const response = (yield call(get_total_active_users, action.payload)) as any;

        yield put({ type: FETCHED_TOTAL_ACTIVE_USERS, payload: { data: response.data } })
    } catch (error) {
        const errorMessage = getErrorMessage(error)
        yield put({ type: ANALYTICS_FAILED, payload: { message: errorMessage } })
    }

}

function* fetchAssignmentsStatus(action: { type: string, payload }): Generator {
    try {
        yield put({ type: SET_LOADING_ANALYTICS });
        const response = (yield call(get_assignments_status, action.payload)) as any;
        yield put({ type: FETCHED_ASSIGNMENTS_STATUS, payload: { data: response.data } })
    } catch (error) {
        const errorMessage = getErrorMessage(error)
        yield put({ type: ANALYTICS_FAILED, payload: { message: errorMessage } })
    }
}

function* fetchAverageTimeToMark(action: { type: string, payload }): Generator {
    try {
        yield put({ type: SET_LOADING_ANALYTICS });
        const response = (yield call(get_average_time_to_mark, action.payload)) as any;
        yield put({ type: FETCHED_AVERAGE_TIME_TO_MARK, payload: { data: response.data } })
    } catch (error) {
        const errorMessage = getErrorMessage(error)
        yield put({ type: ANALYTICS_FAILED, payload: { message: errorMessage } })
    }
}

function* fetchedLearnerSatisfaction(action: { type: string, payload }): Generator {
    try {
        yield put({ type: SET_LOADING_ANALYTICS });
        const response = (yield call(get_learner_satisfaction, {
            ...action.payload,
            start_date: moment().subtract(1, 'years').format('YYYY-MM-DD[T00:00:00.000Z]'),
            end_date: moment(new Date()).format('YYYY-MM-DD[T00:00:00.000Z]')
        })) as any;
        yield put({ type: FETCHED_LEARNER_SATISFACTION, payload: { data: response.data } })
    } catch (error) {
        const errorMessage = getErrorMessage(error)
        yield put({ type: ANALYTICS_FAILED, payload: { message: errorMessage } })
    }
}

function* fetchAverageTimeToSubmit(action: { type: string, payload }): Generator {
    try {
        yield put({ type: SET_LOADING_ANALYTICS });
        const response = (yield call(get_average_time_to_submit, action.payload)) as any;
        yield put({ type: FETCHED_AVERAGE_TIME_TO_SUBMIT, payload: { data: response.data } })
    } catch (error) {
        const errorMessage = getErrorMessage(error)
        yield put({ type: ANALYTICS_FAILED, payload: { message: errorMessage } })
    }
}

function* fetchAverageGradePercentage(action: { type: string, payload }): Generator {
    try {
        yield put({ type: SET_LOADING_ANALYTICS });
        const response = (yield call(get_average_grade_percentage, {
            ...action.payload,
            start_date: moment().subtract(1, 'years').format('YYYY-MM-DD[T00:00:00.000Z]'),
            end_date: moment(new Date()).format('YYYY-MM-DD[T00:00:00.000Z]')
        })) as any;
        // console.log("🚀 ~ function*fetchAverageGradePercentage ~ response:", response)
        yield put({ type: FETCHED_AVERAGE_GRADE_PERCENTAGE, payload: response.data })
    } catch (error) {
        const errorMessage = getErrorMessage(error)
        yield put({ type: ANALYTICS_FAILED, payload: { message: errorMessage } })
    }

}


function* fetchLearnerByUnit(action: { type: string, payload }): Generator {
    try {
        yield put({ type: SET_LOADING_ANALYTICS });
        const response = (yield call(get_grades_distribution, action.payload)) as any;
        yield put({ type: FETCHED_GRADES_BY_UNIT, payload: { data: response.data } });
    } catch (error) {
        const errorMessage = getErrorMessage(error);
        yield put({ type: ANALYTICS_FAILED, payload: { message: errorMessage } });
    }
}

function* fetchCorrectionRate(action: { type: string, payload }): Generator {
    try {
        yield put({ type: SET_LOADING_ANALYTICS });
        const response = (yield call(get_correction_rate, action.payload)) as correctionRateResponse;
        yield put({ type: FETCHED_CORRECTION_RATE, payload: { data: response.data } });
    } catch (error) {
        const errorMessage = getErrorMessage(error);
        yield put({ type: ANALYTICS_FAILED, payload: { message: errorMessage } });
    }
}

function* fetchFeedback(action: { type: string, payload }): Generator {
    try {
        yield put({ type: SET_LOADING_ANALYTICS });
        const response = (yield call(get_feedback, action.payload)) as feedbackResponse;
        yield put({ type: FETCHED_FEEDBACK, payload: { data: response.data } });
    } catch (error) {
        const errorMessage = getErrorMessage(error);
        yield put({ type: ANALYTICS_FAILED, payload: { message: errorMessage } });
    }
}

function* fetchAssignments(action: { type: string, payload }): Generator {
    try {
        yield put({ type: SET_LOADING_ANALYTICS });
        const response = (yield call(get_assignments_submission, {
            ...action.payload,
            end_date: moment().subtract(1, 'years').format('YYYY-MM-DD[T00:00:00.000Z]'),
            start_date: moment(new Date()).format('YYYY-MM-DD[T00:00:00.000Z]')
        })) as any;
        console.log({ payload: action.payload, data: response })
        yield put({ type: FETCHED_SUBMISSION, payload: { data: response.data } });
    } catch (error) {
        const errorMessage = getErrorMessage(error);
        yield put({ type: ANALYTICS_FAILED, payload: { message: errorMessage } });
    }
}

function* fetchTutorAIList(action: { type: string, payload: FetchTutorAIList }): Generator {
    try {
        yield put({ type: SET_LOADING_ANALYTICS });
        const response = (yield call(get_tutor_AI_list, action.payload)) as tutorAIResponse;
        yield put({ type: FETCHED_TUTOR_AI, payload: { data: response.data } });
    } catch (error) {
        const errorMessage = getErrorMessage(error);
        yield put({ type: ANALYTICS_FAILED, payload: { message: errorMessage } });
    }
}

function* analyticsSaga() {
    yield takeLatest(FETCH_GRADES_BY_LEVEL, fetchLearnersByLevel);
    yield takeLatest(FETCH_GRADES_BY_UNIT, fetchLearnerByUnit);
    yield takeLatest(FETCH_CORRECTION_RATE, fetchCorrectionRate);
    yield takeLatest(FETCH_FEEDBACK, fetchFeedback);
    yield takeLatest(FETCH_SUBMISSION, fetchAssignments);
    yield takeLatest(FETCH_TUTOR_AI, fetchTutorAIList);
    yield takeLatest(FETCH_GRADES_DISTRIBUTION_DASHBOARD, fetchGradesDistributionDashboard)
    yield takeLatest(FETCH_AVERAGE_TIME_TO_MARK, fetchAverageTimeToMark)
    yield takeLatest(FETCHED_AVERAGE_GRADE_PERCENTAGE, fetchAverageGradePercentage)
    yield takeLatest(FETCH_TOTAL_ACTIVE_USERS, fetchTotalActiveUsers)
    yield takeLatest(FETCH_ASSIGNMENTS_STATUS, fetchAssignmentsStatus)
    yield takeLatest(FETCH_AVERAGE_TIME_TO_MARK, fetchAverageTimeToMark)
    yield takeLatest(FETCH_LEARNER_SATISFACTION, fetchedLearnerSatisfaction)
    yield takeLatest(FETCH_AVERAGE_TIME_TO_SUBMIT, fetchAverageTimeToSubmit)
}

export default analyticsSaga;

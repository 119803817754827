import { mic, micActive, micDefault } from "@/app/assets/icons/svg";
import Button from "@/app/components/Button";
import {
  FETCH_GRADING_FEEDBACK_SECOND_MARKING_AI,
  UPDATE_FEEDBACK,
} from "@/app/redux/actionTypes";
import { RootState } from "@/app/redux/store";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

interface Answer {
  question?: string;
  answer?: string;
}

interface TaskEvaluation {
  task_id: string;
  description: string;
  answer: string;
  marks: number;
  total_marks: number;
  feedback: string;
}
interface GradeProps {
  data?: Answer[] | TaskEvaluation[] | any;
  task?: string;
}
const Grade: React.FC<GradeProps> = ({ data, task }) => {
  const { level } = useParams<string>();
  const levels = ["Level 3", "Level 5"];

  const [isHovered, setIsHovered] = useState(false);
  const {
    assignmentDetail: { assignment, grading_summary, student, tasks },
  } = useSelector((state: RootState) => state.gradingFeedback);
  const { user_id } = useSelector((state: RootState) => state.auth);
  const grades = data?.filter((item: any) => {
    if (level && levels.includes(level)) {
      if (item.assessmentCriteria === task) {
        return item;
      }
    } else {
      if (item.task_id === task) {
        return item;
      }
    }
  });
  const [marks, setMarks] = useState<string>("0");
  const [feedback, setFeedback] = useState<string>("");
  const [isClicked, setIsClicked] = useState(false);
  const [isEditing, setIsEditing] = useState<boolean>(false); // State to track editing status
  const [isFeedbackEditing, setIsFeedbackEditing] = useState<boolean>(false); // State for feedback editing
  const dispatch = useDispatch();
  const location = useLocation();
  const { file_id, levelStatus } = location.state || {}; // Destructure to get row details

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMarks(e.target.value);
    setIsEditing(true); // Set editing to true when input changes
  };

  const handleFeedbackClick = () => {
    setIsFeedbackEditing(true);
  };

  const handleClick = () => {
    setIsClicked((prev) => !prev); // Toggle clicked state
  };

  const handleFeedbackChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFeedback(e.target.value);
    setIsFeedbackEditing(true);
  };

  const updateFeedback = () => {
    if (isEditing || isFeedbackEditing) {
      setIsEditing(false);
      setIsFeedbackEditing(false);
      if (levelStatus === "seond_marking_in_progress") {
        let tasks = [
          {
            questionNumber: grades[0].task_id,
            second_marking: {
              second_marking_marks: marks,
              second_marking_feedback: feedback,
            },
          },
        ];
        let updatedFeedback = {
          file_id: file_id,
          educator_signature: user_id,
          tasks: tasks,
          second_marking_overall_marks:level && levels.includes(level) ? grading_summary?.total_marks_obtained :assignment?.overall_marks?.second_marking,
          second_marking_feedback_summary: level && levels.includes(level) ? grading_summary?.feedback_summary : grading_summary.second_marking_feedback_summary
        };
        dispatch({
          type: FETCH_GRADING_FEEDBACK_SECOND_MARKING_AI,
          payload: { updatedFeedback },
        });
      } else {
        let tasks =
          level && !levels.includes(level)
            ? [
                {
                  questionNumber: grades[0].task_id,
                  first_marking: {
                    first_marking_marks: marks,
                    first_marking_feedback: feedback,
                  },
                },
              ]
            : [
                {
                  assessmentCriteria: grades[0].assessmentCriteria,
                  first_marking: {
                    first_marking_marks: marks,
                    first_marking_feedback: feedback,
                  },
                },
              ];
              //'6719b59d6886d6b96a8382c2'
              // file_id
        let updatedFeedback = {
          file_id: '6719b59d6886d6b96a8382c2',
          educator_signature: user_id,
          first_marking_overall_marks:level && levels.includes(level) ? grading_summary?.total_marks_obtained :assignment?.overall_marks?.first_marking,
          first_marking_feedback_summary: level && levels.includes(level) ? grading_summary?.feedback_summary : grading_summary.first_marking_feedback_summary,
          tasks: tasks,
        };
        dispatch({
          type: UPDATE_FEEDBACK,
          payload: { updatedFeedback },
        });
      }
    }
  };

  const getFeedbackValue = () => {
    if (feedback) {
        return feedback;
    }
    if (grades && grades[0]) {
        if (grades[0].feedback) {
            return grades[0].feedback;
        }
        if (levelStatus === "seond_marking_in_progress") {
            return grades[0].second_marking?.feedback || "feedback";
        }
        return grades[0].first_marking?.feedback;
    }
    return ""; // Default value if no conditions are met
};

const getMarksValue = () => {
  if (marks !== "0") {
      return marks;
  }
  if (grades && grades[0]) {
      if (grades[0].marks) {
          return grades[0].marks;
      }
      if (levelStatus === "seond_marking_in_progress") {
          return grades[0].second_marking?.marks || "0";
      }
      return grades[0].first_marking?.marks;
  }
  return "0"; // Default value if no conditions are met
};


  return (
    <div className="grade-comp d-flex flex-column z-0">
      <div className="card-heading mb-4 d-flex align-items-center">
        {"Grading"}
      </div>
      <div className="marks-section mx-4 d-flex flex-xl-row flex-column justify-content-between gap-lg-0 gap-2">
        <div className="d-flex gap-3 align-items-center">
          Marks
          <div className="d-flex align-items-center marks-input-container">
            <input
              type="number"
              value={getMarksValue()}
              onChange={handleInputChange}
              className="marks-input"
              min={0}
              max={4}
              onFocus={() => setIsEditing(true)} // Set editing to true on focus
            />
            /4
          </div>
        </div>
        <Button
          label={isEditing || isFeedbackEditing ? "Save" : "Edit"} // Change button text based on editing state
          className={"assignment-btn"}
          onClick={updateFeedback} // Call handleBlur to save changes and exit editing
          isIcon={true}
        />
      </div>
      <div
        className={`feedback-section p-3 d-flex flex-column`}
        style={{ height: level && !levels.includes(level) ? "300px" : "200px" }}
      >
        <label className="feedback-label">Feedback</label>
        <textarea
          className={`feedback-textarea ${isFeedbackEditing ? "active" : ""}`} // Add active class for styling
          value={getFeedbackValue()}
          onClick={handleFeedbackClick} // Enable editing on click
          onChange={handleFeedbackChange}
          readOnly={!isFeedbackEditing} // Make textarea editable only when clicked
        />
      </div>
      <div className="mt-auto">
        {(isEditing || isFeedbackEditing) && (
          <div className="w-100 d-flex justify-content-end align-items-center gap-1">
            <div
              className={`image-container ${isClicked && "image-active"}`}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              onClick={handleClick}
            >
              <img
                src={isClicked ? micActive : isHovered ? mic : micDefault}
                alt="Mic"
              />
            </div>
          </div>
        )}
        {level && !levels.includes(level) && (
          <div className="total w-100 d-flex justify-content-end align-items-center gap-1">
            Overall Marks{" "}
            <span className="">
              {grading_summary?.total_marks_obtained
                ? grading_summary?.total_marks_obtained
                : 20}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Grade;

import React, { useRef, useState } from "react";
import Question from "./Question";
import Answer from "./Answer";
import Grade from "./Grade";
import { useSelector } from "react-redux";
import { RootState } from "@/app/redux/store";
import { useLocation, useParams } from "react-router-dom";

interface SummaryTwoProps {}
const SummaryTwo: React.FC<SummaryTwoProps> = () => {
  const { assignmentDetail } = useSelector(
    (state: RootState) => state.gradingFeedback
  );  
  const location = useLocation();
  const { file_id, levelStatus } = location.state || {}; // Destructure to get row details

  const [task,setTask] = useState<any>(assignmentDetail?.tasks[0].task_id)
  return (
    <>
      <div className="overflow-x-auto summary">
        {" "}
        <div className="row g-3 flex-nowrap mb-3">
          <div className="col-lg-3 col-12">
            <Question questions={assignmentDetail?.tasks} heading={"Learning Objective"} setTask={setTask}/>
          </div>
          <div className="col-lg-3 col-12">
            <Question questions={assignmentDetail?.tasks} heading={"Questions"}/>
          </div>
          <div className="col-lg-6 col-12">
            <Answer data={assignmentDetail?.tasks} task={task}/>
          </div>
          <div className="col-lg-3 col-12">
            <Grade data={assignmentDetail?.tasks} task={task}/>
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-12">
          <div className="feedback-comp">
            <div className="card-heading">Feedback summary</div>
            <div className="feedback-content p-3">
              {levelStatus === "seond_marking_in_progress" ? assignmentDetail?.grading_summary?.second_marking_feedback_summary : assignmentDetail?.grading_summary?.first_marking_feedback_summary}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SummaryTwo;

export const data = {
  labels: [
    "Level 1",
    "Level 2",
    "Level 3",
    "Level 4",
    "Level 5",
    "Level 6",
    "Level 7",
    "Level 8",
    "Level 9",
    "Level 10",
  ],
  datasets: [
    {
      label: "Low Pass",
      data: [10, 20, 10, 10, 40, 20, 10, 20, 10, 10],
      backgroundColor: "#3D5AF1",
      borderSkipped: false,
    },
    {
      label: "Pass",
      data: [10, 20, 10, 40, 15, 30, 10, 20, 10, 10],
      backgroundColor: "rgba(61, 90, 241, 0.35)",
      borderSkipped: false,
    },
    {
      label: "High Pass",
      data: [10, 20, 30, 20, 10, 5, 10, 20, 10, 10],
      backgroundColor: "rgba(61, 90, 241, 0.10)",
      borderSkipped: false,
    },
    {
      label: "Fail",
      data: [10, 20, 30, 10, 15, 15, 10, 20, 10, 10],
      backgroundColor: "#F8849D",
      borderSkipped: false,
    },
  ],
};

export const levelFeedbackData = {
  labels: [
    "Level 1",
    "Level 2",
    "Level 3",
    "Level 4",
    "Level 5",
    "Level 6",
    "Level 7",
    "Level 8",
    "Level 9",
    "Level 10",
  ],
  datasets: [
    {
      label: "5 Star",
      data: [10, 20, 10, 40, 10, 20, 10, 10, 5, 10],
      backgroundColor: "#ECEEFE",
      borderSkipped: false,
    },
    {
      label: "4 Star",
      data: [10, 20, 10, 10, 10, 20, 10, 10, 5, 10],
      backgroundColor: "#BBC5FA",
      borderSkipped: false,
    },
    {
      label: "3 Star",
      data: [10, 20, 20, 10, 10, 20, 10, 10, 5, 10],
      backgroundColor: "#8FA0F7",
      borderSkipped: false,
    },
    {
      label: "2 Star",
      data: [10, 20, 10, 10, 10, 20, 10, 10, 5, 10],
      backgroundColor: "#3D5AF1",
      borderSkipped: false,
    },
    {
      label: "1 Star",
      data: [10, 20, 10, 10, 10, 20, 10, 10, 5, 10],
      backgroundColor: "#F8849D",
      borderSkipped: false,
    },
  ],
};

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: window.innerWidth < 768 ? ("top" as const) : ("right" as const),
      labels: {
        boxWidth: 12,
        font: {
          size: 12,
        },
        color: "#717171",
        padding: 20,
      },
    },
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
      title: {
        display: true,
        padding: {
          top: 10,
          bottom: 10,
        },
      },
      ticks: {
        display: true,
        autoSkip: false,
        color: "#595959",
      },
      border: {
        color: "#1C1D22", // Set your desired x-axis line color
      },
    },
    y: {
      stacked: true,
      grid: {
        display: false,
      },
      title: {
        display: true,
        padding: {
          top: 10,
          bottom: 10,
        },
      },
      ticks: {
        callback: function (value) {
          console.log(value,"value")
          return value + "%";
        },
        stepSize: 20, // Set step size to 20 for 0, 20, 40, ..., 100
        max: 100, // Ensure the maximum value for y-axis is 100
        display: true,
        color: "#1C1D22",
      },
      border: {
        color: "#1C1D22", // Set your desired x-axis line color
      },
      beginAtZero: true,
    },
  },
};

export const levelData = {
  labels: [
    "Level 1",
    "Level 2",
    "Level 3",
    "Level 4",
    "Level 5",
    "Level 6",
    "Level 7",
    "Level 8",
    "Level 9",
    "Level 10",
  ],
  datasets: [
    {
      label: "levels",
      data: [10, 20, 30, 40, 35, 60, 45, 70, 80, 40],
      backgroundColor: "#3D5AF1",
      borderSkipped: false,
      categoryPercentage: 0.5,
    },
  ],
};

export const levelOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
      title: {
        display: true,
        padding: {
          top: 10,
          bottom: 10,
        },
      },
      ticks: {
        autoSkip: false,
        display: true,
        color: "#595959",
      },
      border: {
        color: "#1C1D22",
      },
    },
    y: {
      stacked: true,
      grid: {
        display: false,
      },
      title: {
        display: true,
        padding: {
          top: 10,
          bottom: 10,
        },
      },
      ticks: {
        max: 100,
        display: true,
        color: "#1C1D22",
        stepSize: 20,
        callback: function (value) {
          return value + "%"; // Append percentage symbol
        },
      },
      border: {
        color: "#1C1D22",
      },
      beginAtZero: true,
    },
  },
};

export const educatorData = {
  labels: [
    "John Doe",
    "John Doe",
    "Allen Smith",
    "Allen Smith",
    "Allen Smith",
    "John Doe",
    "John Doe",
    "Allen Smith",
    "Allen Smith",
    "Allen Smith",
  ],
  datasets: [
    {
      label: "educators",
      data: [36, 60, 70, 80, 100, 36, 60, 70, 80, 100],
      backgroundColor: "#3D5AF1",
      borderSkipped: false,
      categoryPercentage: 0.5,
    },
  ],
};

export const gradeData = {
  labels: [
    "Unit 1",
    "Unit 2",
    "Unit 3",
    "Unit 4",
    "Unit 5",
    "Unit 6",
    "unit 7",
    "Unit 8",
    "Unit 9",
    "Unit 10",
  ],
  datasets: [
    {
      label: "Fail",
      data: [10, 20, 30, 10, 20, 30, 10, 20, 30, 20],
      backgroundColor: "#F8849D",
      borderSkipped: false,
    },
    {
      label: "Low Pass",
      data: [10, 20, 30, 10, 20, 30, 10, 20, 10, 30],
      backgroundColor: "#3D5AF1",
      borderSkipped: false,
    },
    {
      label: "Pass",
      data: [10, 20, 10, 10, 20, 10, 10, 20, 30, 25],
      backgroundColor: "rgba(61, 90, 241, 0.35)",
      borderSkipped: false,
    },
    {
      label: "High Pass",
      data: [10, 20, 10, 10, 20, 10, 10, 20, 10, 15],
      backgroundColor: "rgba(61, 90, 241, 0.10)",
      borderSkipped: false,
    },
  ],
};

export const educatorFeedbackData = {
  labels: [
    "John Doe",
    "John Doe",
    "Allen Smith",
    "Allen Smith",
    "Allen Smith",
    "John Doe",
    "John Doe",
    "Allen Smith",
    "Allen Smith",
    "Allen Smith",
    "John Doe",
  ],
  datasets: [
    {
      label: "5 Star",
      data: [10, 10, 5, 10, 10, 10, 10, 5, 10, 10, 10, 10],
      backgroundColor: "#ECEEFE",
      borderSkipped: false,
      categoryPercentage: 0.5,
    },
    {
      label: "4 Star",
      data: [20, 30, 40, 20, 30, 10, 10, 5, 10, 10, 10, 10],
      backgroundColor: "#BBC5FA",
      borderSkipped: false,
      categoryPercentage: 0.5,
    },
    {
      label: "3 Star",
      data: [40, 20, 30, 10, 20, 10, 10, 5, 10, 10, 10, 10],
      backgroundColor: "#8FA0F7",
      borderSkipped: false,
      categoryPercentage: 0.5,
    },
    {
      label: "2 Star",
      data: [10, 20, 10, 40, 20, 10, 10, 5, 10, 10, 10, 10],
      backgroundColor: "#3D5AF1",
      borderSkipped: false,
      categoryPercentage: 0.5,
    },
    {
      label: "1 Star",
      data: [10, 20, 10, 20, 20, 10, 10, 5, 10, 10, 10, 10],
      backgroundColor: "#F8849D",
      borderSkipped: false,
      categoryPercentage: 0.5,
    },
  ],
};

export const lineData = {
  labels: [
    "Jan 2022",
    "Feb 2023",
    "Mar 2023",
    "Apr 2022",
    "May 2022",
    "June 2022",
    "July 2022",
    "Aug 2022",
    "Sep 2022",
    "Ocr 2022",
    "Nov 2022",
    "Dec 2022",
  ],
  datasets: [
    {
      fill: true,
      lineTension: 0.5,
      borderColor: "#0343FA",
      backgroundColor: (context) => {
        const chart = context.chart;
        const ctx = chart.ctx;

        // Create a linear gradient
        const gradient = ctx.createLinearGradient(0, 0, 0, chart.height);
        gradient.addColorStop(0, "#5782FC"); // Darker color at the top
        gradient.addColorStop(1, "rgba(87, 130, 252, 0.00)"); // Lighter, transparent color at the bottom

        return gradient; // Return the gradient as background color
      },
      borderWidth: 2,
      pointRadius: 0, // Set point radius to 0 to remove points
      pointHoverRadius: 0, // Set hover radius to 0 to keep it consistent
      data: [0, 0, 10, 40, 30, 60, 20, 60, 25, 65, 34, 85],
    },
  ],
};

export const lineOptions = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      grid: {
        display: false, // Hides vertical grid lines
      },
      ticks: {
        display: true, // Show tick marks
        autoSkip: false,
        color: "#595959",
      },
      border: {
        color: "#E4E2F1", // Set your desired x-axis line color
      },
    },
    y: {
      stacked: true,
      position: "right" as const,
      border: { color: "#E4E2F1", dash: [6, 6] },
      beginAtZero: true,
      grid: {
        display: true, // Make x-axis grid visible
        color: "rgba(0, 0, 0, 0.1)", // Light x-axis grid color
        borderColor: "rgba(0, 0, 0, 0.5)",
        tickLength: 1,
      },
      ticks: {
        display: true, // Show tick marks
        autoSkip: false,
        color: "#595959",
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
};

window.addEventListener("resize", () => {
  const {
    plugins: { legend },
  } = options;
  legend.position =
    window.innerWidth < 768 ? ("top" as const) : ("right" as const);
});

// Auth action types
export const CHECK_AUTH = 'auth/checkAuth';
export const HANDLE_INVITE = 'auth/invited';
export const HANDLE_USERTYPE = 'auth/userType';
export const FETCH_LOGIN = 'auth/login';
export const HANDLE_LOGOUT = 'auth/logout';
export const LOGIN_SUCCESS = 'auth/loginSuccess';
export const LOGIN_FAILED = 'auth/loginFailed';
export const SET_LOADING = 'auth/setLoading';
export const RESET_PASSWORD = 'auth/resetPassword';
export const RESET_PASSWORD_SUCCESS = 'auth/resetPasswordSuccess';
export const RESET_PASSWORD_FAILED = 'auth/resetPasswordFailed';

export const SEND_VERIFY_OTP_RESET_PASSWORD = 'auth/sendVerifyOtpResetPassword'
export const SEND_VERIFY_OTP_RESET_PASSWORD_SUCCESS = 'auth/sendVerifyOtpResetPasswordSuccess'
export const CLEAR_SUCCESS = 'auth/clearSuccess'
export const CLEAR_FORGET_STEPS = 'auth/clearForgetSteps'

// User action types
export const FETCH_USER = 'user/fetchUser';
export const FETCH_HOME_USERS = 'user/fetchHomeUsers';
export const FETCH_USER_BY_USER_ID = 'user/fetchUserByUserId';
export const FETCH_USER_SUCCESS = 'user/fetchUserSuccess';
export const FETCH_USER_FAILED = 'user/fetchUserFailed';
export const SET_USER_LOADING = 'user/setLoading';
export const UPDATE_USER = 'user/updateUser';
export const UPDATE_USER_SUPERVISOR = 'user/updateUserSuprvisor';
export const UPDATE_USER_SUCCESS = 'user/updateUserSuccess';
export const UPDATE_USER_FAILED = 'user/updateUserFailed';
export const UPDATE_USER_SCHEDULE = 'user/updateUserSchedule';
export const UPDATE_SCHEDULE_SUCCESS = 'user/updateScheduleSuccess';
export const UPDATE_SCHEDULE_FAILED = 'user/updateScheduleFailed'
export const _USER_CLEAR_SUCCESS = 'user/clearSuccess'
export const CREATE_USER = 'user/createUser'
export const CREATE_USER_FAILED = 'user/createUserFailed'
export const ACT_DEACTIVATE_USER = 'user/act_deactivate_user'
export const USER_ACT_DEACTIVATED = 'user/userActDeactivated'
export const DELETE_USER = 'user/deleteUser'
export const USER_DELETED = 'user/userDeleted'
export const USER_ACTION_FAILED = 'user/userActionFailed'
export const CREATE_USER_SUCCESS = 'user/createUserSuccess'
export const FETCH_USERS = 'user/fetchUsers'
export const FETCH_USERS_SUCCESS = 'user/fetchUsersSuccess'
export const CREATE_COHORT = 'user/createCohort'
export const FETCH_USERS_FOR_COHORT = 'user/fetchUsersForCohort'
export const FETCHED_LEARNERS_FOR_COHORT = 'user/fetchedLearnersForCohort'
export const FETCHED_EDUCATORS_FOR_COHORT = 'user/fetchedEducatorsForCohort'
export const RESEND_CREDENTIALS = 'user/resend_credentials'
export const RESEND_CREDENTIALS_SUCCESS = 'user/credentialsSendSuccess'
export const GET_UPDATED_SCHEDULE = 'user/fetchUpdatedSchedules'
export const GET_UPDATED_SCHEDULE_SUCCESS = 'user/fetchUpdatedSchedulesSuccess';
export const GET_UPDATED_SCHEDULE_FAILED = 'user/fetchUpdatedSchedulesFailed';

//Cohorts
export const FETCH_COHORTS = 'cohort/fetchCohorts';
export const FETCHED_COHORTS = 'cohort/fetchedCohorts';
export const FETCH_COHORT_LEARNERS = 'cohort/fetchCohortLearners';
export const FETCHED_COHORT_LEARNERS = 'cohort/fetchedCohortLearners';
export const MOVE_LEARNER = 'cohort/moveLearner';
export const LEARNER_MOVED = 'cohort/learnerMoved';
export const COHORT_FAILED = 'cohort/cohortFailed';
export const SET_LOADING_COHORT = 'cohort/setLoading';
export const CLEAR_SUCCESS_COHORT = 'cohort/clearSuccess';
export const FETCH_LEVELS = 'cohort/fetchLevels';
export const FETCHED_LEVELS = 'cohort/fetchedLevels';
export const DELETE_LEARNER_FROM_COHORT = 'cohort/deleteLearnerFromCohort';
export const DELETE_LEARNER_FROM_COHORT_SUCCESS = 'cohort/deleteLearnerFromCohortSuccess';
export const FETCH_ALL_COHORTS = 'cohort/fetchAllCohorts'
export const FETCHED_ALL_COHORTS = 'cohort/fetchedAllCohorts'
export const DELETE_COHORTS = 'cohort/delete'
export const DELETE_COHORTS_SUCCESS = 'cohort/deleteCohortsSuccess'




// Analytics 
export const FETCHED_SUBMISSION = "analytics/fetchedSubmission"
export const FETCH_GRADES_BY_LEVEL = 'analytics/learnerbyLevel'
export const FETCH_GRADES_BY_UNIT = 'analytics/learnerbyUnit'
export const FETCH_CORRECTION_RATE = 'analytics/correction-rate'
export const FETCH_FEEDBACK = 'analytics/feedback'
export const FETCH_SUBMISSION = 'analytics/assignment-submission'
export const FETCH_TUTOR_AI = 'analytics/tutor-AI'
export const FETCHED_GRADES_BY_LEVEL = 'analytics/fetchedlearnerbyLevel'
export const FETCHED_GRADES_BY_UNIT = 'analytics/fetchedlearnerbyUnit'
export const FETCHED_CORRECTION_RATE = 'analytics/fetchedCorrectionrate'
export const FETCHED_FEEDBACK = 'analytics/fetchedFeedback'
export const FETCHED_TUTOR_AI = 'analytics/fetchedTutorAI'
export const ANALYTICS_FAILED = 'analytics/analyticsFailed';
export const SET_LOADING_ANALYTICS = 'analytics/setLoading';
export const UPLOAD_LEARNER_ASSIGNMENT = "uploadAssignemnt/createLearnerUploadAssignment";
export const FETCH_GRADES_DISTRIBUTION_DASHBOARD = "analytics/fetchgradeDistributionDashboard"
export const FETCHED_GRADES_DISTRIBUTION = "analytics/fetchedgradeDistributionDashboard"
export const FETCH_AVERAGE_GRADE_PERCENTAGE = "analytics/fetchAverageGradePercentage"
export const FETCHED_AVERAGE_GRADE_PERCENTAGE = "analytics/fetchedAverageGradePercentage"
export const FETCH_TOTAL_ACTIVE_USERS = 'analytics/fetchTotalActiveUsers'
export const FETCHED_TOTAL_ACTIVE_USERS = 'analytics/fetchedTotalActiveUsers'
export const FETCH_ASSIGNMENTS_STATUS = 'analytics/assignments-status';
export const FETCHED_ASSIGNMENTS_STATUS = 'analytics/fetchedAssignmentsStatus';
export const FETCH_AVERAGE_TIME_TO_MARK = 'analytics/fetchAverageTimeToMark';
export const FETCHED_AVERAGE_TIME_TO_MARK = 'analytics/fetchedAverageTimeToMark';
export const FETCH_LEARNER_SATISFACTION = 'analytics/fetchLearnerSatisfaction';
export const FETCHED_LEARNER_SATISFACTION = 'analytics/fetchedLearnerSatisfaction';
export const FETCH_AVERAGE_TIME_TO_SUBMIT = 'analytics/fetchAverageTimeToSubmit';
export const FETCHED_AVERAGE_TIME_TO_SUBMIT = 'analytics/fetchedAverageTimeToSubmit';

export const SET_LOADING_UPLOAD_ASSIGNEMNT = "uploadAssignemnt/setLoading";

export const CREATE_LEARNER_UPLOAD_ASSIGNMENT_FAILED = 'uploadAssignment/createLearnerUploadAssignmentFailed'

export const CREATE_LEARNER_UPLOAD_ASSIGNMENT_SUCCESS = "uploadAssignment/createLearnerUploadAssignmentSuccess"

export const CLEAR_LEARNER_UPLOAD_ASSIGNMNET = "uploadAssignment/clearState"
//Assignment
export const FETCH_ALL_ASSIGNMENTS = 'assignment/fetchAssignmentData';
export const FETCHED_ALL_ASSIGNMENTS = 'assignment/fetchedAssignmentData';
export const SET_LOADING_ASSIGNMENT = 'assignment/setLoading';
export const CLEAR_SUCCESS_ASSIGNMENT = 'assignment/clearSuccess';
export const FETCH_ASSIGNMENTS = 'assignment/fetchAssignments';
export const FETCHED_ASSIGNMENTS = 'assignment/fetchedAssignments';
export const FETCHED_LEARNER_ASSIGNMENT = 'assignment/fetchedLearnerAssignment';
export const FETCHED_LEARNER_ASSIGNMENTS = 'assignment/fetchedLearnerAssignments';
export const SET_SUCCESS = 'assignment/setSuccess';
export const SET_ERROR = 'assignment/setError';
export const PRE_BRIEF_CHECK = 'uploadAssignment/fetchPrebriefcheckData';
export const PRE_BRIEF_CHECKED = 'uploadAssignment/fetchedPrebriefcheckData'
export const MARK_ASSIGNMENT = 'uploadAssignment/markAssignment';
export const MARKED_ASSIGNMENT = 'uploadAssignment/markedAssignment';
export const GET_ASSIGNMENT = 'assignment/supervisorAssignment';
export const GET_ASSIGNMENTS = 'assignment/supervisorAssignments';
export const CLAIM_ASSIGNMENT = 'assignment/claimAssignment';

//Grading feedback
export const SET_LOADING_GRADINGFEEDBACK = "gradingFeedback/setGradingFeedbackLoading";
export const UPLOAD_SIGNATURE = "gradingFeedback/uploadSignature";
export const SET_LOADING_UPLOAD_SIGNATURE = "gradingFeedback/setSignatureLoading";
export const CREATE_UPLOAD_SIGNATURE_SUCCESS = "gradingFeedback/uploadSignatureSuccess";
export const CREATE_UPLOAD_SIGNATURE_FAILED = 'gradingFeedback/uploadSignatureFailed';
export const FETCH_SIGNATURE = "gradingFeedback/fetchSignature";
export const SET_LOADING_FETCH_SIGNATURE = "gradingFeedback/setSignatureLoading";
export const FETCHED_SIGNATURE = "gradingFeedback/fetchedSignature";
export const DOWNLOAD_ASSIGNMENTS = "gradingFeedback/downloadAssignment";
export const DOWNLOADS_ASSIGNMENTS = "gradingFeedback/downloadAssignments";
export const FETCHED_SIGNATURE_FAILED = "gradingFeedback/FailedfetchSignature";
export const GRADINGFEEDBACK_FAILED = "gradingFeedback/GradingFeedbackFailed";
export const FETCH_GRADING_ASSIGNMENT = 'gradingFeedback/fetchGradingFeedback';
export const SET_LOADING_GRADING_ASSIGNMENT = 'gradingFeedback/setGradingLoading';
export const FETCHED_GRADING_ASSIGNMENT = 'gradingFeedback/fetchedAssignmentsGrading';
export const CLEAR_GRADING_ASSIGNMENT_STATE = 'gradingFeedback/ClearState';
export const CLEAR_ASSIGNMENT_DETAIL = 'gradingFeedback/clearAssignmentDetail';
export const FETCH_GRADING_FEEDBACK_AI = 'gradingFeedback/fetchassignmentinfo';
export const FETCHED_GRADING_FEEDBACK_AI = 'gradingFeedback/fetchedassignmentinfo';
export const SET_LOADING_GRADING_AI_ASSIGNMENT = 'gradingFeedback/SetAIassignmentLoading';
export const AIASSIGNMENT_FAILED = 'gradingFeedback/AIassignmentFailed';
export const RESUBMIT_FEEDBACK = 'gradingFeedback/Resubmit';
export const RESUBMITED_FEEDBACK = 'gradingFeedback/ResubmittedFeedback';
export const FETCH_LEARNER_GRADING_ASSIGNMENT = 'gradingFeedback/fetchLearnerGradingFeedback';
export const SET_LOADING_LEARNER_GRADING_ASSIGNMENT = 'gradingFeedback/setLearnerGradingLoading';
export const FETCHED_LEARNER_GRADING_ASSIGNMENT = 'gradingFeedback/fetchedLearnerGradingFeedback';
export const LEARNER_GRADINGFEEDBACK_FAILED = 'gradingFeedback/LearnergradingFeedbackFailed';
export const UPDATE_FEEDBACK = 'gradingFeedback/SetUpdateFeedback';
export const UPDATED_FEEDBACK = 'gradingFeedback/SetUpdatedFeedback';
export const FETCH_GRADING_FEEDBACK_SECOND_MARKING_AI = 'gradingFeedback/fetchSecondFeedbackgrading';
export const FETCHED_GRADING_FEEDBACK_SECOND_MARKING_AI = 'gradingFeedback/fetchedSecondFeedbackgrading';
export const UPDATE_LEARNER_GRADING_ASSIGNMENT = 'gradingFeedback/UpdatelearnerAssignment';
export const UPDATED_LEARNER_GRADING_ASSIGNMENT = 'gradingFeedback/UpdatedlearnerAssignment';


//Settings
export const SET_LOADING_SETTING = 'setting/setLoading';
export const CLEAR_SUCCESS_SETTING = 'setting/clearSuccess';
export const GET_SETTING_NOTIFICATION = 'setting/notification';
export const GET_SETTING_NOTIFICATIONS = 'setting/notifications';
export const UPDATE_SETTING_NOTIFICATION = 'settings/updateNotification';
export const UPDATE_SETTING_NOTIFICATIONS = 'settings/updateNotifications';
export const SET_SETTING_ERROR = 'setting/setError';
export const SETTING_FETCHED_SIGNATURE = "setting/settingFetchedSignature";
export const SETTING_FETCHED_SIGNATURES = "setting/settingFetchedSignatures";
export const SETTING_UPLOAD_SIGNATURE = "setting/settingUploadSignature";
export const SETTING_UPLOAD_SIGNATURES = "setting/settingUploadSignatures";

import { AuthSliceState } from '@modules/types/sliceTypes';
import { Invite, FailedPayload, AuthPayload, UserType, ResetPasswordResponsePayload } from '@modules/types/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';


const initialState: AuthSliceState = {
    token: localStorage.getItem('edumrk_dv_ath') ? localStorage.getItem('edumrk_dv_ath') : null,
    user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') || "") : null,
    user_id: localStorage.getItem('edmrki_dkey')  || '',
    loading: false,
    userType: localStorage.getItem('usr_dftype') || 'learner',
    error: null,
    success: null,
    step: 4,
    passwordProfileUpdateState: localStorage.getItem('invitationStatus') || 'done',   //'password' || 'profileUpdate' || 'done' (Default) 
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        checkAuth: (state) => {
            state.loading = true;
        },
        userType: (state, action: PayloadAction<UserType>) => {
            state.userType = action.payload.state;
            localStorage.setItem('usr_dftype', action.payload.state);
            state.loading = false;
        },
        invited: (state, action: PayloadAction<Invite>) => {
            state.passwordProfileUpdateState = action.payload.state;
            localStorage.setItem('invitationStatus', action.payload.state);
            state.loading = false;
        },
        login: (state) => {
            state.loading = true;
            state.error = null;
        },
        loginSuccess: (state, action: PayloadAction<AuthPayload>) => {
            console.log(action)
            state.token = action.payload.data.token;
            state.user = action.payload.data.user;
            localStorage.setItem('edumrk_dv_ath', action.payload.data.token);
            localStorage.setItem('edmrki_dkey', action.payload.data.userId);
            localStorage.setItem('usr_dftype', action.payload.data.userType);
            localStorage.setItem("user", JSON.stringify(action.payload.data.user));
            state.user_id = action.payload.data.userId;
            state.loading = false;
            state.error = null;
        },
        logout: (state) => {
            state.token = null;
            state.user = null;
            localStorage.removeItem('edumrk_dv_ath');
            localStorage.removeItem('edmrki_dkey');
            localStorage.removeItem('usr_dftype');
            localStorage.removeItem("user");
            state.error = null;
        },
        loginFailed: (state, action: PayloadAction<FailedPayload>) => {
            state.error = action.payload.message;
            state.loading = false;
        },
        resetPassword: (state) => {
            state.loading = true;
            state.error = null;
            state.success = null;
        },
        resetPasswordSuccess: (state) => {
            state.success = 'Password Changed Successfully'
            state.passwordProfileUpdateState = 'profileUpdate';
            // localStorage.setItem('invitationStatus', 'profileUpdate')
            state.loading = false;
        },
        resetPasswordFailed: (state, action: PayloadAction<FailedPayload>) => {
            state.error = action.payload.message;
            state.loading = false;
        },
        setLoading: (state) => {
            state.loading = true;
            state.error = null;
        },
        sendVerifyOtpResetPassword: (state) => {
            state.loading = true;
        },
        sendVerifyOtpResetPasswordSuccess: (state, action: PayloadAction<ResetPasswordResponsePayload>) => {
            state.success = action.payload.message;
            state.step = action.payload.step
            state.loading = false;
        },
        clearSuccess: (state) => {
            state.success = null;
            state.loading = false;
        },
        clearForgetSteps: (state, action: PayloadAction<{ step: number }>) => {
            state.step = action.payload.step;
            state.success = null;
            state.error = null;
            state.loading = false;
        },
    },
});

export const {
    checkAuth,
    login,
    invited,
    userType,
    loginSuccess,
    logout,
    loginFailed,
    setLoading,
    resetPassword,
    resetPasswordFailed,
    resetPasswordSuccess,
    sendVerifyOtpResetPassword,
    sendVerifyOtpResetPasswordSuccess,
    clearSuccess,
    clearForgetSteps
} = authSlice.actions;

export default authSlice.reducer;

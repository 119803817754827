import Swal from 'sweetalert2';
import COLORS from '@/app/utils/Colors'; // Assuming COLORS is in your utils folder

export const confirmationModal = async (message?: string): Promise<boolean> => {
    const result = await Swal.fire({
        title: 'Are you sure?',
        text: message ? message : "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: COLORS.PRIMARY,
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, do it!',
        cancelButtonText: 'Cancel',
        allowOutsideClick: false
    });

    return result.isConfirmed; // Return true if confirmed, false if canceled
};

import React, { FC, InputHTMLAttributes, ReactNode, useState } from 'react';
import COLORS from '@utils/Colors';
import Asterisk from './Asterisk';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    leftIcon?: ReactNode,
    rightIcon?: ReactNode,
    placeholder: string,
    type?: string,
    className?: string,
    error: string | null | undefined;
    label?: string
    containerStyle?: React.CSSProperties;
    mandatory?: boolean;
    helpText?: string | null
}

const Input: FC<Props> = ({ containerStyle, label, error, mandatory, type, leftIcon, rightIcon, placeholder, className, helpText, ...props }) => {

    const [focus, setFocus] = useState(false);

    const inputStyle: React.CSSProperties = {
        backgroundColor: focus ? COLORS.WHITE : COLORS.INPUT.BG,
        height: '54px',
        border: 'none',
        outline: 'none',
        boxShadow: 'none',
        padding: leftIcon ? '0px' : '16px',
        ...(props.disabled && {
            backgroundColor: COLORS.INPUT.DISABLED,
            cursor: 'not-allowed',
            opacity: 0.6                                // O
        }),
    };

    const groupStyle: React.CSSProperties = {
        borderWidth: 1,
        borderColor: error ? COLORS.ERROR : COLORS.BORDER.GRAY,
        borderStyle: 'solid',
        borderRadius: '.5rem',
        ...(props.disabled && {
            borderColor: COLORS.BORDER.DISABLEDINPUT
        })
    };

    const iconStyle: React.CSSProperties = {
        backgroundColor: focus ? COLORS.WHITE : COLORS.INPUT.BG,
        border: 'none',
        cursor: 'pointer'
    };

    const errorStyle: React.CSSProperties = {
        color: COLORS.ERROR,
        fontSize: '14px',
        fontStyle: 'italic',
        lineHeight: '14px',
        marginTop: '5px'
    };

    const labelStyle: React.CSSProperties = {
        fontSize: '14px',
        color: COLORS.BLACK,
        lineHeight: '20px'
    }

    return (
        <div className={`${className} `}>
            {label ? <h6 style={labelStyle}>{label} {mandatory && <Asterisk />}</h6> : null}
            <div style={groupStyle} className={`input-group`}>
                {leftIcon &&
                    <span style={iconStyle} className="input-group-text">
                        {leftIcon}
                    </span>
                }
                <input
                    data-testid={'input-field'}
                    style={inputStyle}
                    type={type || "text"}
                    className="form-control"
                    placeholder={placeholder}
                    onFocus={() => setFocus(true)}
                    onBlur={() => setFocus(false)}
                    {...props}
                />
                {rightIcon &&
                    <span style={iconStyle} className="input-group-text">
                        {rightIcon}
                    </span>}
            </div>
            {<div id="help-text" className="form-text">{helpText}</div>}
            {error && <span style={errorStyle}>{error}</span>}
        </div>
    );
};

export default Input;
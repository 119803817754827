import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import LayoutSplashScreen from '@components/LayoutSplashScreen';


const App = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Outlet />
    </Suspense>
  )
}

export default App;

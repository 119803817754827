import { Col, Row } from "react-bootstrap";

type marker = {
  avatar?: string;
  name?: string;
  levels?: number[];
};

interface MarkerCardProps {
  markers?: marker[];
}

const MarkerCard: React.FC<MarkerCardProps> = ({ markers }) => {
  return (
    <>
      {markers?.map((item, index) => (
        <Row
          key={index}
          style={{
            border: "1px solid #e0e0e0", // Light border for the card
            borderRadius: "8px",         // Rounded corners
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Subtle shadow
            padding: "15px",
            marginBottom: "10px",
            alignItems: "center", // Vertically center items
            backgroundColor: "#fff",  // White background
          }}
        >
          <Col xs={3}>
            {/* Avatar Image */}
            <img
              src={item.avatar}
              alt="Avatar"
              style={{
                width: "60px",
                height: "60px",
                borderRadius: "50%",
                objectFit: "cover" // Ensure image covers the entire area
              }}
            />
          </Col>
          <Col>
            {/* Name and Levels */}
            <div style={{lineHeight:"25px"}}>
              <strong className="fs-6 fw-semibold">{item.name}</strong>
              <div className="text-muted">Level {item.levels?.join(", ")}</div>
            </div>
          </Col>
        </Row>
      ))}
    </>
  );
};

export default MarkerCard;

import React, { FC, useCallback, useEffect, useState } from 'react';
import Input from '@components/Input';
import Button from '@components/Button';
import './Login.module.style.css';
import COLORS from '@utils/Colors';
import LogInBanner from '@components/LogInBanner';
import IMAGES from '@utils/Images';
import ICONS from '@utils/Icons';
import { useFormik } from 'formik';
import * as Validation from '@modules/validations/validations';
import { useDispatch } from 'react-redux';
import { FETCH_LOGIN, HANDLE_INVITE, HANDLE_USERTYPE, SET_LOADING } from '@/app/redux/actionTypes';
import { useSelector } from 'react-redux';
import { RootState } from '@/app/redux/store';
import { useLocation, useNavigate } from 'react-router-dom';
import ChangePassword from '@components/ChangePassword';
import UpdateProfileModal from '@/app/components/UpdateProfileModal';
import Loader from '@components/Loading';
import { useParams } from 'react-router-dom';
import ErrorText from '@/app/components/ErrorText';
import { IoMdArrowRoundBack } from "react-icons/io";
import { getAuthMessages } from '@/app/modules/helpers/FormikHelper';

interface Props { }

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const Login: FC<Props> = () => {

    const query = useQuery();

    const { userType } = useParams();

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const invite = query.get('invite');

    const [secured, setSecured] = useState(true);

    const [changePassword, setChangePassword] = useState(false);

    const [updateProfile, setUpdateProfile] = useState(false);

    const { token, loading, error, passwordProfileUpdateState } = useSelector((state: RootState) => state.auth)

    const { title, subheading } = getAuthMessages(invite, userType);

    const formik = useFormik({
        initialValues: { email: '', password: '' },
        onSubmit: (value) => { dispatch({ type: FETCH_LOGIN, payload: { email: value.email, password: value.password, userType: userType } }) },
        validationSchema: Validation.loginSchema
    })

    useEffect(() => {
        dispatch({ type: HANDLE_USERTYPE, payload: { state: userType } });
    }, [userType])

    //  Hnadling Rerenders
    const toggleUpdateProfile = (newState: boolean) => {
        setUpdateProfile((prevState) => {
            if (prevState !== newState) {
                return newState;
            }
            return prevState;
        });
    };

    useEffect(() => {
        if (passwordProfileUpdateState === 'profileUpdate' && invite) return
        if (invite) {
            dispatch({ type: HANDLE_INVITE, payload: { state: 'password' } });
        } else {
            dispatch({ type: HANDLE_INVITE, payload: { state: 'done' } });
        }
    }, [invite]);

    useEffect(() => {
        if (passwordProfileUpdateState === 'password' && token) {
            setChangePassword(true);
        } else if (passwordProfileUpdateState === 'profileUpdate' && token) {
            toggleUpdateProfile(true);
        }
    }, [token, passwordProfileUpdateState]);


    const handleCloseUpdateProfile = useCallback(() => {
        dispatch({ type: SET_LOADING })
        setTimeout(() => {
            dispatch({ type: HANDLE_INVITE, payload: { state: 'done' } })
            toggleUpdateProfile(false);
        }, 2000);
    }, []);

    const handleCloseChangePassword = () => {
        if (passwordProfileUpdateState === 'password') {
            dispatch({ type: HANDLE_INVITE, payload: { state: 'profileUpdate' } })
            setChangePassword(false);
        }
    }





    return (
        <>
            {loading && <Loader />}
            < div className="vh-100 vh-md-100 d-flex flex-column flex-md-row overflow-y-auto" >
                {invite ? null : <div onClick={() => navigate(-1)} style={{ position: 'absolute', left: 24, top: 16 }} className='d-flex py-3 px-2 align-items-center gap-1'>
                    <IoMdArrowRoundBack className='fw-bold' color={COLORS.PRIMARY} fontSize={24} /> <span className='fw-bold'>Back</span>
                </div>}
                <div className="d-flex flex-column justify-content-center align-items-center bg-white w-100 h-100 w-md-50 p-3 p-lg-5">
                    <img alt='logo' src={IMAGES.LOGIN_LOGO} className="login-logo" />
                    <div className='login-form-container login_form mt-5 shadow bordered p-3 rounded-3 p-md-5'>
                        <h1>{title}</h1>
                        <h6 className='mt-3'>{subheading}</h6>
                        <div className="mt-5">
                            <Input
                                type='email'
                                name="email"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.email}
                                placeholder={'Email Address'}
                                leftIcon={<img alt='email' src={ICONS.INPUT.EMAIL} style={{ height: 20, width: 18 }} />}
                                error={formik.errors.email && formik.touched.email ? formik.errors.email : null}
                            />
                            <Input
                                name="password"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.password}
                                type={secured ? 'password' : 'text'}
                                className={'mt-4'}
                                placeholder={'Password'}
                                leftIcon={<img alt='password' src={ICONS.INPUT.PASSWORD} style={{ height: 20, width: 18 }} />}
                                rightIcon={<i onClick={() => setSecured(!secured)} className={`fa ${secured ? 'fa-eye-slash' : 'fa-eye'} eye-icon`} id="clearIcon"></i>}
                                error={formik.errors.password && formik.touched.password ? formik.errors.password : null}
                            />
                            {error && <ErrorText error={error} />}
                        </div>
                        <Button
                            label={'Login'}
                            className={'mt-4 loginButton w-100'}
                            onClick={formik.handleSubmit} />
                        {invite ? <h6 className='mt-4 login-help-text'>
                            The option to reset your password will be available after you log in
                        </h6>
                            :
                            <div onClick={() => navigate('/forget-password')} role='button' className='d-flex flex-column align-items-end'>
                                <span className='mt-4 login-help-text text-primary '>
                                    Forgot your password?
                                </span>
                            </div>
                        }
                    </div>
                    <p className='text-center help-section-text'>
                        Need Help Visit Our&nbsp;
                        <a className='text-decoration-none' href="https://www.example.com/helpcenter">Help Center&nbsp;</a>
                        or contact <a href="mailto:support@edumark.com" >support@edumark.ai</a>.
                    </p>
                </div>
                <LogInBanner backgroundColor={COLORS.LOGIN.LIGHTBLUE} type={userType ? userType : 'educator'} />
                <ChangePassword
                    onClickSkip={handleCloseChangePassword}
                    visible={changePassword}
                    handleClose={handleCloseChangePassword}
                    updatePassword={function (v: { password: string; confirmPassword: string; }): void {
                        throw new Error('Function not implemented.');
                    }} />

                {/* UpdateProfileModal */}
                <UpdateProfileModal
                    onClickSkip={handleCloseUpdateProfile}
                    visible={updateProfile}
                    handleClose={handleCloseUpdateProfile}
                />
            </div >
        </>
    );
}

export default Login;

import React from 'react';
import COLORS from '../utils/Colors';


const Asterisk: React.FC = () => {
    return (
        <span style={{ color: COLORS.ERROR }} className="asterisk">*</span>
    );
}

export default Asterisk;
// src/app/rootReducer.js
import { combineReducers } from '@reduxjs/toolkit';
import authReducer from './slices/auth';
import userReducer from './slices/user';
import cohortReducer from './slices/cohort';
import analyticsReducer from './slices/analytics';
import uploadAssignmentReducer from './slices/uploadAssignment';
import assignmentReducer from './slices/assignment';
import gradingFeedbackReducer from './slices/gradingFeedback';
import settingReducer from './slices/setting';
import homeReducer from './slices/homeSlice';


const rootReducer = combineReducers({
    auth: authReducer,
    user: userReducer,
    cohort: cohortReducer,
    assignment: assignmentReducer,
    gradingFeedback: gradingFeedbackReducer,
    analytics: analyticsReducer,
    uploadAssignment: uploadAssignmentReducer,
    setting: settingReducer,
    home: homeReducer
});

export default rootReducer;

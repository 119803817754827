import Layout from "@/app/components/Layout";
import SettingAccount from "@/app/components/SettingAccount/SettingAccount";
import SettingNotification from "@/app/components/SettingNotification/SettingNotification";
import { RootState } from "@/app/redux/store";
import Button from "@components/Button";
import SettingPreferences from "@/app/components/SettingPreferences/SettingPreferences";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import "./Settings.module.style.css";

const Settings: React.FC = () => {
  const { userType } = useSelector((state: RootState) => state.auth);
  const role = userType === "learner" || userType === "educator" || userType === "supervisor" ? userType : "learner";
  const [activeTab, setActiveTab] = useState("notifications");

  const handleTabChange = (tab: any) => {
    setActiveTab(tab);
  };

  return (
    <Layout>
      <div className="setting-container m-4 vh-100">
        <div className="sticky-top z-3 bg-white">
          <div className="heading-container">
            <span className="heading">Settings</span>
          </div>
          {(role === "learner" || role === "educator" || role === "supervisor" || role === "admin") && (
            <div>
              <div className="d-flex flex-lg-row flex-column justify-content-between mt-4 align-items-lg-center">
                <div className="buttons d-flex flex-lg-row flex-column gap-lg-4 gap-2 mt-lg-0 mt-2">
                  <Button
                    label={"Notifications"}
                    className={`tab-btn ${activeTab === "notifications" ? "active-btn" : ""
                      }`}
                    onClick={() => handleTabChange("notifications")}
                    isIcon={true}
                  />
                  <Button
                    label={"Account"}
                    className={`tab-btn ${activeTab === "account" ? "active-btn" : ""
                      }`}
                    onClick={() => handleTabChange("account")}
                    isIcon={true}
                  />
                  { (role === "educator"|| role=== "supervisor" ) && 
                    <Button
                    label={"Preferences"}
                    className={`tab-btn ${
                      activeTab === "preferences" ? "active-btn" : ""
                    }`}
                    onClick={() => handleTabChange("preferences")}
                    isIcon={true}
                  />
                  }
                </div>
              </div>
              <hr />
              {activeTab === "notifications" && <SettingNotification />}
              {activeTab === "preferences" && <SettingPreferences/>}
              {activeTab === "account" && <SettingAccount />}
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Settings;

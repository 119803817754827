import { AxiosError } from 'axios';

export const getErrorMessage = (error: unknown): string => {
    let errorMessage = 'An unknown error occurred';

    if ((error instanceof AxiosError)) {
        // Handle the error message based on the error type
        if (error.response) {
            // Extract message from server response
            errorMessage = error.response.data.message || errorMessage;
            console.log('Error Response:', error.response.data);
        } else if (error.request) {
            errorMessage = 'No response from server';
            console.log('No response received:', error.request);
        }
    } else if (error instanceof Error) {
        // Fallback to the error message
        errorMessage = error.message;
        console.log('Error:', error.message);
    }

    return errorMessage;
};

import IMAGES from "@utils/Images";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Box, Drawer, List } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";

type SidebarProps = {
  isOpen: boolean;
  onToggleSidebar: () => void;
};
const Sidebar: React.FC<SidebarProps> = ({ isOpen, onToggleSidebar }) => {
  const location = useLocation();
  type itemType = {
    icon: string;
    activeIcon: string;
    name: string;
    route: string;
    childRoutes?: string[];
  };

  type menuItemsMapType = {
    [key: string]: itemType[];
  };
  const itemStyle: React.CSSProperties = {
    fontSize: "14px",
    fontWeight: "500",
    color: "#1C1D22",
    textDecoration: "none",
  };

  const learnerMenuitems = [
    {
      icon: IMAGES.HOME,
      activeIcon: IMAGES.ACTIVE_HOME,
      name: "Home",
      route: "/dashboard",
    },
    {
      icon: IMAGES.REPORT,
      activeIcon: IMAGES.ACTIVE_REPORT,
      name: "Assignments",
      route: "/assignment",
    },
    {
      icon: IMAGES.FEEDBACK,
      activeIcon: IMAGES.ACTIVE_FEEDBACK,
      name: "Grading & Feedbacks",
      route: "/grading-feedback",
    },
    //Need to remove this options
    // {
    //   icon: IMAGES.PROGRESS,
    //   activeIcon: "",
    //   name: "Progress",
    //   route: "/",
    // },
    // {
    //   icon: IMAGES.FOLDER,
    //   activeIcon: "",
    //   name: "Resources",
    //   route: "/",
    // },
    {
      icon: IMAGES.SETTING,
      activeIcon: IMAGES.ACTIVE_SETTING,
      name: "Settings",
      route: "/setting",
    },
  ];

  const educatorMenuitems = [
    {
      icon: IMAGES.HOME,
      activeIcon: IMAGES.ACTIVE_HOME,
      name: "Home",
      route: "/dashboard",
    },
    {
      icon: IMAGES.REPORT,
      activeIcon: IMAGES.ACTIVE_REPORT,
      name: "Assignments",
      route: "/assignment",
    },
    {
      icon: IMAGES.FEEDBACK,
      activeIcon: IMAGES.ACTIVE_FEEDBACK,
      name: "Grading & Feedbacks",
      route: "/grading-feedback",
      childRoutes: ["/grading-feedback/:level"],
    },
    {
      icon: IMAGES.COHORT,
      activeIcon: IMAGES.ACTIVE_COHORT,
      name: "Cohort",
      route: "/cohorts",
    },
    //Need to remove this option
    // {
    //   icon: IMAGES.ORDER_REPORT,
    //   activeIcon: "",
    //   name: "Reports",
    //   route: "/",
    // },
    {
      icon: IMAGES.SETTING,
      activeIcon: IMAGES.ACTIVE_SETTING,
      name: "Settings",
      route: "/setting",
    },
  ];
  const supervisorMenuitems = [
    {
      icon: IMAGES.HOME,
      activeIcon: IMAGES.ACTIVE_HOME,
      name: "Home",
      route: "/dashboard",
    },
    {
      icon: IMAGES.REPORT,
      activeIcon: IMAGES.ACTIVE_REPORT,
      name: "Assignments",
      route: "/assignment",
    },
    //Need to remove this option
    // {
    //   icon: IMAGES.FFEDBACK,
    //   activeIcon: "",
    //   name: "Courses",
    //   route: "/",
    // },
    {
      icon: IMAGES.COHORT,
      activeIcon: IMAGES.ACTIVE_COHORT,
      name: "Users",
      route: "/users",
    },
    {
      icon: IMAGES.ORDER_REPORT,
      activeIcon: IMAGES.ACTIVE_ORDER_REPORT,
      name: "Analytics",
      route: "/analytics",
    },
    {
      icon: IMAGES.SETTING,
      activeIcon: IMAGES.ACTIVE_SETTING,
      name: "Settings",
      route: "/setting",
    },
  ];

  const adminMenuitems = [
    {
      icon: IMAGES.HOME,
      activeIcon: IMAGES.ACTIVE_HOME,
      name: "Home",
      route: "/dashboard",
    },
    {
      icon: IMAGES.REPORT,
      activeIcon: IMAGES.ACTIVE_REPORT,
      name: "Assignments",
      route: "/assignment",
    },
    // {
    //   icon: IMAGES.FFEDBACK,
    //   activeIcon: "",
    //   name: "Courses",
    //   route: "/",
    // },
    {
      icon: IMAGES.COHORT,
      activeIcon: IMAGES.ACTIVE_COHORT,
      name: "Users",
      route: "/users",
    },
    {
      icon: IMAGES.ORDER_REPORT,
      activeIcon: IMAGES.ACTIVE_ORDER_REPORT,
      name: "Analytics",
      route: "/analytics",
    },
    {
      icon: IMAGES.SETTING,
      activeIcon: IMAGES.ACTIVE_SETTING,
      name: "Settings",
      route: "/setting",
    },
  ];
  const menuItemsMap: menuItemsMapType = {
    learner: learnerMenuitems,
    educator: educatorMenuitems,
    supervisor: supervisorMenuitems,
    admin: adminMenuitems
  };
  const handleLogout = () => {
    localStorage.clear();
    window.location.href = '/login';
  }
  const { userType } = useSelector((state: RootState) => state.auth)
  const items = userType ? menuItemsMap[userType] || [] : [];

  return (
    <>
      <div
        className={`sidebar col-md-3 col-lg-2 d-md-block d-none bg-white border-end d-flex flex-column vh-100`}
      >
        <div className="d-flex flex-column mt-4 gap-2 mx-4 ">
          {items?.map((item: itemType) => (
            <div
              className={`d-flex align-items-center gap-3 px-lg-3 menuitem py-2 ${(location.pathname === item.route || item.childRoutes?.includes(location.pathname)) && "active-menuitem"
                }`}
              key={item.name}
            >
              <img src={location.pathname === item.route ? item.activeIcon : item.icon} className="h-5 w-5 active-icon" alt="Logo" />
              <Link to={item.route} style={itemStyle} className="menuitem">
                {item.name}
              </Link>
            </div>
          ))}
        </div>
        <div className="contact-section fixed-bottom mx-4" style={{ width: '200px' }}>
          <div className="d-flex flex-column gap-2 mb-5">
            <div
              className={`d-flex align-items-center gap-3 px-lg-3  py-2 
                }`}
              key={"contact"}
            >
              <img src={IMAGES.CONTACT} className="h-5 w-5" alt="Logo" />
              <Link to={"mailto:contact@edumark.ai"} style={itemStyle}>
                {"Contact Support"}
              </Link>
            </div>
            <div
              className={`d-flex align-items-center gap-3 px-lg-3  py-2 
                }`}
              key={"logout"}
            >
              <img src={IMAGES.LOGOUT} className="h-5 w-5" alt="Logo" />
              <Link to={"/login"} style={itemStyle} className="text-danger" onClick={handleLogout}>
                {"Logout"}
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Drawer open={isOpen} onClose={onToggleSidebar}>
        <Box sx={{ width: 250 }} role="presentation" onClick={onToggleSidebar}>
          <List>
            <div className="d-flex flex-column mt-4 gap-2 px-4">
              {items?.map((item: itemType) => (
                <div
                  className={`d-flex align-items-center gap-3 py-2`}
                  key={item.name}
                >
                  <img src={item.icon} className="h-5 w-5" alt="Logo" />
                  <Link to={item.route} style={itemStyle}>
                    {item.name}
                  </Link>
                </div>
              ))}
              <div
                className={`d-flex gap-3  py-2 
                }`}
                key={"contact"}
              >
                <img src={IMAGES.CONTACT} className="h-5 w-5" alt="Logo" />
                <Link to={"mailto:contact@edumark.ai"} style={itemStyle}>
                  {"Contact Support"}
                </Link>
              </div>
              <div
                className={`d-flex gap-3  py-2 
                }`}
                key={"logout"}
              >
                <img src={IMAGES.LOGOUT} className="h-5 w-5" alt="Logo" />
                <Link to={"/logout"} style={itemStyle} className="text-danger" onClick={handleLogout}>
                  {"Logout"}
                </Link>
              </div>
            </div>
          </List>
        </Box>
      </Drawer>
    </>
  );
};

export default Sidebar;

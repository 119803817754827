import React, { useEffect, useState } from 'react';
import { DialogTitle, DialogContent, Checkbox, Typography, Divider, Box, Button as MuiButton, IconButton } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import CustomTimePickerInput from '@components/TimeField';
import { daysOfWeek } from '@modules/static/FormArray';
import COLORS from '@utils/Colors';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { _USER_CLEAR_SUCCESS, UPDATE_USER_SCHEDULE } from '../redux/actionTypes';
import { DayScheduleArrayItem } from '../modules/types/types';
import { toIsoString } from '../modules/helpers/FormikHelper';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { scheduleValidationSchema } from '../modules/validations/validations';

type Props = {
    visible: boolean;
    handleClose: () => void;
    onSubmit: () => void;
    schedules: DayScheduleArrayItem[]
};



const WorkingTimeModal: React.FC<Props> = ({ visible, handleClose, onSubmit, schedules }) => {

    const dispatch = useDispatch()


    const [initialValues, setInitialValues] = useState({});

    const { user_id } = useSelector((state: RootState) => state.auth)

    const { schedulesUpdated } = useSelector((state: RootState) => state.user)

    useEffect(() => {
        if (schedules && Object.keys(schedules).length > 0) {
            const values = Object.keys(schedules).reduce((acc, dayName) => {
                const day = schedules[dayName];
                acc[day.dayName] = {
                    status: day.status,
                    timeFrom: dayjs(day.timeFrom),
                    timeTo: dayjs(day.timeTo),
                };
                return acc;
            }, {} as any);
            setInitialValues(values);
        } else {
            // Create dummy data for all days of the week
            const dummyValues = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].reduce((acc, dayName) => {
                acc[dayName] = {
                    status: false,
                    timeFrom: null,
                    timeTo: null,
                };
                return acc;
            }, {} as any);
            setInitialValues(dummyValues);
        }
    }, [schedules]);

    const [show, setShow] = useState<boolean>(false);

    useEffect(() => {
        setShow(visible);
    }, [visible]);


    useEffect(() => {
        if (schedulesUpdated) {
            Swal.fire({
                title: 'Success',
                text: schedulesUpdated,
                icon: 'success',
                confirmButtonColor: COLORS.PRIMARY,
                confirmButtonText: 'OK',
                allowOutsideClick: false
            }).then((result) => {
                if (result?.isConfirmed) {
                    onSubmit?.()
                    dispatch({ type: _USER_CLEAR_SUCCESS })
                }
            })
        }
    }, [dispatch, onSubmit, schedulesUpdated])


    return (
        <Modal size='lg' centered backdrop="static" show={show} onHide={handleClose}>
            <DialogTitle sx={{ p: 4, pb: 0 }}>
                <IconButton
                    edge="end"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                    sx={{ position: 'absolute', right: 24, top: 8 }} // Position the icon
                >
                    <i className="fa-solid fa-xmark"></i>
                </IconButton>
                <Typography
                    variant="h4"
                    sx={{ fontWeight: 700, color: COLORS.BLACK }}
                >
                    New Schedule
                </Typography>
                <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: 200, mt: 1, color: COLORS.BLACK }}
                >
                    {JSON.stringify(schedules)}
                    Choose your working days and set daily capacity
                </Typography>
            </DialogTitle>
            <DialogContent sx={{ mt: 5, p: { md: 6, sm: 5, xs: 2 } }}>
                {Object.keys(initialValues).length > 0 &&
                    <Formik
                        initialValues={initialValues}
                        onSubmit={(values: any) => {
                            console.log(values, 'values')
                            const newSchedules = toIsoString(values)
                            dispatch({ type: UPDATE_USER_SCHEDULE, payload: { newSchedules, userId: user_id } })
                        }}
                        validationSchema={scheduleValidationSchema}
                        enableReinitialize
                    >
                        {({ values, setFieldValue, errors, touched }) => (
                            <Form>
                                {daysOfWeek.map((day, index) => (
                                    <div key={day.dayName}>
                                        <Box mb={{ md: 3, sm: 3, xs: 1 }} marginRight={{ md: 10 }} display="flex" flexDirection={{ xs: 'column', sm: 'row', md: 'row' }}>
                                            <Box display={"flex"} alignItems="center" flex={"0.6"} mr={{ md: 5 }}>
                                                <Checkbox
                                                    sx={{
                                                        width: '18px', height: '18px',
                                                        color: COLORS.BORDER.GRAY,
                                                        '&.Mui-checked': {
                                                            color: COLORS.PRIMARY,
                                                        },
                                                    }}
                                                    checked={values[day.dayName]?.status || false}
                                                    onChange={(e) => {
                                                        const isChecked = e.target.checked;
                                                        setFieldValue(`${day.dayName}.status`, isChecked);
                                                        // setFieldValue(`${day.dayName}.disabled`, !isChecked, false);
                                                        if (!isChecked) {
                                                            // If the checkbox is unchecked, clear the timeFrom and timeTo values
                                                            setFieldValue(`${day.dayName}.timeFrom`, null);
                                                            setFieldValue(`${day.dayName}.timeTo`, null);
                                                        }
                                                    }}
                                                />
                                                <Typography
                                                    variant="body1"
                                                    sx={{ display: 'inline', fontWeight: 400, color: COLORS.BLACK, ml: { md: 5, sm: 2, xs: 1 } }}
                                                >
                                                    {day.label}
                                                </Typography>
                                            </Box>
                                            <Box sx={{ cursor: 'pointer', }} display={"flex"} alignItems="center" mt={{ xs: 2, sm: 0, md: 0 }} flex="1" justifyContent={{ sm: "space-between", md: "space-between" }}>
                                                <Field
                                                    value={values[day.dayName]?.timeFrom || null}
                                                    name={`${day.dayName}.timeFrom`}
                                                    component={CustomTimePickerInput}
                                                    onChange={(newValue: any) => setFieldValue(`${day.dayName}.timeFrom`, newValue)}
                                                    fullWidth
                                                />
                                                <Typography variant="body2" sx={{ mx: { md: 5, sm: 5, xs: 2 } }}>
                                                    to
                                                </Typography>
                                                <Field
                                                    value={values[day.dayName]?.timeTo || null}
                                                    name={`${day.dayName}.timeTo`}
                                                    component={CustomTimePickerInput}
                                                    onChange={(newValue: any) => setFieldValue(`${day.dayName}.timeTo`, newValue)}
                                                    fullWidth
                                                />
                                            </Box>

                                        </Box>
                                        {touched[day.dayName] && errors[day.dayName] ?
                                            <Typography variant="body2" sx={{ color: 'red', mt: 0 }}>
                                                {'Please Select Time to Continue'}
                                            </Typography>
                                            : null}
                                        {index < daysOfWeek.length - 1 && <Divider sx={{ backgroundColor: COLORS.GRAY, my: 2 }} />}

                                    </div>
                                ))}
                                <Box display="flex" justifyContent="center" mt={5}>
                                    <MuiButton variant="contained" sx={{ height: '52px', textTransform: 'capitalize', fontStyle: 'normal', backgroundColor: COLORS.PRIMARY }} type="submit">
                                        Update Schedule
                                    </MuiButton>
                                </Box>
                            </Form>
                        )}
                    </Formik>}
            </DialogContent>
        </Modal >
    );
};

export default WorkingTimeModal;

import { call, put, takeLatest } from 'redux-saga/effects';
import { FETCH_HOME_USERS } from '../actionTypes';
import {
    fetchedEducatorsForHome,
    fetchedLearnersForHome,
    fetchHomeUsersFailed,
} from '../slices/homeSlice';
import { get_home_users } from '@/app/api/Api'; // Adjust this import based on your API utility
import { HomeUserPayload } from '@/app/modules/types/types';
import { getErrorMessage } from '@/app/modules/helpers/ErrorHelper';

// Saga to handle fetching home users
function* handleHomeUsers(action: { type: string; payload: { page: string; limit: string; userType: string; sortBy: string } }) {
    try {
        // Type `response` as `HomeUserPayload` instead of `GetUserDetailsPayload`
        if (Array.isArray(action.payload.userType)) {
            for (let index = 0; index < action.payload.userType.length; index++) {
                
                const userType = action.payload.userType[index];
                const response: HomeUserPayload = yield call(get_home_users, {
                    page: action.payload.page,
                    limit: action.payload.limit,
                    userType: userType,
                });
        
                console.log({ data: response.data, action });

                if (userType === 'educator') {
                    yield put(fetchedEducatorsForHome({ data: response.data, pagination : response.pagination } as HomeUserPayload));
                } 
                else if (userType === 'learner') {
                    yield put(fetchedLearnersForHome({ data: response.data, pagination : response.pagination } as HomeUserPayload));
                }
            }
        }
    } catch (error) {
        const errorMessage = getErrorMessage(error);
        yield put(fetchHomeUsersFailed({ message: errorMessage }));
    }
}

// Root saga for homeSlice
function* homeSaga() {
    yield takeLatest(FETCH_HOME_USERS, handleHomeUsers);
}

export default homeSaga;

import React, { Dispatch, SetStateAction, useState } from "react";
import { useParams } from "react-router-dom";

interface Question {
  task_id?: string;
  description: string;
}

interface TaskEvaluation {
  task_id: string;
  description: string;
  answer: string;
  marks: number;
  total_marks: number;
  feedback: string;
}

interface QuestionProps {
  questions: Question[] | TaskEvaluation[];
  heading?: string;
  setTask?: Dispatch<SetStateAction<any>>;
}

const Question: React.FC<QuestionProps> = ({ questions, heading,setTask }) => {
  console.log("🚀 ~ heading:", heading)
  const [active, setActive] = useState<number>(0);
  const { level } = useParams<string>();
  const levels = ["Level 3", "Level 5"];
  return (
    <div className="question-comp">
      <div className="card-heading">{heading ? heading : "Task"}</div>
      <div className="questions d-flex flex-column">
        {questions &&
          questions?.map((item, index) => (
            <>
              <div
                className={`d-flex flex-column gap-1 question-card p-3 justify-content-center ${
                  active === index && "active-question-card"
                }`}
                onClick={() => {
                  setActive(index);
                  if(level && !levels.includes(level)) {
                      setTask && setTask(item.task_id);
                  } else {
                    setTask && setTask(item.assessmentCriteria)
                  }
                }}
              >
                {level && levels.includes(level) && item.assessmentCriteria &&  <label>{item.assessmentCriteria}</label>}
                {level && !levels.includes(level) && heading === "Learning Objective" && <label>{item.learning_objective}</label>}
                {level && !levels.includes(level) && heading === "Questions" && <label>{item.question}</label>}
                {item.description && <label>{item.description}</label>}
              </div>
              <hr
                className=""
                style={{
                  height: "1px",
                  margin: "0 15px",
                  background: "#E4E4E4",
                }}
              />
            </>
          ))}
      </div>
    </div>
  );
};

export default Question;

import React, { ReactNode, useState } from 'react';
import { Dropdown, ButtonGroup } from 'react-bootstrap';
import styles from './TableAction.module.css';
import { useSelector } from 'react-redux';
import { RootState } from '@/app/redux/store';

interface FilterOption {
    label: string;
    value: string | number;
}

interface MultipleFilterOption {
    [key: string]: FilterOption[];
}

interface FilterDropdownProps {
    options: FilterOption[] | MultipleFilterOption[] | any;
    onFilterSelect?: (value: (string | number)[]) => void;
    onSortSelect?: (value: string | number) => void;
    placeholder?: ReactNode;
    type: string;
    isMultiple?: boolean;
    headings?: string[];
}

const FilterDropdown: React.FC<FilterDropdownProps> = ({
    options,
    onFilterSelect,
    placeholder,
    type,
    isMultiple,
    headings,
    onSortSelect
}) => {
    const [selectedFilters, setSelectedFilters] = useState<(string | number)[]>([]);
    const [selectedSortedData, setSelectedSortedData] = useState<string | number | null>(
        null
    );

    const handleFilterSelect = (heading: string, value: string | number) => {
        setSelectedFilters(prev => {
            const newSelection = { ...prev };

            if (!newSelection[heading]) {
                newSelection[heading] = [];
            }

            const isSelected = newSelection[heading].includes(value);

            if (isSelected) {
                newSelection[heading] = newSelection[heading].filter(item => item !== value);
            } else {
                newSelection[heading].push(value);
            }

            if (onFilterSelect) {
                onFilterSelect(newSelection);
            }

            return newSelection;
        });
    };

    const handleSorting = (value: string | number) => {
        setSelectedSortedData(value);
        if (onSortSelect) {
            onSortSelect(value);
        }
    };

    const handleClearFilter = () => {
        setSelectedFilters([]);
        onFilterSelect && onFilterSelect([]);
        onSortSelect && onSortSelect("");
    };

    return (
        <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle
                className={`p-0 ${styles.customDropdownToggle}`}
                style={{ background: "none", border: "none", padding: "none" }}
            >
                {placeholder}
            </Dropdown.Toggle>
            <Dropdown.Menu
                className={`${styles.usersDropdown}`}
                style={{ zIndex: 1050 }}
                align="end"
            >
                {type === "filter" ? (
                    <>
                        {isMultiple ? (
                            <>
                                <p className={`p-4 pb-0 ${styles.filterText}`}>Filter By</p>
                                {headings?.map((heading) => {
                                    const subOptions = options.find(
                                        (optionGroup) => optionGroup[heading]
                                    );

                                    return (
                                        <div key={heading}>
                                            <p className="p-4 pb-0">{heading}</p>
                                            {subOptions &&
                                                subOptions[heading].map((option, index) => (
                                                    <Dropdown.Item
                                                        key={index}
                                                        className={styles.dropdownItem}
                                                        onClick={() => handleFilterSelect(heading, option.value)}
                                                        active={selectedFilters[heading]?.includes(option.value)}
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            checked={selectedFilters[heading]?.includes(option.value) || false}
                                                            onChange={() => handleFilterSelect(heading, option.value)}
                                                            className={styles.checkbox}
                                                        />
                                                        {option.label}
                                                    </Dropdown.Item>
                                                ))}
                                        </div>
                                    );
                                })}
                            </>
                        ) : (
                            <>
                                <p className={`p-4 pb-0 ${styles.filterText}`}>Filter By</p>
                                <p className="p-4 pb-0">Result</p>
                                {options.map((option, index) => (
                                    <Dropdown.Item
                                        key={index}
                                        className={styles.dropdownItem}
                                        onClick={() => handleFilterSelect("Result", option.value)}
                                        active={selectedFilters["Result"]?.includes(option.value)}
                                    >
                                        <input
                                            type="checkbox"
                                            checked={selectedFilters["Result"]?.includes(option.value) || false} // Default to false if undefined
                                            onChange={() => handleFilterSelect("Result", option.value)}
                                            className={styles.checkbox}
                                        />
                                        {option.label}
                                    </Dropdown.Item>
                                ))}
                            </>
                        )}
                    </>

                ) : (
                    <>
                        <p className={`p-4 pb-0 ${styles.filterText}`}>Sort By</p>
                        {options.map((option, index) => (
                            <Dropdown.Item
                                key={index}
                                className={styles.dropdownItem}
                                onClick={() => handleSorting(option.value)}
                                active={selectedSortedData === option.value}
                            >
                                {option.label}
                            </Dropdown.Item>
                        ))}
                    </>
                )}
                <Dropdown.Divider />
                <Dropdown.Item
                    className={
                        selectedFilters.length === 0 ? styles.dropdownDisableItem : styles.dropdownItem
                    }
                    onClick={handleClearFilter}
                    disabled={selectedFilters.length === 0}
                >
                    Clear Filter
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default FilterDropdown;

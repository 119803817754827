import React, { useEffect, useState } from 'react'
import IMAGES from '../utils/Images';
import { Modal } from 'react-bootstrap';
import Input from './Input';
import { useDispatch } from 'react-redux';
import { FETCH_ALL_COHORTS } from '../redux/actionTypes';
import Button from './Button';
import COLORS from '../utils/Colors';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import debounce from 'lodash.debounce';

type Props = {
    visible: boolean;
    handleClose: () => void;
    handleSubmit: (newCohortId: string) => void
}

const MoveCohortList: React.FC<Props> = ({ visible, handleClose, handleSubmit }) => {

    const [show, setShow] = useState<boolean>(false);

    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    const { allCohorts } = useSelector((state: RootState) => state.cohort)

    const dispatch = useDispatch()

    useEffect(() => {
        setShow(visible);
        setWindowHeight(window.innerHeight);
        dispatch({ type: FETCH_ALL_COHORTS, payload: { search: '' } })
    }, [visible]);


    const [checked, setChecked] = useState<string | null>(null);

    const [cohortDetails, setCohortDetails] = useState<string>('');

    const onClickMove = () => {
        if (cohortDetails) {
            handleSubmit(cohortDetails)
        } else {
            alert('Select Cohort First')
        }
    }

    const handleSearchChange = debounce((event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch({ type: FETCH_ALL_COHORTS, payload: { search: event.target.value } })
    }, 500);

    return (
        <div>
            <Modal contentClassName='move-learner-modal' centered backdrop="static" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Move Learner</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ maxHeight: windowHeight - 300, overflowY: 'auto', overflowX: 'hidden' }} className={`col-md-8 col-12 `}>
                        <Input
                            leftIcon={<img alt="Logo" src={IMAGES.SEARCH_LIGHT} />}
                            placeholder={"Search by cohort name"}
                            type={"text"}
                            className={"search-cohort-input"}
                            error={null}
                            onChange={handleSearchChange}
                        />
                        <div className="d-flex flex-column my-4 gap-4">
                            {allCohorts?.map((item, index) => (
                                <div
                                    className={` border cohort-card p-4 ${item.id === checked && "cohort-selected-card"
                                        }`}
                                    key={index}
                                >
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="card-title d-flex align-items-center gap-4">
                                            <img alt="Logo" src={IMAGES.COHORT_ICON} className="p-2 icon rounded-circle" />
                                            <div>
                                                <label className="title fw-bold">{item.name}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className="card-divider" />
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items-center gap-4">
                                            <div className="card-title cohort-detail">
                                                <label className="d-block">{`Total learners: ${item.learners}`}</label>
                                                <label>{`Course : ${item.course?.name}`}</label>
                                            </div>
                                        </div>
                                        <input
                                            type="checkbox"
                                            checked={checked === item.id}
                                            onChange={() => {
                                                setChecked(item.id === checked ? null : item.id);
                                                setCohortDetails(item?.id);
                                            }}
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="column">
                        <Button
                            label={'Cancel'}
                            backgroundColor={COLORS.WHITE}
                            onClick={handleClose}
                            labelStyle={{ color: COLORS.PRIMARY, fontSize: '14px' }}
                        />
                        <Button
                            className='ms-2'
                            label={'Move'}
                            backgroundColor={COLORS.PRIMARY}
                            onClick={onClickMove}
                            style={{ height: 40 }}
                            labelStyle={{ color: COLORS.WHITE, fontSize: '14px' }}
                        />
                    </div>
                </Modal.Footer>
            </Modal>

        </div>
    )
}

export default MoveCohortList;
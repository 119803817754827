import React, { useEffect, useState } from 'react';
import { CiFilter } from "react-icons/ci";
import { IoIosAdd } from "react-icons/io";
import { LuArrowUpDown } from "react-icons/lu";
import COLORS from '../../utils/Colors';
import { useSelector } from 'react-redux';
import { RootState } from '@/app/redux/store';
import FilterDropdown from './TableFilter';
import Button from '@/app/components/Button';
import IMAGES from '@/app/utils/Images';
import { sortOptions } from '@/app/utils/helper';
interface Props {
    onClickAddNew?: () => void;
    openFilter?: () => void;
    selectedUser: (value: string) => void
    selectedSortOption: (value: string) => void
    filteredDataOptionUserStatus: (value: string[]) => void;
    filteredDataOptionLevels: (value: string[]) => void;
    selectedUserTab: string;
}

interface FilterOption {
    label: string;
    value: string | number;
}

interface MultipleFilterOption {
    [key: string]: FilterOption[];
}

const UserTableHeader: React.FC<Props> = ({ selectedUserTab, selectedUser, onClickAddNew, openFilter, selectedSortOption, filteredDataOptionUserStatus, filteredDataOptionLevels, }) => {
    const { userType } = useSelector((state: RootState) => state.auth)
    const [selected, setSelected] = useState('Learner');
    const [isFilterStatus, setIsFilterStatus] = useState(false);

    const items = userType == "supervisor" ? ['Learner', 'Educator', 'Cohort'] : userType === "admin" ? ['Learner', 'Educator', 'Cohort', "Supervisor"] : [];
    const handleSort = (status: any) => {
        selectedSortOption(status)
    };

    const handleStatusFilter = (status: (string | number)[] | any) => {
        const { "Level": filterLevel, "User Status": filterStatus } = status;
        filteredDataOptionUserStatus(filterStatus || [])
        filteredDataOptionLevels(filterLevel || [])
    };

    useEffect(() => {
        if (selectedUserTab !== selected) {
            setSelected(selectedUserTab);
        }
    }, [selected, selectedUser])

    const customButton: React.CSSProperties = {
        border: `1px solid ${COLORS.TABLE.BLUE}`,
        height: '40px',
        color: COLORS.TABLE.BLUE,
        fontWeight: '600'
    }

    const addButton: React.CSSProperties = {
        border: `1px solid ${COLORS.TABLE.BLUE}`,
        height: '40px',
        color: '#FFFFFF',
        fontWeight: '600',
        backgroundColor: COLORS.TABLE.BLUE
    }

    const tabStyle: React.CSSProperties = {
        paddingBottom: '4px',
        cursor: 'pointer',
        fontSize: '16px',
        width: '100px',
        textAlign: 'center',
        borderRadius: 1
    }

    const seprator: React.CSSProperties = {
        position: 'relative',
        top: '-2px',
        border: 'none', // Remove default border style
        height: '2px',  // Set the thickness of the line
        backgroundColor: COLORS.BORDER.GRAY,

    }

    const headings = ["Level", "User Status"];
    const activeOptions: MultipleFilterOption[] = [
        {
            Level: [
                { label: "Level 3", value: "Level 3" },
                { label: "Level 5", value: "Level 5" },
                { label: "Level 7", value: "Level 7" },
            ],
        },
        {
            "User Status": [
                { label: "Active User", value: "Active" },
                { label: "Inactive User", value: "InActive" },
                { label: "Pending Activation", value: "Pending" },
            ],
        },
    ];
    const handleTabClick = (item: string) => {
        setSelected(item);
        selectedUser(item); // Update parent component with new selection
    };

    return (
        <>
            <div className='d-flex flex-row justify-content-between align-items-end'>
                <div className='d-flex flex-row  position-relative'>
                    {items.map((item, index) => (
                        <div
                            key={index}
                            style={{
                                ...tabStyle,
                                color: selected === item ? COLORS.PRIMARY : '#1C1D22',
                                borderBottom: selected === item ? `4px solid ${COLORS.TABLE.BLUE}` : `4px solid ${COLORS.BORDER.GRAY}`,
                                fontWeight: selected === item ? 700 : 300,
                            }}
                            onClick={() => handleTabClick(item)}
                            role='button'>
                            {item}
                        </div>
                    ))}
                </div>
                <div className='d-flex flex-row gap-4  position-relative mb-3'>
                    <FilterDropdown
                        type='sort'
                        options={sortOptions}
                        onFilterSelect={handleSort}
                        onSortSelect={handleSort}
                        placeholder={
                            <Button
                                label={""}
                                className={"assignment-btn"}
                                onClick={() => { }}
                                isIcon={true}
                                icon={IMAGES.SORT_ICON}
                            />
                        }
                    />

                    <FilterDropdown
                        type='filter'
                        options={activeOptions}
                        headings={headings}
                        onFilterSelect={handleStatusFilter}
                        isMultiple={true}
                        placeholder={
                            <Button
                                label={"Filter"}
                                className={"assignment-btn"}
                                onClick={() => {
                                    setIsFilterStatus(!isFilterStatus);
                                }}
                                isIcon={true}
                                icon={IMAGES.FILTER}

                            />
                        }
                    />
                    <button onClick={onClickAddNew} style={addButton} className='btn'><IoIosAdd size={30} color={'#FFFFFF'} />Add New</button>

                </div >
            </div >
            <hr style={seprator} />
        </>
    );
}

export default UserTableHeader;
import React from "react";
import IMAGES from "@utils/Images";
import LinearProgress from "@mui/joy/LinearProgress";

interface ProgresscardProps {
  heading: string;
  Units: string;
}
const ProgressCard: React.FC<ProgresscardProps> = ({ heading, Units }) => {
  const chartContainerStyle: React.CSSProperties = {
    border: "1px solid transparent",
    borderColor: "#E5E7E7",
    borderRadius: "8.36px",
    padding: "14px",
    width: "100%",
    fontSize: "14px",
    lineHeight: "14.52px",
    height: "150px",
  };
  const imgStyle: React.CSSProperties = {
    padding: "7.22px",
    background: "#1C1D1D0D",
    border: "1px solid transparent",
    borderRadius: "7.22px",
    margin: "16px 0",
  };
  const UnitStyle: React.CSSProperties = {
    fontSize: "10px",
    fontWeight: "500",
    lineHeight: "10.49px",
    color: "#1C1D1D80",
  };

  return (
    <div className="chart-container" style={chartContainerStyle}>
      <img src={IMAGES.PEN} className="d-block" style={imgStyle} alt="Logo" />
      <label className="my-3">{heading}</label>
      {/* <div className="d-flex align-items-center gap-2">
        <LinearProgress determinate value={50} />
        <div style={UnitStyle}>
          <span className="text-primary">{Units}</span>
          Units
        </div>
      </div> */}
    </div>
  );
};

export default ProgressCard;

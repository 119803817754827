import React, { FC, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Modal } from 'react-bootstrap';
import Input from '@components/Input';
import ICONS from '@utils/Icons';
import { passwordSchema } from '@modules/validations/validations';
import Button from './Button';
import COLORS from '@utils/Colors';
import { useDispatch } from 'react-redux';
import { RESET_PASSWORD } from '../redux/actionTypes';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { toast } from 'react-toastify';

interface Props {
    visible: boolean;
    handleClose: () => void;
    onClickSkip: () => void;
    updatePassword: (v: { password: string, confirmPassword: string }) => void
}

const ChangePassword: FC<Props> = ({ visible, handleClose, onClickSkip, updatePassword }) => {

    const dispatch = useDispatch()

    const [show, setShow] = useState<boolean>(false);

    const [secured, setSecured] = useState(true)

    const [confirmSecured, setConfirmSecured] = useState(true)

    const { error, success, user_id } = useSelector((state: RootState) => state.auth)

    const formik = useFormik({
        initialValues: { password: '', confirmPassword: '' },
        // onSubmit: (v) => updatePassword?.(v),
        onSubmit: (values) => { dispatch({ type: RESET_PASSWORD, payload: { userId: user_id, password: values.password, confirmPassword: values.confirmPassword } }) },
        validationSchema: passwordSchema
    })

    useEffect(() => {
        setShow(visible)
    }, [visible])

    const customError: React.CSSProperties = {
        color: formik.touched.password && formik.errors.password ? COLORS.ERROR : formik.touched.password && formik.errors.password === undefined ? COLORS.GREEN : COLORS.GRAY, // Conditional color change
        fontSize: '14px',
        marginLeft: '16px',
        marginTop: '5px',
        fontStyle: 'italic',
        lineHeight: '20px'
    }
    const skipButton: React.CSSProperties = {
        color: COLORS.BUTTON.BLUE,
        cursor: 'pointer'
    }

    useEffect(() => {
        if (success) {
            toast.success(success);
        }
    }, [success]);

    useEffect(() => {
        if (error) {
            toast.error(error);
        }
    }, [error]);

    return (
        <>
            <Modal
                aria-labelledby="contained-modal-title-vcenter"
                centered
                keyboard={false} backdrop="static" show={show} onHide={handleClose}>
                <Modal.Header className='border-0 px-4 pt-4 fw-bold' closeButton />
                <Modal.Body className='px-5 pt-0 px-md-5 py-md-2'>
                    <Modal.Title className='text-center fw-bold fs-2'>Create new password</Modal.Title>
                    <Input
                        label={'New password'}
                        name="password"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.password}
                        type={secured ? 'password' : 'text'}
                        className={'mt-md-4 mt-4 px-md-3'}
                        placeholder={'Password'}
                        leftIcon={<img src={ICONS.INPUT.PASSWORD} alt='password' style={{ height: 20, width: 18 }} />}
                        rightIcon={<i onClick={() => setSecured(!secured)} className={`fa ${secured ? 'fa-eye-slash' : 'fa-eye'} eye-icon`} id="clearIcon"></i>}
                        error={null}
                    />
                    <div style={customError} >
                        Password must be at least 8 characters long, include 1 uppercase letter, and 1 number.
                    </div>
                    <Input
                        label={'Confirm password'}
                        name="confirmPassword"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.confirmPassword}
                        type={confirmSecured ? 'password' : 'text'}
                        className={'mt-4 px-md-3'}
                        placeholder={'Password'}
                        leftIcon={<img src={ICONS.INPUT.PASSWORD} alt='confirmPassword' style={{ height: 20, width: 18 }} />}
                        rightIcon={<i onClick={() => setConfirmSecured(!confirmSecured)} className={`fa ${confirmSecured ? 'fa-eye-slash' : 'fa-eye'} eye-icon`} id="clearIcon"></i>}
                        error={formik.errors.confirmPassword && formik.touched.confirmPassword ? formik.errors.confirmPassword : null}
                    />
                </Modal.Body>
                <Modal.Footer className='row d-flex justify-content-center border-0'>
                    <Button
                        label={'Create new passowrd'}
                        className={'mt-md-4 mt-0 loginButton w-md-50 w-75'}
                        onClick={formik.handleSubmit} />
                    <span onClick={onClickSkip} style={skipButton} className='text-center mt-4 fw-bold'>Skip</span>
                </Modal.Footer>
            </Modal>
        </>
    );
};




export default ChangePassword;

import { get_pre_assignment_brief_check, mark_assignment, upload_learner_assignment } from "@/app/api/Api";
import { getErrorMessage } from "@/app/modules/helpers/ErrorHelper";
import { GetLearnerAssignmentDetails, LearnerUploadAssignment } from "@/app/modules/types/types";
import { call, put, takeLatest } from "redux-saga/effects";
import { CREATE_LEARNER_UPLOAD_ASSIGNMENT_FAILED, CREATE_LEARNER_UPLOAD_ASSIGNMENT_SUCCESS, MARK_ASSIGNMENT, MARKED_ASSIGNMENT, PRE_BRIEF_CHECK, PRE_BRIEF_CHECKED, SET_LOADING_UPLOAD_ASSIGNEMNT, UPLOAD_LEARNER_ASSIGNMENT } from "../actionTypes";

function* handleLearnerAssignmentUpload(action: { type: string, payload: LearnerUploadAssignment }): Generator {
    try {
        yield put({ type: SET_LOADING_UPLOAD_ASSIGNEMNT });
        const response = (yield call(upload_learner_assignment, action.payload)) as GetLearnerAssignmentDetails;
        yield put({ type: CREATE_LEARNER_UPLOAD_ASSIGNMENT_SUCCESS, payload: { message: response?.message } });
    } catch (error) {
        const errorMessage = getErrorMessage(error);
        yield put({ type: CREATE_LEARNER_UPLOAD_ASSIGNMENT_FAILED, payload: { message: errorMessage } });
    }
}

function* getPreassignmentBriefcheck(action: { type: string, payload: LearnerUploadAssignment }): Generator {
    try {
        yield put({ type: SET_LOADING_UPLOAD_ASSIGNEMNT });
        const response = (yield call(get_pre_assignment_brief_check, action.payload)) as any;
        if(response?.data?.result){
            yield put({ type: PRE_BRIEF_CHECKED, payload: { data : response?.data?.result } });
        } 
    } catch (error) {
        const errorMessage = getErrorMessage(error);
        yield put({ type: CREATE_LEARNER_UPLOAD_ASSIGNMENT_FAILED, payload: { message: errorMessage } });
    }
}

function* markAssignment(action :{type:string,payload : any}) : Generator {
    try {
        yield put({ type: SET_LOADING_UPLOAD_ASSIGNEMNT });
        const response = (yield call(mark_assignment, action.payload)) as any;
        if(response?.data?.result && response?.data?.result?.status==="success"){
            yield put({ type: MARKED_ASSIGNMENT, payload: { data : response?.data?.result } });
        }else{
            yield put({ type: CREATE_LEARNER_UPLOAD_ASSIGNMENT_FAILED, payload: { message: response?.data?.result?.message } });
        }
    } catch (error) {
        const errorMessage = getErrorMessage(error);
        yield put({ type: CREATE_LEARNER_UPLOAD_ASSIGNMENT_FAILED, payload: { message: errorMessage } });
    }
}

function* learnerUploadAssignmentSaga() {
    yield takeLatest(PRE_BRIEF_CHECK, getPreassignmentBriefcheck);
    yield takeLatest(UPLOAD_LEARNER_ASSIGNMENT, handleLearnerAssignmentUpload);
    yield takeLatest(MARK_ASSIGNMENT,markAssignment)
}

export default learnerUploadAssignmentSaga;
import { call, put, takeLatest } from "redux-saga/effects";
import { COHORT_FAILED, DELETE_COHORTS, DELETE_COHORTS_SUCCESS, DELETE_LEARNER_FROM_COHORT, DELETE_LEARNER_FROM_COHORT_SUCCESS, FETCH_ALL_COHORTS, FETCH_COHORT_LEARNERS, FETCH_COHORTS, FETCH_LEVELS, FETCHED_ALL_COHORTS, FETCHED_COHORT_LEARNERS, FETCHED_COHORTS, FETCHED_LEVELS, LEARNER_MOVED, MOVE_LEARNER, SET_LOADING_COHORT } from "../actionTypes";
import { delete_cohort, delete_learners_from_cohorts, get_all_users, get_cohorts, get_levels, move_learners_from_cohorts } from "@/app/api/Api";
import { getErrorMessage } from "@/app/modules/helpers/ErrorHelper";
import { CohortLearnerResponse, CohortResponse, DeleteCohortResponse, LevelsResponse } from "@/app/modules/types/types";

function* fetchCohorts(action: { type: string, payload: { page: string, limit: string, search: string, userType: string, sortBy: string } }): Generator {
    try {
        yield put({ type: SET_LOADING_COHORT });
        const response = (yield call(get_cohorts, action.payload)) as CohortResponse;
        yield put({ type: FETCHED_COHORTS, payload: { data: response.data } });
    } catch (error) {
        const errorMessage = getErrorMessage(error);
        yield put({ type: COHORT_FAILED, payload: { message: errorMessage } });
    }
}


function* fetchCohortsLearners(action: { type: string, payload: { cohortId: string, userType: string, sortBy?:string } }): Generator {
    try {
        yield put({ type: SET_LOADING_COHORT });
        const response = (yield call(get_all_users, action.payload.cohortId, action.payload.userType, action.payload.sortBy)) as CohortLearnerResponse;
        yield put({ type: FETCHED_COHORT_LEARNERS, payload: { data: response.data } });
    } catch (error) {
        const errorMessage = getErrorMessage(error);
        yield put({ type: COHORT_FAILED, payload: { message: errorMessage } });
    }
}

function* fetchLevels(): Generator {
    try {
        yield put({ type: SET_LOADING_COHORT });
        const response = (yield call(get_levels)) as LevelsResponse;
        yield put({ type: FETCHED_LEVELS, payload: { data: response.data } })
    } catch (error) {
        const errorMessage = getErrorMessage(error);
        yield put({ type: COHORT_FAILED, payload: { message: errorMessage } });
    }
}

function* deleteLearners(action: { type: string, payload: { cohortId: string, learnerIds: string[] } }): Generator {
    try {
        yield put({ type: SET_LOADING_COHORT });
        const response = (yield call(delete_learners_from_cohorts, action.payload.cohortId, action.payload.learnerIds)) as CohortLearnerResponse;
        yield put({ type: DELETE_LEARNER_FROM_COHORT_SUCCESS, payload: { message: response.message } })
    } catch (error) {
        const errorMessage = getErrorMessage(error);
        yield put({ type: COHORT_FAILED, payload: { message: errorMessage } });
    }
}

function* deleteCohorts(action: { type: string; payload: { cohortId: string } }): Generator {
    try {
        yield put({ type: SET_LOADING_COHORT });
        const response = (yield call(delete_cohort, action.payload.cohortId)) as DeleteCohortResponse;
        yield put({ type: DELETE_COHORTS_SUCCESS, payload: { message: response.message } });
    } catch (error) {
        const errorMessage = getErrorMessage(error);
        yield put({ type: COHORT_FAILED, payload: { message: errorMessage } });
    }
}


function* fetchAllCohorts(action: { type: string, payload: { search: string, userType: string } }) {
    try {
        yield put({ type: SET_LOADING_COHORT });
        const response = (yield call(get_cohorts, action.payload)) as CohortResponse;
        yield put({ type: FETCHED_ALL_COHORTS, payload: { data: response.data } })
    } catch (error) {
        const errorMessage = getErrorMessage(error);
        yield put({ type: COHORT_FAILED, payload: { message: errorMessage } });
    }
}

function* moveLearner(action: { type: string, payload: { newCohortId: string, oldCohortId: string, learnerIds: string[], } }) {
    try {
        yield put({ type: SET_LOADING_COHORT });
        const response = yield call(move_learners_from_cohorts, action.payload);
        yield put({ type: LEARNER_MOVED, payload: { message: response.message } })
    } catch (error) {
        const errorMessage = getErrorMessage(error);
        yield put({ type: COHORT_FAILED, payload: { message: errorMessage } });
    }
}

function* cohortSaga() {
    yield takeLatest(FETCH_COHORTS, fetchCohorts);
    yield takeLatest(FETCH_COHORT_LEARNERS, fetchCohortsLearners);
    yield takeLatest(FETCH_LEVELS, fetchLevels)
    yield takeLatest(DELETE_LEARNER_FROM_COHORT, deleteLearners)
    yield takeLatest(FETCH_ALL_COHORTS, fetchAllCohorts)
    yield takeLatest(MOVE_LEARNER, moveLearner)
    yield takeLatest(DELETE_COHORTS, deleteCohorts)
}

export default cohortSaga;

import IMAGES from '@utils/Images';
import React from 'react';

const LayoutSplashScreen: React.FC = () => {

    const containerStyle: React.CSSProperties = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#f9f9f9',
    };

    const logoStyle: React.CSSProperties = {
        width: '150px',
        height: 'auto',
    };

    return (
        <div style={containerStyle}>
            <img
                src={IMAGES.LOGIN_LOGO}
                alt="Logo"
                style={logoStyle}
            />
        </div>
    );
};

export default LayoutSplashScreen;

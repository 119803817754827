import { profileFieldsByUserType } from '@modules/static/FormArray';
import React, { useEffect, useRef, useState } from 'react';
import Input from '@components/Input';
import { useFormik } from 'formik';
import { Modal } from 'react-bootstrap';
import Button from '@components/Button';
import COLORS from '@utils/Colors';
import { DayScheduleArrayItem, FieldUpateProfile } from '@modules/types/types';
import WorkScheduleInput from '@components/WorkScheduleInput';
import WorkingTimeModal from '@components/WorkingTimeModal';
import { useSelector } from 'react-redux';
import { RootState } from '@/app/redux/store';
import { useDispatch } from 'react-redux';
import { FETCH_USER, UPDATE_USER } from '../redux/actionTypes';
import Loader from './Loading';
import { toast } from 'react-toastify';
import { getResponsiveStyles } from '../modules/helpers/styleHelpers';
import IMAGES from '../utils/Images';
import Swal from 'sweetalert2';


type Props = {
    visible: boolean;
    handleClose: () => void;
    onClickSkip: () => void;
}

const UpdateProfileModal: React.FC<Props> = ({ visible, handleClose, onClickSkip }) => {

    const dispatch = useDispatch()

    const { user, schedules, error, loading, success } = useSelector((state: RootState) => state.user)

    const [imageFile, setImageFile] = useState<File | null>(null);

    const { userType, user_id } = useSelector((state: RootState) => state.auth)

    const [timePicker, setTimePicker] = useState(false)

    const [isEditing, setIsEditing] = useState(false);

    const [scheduleTimings, setScheduleTimings] = useState<DayScheduleArrayItem[]>([])

    const [userName, setUserName] = useState(user ? user.name : '');

    const [image, setImage] = useState(user ? user?.avatarChunkData : '');

    const [show, setShow] = useState<boolean>(false);

    const updateProfileFields: FieldUpateProfile[] = profileFieldsByUserType[userType];

    useEffect(() => {
        setShow(visible);
        if (visible === true) {
            dispatch({ type: FETCH_USER, payload: { userId: user_id } })
        }
    }, [dispatch, visible]);

    useEffect(() => {
        if (user) {
            setScheduleTimings(schedules)
        }
    }, [schedules, user])

    useEffect(() => {
        if (user) {
            setUserName(user.name || '');
            setImage(user.avatarChunkData || '');
        }
    }, [user]);

    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const nameInputRef = useRef<HTMLInputElement>(null);

    const handleEditClick = () => {
        setIsEditing(true);
        setTimeout(() => nameInputRef.current?.focus(), 0);
    };

    const handleEditImageClick = () => {
        fileInputRef.current?.click();
    };

    const handleBlur = () => {
        setIsEditing(false);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUserName(e.target.value);
    };

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            setImageFile(file)
            const reader = new FileReader();
            reader.onloadend = () => setImage(reader.result as string);
            reader.readAsDataURL(file);
        }
    };

    const initialValues = updateProfileFields?.reduce<Record<string, string>>((values, field) => {
        const fieldName = field.name.trim();
        values[fieldName] = fieldName === 'phone' ? '+44' : '';
        if (user && user[fieldName] !== undefined && user[fieldName] !== null) {
            values[fieldName] = user[fieldName];
        }
        return values;
    }, {});

    const updateWorkSchedules = () => {
        // const convertedValues = toIsoString(values)
        // setScheduleTimings(convertedValues)
        setTimePicker(false)
    }

    useEffect(() => {
        if (success) {
            Swal.fire({
                title: 'Success',
                text: success,
                icon: 'success',
                confirmButtonColor: COLORS.PRIMARY,
                confirmButtonText: 'OK',
                allowOutsideClick: false
            }).then((result) => {
                if (result?.isConfirmed) {
                    handleClose()
                }
            })
        }
    }, [handleClose, success]);

    useEffect(() => {
        if (error) {
            toast.error(error);
        }
    }, [error]);


    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        onSubmit: (values) => { dispatch({ type: UPDATE_USER, payload: { userId: user_id, phone: values.phone, image: imageFile, name: userName } }) },
        validationSchema: '', // Add schema here if necessary
    });


    //Styles
    const editImageButtonStyle: React.CSSProperties = {
        width: "40px",
        height: "40px",
        bottom: '0px',
        right: '0px',
        backgroundColor: 'black',
        color: 'white',
        border: '2px solid rgb(255, 255, 255)',
        borderRadius: '50%',
        fontSize: '14px',
        cursor: 'pointer',
    };

    const inputStyle: React.CSSProperties = {
        width: '100%',
        border: `1.5px solid ${COLORS.BORDER.DISABLEDINPUT}`,
        outline: 'none',
        textDecoration: 'none',
        fontSize: '48px',
        borderRadius: '10px',
        padding: '8px 12px',
        boxSizing: 'border-box',
    };

    const inputDisabledStyle: React.CSSProperties = {
        maxWidth: '400px',
        border: 'none',
        outline: 'none',
        textDecoration: 'none',
        fontSize: '48px',
        backgroundColor: COLORS.WHITE,
        padding: '8px 12px',
        boxSizing: 'border-box',
        overflow: 'hidden',
        display: 'inline-block',
        lineHeight: '56px'
    };

    const editButtonStyle: React.CSSProperties = {
        color: COLORS.PRIMARY,
        fontWeight: 'bold',
        cursor: 'pointer',
        maxWidth: '100%',
        display: 'inline-block',
        verticalAlign: 'bottom',
        marginLeft: '10px',
    };

    const updateScheduleButton: React.CSSProperties = {
        border: `1.5px solid ${COLORS.BORDER.DISABLEDINPUT}`,
        height: '40px'
    };

    const profileImageStyle: React.CSSProperties = {
        borderRadius: '50%',
        width: '120px',
        height: '120px',
    };

    const profileInputContainerStyle: React.CSSProperties = {
        width: '100%',
    };

    const inputDisabledTextStyle: React.CSSProperties = {
        ...inputDisabledStyle,
        display: 'inline-block',
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    };

    const optionalTextStyle: React.CSSProperties = {
        fontSize: '14px',
    };

    const scheduleDefaultTextStyle: React.CSSProperties = {
        fontSize: '14px',
    };

    const skipButtonStyle: React.CSSProperties = {
        textAlign: 'center',
        fontWeight: 'bold',
        cursor: 'pointer',
        marginTop: '1rem'
    };




    return (
        <>
            {loading && <Loader />}
            <Modal size='lg' centered backdrop="static" show={show} onHide={handleClose}>
                <Modal.Header className='border-0 px-4 pt-4 fw-bold' closeButton />
                <Modal.Body className='px-5'>
                    <h1>Update profile</h1>
                    <p className='fw-bold fs-6 mt-3 mb-4'>Review and update your profile information</p>
                    <div className="row">
                        <div className="col-12 col-sm-auto mb-3 d-flex justify-content-center">
                            <div className="position-relative" style={{ width: "120px", height: "120px" }}>
                                <img
                                    onError={(e) => {
                                        (e.target as HTMLImageElement).src = IMAGES.AVTAR; // Set default avatar on error
                                    }}
                                    src={image ? `data:image/png;base64,${image}` : IMAGES.AVTAR} alt="Profile" style={profileImageStyle} className='border border-light-2' />
                                <button onClick={handleEditImageClick} style={{ ...editImageButtonStyle, position: 'absolute' }} className="edit-image-button">
                                    <i className="fa-solid fa-pen edit-image-icon"></i>
                                </button>
                            </div>
                            <input
                                type="file"
                                ref={fileInputRef}
                                style={{ display: 'none' }}
                                accept="image/*"
                                onChange={handleImageChange}
                            />
                        </div>
                        <div className="col d-md-flex flex-column align-items-md-end align-items-center flex-sm-row justify-content-between mb-3">
                            <div className="my-2 mb-sm-0 d-md-flex align-items-md-end align-items-center" style={profileInputContainerStyle}>
                                {isEditing ? <input
                                    className='text-md-start text-center p-0'
                                    ref={nameInputRef}
                                    disabled={!isEditing}
                                    style={isEditing ? getResponsiveStyles(inputStyle) : getResponsiveStyles(inputDisabledStyle)}
                                    type="text"
                                    value={userName}
                                    onChange={handleInputChange}
                                    onBlur={handleBlur}
                                    autoFocus
                                /> : <span className='text-md-start text-center p-0'
                                    style={getResponsiveStyles(inputDisabledTextStyle)}
                                >{userName}</span>}
                                {!isEditing && (
                                    <div className='text-center'>
                                        <span style={editButtonStyle} onClick={handleEditClick}>Edit</span>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="row d-flex justify-content-center mt-4">
                        <div className="col-12 col-sm-12 col-lg-8">
                            {updateProfileFields?.map((field, index) => (
                                <div key={index} className={`${index > 0 ? 'mt-4' : ''} px-md-3`}>
                                    <Input
                                        disabled={field.prefilled}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values[field.name]}
                                        label={field.label}
                                        name={field.name}
                                        type="text"
                                        placeholder={field.placeholder}
                                        error={null}
                                    />
                                    {!field.required && (
                                        <span className='d-flex text-secondary justify-content-end me-3 mt-3 fst-italic' style={optionalTextStyle}>Optional</span>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                    {/* Type educator and supervisor only */}
                    {user?.userType === 'learner' ? null : <>
                        <hr />
                        <div className="row d-flex justify-content-center mt-4 px-0 px-md-4">
                            <div className="col-12 col-sm-12 col-lg-8">
                                {scheduleTimings && <WorkScheduleInput scheduleTimings={scheduleTimings} />}
                                <span className='d-flex text-dark justify-content-end mt-3 fst-italic' style={scheduleDefaultTextStyle}>Default Schedule</span>
                                <div className="d-grid gap-2 d-md-flex justify-content-md-center mt-5">
                                    <Button
                                        label={'Update Schedule'}
                                        backgroundColor={COLORS.WHITE}
                                        style={updateScheduleButton}
                                        onClick={() => setTimePicker(true)}
                                        labelStyle={{ color: COLORS.PRIMARY, fontSize: '14px' }}
                                    />
                                </div>
                            </div>
                        </div>
                    </>}
                </Modal.Body>
                <Modal.Footer className='row d-flex justify-content-center align-self-center border-0 pb-4 col-12 col-sm-9 col-lg-7'>
                    <p className='text-right'>
                        If any pre-filled information is incorrect or missing, contact&nbsp;<a href="mailto:support@edumark.com">support@edumark.ai</a>.
                    </p>
                    <div className="d-grid gap-2 d-md-flex justify-content-md-center mt-5">
                        <Button label={'Save Profile'} onClick={formik.handleSubmit} />
                    </div>
                    <span onClick={onClickSkip} style={skipButtonStyle}>Skip</span>
                </Modal.Footer>
            </Modal>
            <div>
                {timePicker && <WorkingTimeModal
                    schedules={scheduleTimings}
                    visible={timePicker}
                    handleClose={() => setTimePicker(false)}
                    onSubmit={() => { updateWorkSchedules() }} />}
            </div>
        </>

    )

}



export default React.memo(UpdateProfileModal);
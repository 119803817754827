import { ReactNode, useState } from "react";
import React from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import UserProfileModal from "./UserProfileModal";

type LayoutProps = {
  children: ReactNode;
};

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const [isOpen, setIsopen] = useState<boolean>(false);

  const [userProfile, setUserProfile] = useState(false)

  return (
    <div className="layout d-flex flex-column vh-100">
      <Header
        onClickProfileButton={() => setUserProfile(true)}
        onToggleSidebar={() => {
          setIsopen((prev) => !prev);
        }}
      />
      <div className="content flex-grow-1 overflow-hidden">
        <div className="row h-100">
          <Sidebar
            isOpen={isOpen}
            onToggleSidebar={() => {
              setIsopen((prev) => !prev);
            }}
          />
          <div className="screen-content col-md-9 col-lg-10 col-xs-12 d-flex flex-column h-100">
            <div className="child-content flex-grow-1" style={{ overflowY: 'auto', height: '100%' }}>
              {children}
            </div>

          </div>
        </div>
      </div>
      {userProfile && <UserProfileModal visible={userProfile} handleClose={() => setUserProfile(false)} />}
    </div>
  );
};

export default Layout;

import Layout from "@components/Layout";
import Accordion from "react-bootstrap/Accordion";
import "./analytics.moduke.style.css";
import { Bar, Line } from "react-chartjs-2";
import Button from "@components/Button";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { levelOptions, options, lineOptions } from "./ChartData";
import IMAGES from "@/app/utils/Images";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useCallback, useEffect, useState } from "react";
import Rangedropdown from "./Rangedropdown";
import { useDispatch } from "react-redux";
import {
  FETCH_CORRECTION_RATE,
  FETCH_FEEDBACK,
  FETCH_GRADES_BY_LEVEL,
  FETCH_GRADES_BY_UNIT,
  FETCH_SUBMISSION,
  FETCH_TUTOR_AI,
  FETCHED_SUBMISSION,
} from "@/app/redux/actionTypes";
import { RootState } from "@/app/redux/store";
import { useSelector } from "react-redux";
import Loader from "@/app/components/Loading";
import PaginatedTable from "@/app/components/Table/PaginatedTable";
import { toast } from "react-toastify";
ChartJS.register(
  LineElement,
  PointElement,
  LinearScale,
  Title,
  Tooltip,
  Legend
);

interface AnalyticsProps { }
const Analytics: React.FC<AnalyticsProps> = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(0);
  const [activeTab, setActiveTab] = useState("level");
  const [activeFeedbackTab, setActiveFeedbackTab] = useState("level");


  const endDate = new Date().toISOString();
  const startDate = new Date();
  startDate.setFullYear(startDate.getFullYear() - 1);
  const startDateISO = startDate.toISOString();


  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
  };

  const handleFeedbackTabchange = (tab: string) => {
    console.log("🚀 ~ handleFeedbackTabchange ~ tab:", tab);
    setActiveFeedbackTab(tab);
  };
  const {
    learnerbyUnit,
    learnersBylevel,
    educatorCorrectionRate,
    levelCorrectionRate,
    assignments,
    educatorFeedback,
    levelFeedback,
    loading,
    error,
  } = useSelector((state: RootState) => state.analytics);
  const dispatch = useDispatch();
  useEffect(() => { }, [activeFeedbackTab]);
  const handleToggle = (index, action, payload = {}) => {
    console.log(activeFeedbackTab, "handle toggle");
    if (openIndex !== index) {
      if (openIndex === 2) {
        fetchData(1, 10);
      } else {
        const formData = new FormData();
        for (const [key, value] of Object.entries(payload)) {
          if (typeof value === "string" || value instanceof Blob) {
            formData.append(key, value);
          } else {
            formData.append(key, String(value));
          }
        }
        dispatch({ type: action, payload: payload });
      }
    }
    setOpenIndex(openIndex === index ? null : index);
  };

  const columns = [
    { header: "Name", accessorKey: "Name" },
    { header: "Email", accessorKey: "Email" },
    { header: "Total Assessment", accessorKey: "Total" },
    { header: "Feedback", accessorKey: "Feedback" },
    { header: "Marked", accessorKey: "Marked" },
  ];

  const fetchData = async (pageNo: number, rowsPerPage: number) => {
    dispatch({
      type: FETCH_TUTOR_AI,
      payload: { page: pageNo, limit: rowsPerPage },
    });
  };


  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  useEffect(() => {
    const func = async () => {
      await Promise.all([
        dispatch({
          type: FETCH_GRADES_BY_LEVEL,
          payload: {
            start_date: "2024-10-24T08:48:08.894Z",
            end_date: "2020-10-24T08:48:08.894Z",
            category: "level",
          },
        })
      ]);
    }
    func();
  }, []);
  return (
    <Layout>
      <div className="analytics-container p-3 p-lg-4">
        <div className="sticky-top z-1 bg-white analytics-heading mb-4">
          Analytics & Insights
        </div>
        <div className="vh-100 overflow-y-auto z-0">
          <Accordion
            defaultActiveKey="0"
          // onClick={() =>
          //   handleToggle(0, FETCH_GRADES_BY_LEVEL, {
          //     start_date: "2024-10-24T08:48:08.894Z",
          //     end_date: "2020-10-24T08:48:08.894Z",
          //     category: "level",
          //   })
          // }
          >
            <Accordion.Item eventKey="0">
              <Accordion.Header>Learner Grades Distribution</Accordion.Header>
              <Accordion.Body>
                <Rangedropdown
                  onDateChange={(dates) => {
                    console.log("🚀 ~ dates:", dates)
                    handleToggle(0, FETCH_GRADES_BY_LEVEL, {
                      ...dates,
                      category: "level",
                    });
                  }}
                />
                {loading ? (
                  <Loader />
                ) : (
                  learnersBylevel && (
                    <>
                      <div
                        style={{ height: "318px" }}
                        className="analytics-chart-container"
                      >
                        <Bar data={learnersBylevel} options={options} />
                      </div>
                    </>
                  )
                )}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Accordion
            onClick={() =>
              handleToggle(1, FETCH_CORRECTION_RATE, {
                start_date: "2024-10-24T08:45:56.681Z",
                end_date: "2020-10-24T08:45:56.681Z",
                category: "level",
              })
            }
          >
            <Accordion.Item eventKey="1">
              <Accordion.Header>AI Correction Rate</Accordion.Header>
              <Accordion.Body>
                {/* <Rangedropdown
                  onDateChange={(dates) => {
                    handleToggle(0, FETCH_CORRECTION_RATE, {
                      ...dates,
                      category: activeTab,
                    });
                  }}
                /> */}
                <div className="buttons d-flex flex-lg-row flex-column gap-lg-4 gap-2 mt-lg-0 mt-2 mb-4 level-educator-tabs">
                  <Button
                    label={"Levels"}
                    className={`tab-btn ${activeTab === "level" ? "active-btn" : ""
                      }`}
                    onClick={() => {
                      handleTabChange("level");
                      dispatch({
                        type: FETCH_CORRECTION_RATE,
                        payload: {
                          start_date: "2024-10-24T08:45:56.681Z",
                          end_date: "2020-10-24T08:45:56.681Z",
                          category: "level",
                        },
                      });
                    }}
                    isIcon={true}
                  />
                  <Button
                    label={"Educator"}
                    className={`tab-btn ${activeTab === "educator" ? "active-btn" : ""
                      }`}
                    onClick={() => {
                      handleTabChange("educator");
                      dispatch({
                        type: FETCH_CORRECTION_RATE,
                        payload: {
                          start_date: "2024-10-24T08:45:56.681Z",
                          end_date: "2020-10-24T08:45:56.681Z",
                          category: "educator",
                        },
                      });
                    }}
                    isIcon={true}
                  />
                </div>
                {loading ? (
                  <></>
                ) : (
                  (educatorCorrectionRate || levelCorrectionRate) && (
                    <div
                      style={{ height: "318px", marginTop: "4rem" }}
                      className="analytics-chart-container"
                    >
                      {activeTab === "level" && levelCorrectionRate && (
                        <Bar
                          data={levelCorrectionRate}
                          options={levelOptions}
                        />
                      )}
                      {activeTab === "educator" && educatorCorrectionRate && (
                        <Bar
                          data={educatorCorrectionRate}
                          options={levelOptions}
                        />
                      )}
                    </div>
                  )
                )}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          {/* Commented for temporary purpose do not remove it */}
          {/* <Accordion
            onClick={() => {
              handleToggle(2, FETCH_TUTOR_AI);
            }}
          >
            <Accordion.Item eventKey="2">
              <Accordion.Header>Tutor & AI Amendments</Accordion.Header>
              <Accordion.Body>
                {!tutorAIAmendment ? (
                  <Loader />
                ) : (
                  <>
                    {tutorAIAmendment && tutorAIAmendment?.data && (
                      <PaginatedTable<any>
                        columns={columns}
                        data={tutorAIAmendment?.data}
                        pageCount={tutorAIAmendment?.totalPages || 10}
                        fetchData={() => fetchData(1, 10)}
                        handleSearchChange={null}
                      />
                    )}
                  </>
                )}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion> */}
          <Accordion
            onClick={() =>
              handleToggle(3, FETCH_SUBMISSION)
            }
          >
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                Assignment Submissions Over Time
              </Accordion.Header>
              <Accordion.Body>
                {loading ? (
                  <Loader />
                ) : (
                  assignments && (
                    <>
                      {/* <Rangedropdown
                        onDateChange={(dates) => {
                          handleToggle(0, FETCH_SUBMISSION, {
                            ...dates,
                            category: activeTab,
                          });
                        }}
                      /> */}
                      {/* <div className="time-frame-section d-flex justify-content-end align-items-center gap-md-2 gap-1 flex-wrap">
                        <div className="px-3 py-2">
                          <img src={IMAGES.CLOCK_ICON} alt="Logo" />
                        </div>
                        <div className="px-3 py-2">D</div>
                        <div className="px-3 py-2">W</div>
                        <div className="px-3 py-2">M</div>
                        <div className="px-3 py-2">Y</div>
                        <div className="px-3 py-2">Custom Time</div>
                      </div> */}
                      <div
                        style={{ height: "318px", marginTop: "4rem" }}
                        className="analytics-chart-container"
                      >
                        <Line data={assignments} options={lineOptions} />
                      </div>
                    </>
                  )
                )}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Accordion
            onClick={() =>
              handleToggle(4, FETCH_GRADES_BY_UNIT, {
                start_date: "2024-10-24T08:48:08.894Z",
                end_date: "2020-10-24T08:48:08.894Z",
                category: "unit",
              })
            }
          >
            <Accordion.Item eventKey="4">
              <Accordion.Header>Grades Distribution by Unit</Accordion.Header>
              <Accordion.Body>
                {/* <Rangedropdown
                onDateChange={(dates) => {
                  handleToggle(0, FETCH_GRADES_BY_UNIT, {
                    ...dates,
                    category: activeTab,
                  });
                }}
              /> */}
                {loading ? (
                  <Loader />
                ) : (
                  learnerbyUnit && (
                    <div
                      style={{ height: "318px" }}
                      className="analytics-chart-container"
                    >
                      <Bar data={learnerbyUnit} options={options} />
                    </div>
                  )
                )}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Accordion
            onClick={() =>
              handleToggle(5, FETCH_FEEDBACK, {
                start_date: "2024-10-24T12:19:33.398Z",
                end_date: "2000-10-24T12:19:33.398Z",
                category: "level",
              })
            }
          >
            <Accordion.Item eventKey="5">
              <Accordion.Header>
                {" "}
                Assignment Feedback Satisfaction Overview
              </Accordion.Header>
              <Accordion.Body>
                {/* <Rangedropdown
                  onDateChange={(dates) => {
                    handleToggle(0, FETCH_GRADES_BY_UNIT, {
                      ...dates,
                      category: activeTab,
                    });
                  }}
                /> */}
                <div className="buttons d-flex flex-lg-row flex-column gap-lg-4 gap-2 mt-lg-0 mt-2 mb-4 level-educator-tabs">
                  <Button
                    label={"Levels"}
                    className={`tab-btn ${activeFeedbackTab === "level" ? "active-btn" : ""
                      }`}
                    onClick={() => {
                      setActiveFeedbackTab("level");
                      dispatch({
                        type: FETCH_FEEDBACK,
                        payload: {
                          start_date: "2024-10-24T12:19:33.398Z",
                          end_date: "2000-10-24T12:19:33.398Z",
                          category: "level",
                        },
                      });
                    }}
                    isIcon={true}
                  />
                  <Button
                    label={"Educator"}
                    className={`tab-btn ${activeFeedbackTab === "educator" ? "active-btn" : ""
                      }`}
                    onClick={() => {
                      setActiveFeedbackTab("educator");
                      dispatch({
                        type: FETCH_FEEDBACK,
                        payload: {
                          start_date: "2024-10-24T12:19:33.398Z",
                          end_date: "2000-10-24T12:19:33.398Z",
                          category: "educator",
                        },
                      });
                    }}
                    isIcon={true}
                  />
                </div>
                {loading ? (
                  <Loader />
                ) : (
                  (levelFeedback || educatorFeedback) && (
                    <div
                      style={{ height: "318px", marginTop: "4rem" }}
                      className="analytics-chart-container"
                    >
                      {activeFeedbackTab === "level" && (
                        <Bar data={levelFeedback} options={options} />
                      )}
                      {activeFeedbackTab === "educator" && (
                        <Bar data={educatorFeedback} options={options} />
                      )}
                    </div>
                  )
                )}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </Layout>
  );
};

export default Analytics;

import { AssignmentSliceState } from "@/app/modules/types/sliceTypes";
import { FailedPayload, FetchAssignmentsResponse, FetchLearnerAssignmentsResponse, SuccessPayload } from "@/app/modules/types/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit"

const initialState: AssignmentSliceState = {
    success: null,
    loading: false,
    error: null,
    page: 0,
    totalPages: 0,
    assignments: [],
    LearnerAssignments: []
}

const assignmentSlice = createSlice({
    name: 'assignment',
    initialState,
    reducers: {
        clearSuccess: (state) => {
            state.success = null;
            state.loading = false;
        },
        setLoading: (state) => {
            state.loading = true;
            state.error = null;
        },
        fetchAssignmentsData: (state) => {
            state.loading = true;
            state.error = null;
        },
        fetchedAssignmentData: (state, action: PayloadAction<FetchAssignmentsResponse>) => {
            const { assignments, totalPages, currentPage } = action.payload.data
            state.assignments = assignments;
            state.totalPages = totalPages;
            state.page = currentPage;
            state.loading = false;
        },
        fetchAssignments: (state) => {
            state.loading = true;
            state.error = null;
        },
        fetchedAssignments: (state, action: PayloadAction<FetchAssignmentsResponse>) => {
            const { assignments, totalPages, currentPage } = action.payload.data
            state.assignments = assignments;
            state.totalPages = totalPages;
            state.page = currentPage;
            state.loading = false;
        },
        fetchedLearnerAssignments: (state, action: PayloadAction<FetchLearnerAssignmentsResponse>) => {
            const { assignments, totalPages, page } = action.payload.data
            state.LearnerAssignments = assignments;
            state.totalPages = totalPages;
            state.page = page;
            state.loading = false;
            state.error = null;
        },
        setSuccess: (state, action: PayloadAction<SuccessPayload>) => {
            state.success = action.payload.message;
            state.loading = false;
            state.error = null;
        },
        setError: (state, action: PayloadAction<FailedPayload>) => {
            state.error = action.payload.message;
            state.loading = false;
        },
    }
})



export const {
    setLoading,
    clearSuccess,
    fetchAssignmentsData,
    fetchedAssignmentData,
    fetchAssignments,
    fetchedAssignments,
    setSuccess,
    setError
} = assignmentSlice.actions



export default assignmentSlice.reducer;
import React, { useEffect, useState } from "react";
import "./style.css";
import { Button as MuiButton, Checkbox, FormControlLabel, Paper, Table, TableBody, TableCell, TableContainer, TableRow, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { GET_UPDATED_SCHEDULE, UPDATE_USER_SCHEDULE } from "@/app/redux/actionTypes";
import { RootState } from "@/app/redux/store";
import dayjs from "dayjs";
import COLORS from "@/app/utils/Colors";

const toIsoString = (values) => {
    const scheduleArray: any = Object.entries(values)
        .filter(([_, details]: any) => details.status)
        .map(([dayName, details]) => {
            const dayDetails = details as any;
            const timeFrom = dayDetails.timeFrom
                ? dayjs().set('hour', parseInt(dayDetails.timeFrom.split(':')[0]))
                    .set('minute', parseInt(dayDetails.timeFrom.split(':')[1]))
                    .toISOString()
                : null;
            const timeTo = dayDetails.timeTo
                ? dayjs().set('hour', parseInt(dayDetails.timeTo.split(':')[0]))
                    .set('minute', parseInt(dayDetails.timeTo.split(':')[1]))
                    .toISOString()
                : null;

            return {
                dayName,
                status: dayDetails.status,
                timeFrom: timeFrom,
                timeTo: timeTo,
            };
        });
    return scheduleArray;
};

const SettingPreferences: React.FC = () => {
    const [isEditing, setIsEditing] = useState(false);
    const dispatch = useDispatch();
    const { user_id } = useSelector((state: RootState) => state.auth);

    const schedules = useSelector((state: RootState) => state.user.schedules);
    const [schedule, setSchedule] = useState<{ [key: string]: { status: boolean; timeFrom: string | null; timeTo: string | null } }>({});

    useEffect(() => {
        dispatch({ type: GET_UPDATED_SCHEDULE, payload: { userId: user_id } })
    }, [dispatch]);


    const daysOfWeek = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

    const toggleEdit = () => {
        setIsEditing((prev) => !prev);
    };

    const handleChange = (day: string, field: string, value: any) => {
        setSchedule((prevSchedule) => ({
            ...prevSchedule,
            [day]: {
                ...prevSchedule[day],
                [field]: value,
            },
        }));
    };

    const handleSave = () => {
        try {
            const payload = {
                userId: user_id,
                newSchedules: toIsoString(schedule),
            };

            dispatch({
                type: UPDATE_USER_SCHEDULE,
                payload: payload
            });
        } catch (error) {
            console.log('Error during save:', error);
        }
        setIsEditing(false);
    };

    useEffect(() => {
        if (schedules) {
            const fetchedSchedule = daysOfWeek.reduce((acc, dayName) => {
                const daySchedule: any = schedules.find(schedule => schedule.dayName === dayName);
                acc[dayName] = {
                    status: daySchedule ? daySchedule.status : false,
                    timeFrom: daySchedule && daySchedule.timeFrom ? dayjs(daySchedule.timeFrom).format('HH:mm') : null, // ISO to HH:mm
                    timeTo: daySchedule && daySchedule.timeTo ? dayjs(daySchedule.timeTo).format('HH:mm') : null, // ISO to HH:mm
                };
                return acc;
            }, {} as { [key: string]: { status: boolean; timeFrom: string | null; timeTo: string | null } });
            setSchedule(fetchedSchedule);
        }
    }, [schedules]);

    useEffect(() => {
        const initialSchedule = daysOfWeek.reduce((acc, dayName) => {
            acc[dayName] = {
                status: false,
                timeFrom: null,
                timeTo: null,
            };
            return acc;
        }, {} as { [key: string]: { status: boolean; timeFrom: string | null; timeTo: string | null } });
        setSchedule(initialSchedule);
    }, []);

    const groupDaysWithSameTime = () => {
        let groupedSchedule: { days: string[], timeFrom: string | null, timeTo: string | null }[] = [];
        let currentGroup: string[] = [];
        let currentTimeRange: { timeFrom: string | null, timeTo: string | null } | null = null;

        for (let i = 0; i < daysOfWeek.length; i++) {
            const day = daysOfWeek[i];
            const { status, timeFrom, timeTo } = schedule[day] || { status: false, timeFrom: null, timeTo: null };

            if (status) {
                if (!currentTimeRange || currentTimeRange.timeFrom !== timeFrom || currentTimeRange.timeTo !== timeTo) {
                    if (currentGroup.length > 0) {
                        groupedSchedule.push({
                            days: currentGroup,
                            timeFrom: currentTimeRange!.timeFrom,
                            timeTo: currentTimeRange!.timeTo,
                        });
                    }
                    currentGroup = [day];
                    currentTimeRange = { timeFrom, timeTo };
                } else {
                    currentGroup.push(day);
                }
            } else {
                if (currentGroup.length > 0) {
                    groupedSchedule.push({
                        days: currentGroup,
                        timeFrom: currentTimeRange!.timeFrom,
                        timeTo: currentTimeRange!.timeTo,
                    });
                    currentGroup = [];
                    currentTimeRange = null;
                }
            }
        }
        if (currentGroup.length > 0) {
            groupedSchedule.push({
                days: currentGroup,
                timeFrom: currentTimeRange!.timeFrom,
                timeTo: currentTimeRange!.timeTo,
            });
        }
        return groupedSchedule;
    };

    return (
        <div className="container mt-2">
            <div className="row">
                <div className="col-12">
                    <div className="card preference-header p-3">
                        <h6 className="mb-0">Your work schedules</h6>
                    </div>
                </div>

                <div className="col-12">
                    <div className="preference-schedule p-4 shadow-sm">
                        <div className="row">
                            <div className="col-md-6">
                                <p>Choose your working days and set daily capacity</p>
                            </div>
                            <div className="col-md-6 d-flex justify-content-end align-items-center mb-3">
                                {isEditing ? (

                                    <MuiButton variant="outlined" sx={{ height: '52px', textTransform: 'capitalize', borderColor: "#0343FA", fontStyle: 'normal', fontSize: '14px', color: COLORS.PRIMARY }} type='submit' onClick={handleSave}>
                                        Save Schedule
                                    </MuiButton>
                                ) : (

                                    <MuiButton variant="outlined" sx={{ height: '52px', textTransform: 'capitalize', borderColor: "#0343FA", fontStyle: 'normal', color: COLORS.PRIMARY }} onClick={toggleEdit}>
                                        Edit Schedule
                                    </MuiButton>

                                )}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                {isEditing ? (
                                    <TableContainer className="schedule-table">
                                        <Table>
                                            <TableBody>
                                                {Object.keys(schedule).map((day) => (
                                                    <TableRow key={day}>
                                                        <TableCell>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={schedule[day].status}
                                                                        onChange={(e) =>
                                                                            handleChange(day, 'status', e.target.checked)
                                                                        }
                                                                    />
                                                                }
                                                                label={day.charAt(0).toUpperCase() + day.slice(1)}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <div className="d-flex align-items-center justify-content-between">
                                                                <TextField
                                                                    type="time"
                                                                    size="small"
                                                                    value={schedule[day].timeFrom || ""}
                                                                    disabled={!schedule[day].status}
                                                                    onChange={(e) =>
                                                                        handleChange(day, 'timeFrom', e.target.value)
                                                                    }
                                                                    sx={{ width: '150px' }}
                                                                />
                                                                <span style={{ padding: '0 15px' }}>to</span>
                                                                <TextField
                                                                    type="time"
                                                                    size="small"
                                                                    value={schedule[day].timeTo || ""}
                                                                    disabled={!schedule[day].status}
                                                                    onChange={(e) =>
                                                                        handleChange(day, 'timeTo', e.target.value)
                                                                    }
                                                                    sx={{ width: '150px' }}
                                                                />
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                ) : (
                                    groupDaysWithSameTime().map((group, index) => (
                                        <div key={index} className="col-12 mb-3">
                                            <div className="schedule-box p-2 d-flex justify-content-between align-items-center">
                                                <span>
                                                    {group.days.length > 1
                                                        ? `${group.days[0].charAt(0).toUpperCase() + group.days[0].slice(1)}-${group.days[group.days.length - 1].charAt(0).toUpperCase() + group.days[group.days.length - 1].slice(1)}`
                                                        : group.days[0].charAt(0).toUpperCase() + group.days[0].slice(1)}
                                                </span>
                                                <span>{group.timeFrom} - {group.timeTo}</span>
                                            </div>
                                        </div>
                                    ))
                                )}
                            </div>
                        </div>
                        {!isEditing && (
                            <p className="text-muted fst-italic mt-2">Default Schedule</p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SettingPreferences;

import AssignmentsDetail from "@components/AssignmentsDetail";
import Button from "@components/Button";
import Calendar from "@components/Calendar";
import Layout from "@components/Layout";
import NotificationList from "@components/NotificationList";
import PerformanceChart from "@components/PerformanceChart";
import ProgressCard from "@components/ProgressCard";
import TodoList from "@components/TodoList";
import UserList from "@components/UserList";
import IMAGES from "@/app/utils/Images";
import React, { useCallback, useEffect, useState } from "react";
import AssignmentsDetailNew from "./AssignmentDetailNew";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { FETCH_AVERAGE_TIME_TO_MARK, FETCH_HOME_USERS, FETCH_LEARNER_SATISFACTION, FETCH_TOTAL_ACTIVE_USERS } from "../redux/actionTypes";

interface Props {
  startDate: Date | any;
  endDate: Date | any;
}

const SupervisorDashboard: React.FC<Props> = ({ startDate, endDate }) => {
  const dispatch = useDispatch();

  const { totalActiveUsers, averageTimeToMark, averageSatisfaction } = useSelector((state: RootState) => state.analytics)
  const { learner, educator } = totalActiveUsers
  // console.log("🚀 ~ educators:", educators)
  // console.log("🚀 ~ learners:", learners)

  const { learners: learnersData, educators: educatorsData, loading: isHomeLoading, error, pagination } = useSelector((state: RootState) => state.home);


  const fetchUsers = useCallback((userType: string[], tPage: number = 1, tPageSize: number = 10) => {
    console.log('called')
    dispatch({
      type: FETCH_HOME_USERS,
      payload: {
        userType,
        page: tPage,
        limit: tPageSize,
      }
    });
  }, [dispatch]);

  useEffect(() => {
    const func = async () => {
      await Promise.all([
        fetchUsers(['learner', 'educator']),
        dispatch({ type: FETCH_TOTAL_ACTIVE_USERS, payload: { userType: 'learner' } }),
        dispatch({ type: FETCH_TOTAL_ACTIVE_USERS, payload: { userType: 'educator' } }),
      ]);
    }
    func();
  }, [dispatch]);

  // useEffect(() => {
  //   const func = async () => {
  //     await fetchUsers('educator');
  //   }
  //   func();
  // }, []);

  const containeroneStyle: React.CSSProperties = {
    height: "250px",
  };

  const containertwoStyle: React.CSSProperties = {
    height: "300px",
  };

  const weekDrpDownStyle: React.CSSProperties = {
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "14.52px",
    color: "#000000",
  };

  const assignmentLabel: React.CSSProperties = {
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "16.94px",
    color: "#8B8D97",
  };

  const numberLabel: React.CSSProperties = {
    fontSize: "20px",
    fontWeight: "600",
    lineHeight: "30px",
    color: "#45464E",
  };

  const totalData = [1, 2, 3, 4, 5];
  const correctData = [6, 7, 8, 9, 10];

  type Series = {
    data: number[];
    label: string;
    id: string;
    stack: string;
    color: string;
  };

  const seriesData: Series[] = [
    {
      data: correctData,
      label: "Correct",
      id: "pvId",
      stack: "total",
      color: "#0343FA",
    },
    {
      data: totalData,
      label: "Overall numbers",
      id: "uvId",
      stack: "total",
      color: "#DDE5FF",
    },
  ];
  const data = [
    {
      item: "Add new assignments",
      date: "Tuesday, 30 June 2024",
    },
    {
      item: "Assign Cohorts",
      date: "Monday, 24 June 2024",
    },
    {
      item: "Assign Roles",
      date: "Friday, 10 June 2024",
    },
    {
      item: "Add level 5 users",
      date: "Friday, 05 June 2024",
    },
  ];
  const notificationData = [
    {
      item: "New Assignment",
      date: "5:30pm",
      isReviewCompleted: false,
      icon: IMAGES.ASSIGNMENT,
    },
    {
      item: "New Feedback",
      date: "9:00pm",
      isReviewCompleted: true,
      icon: IMAGES.FEEDBACK_IMG,
    },
  ];
  const userData = [
    {
      item: "Mark Jones",
      level: "Tutor",
      cohort: "1 Cohorts",
      isMarked: true,
      icon: IMAGES.PROFILE,
      name: "Marker",
    },
    {
      item: "Grace Lee",
      level: "Level 4",
      cohort: "3 Cohorts",
      isMarked: false,
      icon: IMAGES.PROFILE,
    },
  ];

  const SupervisorDashboardCard = [{
    icon: IMAGES.USERS,
    title: "Learners",
    labelStart: "Total Learners",
    labelEnd: "Learner Satisfaction",
    valueStart: learner.toString(),
    valueEnd: averageSatisfaction,
  },
  {
    icon: IMAGES.EDUCATOR_PEN,
    title: "Educators",
    labelStart: "Total Educators",
    labelEnd: "Avg Time To Mark",
    valueStart: educator.toString(),
    valueEnd: averageTimeToMark,
  }]

  const imgStyle: React.CSSProperties = {
    background: "#CDD9FE",
    border: "1px solid transparent",
    borderRadius: "10px",
  };

  console.log({ averageTimeToMark })
  useEffect(() => {
    dispatch({
      type: FETCH_AVERAGE_TIME_TO_MARK,
    });
    dispatch({ type: FETCH_LEARNER_SATISFACTION });

  }, [dispatch])

  return (
    <div className="dashboard-container mt-4">
      {/* <div className="row mx-lg-auto mx-0">
        <div className="col-12 col-lg-4 card-content course-container">
          <label className="heading">{"Active Learners"}</label>
          <ProgressCard
            heading={"CIPD Level 3, Level 5, Level 7"}
            Units={"15/30"}
          />
        </div>
        <div className="col-12 col-lg-4 card-content" style={containeroneStyle}>
          <div className="course-container">
            <label className="heading">{"Active Educators"}</label>
            <ProgressCard
              heading={"CIPD Level 3, Level 5, Level 7"}
              Units={"15/30"}
            />
          </div>
        </div>
        <div
          className="col-12 col-lg-4 mt-lg-0 card-content"
          style={containeroneStyle}
        >
          <Calendar />
        </div>
      </div> */}
      <div className="row mx-lg-auto mx-0 gap-5">
        {
          SupervisorDashboardCard?.map((data, index) => (
            <div className="col-12 col-lg-6 card-content course-supervisor-container" key={index}>
              <div className="h-100 d-flex flex-column">
                <div className="d-flex align-items-center gap-3">
                  <img src={data?.icon} alt="ICON" style={data.icon === IMAGES.EDUCATOR_PEN ? imgStyle : {}} />
                  <div style={weekDrpDownStyle}>{data?.title}</div>
                </div>
                <div className="mt-auto">
                  <div
                    className="d-flex justify-content-between"
                    style={assignmentLabel}
                  >
                    <label>{data?.labelStart}</label>
                    <label>{data?.labelEnd}</label>
                  </div>
                  <div
                    className="d-flex justify-content-between"
                    style={numberLabel}
                  >
                    <label>{data?.valueStart}</label>
                    <label>{data?.valueEnd}</label>
                  </div>
                </div>
              </div>
            </div>
          ))
        }
      </div>
      <div className="row mt-4">
        <div className="col-12 col-lg-5 card-content" style={containertwoStyle}>
          {/* <AssignmentsDetail
            labelText={"Grading Effieciency"}
            data={seriesData}
          /> */}
          {/* <AssignmentsDetailNew labelText={"Assignments Over Time"} startDate={startDate} endDate={endDate} /> */}
          <AssignmentsDetailNew labelText={"Assignments Over Time"} />
        </div>
        <div
          className="col-12 col-lg-3 mt-lg-0  card-content"
          style={containertwoStyle}
        >
          <PerformanceChart role={"educator"} />
        </div>
        <div
          className="col-12 col-lg-4 mt-lg-0 card-content"
          style={containertwoStyle}
        >
          {/* <TodoList data={data} /> */}
          <NotificationList data={notificationData} role={"supervisor"} />
        </div>
      </div>
      <div className="row mt-4">
        <div className={"col-lg-6 col-12 listitems-container"}>
          <UserList userType={['educator']} totalPages={pagination?.totalPages} fetchData={fetchUsers} data={educatorsData} label="Educators" />
        </div>
        <div className={"col-lg-6 col-12 listitems-container"}>
          <UserList userType={['learner']} totalPages={pagination?.totalPages} data={learnersData} label="Learners" fetchData={fetchUsers} />
        </div>
      </div>
    </div>
  );
};

export default SupervisorDashboard;

import React from "react";
import Pagination from "@mui/material/Pagination";

interface PaginationButtonProps {
  count: number;
  page?: number;
  changePage?: (event: React.ChangeEvent<unknown>, page: number) => void;
}

const PaginationButton = ({
  count,
  page,
  changePage,
}: PaginationButtonProps) => {
  return (
    <div className="pagination__button">
      <Pagination
        count={count}
        variant="outlined"
        shape="rounded"
        page={page}
        onChange={changePage}
      />
    </div>
  );
};

export default PaginationButton;

export const API = {
    LOGIN: 'user/login',
    CHANGE_PASSWORD: 'user/change-password',
    USER: 'user',
    USER_ACCOUNT: 'user/userAccount',
    USER_UPDATE: 'user/userUpdate',
    USER_SCHEDULE_UPDATE: 'user/schedules',
    CREATE_USER: 'api/users/createUser',
    COHORT: 'cohort',
    GET_USERS: 'api/users/getusers',
    USERS_LIST: 'cohort/userList',
    ALL_COHORTS: 'cohort/all',
    DELETE_COHORT_LEARNER: 'cohort/deleteLearner',
    DELETE_COHORT: 'cohort',
    MOVE_COHORT_LEARNER: 'cohort/moveLearner',
    LEVEL: 'level',
    TUTOR_AI: 'analytics/TutorAIamendments',
    UPLOAD_ASSIGNMENT: 'assignment-detail',
    ASSIGNMENTS: 'assignment/assignments',
    ASSIGNMENT_DATA: 'assignment',
    RESEND_LOGIN_CREDENTIALS: 'admin/resend-email',
    USER_ACTIVATION: 'user',
    DELETE_USER: 'user/delete',
    FETCH_ASSIGNMENT_GRADING: "assignment/grading/list",
    UPLOAD_SIGNATURE: "assignment/signature/upload",
    FETCH_SIGNATURE: "assignment/signature",
    UPDATE: 'user/update',
    SETTING_NOTIFICATION: 'settings/notification',
    UPDATE_SETTING_NOTIFICATION: 'settings/update-notification',
    FETCH_LEARNER_ASSIGNMENT_GRADING: "assignment/grades-and-feedback",
    SETTING_GET_SIGNATURE: "settings/account/signature",
    SETTING_UPLOAD_SIGNATURE: "settings/account/upload-signature",
    DOWNLOAD_ASSIGNMENT: "download",
    RESUBMIT_FEEDBACK: "resubmission",
    ASSIGNMENT_INFORMATION: "getassignmentinfo",
    UPDATE_FFEDBACK: "updatefeedback",
    TASK_STATUS: "task-status",
    SECOND_MARKING: "secondmarking",
    GET_UPDATED_SCHEDULE: "user/schedules",
    RESET_PASSWORD: "admin/reset-password",
    PRE_ASSIGNMENT_BRIEF_CHECK: "pre-assignment-brief-check",
    MARK_ASSIGNMENT: "markassignment",
    GRADES_BY_LEVEL: "grades-distribution",
    GRADES_DISTRIBUTION_DASHBOARD: "grades-distribution-dashboard",
    AVERAGE_GRADE_PERCENTAGE: "average-grade-percentage",
    AVERAGE_TIME_TO_MARK: "average-time-to-mark",
    CORRECTION_RATE: 'correction-rate',
    ASSIGNMENT_SUBMISSION: 'assignment-submissions-over-time',
    FEEDBACK: 'feedback-satisfaction',
    TOTAL_ACTIVE_USERS: 'total-active-users',
    LEARNER_ASSIGNMENT: 'assignment/learner/assignments',
    ASSESOR_DECLARATION: "assesordeclaration",
    UPDATE_LEARNER_ASSIGNMENT: "assignment/publish",
    CREATE_ASSIGNMENT: '/assignment',
    CLAIM_ASSIGNMENT: '/assignment/claim-assignment',
    ASSIGNMENTS_STATUS: 'assignments-status',
    GET_LEARNER_SATISFACTION:'feedback-satisfaction-dashboard',
    AVERAGE_TIME_TO_SUBMIT: 'average-time-to-submit'
}
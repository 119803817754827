export const getResponsiveStyles = (style: React.CSSProperties): React.CSSProperties => {
    const screenWidth = window.innerWidth;

    // Adjust font size for smaller screens
    if (screenWidth < 956) {
        return {
            ...style,
            fontSize: '30px', // Adjust this size based on your design requirements
        };
    }

    return style;
};
import { all } from 'redux-saga/effects';
import authSaga from './sagas/authSaga';
import userSaga from './sagas/userSaga';
import cohortSaga from './sagas/cohortSaga';
import analyticsSaga from './sagas/analyticsSaga';
import learnerUploadAssignmentSaga from './sagas/uploadAssignmentSaga';
import assignmentSaga from './sagas/assignmentSaga';
import gradingFeedbackSaga from "./sagas/gradingFeedbackSaga"
import settingSaga from './sagas/settingSaga';
import homeSaga from './sagas/homeSaga';

export default function* rootSaga() {
    yield all([
        authSaga(),
        userSaga(),
        cohortSaga(),
        assignmentSaga(),
        gradingFeedbackSaga(),
        analyticsSaga(),
        learnerUploadAssignmentSaga(),
        assignmentSaga(),
        settingSaga(),
        homeSaga()
    ]);
}
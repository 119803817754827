import { CohortSliceState } from "@/app/modules/types/sliceTypes";
import { CohortLearnerResponse, CohortResponse, FailedPayload, LevelsResponse, SuccessPayload } from "@/app/modules/types/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: CohortSliceState = {
    selectedCohort: null,
    success: null,
    loading: false,
    cohorts: [],
    error: null,
    sortBy:null,
    mappedLearners: [],
    page: 1,
    totalPages: 0,
    levels: [],
    allCohorts: []
}

const cohortSlice = createSlice({
    name: 'cohort',
    initialState,
    reducers: {
        fetchCohorts: (state) => {
            state.loading = true;
            state.error = null;
        },
        fetchedCohorts: (state, action: PayloadAction<CohortResponse>) => {
            const { cohorts, totalPages, page } = action.payload.data
            state.cohorts = cohorts;
            state.totalPages = totalPages;
            state.page = page;
            state.loading = false;
            state.error = null;
        },
        fetchCohortLearners: (state) => {
            state.loading = true;
            state.error = null;
        },
        fetchedCohortLearners: (state, action: PayloadAction<CohortLearnerResponse>) => {
            const { cohort, users } = action.payload.data
            state.mappedLearners = users;
            state.selectedCohort = cohort;
            state.loading = false;
            state.error = null;
        },
        moveLearner: (state) => {
            state.loading = true;
            state.error = null;
        },
        learnerMoved: (state, action: PayloadAction<SuccessPayload>) => {
            state.success = action.payload.message;
            state.loading = false;
            state.error = null;
        },
        cohortFailed: (state, action: PayloadAction<FailedPayload>) => {
            state.error = action.payload.message;
            state.loading = false;
        },
        setLoading: (state) => {
            state.loading = true;
            state.error = null;
        },
        clearSuccess: (state) => {
            state.success = null;
            state.loading = false;
        },
        fetchLevels: (state) => {
            state.loading = true;
            state.error = null
        },
        fetchedLevels: (state, action: PayloadAction<LevelsResponse>) => {
            state.levels = action.payload.data;
            state.loading = false
        },
        deleteLearnerFromCohort: (state) => {
            state.loading = true;
            state.error = null
        },
        deleteLearnerFromCohortSuccess: (state, action: PayloadAction<SuccessPayload>) => {
            state.success = action.payload.message;
            state.loading = false;
        },
        fetchAllCohorts: (state) => {
            state.loading = true;
            state.error = null
        },
        fetchedAllCohorts: (state, action: PayloadAction<CohortResponse>) => {
            const { cohorts } = action.payload.data
            state.allCohorts = cohorts
            state.loading = false;
            state.error = null
        },
        deleteCohorts: (state) => {
            state.loading = true
            state.error = null
        },
        deleteCohortsSuccess: (state, action: PayloadAction<SuccessPayload>) => {
            state.success = action.payload.message
            state.loading = false
        }


    }
})

export const {
    setLoading,
    clearSuccess,
    fetchCohorts,
    fetchedCohorts,
    fetchCohortLearners,
    fetchedCohortLearners,
    moveLearner,
    learnerMoved,
    cohortFailed,
    fetchLevels,
    deleteLearnerFromCohort,
    deleteLearnerFromCohortSuccess,
    fetchAllCohorts,
    fetchedAllCohorts,
    deleteCohorts,
    deleteCohortsSuccess
} = cohortSlice.actions;

export default cohortSlice.reducer;
import Layout from "@components/Layout";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@/app/redux/store";
import { toast } from "react-toastify";
import Educator from "./Educator";
import Learner from "./Learner";
import "./gradingfeedback.module.style.css";

interface GradingFeedbackProps {}
const GradingFeedback: React.FC<GradingFeedbackProps> = ({}) => {
  const { assignmentsGrading, error, learnerAssignmentsGrading } = useSelector(
    (state: RootState) => state.gradingFeedback
  );
  const { userType } = useSelector((state: RootState) => state.auth);
  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  return (
    <Layout>
      <div className="grading-feedback-container m-4 vh-100">
        <div className="sticky-top z-3 bg-white">
          <div className="heading-container">
            <span className="heading">Grading & Feedback</span>
            {userType === "supervisor" && (
              <span className="label">
                Manage course assesment and feedback
              </span>
            )}
          </div>
        </div>
        <div>
          <div className="z-2 my-3">
            {userType === "educator" && (
              <Educator assignmentsGrading={assignmentsGrading} />
            )}
            {userType === "learner"  && (
              <Learner learnerAssignmentsGrading={learnerAssignmentsGrading} />
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default GradingFeedback;

import React, { ReactNode, useEffect } from "react";
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  ColumnDef,
  flexRender,
  PaginationState,
} from "@tanstack/react-table";
import Pagination from "@components/Table/Pagination";
import IMAGES from "@/app/utils/Images";

interface TableProps<TData> {
  columns: ColumnDef<TData, any>[];
  data: TData[];
  pageCount: number;
  fetchData: (pageIndex: number, pageNumber: number | 1, query: string) => void;
  handleSearchChange?:
  | ((event: React.ChangeEvent<HTMLInputElement>) => void)
  | null;
  onRowClick?: (row: TData, rowIndex: number) => void;
  customButtons?: ReactNode;
  handlePaginationChange?: (pageIndex: number, pageNumber: number | 1) => void;
}

const PaginatedTable = <TData extends object>({
  columns,
  data,
  pageCount,
  fetchData,
  handleSearchChange,
  onRowClick,
  customButtons,
  handlePaginationChange,
}: TableProps<TData>) => {
  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [selectedRowIndex, setSelectedRowIndex] = React.useState<number | null>(
    null
  );

  const table = useReactTable({
    columns,
    data,
    pageCount,
    manualPagination: true,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    state: {
      pagination,
    },
  });

  const { pageIndex, pageSize } = table.getState().pagination;

  useEffect(() => {
    if (handlePaginationChange) {
      handlePaginationChange(pageIndex + 1, pageSize);
    } else {
      fetchData(pageIndex + 1, pageSize, "");
    }
  }, [pageIndex, pageSize]);

  const searchIcon: React.CSSProperties = {
    position: "absolute",
    left: "16px",
    top: "50%",
    transform: "translateY(-50%)",
    color: "#ccc",
  };

  const searchStyle: React.CSSProperties = {
    padding: "14px 14px 14px 50px",
    borderRadius: "5px",
    border: "1px solid #ccc",
  };

  const handleRowClick = (row: TData, rowIndex: number) => {
    if (onRowClick) {
      setSelectedRowIndex(rowIndex);
      onRowClick(row, rowIndex); // Pass the row data and row index to the parent if the callback is provided
    }
  };

  return (
    <>
      <div className="table-responsive">
        {handleSearchChange && (
          <div className="right-section mb-3 d-flex flex-md-row flex-column align-items-md-center justify-content-md-between gap-lg-0 gap-2">
            <div style={{ position: "relative", width: "fit-content" }}>
              <div style={searchIcon}>
                <img src={IMAGES.SEARCH_LIGHT} alt="Logo" />
              </div>
              <input
                type="text"
                placeholder="Search List"
                style={searchStyle}
                onChange={handleSearchChange}
              />
            </div>
            <div>{customButtons}</div>
          </div>
        )}
        {customButtons && <hr></hr>}
        {data && data?.length > 0 ? (
          <div
            style={{
              minHeight: "400px",
              maxHeight: "400px",
              overflowY: "auto",
            }}
          >
            <table
              style={{
                borderCollapse: "separate",
                borderSpacing: "0 8px",
                width: "100%",
              }}
            >
              <thead
                style={{
                  position: "sticky",
                  top: 0,
                  background: "#F9F9F9",
                  zIndex: 1,
                }}
              >
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr
                    key={headerGroup.id}
                    style={{ borderRadius: "8px", background: "#F9F9F9" }}
                  >
                    {headerGroup.headers.map((header, index) => (
                      <th
                        key={header.id}
                        style={{
                          color: "#727272",
                          padding: "13px",
                          textAlign: "left",
                          borderTop: "1px solid #ddd",
                          borderBottom: "1px solid #ddd",
                          borderLeft: index === 0 ? "1px solid #ddd" : "none",
                          borderRight:
                            index === headerGroup.headers.length - 1
                              ? "1px solid #ddd"
                              : "none",
                          borderRadius:
                            index === 0
                              ? "8px 0 0 8px"
                              : index === headerGroup.headers.length - 1
                                ? "0 8px 8px 0"
                                : undefined,
                        }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row, rowIndex) => (
                  <tr
                    onClick={() => handleRowClick(row.original, rowIndex)}
                    key={row.id}
                    style={{ borderRadius: "8px" }}
                  >
                    {row.getVisibleCells().map((cell, index) => (
                      <td
                        key={cell.id}
                        style={{
                          background:
                            selectedRowIndex === rowIndex ? "#e6f7ff" : "#fff",
                          padding: "12px",
                          textAlign: "left",
                          borderTop: "1px solid #ddd",
                          borderBottom: "1px solid #ddd",
                          borderLeft: index === 0 ? "1px solid #ddd" : "none",
                          borderRight:
                            index === row.getVisibleCells().length - 1
                              ? "1px solid #ddd"
                              : "none",
                          borderRadius:
                            index === 0
                              ? "8px 0 0 8px"
                              : index === row.getVisibleCells().length - 1
                                ? "0 8px 8px 0"
                                : undefined,
                        }}
                        onClick={(event) => {
                          if (cell.column.columnDef.header === 'Actions') {
                            event.stopPropagation();
                            const rowIndex = cell.row.index;
                            if (onRowClick) {
                              setSelectedRowIndex(rowIndex)
                            }
                          }
                        }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{
              minHeight: "400px",
              maxHeight: "400px",
              overflowY: "auto",
              fontWeight: "500",
            }}
          >
            No records found!
          </div>
        )}
      </div>
      {data && data?.length > 0 &&
        <Pagination table={table} />
      }
    </>
  );
};

export default PaginatedTable;

import { HomeSliceState } from '@/app/modules/types/sliceTypes';
import { FailedPayload, GetUserDetailsPayload, SuccessPayload, HomeUser, HomeUserPayload } from '@/app/modules/types/types';
import IMAGES from '@/app/utils/Images';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: HomeSliceState = {
    loading: false,
    error: null,
    success: null,
    pagination: {
        page: 1,
        limit: 10,
        totalPages: 0,
        totalUsers: 0,
    },
    learners: [],
    educators: [],
};

const homeSlice = createSlice({
    name: 'home',
    initialState,
    reducers: {
        setHomeLoading: (state) => {
            state.loading = true;
            state.error = null;
        },
        fetchedEducatorsForHome: (state, action: PayloadAction<HomeUserPayload>) => {
            const { data } = action.payload;
            if (data) {
                state.educators = data.map(educator => ({
                    level: educator.levels.map((item:any) => item?.name).join(', '),
                    name: educator.name,
                    item: educator.name,
                    cohort: `${data.length} cohort(s)`,
                    icon: IMAGES.PROFILE,
                    isMarked : true
                }));
            }
            state.loading = false;
            state.error = null;
            state.pagination = action.payload.pagination;
        },
        fetchedLearnersForHome: (state, action: PayloadAction<HomeUserPayload>) => {
            const { data } = action.payload;
            if (data) {
                state.learners = data.map(learner => ({
                    level: learner.levels.map((item:any) => item?.name).join(', '),
                    name: learner.name,
                    item: learner.name,
                    cohort: `${data.length} cohort(s)`,
                    icon: IMAGES.PROFILE,
                    isMarked : true
                }));
            }
            state.loading = false;
            state.error = null;
            state.pagination = action.payload.pagination;
        },
        fetchHomeUsersFailed: (state, action: PayloadAction<FailedPayload>) => {
            state.error = action.payload.message;
            state.loading = false;
        },
    },
});

export const {
    setHomeLoading,
    fetchedEducatorsForHome,
    fetchedLearnersForHome,
    fetchHomeUsersFailed,
} = homeSlice.actions;

export default homeSlice.reducer;

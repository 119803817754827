import { CrossIcon } from "@/app/assets/icons/svg";
import Button from "@/app/components/Button";
import Loader from "@/app/components/Loading";
import { CLEAR_GRADING_ASSIGNMENT_STATE } from "@/app/redux/actionTypes";
import IMAGES from "@/app/utils/Images";
import { Button as ButtonInput, Modal, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";

const UplaodSignatureModal = ({
  loading,
  open,
  setOpen,
  setChecked,
  signature,
  handleLabelClick,
  fileInputRef,
  handleFileChange,
  handleFileUpload,
  file,
  checked,
}) => {
  const dispatch = useDispatch();
  console.log(signature, "signature");
  return (
    <Modal show={open} className="success-modal">
      {loading ? (
        <Loader />
      ) : (
        <>
          <Modal.Header className="w-100">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "between",
                width: "100%",
              }}
            >
              <span style={{ flex: 1, fontWeight: "700", fontSize: "20px" }}>
                Add signature
              </span>
              <ButtonInput
                variant="link"
                onClick={() => {
                  setOpen(false);
                  setChecked(false);
                  dispatch({
                    type: CLEAR_GRADING_ASSIGNMENT_STATE,
                  });
                }}
              >
                <img src={CrossIcon} alt="" />
              </ButtonInput>
            </div>
          </Modal.Header>
          <Modal.Body>
            <Row className="w-100">
              <label className="signature-text">
                Use your saved signature or upload a new one to publish the
                grade and feedback for this assignment
              </label>
              {!signature && (
                <>
                  <label className="signature-bold-text">
                    You have not saved any signature
                  </label>
                  <div className="upload-signature d-flex align-items-center justify-content-center gap-2">
                    <img
                      src={IMAGES.UPLOAD}
                      onClick={handleLabelClick}
                      style={{ cursor: "pointer" }}
                      alt="UPLOAD"
                    />
                    <label
                      className="upload-text"
                      onClick={handleLabelClick}
                      style={{ cursor: "pointer" }}
                    >
                      Upload New signature
                    </label>
                    <input
                      type="file"
                      accept=".jpg,.jpeg,.png" // Accept only JPG, JPEG, and PNG formats
                      ref={fileInputRef}
                      onChange={handleFileChange}
                      style={{ display: "none" }} // Hide the file input
                      multiple={false} // Only allow a single file
                    />
                  </div>
                </>
              )}
              {signature  && (
                <div className="signature-container">
                  <div className="d-flex flex-lg-row flex-column align-items-center signature-section-one gap-lg-0 gap-3">
                    <div className="col-12 col-lg-6">
                      <img
                        src={`data:image/png;base64,${signature?.signatureFiledata}`}
                        alt="signature"
                        className="ms-lg-5 signature-img"
                      />
                    </div>
                    <div className="col-12 col-lg-6">
                      <label className="d-flex align-items-center gap-2">
                        <label className="custom-radio">
                          <input
                            type="radio"
                            name="options"
                            onChange={(e) => {
                              setChecked(e.target.checked);
                            }}
                          />
                        </label>
                        <label className="signature-img-text">
                          Use Saved Signature
                        </label>
                      </label>
                    </div>
                  </div>
                  <div className="d-flex flex-lg-row flex-column align-items-center signature-section-two gap-lg-0 gap-3">
                    <div className="col-12 col-lg-6" />
                    <div className="col-12 col-lg-6">
                      <img
                        src={IMAGES.UPLOAD}
                        onClick={handleLabelClick}
                        style={{ cursor: "pointer" }}
                        alt=""
                      />
                      <label
                        className="upload-text"
                        onClick={handleLabelClick}
                        style={{ cursor: "pointer" }}
                      >
                        Upload New signature
                      </label>
                      <input
                        type="file"
                        accept=".jpg,.jpeg,.png" // Accept only JPG, JPEG, and PNG formats
                        ref={fileInputRef}
                        onChange={handleFileChange}
                        style={{ display: "none" }} // Hide the file input
                        multiple={false} // Only allow a single file
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className="d-flex justify-content-end align-items-center gap-1">
                <Button
                  label={"Publish review"}
                  className={!checked ? "publish-btn" : "active-publish-btn"}
                  onClick={() => {
                    setOpen(false);
                    setChecked(false);
                    handleFileUpload(file);
                  }}
                />
              </div>
            </Row>
          </Modal.Body>
        </>
      )}
    </Modal>
  );
};

export default UplaodSignatureModal;

import {
    RESET_PASSWORD,
    SETTING_FETCHED_SIGNATURE,
    SETTING_UPLOAD_SIGNATURE
} from "@/app/redux/actionTypes";
import { RootState } from "@/app/redux/store";
import ICONS from "@/app/utils/Icons";
import IMAGES from "@/app/utils/Images";
import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import Input from "../Input";
import PasswordModal from "../PasswordModal/PasswordModal";
import "./style.css";

const SettingAccount: React.FC = () => {
    const dispatch = useDispatch();
    const [secured, setSecured] = useState(true);
    const [confirmSecured, setConfirmSecured] = useState(true);
    const [showPasswordModal, setShowPasswordModal] = useState<boolean>(false);
    const {loading, success, userType } = useSelector((state: RootState) => state.auth);
    const [file, setFile] = useState<File | null>(null);
    const fileInputRef: any = useRef(null);
    const role = userType ?? "learner";
    const { signature } = useSelector(
        (state: RootState) => state.setting
    );

    const formik = useFormik({
        initialValues: {
            password: "",
            confirmPassword: "",
        },
        validationSchema: Yup.object({
            password: Yup.string()
                .min(8, "Password must be at least 8 characters long")
                .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
                .matches(/[a-z]/, "Password must contain at least one lowercase letter")
                .matches(/\d/, "Password must contain at least one number")
                .matches(/[!@#$%^&*(),.?":{}|<>]/, "Password must contain at least one Special Character")
                .required("Password is required"),
            confirmPassword: Yup.string()
                .oneOf(
                    [Yup.ref("password"), ""],
                    "The passwords do not match. Please try again"
                )
                .required("Confirm password is required"),
        }),
        onSubmit: (values) => {
            setShowPasswordModal(true);
            dispatch({
                type: RESET_PASSWORD,
                payload: {
                    password: values.password,
                    confirmPassword: values.confirmPassword,
                },
            });
        },
    });

    const handleLabelClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current?.click();
        }
        handleFileUpload(file);
    };

    const handleFileChange = async (event) => {
        const files = event.target.files;

        if (files.length > 0) {
            const selectedFile = files[0];
            setFile(selectedFile);
            await handleFileUpload(selectedFile);
        }
    };

    const handleFileUpload = async (file: File | null) => {
        if (file) {
            const fileUploadPayload = {
                signature: file,
            };
            await dispatch({ type: SETTING_UPLOAD_SIGNATURE, payload: fileUploadPayload });
        }
    };


    useEffect(() => {
        if(role === "educator" || role === "supervisor" || role === "admin") {
            dispatch({
                type: SETTING_FETCHED_SIGNATURE
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="p-3">
            <p className="setting-account-tittle p-lg-3 pb-0">Create new password</p>
            <form onSubmit={formik.handleSubmit}>
                <Input
                    label={"New password"}
                    name="password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                    type={secured ? "password" : "text"}
                    className={"mt-md-4 mt-4 px-md-3"}
                    placeholder={"Password"}
                    helpText={"Password must be at least 8 characters long, include 1 uppercase letter, 1 lowercase letter, 1 number, and one Special Character"}
                    leftIcon={
                        <img
                            src={ICONS.INPUT.PASSWORD}
                            alt="password"
                            style={{ height: 20, width: 18 }}
                        />
                    }
                    rightIcon={
                        <i
                            onClick={() => setSecured(!secured)}
                            className={`fa ${secured ? "fa-eye-slash" : "fa-eye"} eye-icon`}
                            id="clearIcon"
                        ></i>
                    }
                    error={
                        formik.touched.password && formik.errors.password
                            ? formik.errors.password
                            : null
                    }
                />
                <Input
                    label={"Confirm password"}
                    name="confirmPassword"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.confirmPassword}
                    type={confirmSecured ? "password" : "text"}
                    className={"mt-4 px-md-3"}
                    placeholder={"Password"}
                    helpText={"Password must be at least 8 characters long, include 1 uppercase letter, 1 lowercase letter, 1 number, and one Special Character"}
                    leftIcon={
                        <img
                            src={ICONS.INPUT.PASSWORD}
                            alt="confirmPassword"
                            style={{ height: 20, width: 18 }}
                        />
                    }
                    rightIcon={
                        <i
                            onClick={() => setConfirmSecured(!confirmSecured)}
                            className={`fa ${confirmSecured ? "fa-eye-slash" : "fa-eye"
                                } eye-icon`}
                            id="clearIcon"
                        ></i>
                    }
                    error={
                        formik.touched.confirmPassword && formik.errors.confirmPassword
                            ? formik.errors.confirmPassword
                            : null
                    }
                />
                <div className="px-md-3">
                    <button
                        type="submit"
                        className="btn btn-primary mt-4"
                        disabled={!formik.values.confirmPassword || !formik.values.password}
                    >
                        Create New Password
                    </button>
                </div>
            </form>
            {(role === "educator" || role === "supervisor" || role === "admin") && (
                <div className="p-3 mt-5">
                    <p className="setting-account-tittle">Signature</p>
                    <div className="mt-2">
                        Upload your signature to publish the grade and feedback the
                        assignments
                    </div>
                    {!signature && (
                        <>
                            <div className="setting-upload-signature d-flex align-items-center justify-content-center gap-2">
                                <img
                                    src={IMAGES.UPLOAD}
                                    style={{ cursor: "pointer" }}
                                    alt="upload"
                                    onClick={handleLabelClick}
                                />
                                <label
                                    className="upload-text"
                                    onClick={handleLabelClick}
                                    style={{ cursor: "pointer", marginLeft: 5 }}
                                >
                                    Upload New signature
                                </label>
                                <input
                                    type="file"
                                    accept=".jpg,.jpeg,.png"
                                    ref={fileInputRef}
                                    onChange={handleFileChange}
                                    style={{ display: "none" }}
                                    multiple={false}
                                />
                            </div>
                        </>
                    )}
                    {signature && (
                        <div className="setting-upload-signature">
                            <div className="d-flex flex-lg-row flex-column align-items-center gap-lg-0 gap-3">
                                <div className="col-12 col-lg-6 d-flex flex-column align-items-center ms-lg-5">
                                    {loading ? <div className="d-flex justify-content-center">
                                        <div className="spinner-border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div> :
                                        <img src={signature?.signatureFiledata ? `data:image/png;base64,${signature?.signatureFiledata}` : IMAGES.SIGNATURE} style={{ height: 100, width: 100 }} alt="signature" className="ms-lg-5" />}
                                </div>
                            </div>
                            <div className="d-flex mt-5 flex-column align-items-center gap-lg-0 gap-3">
                                <div className="col-12 col-lg-6">
                                    <img
                                        src={IMAGES.UPLOAD}
                                        onClick={handleLabelClick}
                                        style={{ cursor: "pointer" }}
                                        alt="uploadImg"
                                    />
                                    <label
                                        className="upload-text"
                                        onClick={handleLabelClick}
                                        style={{ cursor: "pointer", marginLeft: 5 }}
                                    >
                                        Upload New signature
                                    </label>
                                    <input
                                        type="file"
                                        accept=".jpg,.jpeg,.png"
                                        ref={fileInputRef}
                                        onChange={handleFileChange}
                                        style={{ display: "none" }}
                                        multiple={false}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
            <PasswordModal
                open={showPasswordModal}
                onClose={() => {
                    setShowPasswordModal(false);
                    if(success){
                        localStorage.clear();
                    }
                }}
            />
        </div>
    );
};

export default SettingAccount;

import React, { useEffect, useState } from 'react'
import COLORS from '@utils/Colors'
import { DayScheduleArrayItem, DaysGroup } from '../modules/types/types';
import { groupDaysByTime } from '../modules/helpers/FormikHelper';
import { Box, List, ListItem } from '@mui/material';

type Props = {
    scheduleTimings: DayScheduleArrayItem[]
}

const WorkScheduleInput: React.FC<Props> = ({ scheduleTimings }) => {



    const [groupDays, setGroupDays] = useState<DaysGroup[]>([])

    useEffect(() => {
        const getGroupDays = groupDaysByTime(scheduleTimings)
        console.log(getGroupDays,)
        setGroupDays(getGroupDays)
    }, [scheduleTimings])

    const containerStyle: React.CSSProperties = {
        height: "54px",
        backgroundColor: COLORS.INPUT.BG,
        borderRadius: '8px',
        padding: '16px'
    }

    const labelStyle: React.CSSProperties = {
        fontSize: '14px',
        color: COLORS.BLACK,
        lineHeight: '20px',
    }

    const nameStyle: React.CSSProperties = {
        fontSize: '14px',
        color: COLORS.GRAY,
        lineHeight: '20px',
        textTransform: 'capitalize'
    }

    const placeholderStyle: React.CSSProperties = {
        fontSize: '14px',
        color: COLORS.GRAY,
        lineHeight: '20px'
    }

    const scheduleListBoxStyle: React.CSSProperties = {
        maxHeight: 150,
        overflow: 'auto'
    }

    return (<div>
        <h6 style={labelStyle}>{'Work Schedule Input'}</h6>
        {groupDays && <Box style={scheduleListBoxStyle}>
            <List>
                {groupDays.map((item, index) => (
                    <ListItem key={index}>
                        <div className="col-12">
                            <div className='d-flex flex-row align-items-center justify-content-between ' style={containerStyle} >
                                {item?.dayFrom === item?.dayTo ? <span style={nameStyle}>{item?.dayFrom}</span>
                                    : <span style={nameStyle}>{item?.dayFrom + ' - ' + item?.dayTo}</span>}
                                <span style={placeholderStyle}>{item?.timeFrom + ' to ' + item?.timeTo}</span>
                            </div>
                        </div>
                    </ListItem>
                ))}
            </List>
        </Box>}

    </div >
    )
}

export default WorkScheduleInput;
import React, { useState } from 'react';
import styles from './OTPInput.module.css'; // Assuming you have styles for the input

interface OTPInputProps {
    length: number; // Number of OTP digits
    onChange: (otp: string) => void; // Callback for when the OTP changes
}

const OTPInput: React.FC<OTPInputProps> = ({ length, onChange }) => {
    const [otp, setOtp] = useState<string[]>(Array(length).fill(''));

    const handleChange = (value: string, index: number) => {
        if (isNaN(Number(value))) return; // Allow only numeric input

        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);
        onChange(newOtp.join(''));

        // Move to the next input if there is a value and it's not the last input
        if (value && index < length - 1) {
            const nextInput = document.getElementById(`otp-input-${index + 1}`);
            nextInput?.focus();
        }
    };

    const handleBackspace = (index: number) => {
        if (otp[index] === '') {
            if (index > 0) {
                const prevInput = document.getElementById(`otp-input-${index - 1}`);
                prevInput?.focus();
            }
        }
    };

    return (
        <div className={styles.otpContainer}>
            {otp.map((digit, index) => (
                <input
                    key={index}
                    id={`otp-input-${index}`}
                    type="text"
                    inputMode="numeric"
                    maxLength={1}
                    value={digit}
                    onChange={(e) => handleChange(e.target.value, index)}
                    onKeyDown={(e) => {
                        if (e.key === 'Backspace') handleBackspace(index);
                    }}
                    className={styles.otpInput}
                />
            ))}
        </div>
    );
};

export default OTPInput;

import dayjs from 'dayjs';
import { DayDetails, DayScheduleArrayItem, DaysGroup } from "../types/types";


export const convertApiDataToDayjs = (apiData: any, daysOfWeek: { dayName: string }[]) => {
    const convertedValues = { ...apiData };

    daysOfWeek.forEach(day => {
        const dayValue = convertedValues[day.dayName];

        if (dayValue) {
            if (dayValue.fromTime) {
                dayValue.fromTime = dayjs(dayValue.fromTime);
            }
            if (dayValue.toTime) {
                dayValue.toTime = dayjs(dayValue.toTime);
            }
        }
    });

    return convertedValues;
};

function formatTime(timeString) {
    const date = new Date(timeString);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
}

export const toIsoString = (values) => {
    const scheduleArray: any = Object.entries(values)
        .filter(([_, details]: any) => details.status)
        .map(([dayName, details]) => {
            const dayDetails = details as DayDetails;

            const timeFrom = dayDetails.timeFrom?.toISOString();
            const timeTo = dayDetails.timeTo?.toISOString();

            return {
                dayName,
                status: dayDetails.status,
                timeFrom: timeFrom,
                timeTo: timeTo,
                disabled: dayDetails.disabled,
            };
        });
    return scheduleArray
}



export const dayJstoDateConverter = (values) => {
    const scheduleArray: DayScheduleArrayItem[] = Object.entries(values).map(
        ([dayName, details]) => {
            const dayDetails = details as DayDetails;

            const timeFrom = dayjs(dayDetails.timeFrom)
            const timeTo = dayjs(dayDetails.timeTo)

            return {
                dayName,
                status: dayDetails.status,
                timeFrom: timeFrom.toDate(),
                timeTo: timeTo.toDate(),
                disabled: dayDetails.disabled,
            };
        }
    );
    return scheduleArray
}


export const groupDaysByTime = (days: DayScheduleArrayItem[]) => {
    let result: DaysGroup[] = [];
    let currentGroup: DaysGroup | undefined;

    days.forEach((day, index) => {
        if (day.status) {
            const timeFrom = formatTime(day.timeFrom);
            const timeTo = formatTime(day.timeTo);

            if (!currentGroup) {
                currentGroup = {
                    dayFrom: day.dayName,
                    dayTo: day.dayName,
                    timeFrom: timeFrom,
                    timeTo: timeTo,
                    status: day.status
                };
            } else if (
                timeFrom === currentGroup.timeFrom &&
                timeTo === currentGroup.timeTo
            ) {
                currentGroup.dayTo = day.dayName;
            } else {
                result.push(currentGroup);
                currentGroup = {
                    dayFrom: day.dayName,
                    dayTo: day.dayName,
                    timeFrom: timeFrom,
                    timeTo: timeTo,
                    status: day.status
                };
            }
        }
        if (index === days.length - 1 && currentGroup) {
            result.push(currentGroup);
        }
    });
    return result;
}



export function formatDateToMonthDateYear(isoString: string): string {
    const date = new Date(isoString);

    // Define the type for options
    const options: Intl.DateTimeFormatOptions = { month: 'long', day: 'numeric', year: 'numeric' };

    // Format the date using toLocaleDateString with options
    return date.toLocaleDateString('en-US', options);
}

export function formatLevels(levels: any[]) {
    // Extract the numeric parts, sort them, and join them into the desired format
    const sortedLevels = levels
        .map(level => parseInt(level.split(' ')[1])) // Extract the number part
        .sort((a, b) => a - b); // Sort numerically

    return 'Level ' + sortedLevels.join(',');
}


export function getAuthMessages(invite?: string | null, userType?: string) {
    let title;
    let subheading;

    if (userType === 'admin') {
        title = invite ? 'Admin Setup' : 'Admin Log in';
        subheading = invite
            ? 'Welcome to the admin panel. Please use the provided credentials for setup.'
            : 'Welcome back!';
    } else {
        title = invite
            ? 'Setup your account'
            : `Log in to ${userType} account`;

        subheading = invite
            ? 'For first-time login, you must use the provided credentials'
            : "Don't have an account? Contact your administrator.";
    }

    return { title, subheading };
}




export function formatLevelsWithDetails(levels: any[]): string {
    function extractLevelNames(levels: Array<{ name: string, [key: string]: any }>): string[] {
        return levels.map(level => level.name);
    }

    return extractLevelNames(levels ? levels : []).join(', ');
}
import React, { useRef, useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";

interface RangedropdownProps {
  onDateChange: (dates: { start_date: string; end_date: string }) => void;
}

const Rangedropdown: React.FC<RangedropdownProps> = ({onDateChange}) => {
  const [title, setTitle] = useState<string>("Last 7 Days");
  const [open,setOpen] = useState<boolean>(false);
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const dropdownItems = [
    "Last 7 Days",
    "Last 14 Days",
    "Last 30 Days",
    "This Week",
    "Last Week",
    "This Month",
    "Last Month",
    "Custom Range",
  ];

  const handleSelect = (item: string) => {
    setTitle(item);
    let startDate: Date;
    let endDate: Date = new Date();

    const today = new Date();
    
    switch (item) {
      case "Last 7 Days":
        startDate = new Date(today);
        startDate.setDate(today.getDate() - 7);
        break;
      case "Last 14 Days":
        startDate = new Date(today);
        startDate.setDate(today.getDate() - 14);
        break;
      case "Last 30 Days":
        startDate = new Date(today);
        startDate.setDate(today.getDate() - 30);
        break;
      case "This Week":
        startDate = new Date(today);
        startDate.setDate(today.getDate() - today.getDay());
        break;
      case "Last Week":
        startDate = new Date(today);
        startDate.setDate(today.getDate() - (today.getDay() + 7));
        endDate.setDate(startDate.getDate() + 6);
        break;
      case "This Month":
        startDate = new Date(today.getFullYear(), today.getMonth(), 1);
        break;
      case "Last Month":
        startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        endDate = new Date(today.getFullYear(), today.getMonth(), 0);
        break;
      case "Custom Range":
        return; // Custom range handled in the date picker
      default:
        startDate = new Date(today);
        startDate.setDate(today.getDate() - 7);
        break;
    }

    // Emit the start and end dates in the required format
    onDateChange({
      start_date: startDate.toISOString(),
      end_date: endDate.toISOString(),
    });
  };


  const handleDateChange = (item: any) => {
    setState([item.selection]);
    setTitle(
      `From ${item.selection.startDate.toLocaleDateString()} To ${item.selection.endDate.toLocaleDateString()}`
    );
    onDateChange({
      start_date: item.selection.startDate.toISOString(),
      end_date: item.selection.endDate.toISOString(),
    });
  };

  return (
    <DropdownButton
      id="dropdown-basic-button"
      title={
        <div className="d-flex align-items-center gap-2">
          {title}
          {open ? <FaChevronUp /> : <FaChevronDown />}
        </div>
      }
      className="d-flex justify-content-end align-items-center daterange-dropdown"
      onClick={() => setOpen((prev) => !prev)}
    >
      <div className="d-flex flex-md-row flex-column">
        {open &&
          title === "Custom Range" && (
            <div className="date-range-picker w-100 p-4">
              <DateRangePicker
                onChange={handleDateChange}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={2}
                ranges={state}
                direction="horizontal"
                preventSnapRefocus={true}
                calendarFocus="backwards"
              />
            </div>
          )}
        <div className="menu w-100">
          {dropdownItems.map((item) => (
            <Dropdown.Item
              key={item}
              eventKey={item}
              onClick={() => handleSelect(item)}
            >
              {item}
            </Dropdown.Item>
          ))}
        </div>
      </div>
    </DropdownButton>
  );
};

export default Rangedropdown;

import { useState, useEffect, useMemo, memo } from "react";
import {
    Select,
    MenuItem,
    FormControl,
    Checkbox,
    ListItemText,
    SelectChangeEvent
} from '@mui/material';

interface UserDetailFieldProps {
    heading?: string;
    value?: string | string[];
    name?: string;
    editable?: boolean;
    inputType?: 'text' | 'email' | 'tel' | 'select' | 'multiselect';
    options?: any[];
    valueStyle?: React.CSSProperties;
    onChange?: (name: string, value: string | string[]) => void;
}

const UserDetailField: React.FC<UserDetailFieldProps> = memo(({
    heading,
    value = '',
    name = '',
    editable = false,
    inputType = 'text',
    options = [],
    valueStyle,
    onChange,
}) => {
    const [editableUser, setEditableUser] = useState<{ [key: string]: string | string[] }>({});

    const [levels, setLevels] = useState<string[]>([])

    useEffect(() => {
        if (name && value) {
            if (inputType === 'multiselect') {
                if (Array.isArray(value)) {
                    setLevels(value)
                }
            } else {
                setEditableUser((prevState) => ({
                    ...prevState,
                    [name]: value,
                }));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [name, value]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        if (name) {
            setEditableUser((prevState) => ({
                ...prevState,
                [name]: value,
            }));
            onChange?.(name, value);
        }
    };

    const handleSelectChange = (event: SelectChangeEvent<string | string[]>) => {
        if (name) {
            setEditableUser((prevState) => ({
                ...prevState,
                [name]: event.target.value as string | string[],
            }));
            onChange?.(name, event.target.value);
        }
    };

    // Memoize the renderValue function to optimize performance
    const renderSelectValue = useMemo(() => {
        return (selected: string | string[]) =>
            Array.isArray(selected)
                ? selected.map(id => options.find(opt => opt.id === id)?.name).join(', ')
                : options.find(opt => opt.id === selected)?.name || '';
    }, [options]);

    // Memoize the current value to avoid unnecessary re-renders
    const currentValue = useMemo(() => {
        return editableUser[name] || (inputType === 'multiselect' ? levels : '');
    }, [editableUser, name, inputType, levels]);

    return (
        <div style={{
            backgroundColor: editable ? '#edf2fa' : '#ffffff', marginTop: editable ? 10 : 0
            , display: 'flex',
            alignItems: 'center',
            height: '32px',

        }} className="my-3">
            <span style={{ backgroundColor: editable ? '#edf2fa' : '#ffffff', fontSize: '14px', fontWeight: 'bold' }} className="me-2">
                {heading ? heading : ''}
            </span>
            {editable ? (
                inputType === 'select' || inputType === 'multiselect' ? (
                    <FormControl style={{ marginLeft: '20px', flex: 0.5, }}>
                        {/* <InputLabel>{heading}</InputLabel> */}
                        <Select
                            name={name}
                            multiple={inputType === 'multiselect'}
                            value={currentValue}
                            onChange={handleSelectChange}
                            renderValue={renderSelectValue}
                            style={{ backgroundColor: '#edf2fa', height: '32px', }}
                        >
                            {options.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {inputType === 'multiselect' && (
                                        <Checkbox
                                            checked={Array.isArray(currentValue) && currentValue.includes(option.id)}
                                        />
                                    )}
                                    <ListItemText primary={option.name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                ) : (
                    <input
                        type={inputType}
                        name={name}
                        value={currentValue as string}
                        className="border-0"
                        onChange={handleInputChange}
                        style={{ marginLeft: '10px', backgroundColor: '#edf2fa', flex: 1 }}
                    />
                )
            ) : (
                <span style={{ fontSize: '14px', fontWeight: "normal", ...valueStyle }}>
                    {Array.isArray(value) ? value.join(', ') : value}
                </span>
            )}
        </div>
    );
});


export default UserDetailField
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SuccessPayload, FailedPayload } from "@/app/modules/types/types";
import { UploadAssignemntState } from "@/app/modules/types/sliceTypes";

const initialState: UploadAssignemntState = {
    data: null,
    status: null,
    loading: false,
    message: null,
    success: true,
    assignmentBriefData : null,
    assignmentMarkedData : null,
    learnerAssignmentSuccess: false
};

const uploadAssignmentSlice = createSlice({
    name: 'uploadAssignment',
    initialState,
    reducers: {
        setLoading: (state) => {
            state.success = true;
            state.loading = true;
        },
        createLearnerUploadAssignmentSuccess: (state, action: PayloadAction<SuccessPayload>) => {
            state.success = true;
            state.message = action.payload.message;
            state.loading = false;
            state.learnerAssignmentSuccess = true;
        },
        createLearnerUploadAssignmentFailed: (state, action: PayloadAction<FailedPayload>) => {
            state.success = false;
            state.message = action.payload.message;
            state.loading = false;
        },
        createLearnerUploadAssignment: (state) => {
            state.success = false;
            state.loading = true;
        },
        clearState: (state) =>{
            state.success = true;
            state.loading = false;
            state.assignmentBriefData=null;
            state.assignmentMarkedData=null;
        },
        fetchedPrebriefcheckData : (state,action) => {
            state.loading = false;
            state.success = true;
            state.assignmentBriefData = action.payload.data    
        },
        markedAssignment : (state,action) => {
            state.loading = false;
            state.success = true;
            state.assignmentMarkedData = action.payload.data;
        }
    },
});

export const {
    setLoading,
    createLearnerUploadAssignmentSuccess,
    createLearnerUploadAssignmentFailed,
    createLearnerUploadAssignment,
    fetchedPrebriefcheckData,
    markedAssignment,
    clearState
} = uploadAssignmentSlice.actions;

export default uploadAssignmentSlice.reducer;

import React from 'react';
import { BrowserRouter, Navigate, Route, Routes, } from 'react-router-dom';
import Login from '@screens/Login/Login';
import App from '../App';
import ErrorsPage from '@screens/Errors/ErrorsPage';
import { RootState } from '@/app/redux/store';
import { useSelector } from 'react-redux';
import PrivateRoutes from './PrivateRoutes';
import Logout from '@screens/Logout';
import Landing from '../screens/Landing';
import Error from '../screens/Error/Error';
import ForgetPassword from '../screens/ForgetPassword/ForgetPassword';
import VerifyOtp from '../screens/ForgetPassword/VerifyOtp';
import ForgetEnterPassword from '../screens/ForgetPassword/ForgetEnterPassword';


const AppRoutes: React.FC = () => {

    const { token, passwordProfileUpdateState, userType } = useSelector((state: RootState) => state.auth)

    return (
        <BrowserRouter>
            <Routes>
                <Route element={<App />}>
                    <Route path='error/*' element={<Error />} />
                    <Route path='logout' element={<Logout />} />
                    {
                        token && passwordProfileUpdateState === 'done' ? (
                            <>
                                <Route path="/*" element={<PrivateRoutes />} />
                                <Route index element={<Navigate to='/dashboard' />} />
                            </>
                        ) : (
                            <>
                                <Route path=":userType/login" element={<Login />} />
                                <Route path="/login" element={<Landing />} />
                                <Route path="*" element={<Navigate to={`/login`} />} />
                                <Route path='forget-password' element={<ForgetPassword />} />
                                <Route path='verifyOtp' element={<VerifyOtp />} />
                                <Route path='forgetEnterPassword' element={<ForgetEnterPassword />} />
                                {/* <Route path="*" element={<Navigate to={`/${userType || 'default'}/login`} />} /> */}
                            </>
                        )
                    }
                </Route>
            </Routes >
        </BrowserRouter >
    )
}

export default AppRoutes;

import React, { FC, useState } from 'react';
import { Row, Col, ListGroup } from 'react-bootstrap'; 
import COLORS from '@utils/Colors';
import { TbWorldUpload } from "react-icons/tb";

interface Props {
    onFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    name?: string;
    value: File[];
    maxFileSize?: number;
    required?: boolean;
    className?: string;
    readOnly?: boolean|false;
    text?: React.ReactNode;
    errorMsg?:string|null;
}

const FileUpload: FC<Props> = ({ onFileChange, value, name, className, maxFileSize = 5 * 1024 * 1024,readOnly, text,errorMsg }) => {
    const [error, setError] = useState<string | null>(null);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;

        if (files) {
            let totalSize = 0;
            for (let i = 0; i < files.length; i++) {
                totalSize += files[i].size;
            }
            if (totalSize > maxFileSize) {
                setError(`Total file size exceeds ${maxFileSize / (1024 * 1024)} MB.`);
                event.target.value = '';
            } else {
                setError(null);
                onFileChange(event);
            }
        }
    };

    return (
        <>
            <div style={{ padding: '20px', border: `2px dashed ${COLORS.BORDER.GRAY}`,display:`${!readOnly?"block":"none"}`, borderRadius: '.5rem' }} className={`${className}`}>
                <Row className="text-center justify-content-center align-items-center">
                    <Col xs={4} className='text-end'>
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{ marginBottom: '10px' }}
                        >
                            <g clipPath="url(#clip0_869_10330)">
                                <path
                                    d="M16 16L12 12L8 16"
                                    stroke="#1C1D22"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M12 12V21"
                                    stroke="#1C1D22"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M20.3895 18.3895C21.3648 17.8578 22.1353 17.0164 22.5793 15.9981C23.0234 14.9799 23.1157 13.8427 22.8417 12.7662C22.5677 11.6896 21.943 10.735 21.0661 10.0529C20.1893 9.37088 19.1103 9.00023 17.9995 8.9995H16.7395C16.4368 7.82874 15.8726 6.74183 15.0894 5.82049C14.3062 4.89915 13.3243 4.16735 12.2176 3.6801C11.1108 3.19286 9.90802 2.96285 8.69959 3.00738C7.49116 3.0519 6.30854 3.3698 5.24065 3.93716C4.17276 4.50453 3.24738 5.3066 2.53409 6.28308C1.8208 7.25956 1.33816 8.38503 1.12245 9.57489C0.906742 10.7647 0.963577 11.988 1.28869 13.1528C1.61379 14.3175 2.19871 15.3934 2.99947 16.2995"
                                    stroke="#1C1D22"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_869_10330">
                                    <rect width="24" height="24" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    </Col>
                    <Col xs={3}>
                        <div style={{ marginBottom: '10px' }}>{text}</div>
                    </Col>
                    <Col xs={5} className='text-start'>
                        <input
                            type="file"
                            onChange={handleFileChange} // Use the new handler for validation
                            style={{ display: 'none' }}
                            id={`file-upload-${name}`} // Unique ID per file upload
                            name={name}
                            multiple
                        />
                        <label
                            htmlFor={`file-upload-${name}`}
                            className="browse-label"
                            style={{
                                display: 'inline-block',
                                padding: '10px 20px',
                                border: `2px solid ${COLORS.PRIMARY}`,
                                borderRadius: '.5rem',
                                color: COLORS.PRIMARY,
                                cursor: 'pointer',
                                textAlign: 'center',
                                textDecoration: 'none',
                            }}
                        >
                            Browse
                        </label>
                        <span className="browse-icon"> <TbWorldUpload /></span>
                        {error && <div style={{ color: 'red', marginTop: '10px' }}>{error}</div>}
                    </Col>
                </Row>
            </div>
            <ListGroup style={{ marginTop: `${!readOnly?'10px':""}`, marginBottom: '10px'}}>
                {value.map((file) => (
                    <ListGroup.Item
                        key={file.name}
                        className="border border-success text-success"
                        style={{ borderRadius: '5px', margin: '5px 0' }}
                    >
                        {file.name}
                    </ListGroup.Item>
                ))}
                {errorMsg &&<ListGroup.Item
                    key={errorMsg}
                    className="border border-danger text-danger"
                    style={{ borderRadius: '5px', margin: '5px 0' }}
                    >
                    {errorMsg}
                    </ListGroup.Item>}
            </ListGroup>
        </>
    );
};

export default FileUpload;

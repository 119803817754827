import React from 'react';

interface ProgressBarProps {
  percentage?: number|0;
  label?:string;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ percentage, label }) => {
  return (
    <div style={styles.container}>
      <div style={styles.label}>{label}</div>
      <div style={styles.progressBarContainer}>
          <span style={styles.percentage}>{`${percentage}%`}</span>
        <div style={{ ...styles.progressBarFill, width: `${percentage}%` }}>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    width: '100%',
    padding: '10px',
  },
  label: {
    fontWeight: 'bold',
    marginBottom: '5px',
  },
  progressBarContainer: {
    position: 'relative' as 'relative',
    height: '8px',
    width: '100%',
    backgroundColor: '#e0e0de',
    borderRadius: '5px',
    marginTop:"30px"
  },
  progressBarFill: {
    height: '100%',
    backgroundColor: '#4e82f4',
    borderRadius: 'inherit',
    textAlign: 'right' as 'right',
    position: 'relative' as 'relative',
  },
  percentage: {
    position: 'absolute' as 'absolute',
    right: '10px',
    top: '-25px',
    fontSize: '16px',
    color: '#000',
    fontWeight:"bold"
  },
};

export default ProgressBar;

import React, { FC, SelectHTMLAttributes, ReactNode, useState } from 'react';
import COLORS from '@utils/Colors';

interface Props extends SelectHTMLAttributes<HTMLSelectElement> {
    leftIcon?: ReactNode;
    rightIcon?: ReactNode;
    placeholder: string;
    className?: string;
    error: string | null | undefined;
    label?: string;
    containerStyle?: React.CSSProperties;
    options: { value: string; label: string }[];
    readOnly?: boolean|false;
}

const Select: FC<Props> = ({
    containerStyle,
    label,
    error,
    leftIcon,
    rightIcon,
    placeholder,
    className,
    options,
    readOnly,
    ...props
}) => {
    const [focus, setFocus] = useState(false);

    const selectStyle: React.CSSProperties = {
        backgroundColor: focus ? COLORS.WHITE : COLORS.INPUT.BG,
        height: '54px',
        border: 'none',
        outline: 'none',
        boxShadow: 'none',
        padding: leftIcon ? '0px' : '16px',
        ...(props.disabled && {
            backgroundColor: COLORS.INPUT.DISABLED,
            cursor: 'not-allowed',
            opacity: 0.6,
        }),
    };

    const groupStyle: React.CSSProperties = {
        borderWidth: 1,
        borderColor: error ? COLORS.ERROR : COLORS.BORDER.GRAY,
        borderStyle: 'solid',
        borderRadius: '.5rem',
        display: 'flex',
        alignItems: 'center',
        ...(props.disabled && {
            borderColor: COLORS.BORDER.DISABLEDINPUT,
        }),
    };

    const iconStyle: React.CSSProperties = {
        backgroundColor: focus ? COLORS.WHITE : COLORS.INPUT.BG,
        border: 'none',
        cursor: 'pointer',
    };

    const errorStyle: React.CSSProperties = {
        color: COLORS.ERROR,
        fontSize: '14px',
        fontStyle: 'italic',
        lineHeight: '14px',
        marginTop: '5px'
    };

    const labelStyle: React.CSSProperties = {
        fontSize: '14px',
        color: COLORS.BLACK,
        lineHeight: '20px',
    };

    return (
        <div className={`${className}`} style={containerStyle}>
            {label && <h6 style={labelStyle}>{label}</h6>}
            <div style={groupStyle} className="input-group">
                {leftIcon && (
                    <span style={iconStyle} className="input-group-text">
                        {leftIcon}
                    </span>
                )}
                <select
                    data-testid={'select-field'}
                    style={selectStyle}
                    className="form-control"
                    onFocus={() => setFocus(true)}
                    onBlur={() => setFocus(false)}
                    {...props}
                    disabled={readOnly}
                >
                    <option value="" disabled>
                        {placeholder}
                    </option>
                    {options.map(option => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
                {rightIcon && (
                    <span style={iconStyle} className="input-group-text">
                        {rightIcon}
                    </span>
                )}
            </div>
            {error && <span style={errorStyle}>{error}</span>}
        </div>
    );
};

export default Select;

import React, { useEffect, useState } from "react";
import LearnerDashboard from "@components/LearnerDashboard";
import EducatorDashboard from "@components/EducatorDashboard";
import SupervisorDashboard from "@components/SupervisorDashboard";
import Layout from "@components/Layout";
import "./Dashboard.module.style.css";
import AdminDashboard from "@/app/components/AdminDashboard";
import { useSelector } from "react-redux";
import { RootState } from "@/app/redux/store";
import { useDispatch } from "react-redux";
import { FETCH_GRADES_DISTRIBUTION_DASHBOARD } from "@/app/redux/actionTypes";

interface Props { }

const Dashboard: React.FC<Props> = () => {
  const { userType, user } = useSelector((state: RootState) => state.auth);

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch({ type: FETCH_GRADES_DISTRIBUTION_DASHBOARD })
  }, [])


  // const handleDateSubmit = () => {
  //   if (startDate && endDate) {
  //     console.log("Selected Start Date:", startDate);
  //     console.log("Selected End Date:", endDate);

  //     const payload = {
  //       start_date: startDate?.toISOString(),
  //       end_date: endDate?.toISOString(),
  //     };

  //     dispatch({ type: FETCH_GRADES_DISTRIBUTION_DASHBOARD, payload })
  //   } else {
  //     console.log("Please select both a start and end date.");
  //   }
  // };

  return (
    <Layout>
      <div className="dashboard p-3 p-lg-4">
        <div className="Heading d-flex flex-column flex-md-row gap-2 gap-md-0 justify-content-between align-items-center">
          <div className="username">Good morning, {user.name}! </div>
          <div className="d-flex align-items-center gap-3">
            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Start Date"
                value={startDate}
                onChange={(newValue) => setStartDate(newValue)}
                slotProps={{
                  textField: {
                    fullWidth: false,
                    variant: "outlined",
                    size: "small",
                    style: { minWidth: 150 },
                  },
                }}
              />
              <DatePicker
                label="End Date"
                value={endDate}
                onChange={(newValue) => setEndDate(newValue)}
                slotProps={{
                  textField: {
                    fullWidth: false,
                    variant: "outlined",
                    size: "small",
                    style: { minWidth: 150 },
                  },
                }}
              />
            </LocalizationProvider>
            <Button
              label="Submit Dates"
              className="submit-dates-btn"
              onClick={handleDateSubmit}
              backgroundColor="#4CAF50"
            /> */}
            {/* <Button
              label={
                userType === "learner"
                  ? "Upload Assignment"
                  : userType === "educator"
                    ? "Grade assignment"
                    : "Create user"
              }
              className={"uploadbtn"}
              onClick={() => { }}
              backgroundColor="#5782FC"
            /> */}
          </div>
        </div>
        {userType === "learner" && <LearnerDashboard />}
        {userType === "educator" && <EducatorDashboard />}
        {userType === "supervisor" && <SupervisorDashboard startDate={startDate} endDate={endDate} />}
        {userType === "admin" && <AdminDashboard />}

        {/* <AssignmentsDetailNew labelText="Assignment Details" startDate={startDate} endDate={endDate} /> */}
      </div>
    </Layout>
  );
};

export default Dashboard;


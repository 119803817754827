// assignMarker.tsx
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import LearnersCard from './Cards/LearnersCard';
import { SearchIcon } from '@/app/assets/icons/svg';
import Input from '../Input';
import Markers from "../../assets/JSON/Markers.json";

interface AssignedMarkerProps {
    formdata: { markers: string[] };
    setAnyAssigned: (hasAssigned: boolean) => void;
}

const AssignedMarker = forwardRef((props: AssignedMarkerProps, ref) => {
    const cardData = Markers;

    const [assignedStatus, setAssignedStatus] = useState(
        cardData.reduce((acc, card) => {
            acc[card.id] = card.isAssigned; 
            return acc;
        }, {})
    );

    const handleAssignClick = (id: string) => {
        setAssignedStatus((prevStatus) => {
            const newStatus = !prevStatus[id];
            props.formdata.markers = newStatus
                ? [...props.formdata.markers, id] // Add marker ID if assigned
                : props.formdata.markers.filter(markerId => markerId !== id); // Remove marker ID if unassigned

            return {
                ...prevStatus,
                [id]: newStatus,
            };
        });
    };

    const handleUnassignAction = () => {
        setAssignedStatus(Object.keys(assignedStatus).reduce((acc, id) => {
            acc[id] = false;
            return acc;
        }, {}));
        // Clear the markers in formdata
        props.formdata.markers = [];
    };

    useEffect(() => {
        const hasAssigned = Object.values(assignedStatus).some(status => status);
        props.setAnyAssigned(hasAssigned);
    }, [assignedStatus, props]);

    const [searchQuery, setSearchQuery] = useState('');

    const filteredData = cardData.filter(card => 
        card.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    useImperativeHandle(ref, () => ({
        handleUnassignAction,
    }));

    return (
        <>
            <div className="container">
                <Input 
                    placeholder={'Search by name'} 
                    error={undefined}
                    leftIcon={<img src={SearchIcon} alt="Search" />}
                    className='mb-4 cstm-search'
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
                <div className="row">
                    {filteredData.map(card => (
                        <div className="col-md-6" key={card.id}>
                            <LearnersCard
                                id={card.id}
                                name={card.name}
                                level={card.level.join(', ')}
                                joinedTime={card.joinedTime}
                                assigned={card.assigned}
                                completed={card.completed}
                                avatar={card.avatar}
                                isAssigned={assignedStatus[card.id]}
                                handleAssignClick={() => handleAssignClick(card.id)}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
});

export default AssignedMarker;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FETCH_USERS } from '@/app/redux/actionTypes';
import { RootState } from '@/app/redux/store';
import PaginatedTable from '@components/Table/PaginatedTable';
import Loader from '@/app/components/Loading';
import { debounce } from 'lodash';
import { User } from '@/app/modules/types/types';
import { formatDateToMonthDateYear, formatLevels } from '@/app/modules/helpers/FormikHelper';
import { toast } from 'react-toastify';
import TableActionDropdown from '@/app/components/Table/TableAction';

interface SupervisorTableProps {
    selectedSort: string
}
const SupervisorTable: React.FC<SupervisorTableProps> = ({ selectedSort }) => {

    const dispatch = useDispatch();

    const { users, pagination, loading, error } = useSelector((state: RootState) => state.user);

    const fetchData = async (page: number, rows: number, sortBy: string = "", query: string = '') => {
        dispatch({ type: FETCH_USERS, payload: { page, limit: rows, search: query, userType: 'supervisor', sortBy, } });
    };

    useEffect(() => {
        fetchData(1, 10);
    }, []);

    useEffect(() => {
        fetchData(1, 10, selectedSort);
    }, [selectedSort]);

    const columns = [
        { header: 'Name', accessorKey: 'name' },
        { header: "Email", accessorKey: "email" },
        { header: 'Joining Date', cell: ({ row }) => <span>{formatDateToMonthDateYear(row.original.enrolmentDate)}</span> },
        {
            header: 'Actions', cell: () => (
                <TableActionDropdown
                    actions={[
                        { label: 'Action', onClick: () => console.log('Action clicked') },
                        { label: 'Another action', onClick: () => console.log('Another action clicked') },
                    ]}
                />
            )
        },
    ];

    const handleSearchChange = debounce((event: React.ChangeEvent<HTMLInputElement>) => {
        fetchData(1, 10, event.target.value);
    }, 500);

    useEffect(() => {
        if (error) {
            toast.error(error);
        }
    }, [error]);

    return (
        <>
            {loading && <Loader />}
            {users && pagination && <PaginatedTable<User>
                columns={columns}
                data={users}
                pageCount={pagination?.totalPages}
                fetchData={fetchData}
                handleSearchChange={handleSearchChange}
            />}
        </>
    );
};

export default SupervisorTable;
